import React, {useState, useEffect} from 'react';
import {NotificacaoListStyle, NotificacaoStyle} from './style';

export function NotificacaoLista({lista}) {
    const [notificacaoLista, setNotificacaoLista] = useState(['sasad', 'ásdsad']);
    const [isNotificationVisible, setIsNotificationVisible] = useState([]);

    useEffect(()=>{
        if(lista){
            setNotificacaoLista(lista);
            lista.map(notificacao => {
                setIsNotificationVisible([...isNotificationVisible, true]);
                return notificacao;
            })
        }
    }, [isNotificationVisible, lista]);
    useEffect(()=>{
        setNotificacaoLista(lista);
    }, [lista]);

    function setIsVisible(isVisible, index){
        const newIsVisbleList = [...isNotificationVisible];
        newIsVisbleList[index] = isVisible;

        setIsNotificationVisible([...newIsVisbleList]);
    }
  return (
   <NotificacaoListStyle>
       {
           notificacaoLista.map((notificacao, index) =>(
            <NotificacaoStyle key={index}>
                <div className="header">
                    <div className="left-header"> 
                        <div className="arrow-icon" onClick={() => setIsVisible(!isNotificationVisible[index], index)} >
                            <ion-icon  className="chevron-icon"
                            name={isNotificationVisible[index]? "chevron-up-outline": "chevron-down-outline"}></ion-icon>
                        </div>

        
                        <div className="notification-date">
                            <strong>Data: </strong> <span>{notificacao.dataInclusao}</span>
                        </div> 

                    </div>

                    <div className="assunto"> 
                        <div>
                            <span>assunto: </span> <strong>{notificacao.assunto}</strong>
                        </div>
                        {
                            isNotificationVisible[index]? ( <span className="eye-icon" onClick={() => setIsVisible(!isNotificationVisible[index], index)} > <ion-icon  name="eye-off-outline" ></ion-icon> </span>):
                                ( <span className="eye-icon" onClick={() => setIsVisible(!isNotificationVisible[index], index)} > <ion-icon  name="eye-outline"></ion-icon> </span>)
                        }
                    </div>
                </div>

                    
                
                {
                    isNotificationVisible[index]? (
                        <>
                           
                            <div className="conteudo"> 
                                <p>
                                    {notificacao.texto}
                                </p>
                            </div> 
                        </>
                        ):""
                } 

                    
                
                    
            </NotificacaoStyle>
           ))
       }
      
   </NotificacaoListStyle>
  );
}

