import api from './Api';
import AutenticacaoService from './AutenticacaoService';


export default class AssinaturaService{
    put(uuid, body, callback){
        api
        .put(this.host() + '/api/v1/preferencias/' + uuid, body)
        .then((response)=>{
            this.setAssinaturaSelecionada(response.data);
            callback(null, response.data);
        })
        .catch((err)=>{
            callback(err, null);
        })
    }
    getAssinaturaSelecionada() {

        const assinaturaSelecionada = localStorage.getItem('assinatura-selecionada');

        if(typeof assinaturaSelecionada === "string") {
            return JSON.parse(assinaturaSelecionada);
        } else {
            return null;
        }
    }
    setAssinaturaSelecionada(perfis){
        localStorage.setItem('assinatura-selecionada', JSON.stringify(perfis))
    }
    getEnderecoAssinatura() {

        const endereco = localStorage.getItem('assinatura-endereco');

        
        return JSON.parse(endereco);
        
    }
    setEnderecoAssinatura(endereco){
        localStorage.setItem('assinatura-endereco', JSON.stringify(endereco))
    }
    loadUsuarioAssinatura(callback){
        const autenticacaoService = new AutenticacaoService();

        const uuid = autenticacaoService?.usuarioCorrente()?.identificadorAssinatura;
        if(!uuid){
            return callback(true, null);
        }   
        api
        .get(this.host() + '/api/v1/assinatura/' + uuid, {
        })
        .then((response)=>{
            this.setAssinaturaSelecionada(response.data);
            callback(null, response.data);
        })
        .catch((err)=>{
            callback(err, null);
        })
    }
    loadAssinaturaSelecinadaHome(callback){
        api
        .get(this.host() + '/api/v1/assinatura/home')
        .then((response)=>{
            callback(null, response.data);
        })
        .catch((err)=>{
            callback(err, null);
        })
    }
    solicitarAumentoFrete(callback){
        api
        .post(this.host() + '/api/v1/assinatura/aumentoFrete')
        .then((response)=>{
            callback(null, response.data);
        })
        .catch((err)=>{
            callback(err, null);
        })
    }
    atualizarSaldo(request, callback){
        api
        .put(this.host() + "/api/v1/assinatura/atualizacaoSaldo", request)
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }
    host(){
        return process.env.REACT_APP_HOST_API
    }
}