import styled from 'styled-components';

export const CalculadoraLogadoFormStyle = styled.form`
  
    background-color: #fff;
    width: 80%;
    border-radius: 10px;
    padding: 30px;
    .input{
      color: #202020;
    }
    .form-title{
        font-size: 3rem;
        text-align: center;
        color: #340040;
        margin-bottom: 25px;
    }
    .cotacoes-ultimas{
        margin-bottom: 15px;
        select{
            color: rgba(136,48,152,1);
            font-weight: bold;
            font-size: 1.4rem;
            width: 100%;
        }        

        div {
            width: 95%;
        }
    }
    .cotacao-planilha{
        margin: 20px 0;
        display:flex;
        align-items: center;
        justify-content: center;
        a{
            color: rgba(136,48,152,1);
            font-weight: bold;
            font-size: 1.2rem;
        }
        color: rgba(136,48,152,1);
    }
    .volumes{
        margin-bottom: 40px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .volume{
            border-bottom: 1px solid #ddd;
            width: 90%;
            max-width: 300px;
            padding-bottom: 5px;
            margin-bottom: 10px;
            display: flex; 
            align-items: center;
            justify-content: space-between;
            
            div{
                display: flex;
                align-items: center;
            }
            .show-volume-area{
                width: 100%;
                justify-content: space-between;
                margin-right: 30px;
                cursor: pointer;
            }
           
            ion-icon{
                color: rgba(136,48,152,1);
            }
            ion-icon:first-child{
                margin-right: 15px;
                font-size: 26px;
            }
        }
    }
    .ceps{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        margin-bottom: 20px;
        div{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
    }
    .logistica-reversa, .aviso-recebimento, .adicionar-volume{
        display: flex;
        justify-content: flex-start;  
        align-items: center;  
        label{
            margin-right: 10px;
            align-items: center;
        }
        margin-bottom: 20px;
        input{
            margin-left: 10px;
        }
    }
    .detalhes{
        margin-bottom: 20px;
        .line{
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
        }
       
        .inputs-detalhes{
            >div{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                text-align: left;
            }
        }
        .select, select{
            width: 100%;
        }
            
    }
    .dimensoes{
        flex-direction: row !important;
       
    }
    .dimensao{
        display: flex;
        align-items: center;
        .dimensoes-label-aux{
            position: relative;
            left: -30px;
            font-size: 1.2rem;
        }
    }
    .aviso-recebimento{
        margin-bottom: 20px;
    }
    .adicionar-volume{
        background: none;
        color: rgba(136,48,152,1);
        cursor: pointer;
        border: none;
        width: fit-content;
        margin-bottom: 10px;
    }
    .volume-selecionado{
        color: rgba(136,48,152,1);
        text-align: left;
        margin-bottom: 20px;
        font-weight: bold;
    }
    .actions{
        margin-top: 20px;
        .button{
            width: 150px;
            height: 40px;
            cursor: pointer;
            font-weight: bolder;
            margin: 10px
        }
        .button-submit{
            background: rgb(136,48,152);
            background: linear-gradient(30deg, rgba(136,48,152,1) 39%, rgba(163,71,179,1) 100%);
            color: #fff;
        }
        .button-submit:hover{
            background: rgba(136,48,152, 0.7);
        }
        .button-link{
              background-color: rgba(255, 255, 255, 1);
        }
        .button-link:hoover{
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
    .inputs-detalhes{
        >div{
            margin-bottom: 20px;
        }
    }
    .diametro{
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 10px;
        div{
            width: 100%;
        }
    }
    .error-request{
        margin-top: 20px;
    }

    .pesoGrow{
        flex-grow: 1;
    }
  @media(max-width: 1400px){
    width: 88%;
    .padding{
      padding: 10px 15px!important;
    }
  }
    @media(max-width: 600px){
        width: 85%;
        .button-submit{
            margin-right: 0!important;
        }
      
        .padding{
          padding: 10px 15px;
        }
        
        .detalhes{
            margin-bottom: 20px;
            .line{
                display: grid;
                grid-template-columns: 1fr;
                column-gap: 20px;
                div{
                    width: 100%;
                }
            }
        }
        .dimensoes{
            div{
                text-align: left;
            }
        }
        .diametro{
            max-width: 100%;
        }
        .cotacao-info{
            font-size: 1.2em;
        }
    }
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }

    

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
`;
