import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Input from "../../components/FlexFreteInput/Input";
import Modal from '../../components/FlexFreteModal/Modal';
import FlexSpinner from "../../components/FlexFreteSpinner/FlexSpinner";
import GeografiaService from "../../services/GeografiaService";
import UsuarioService from '../../services/UsuarioService';
import ValidaCPF from "../../utils/ValidaCPF";
import validarEmail from '../../utils/ValidaEmail';
import validarTelefone from '../../utils/validarTelefone';
import ValidaSenha from "../../utils/ValidaSenha";
import { ActionCreators } from '../../_actions/ActionCreators';
import {
  BtnLoginRegisterChangePasswordStyle, FormRegisterContainerStyle, RegisterStyle
} from "./styleRegister";

import DocumentosService from '../../services/DocumentosService';
import DownloadService from '../../services/DownloadService';
import TagueamentoService from '../../services/TagueamentoService';

function Register(props) {
  const docservice = new DocumentosService();
  const downloadService = new DownloadService();
  const tagueamentoService = new TagueamentoService();
  const dispatch = useDispatch();
  const geografiaService = new GeografiaService();
  const validacaoDo = new ValidaCPF();
  const validacaoDa = new ValidaSenha();
  const [hiddenPassword1, setHiddenPassword1] = useState(true)
  const [hiddenPassword2, setHiddenPassword2] = useState(true)
  const [semNumero, setSemNumero] = useState(false)
  const numero = useRef();
  const [city, setCity] = useState(false)
	const [request, setRequest] = useState({
		nome: null,
		sobrenome: null,
		cpf: null,
		password1: null,
    password2: null,
		telefone: null,
		email1: null,
    email2: null,
    logradouro: null,
    numero: "",
    cep: null,
    bairro: null,
    cidade: null,
    complemento: null,
	});

  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [disabledEndereco, setDisabledEndereco] = useState({
    enderecoBairro: false,
    estadoMunicipio: false
  });
  const [cepEndereco, setCepEndereco] = useState({
    cep: "",
    bairro: "",
    nomeCidade: "",
    cidade: "",
    logradouro: "",
    nomeUf: "",
    siglaUf: "",
    numero: "",
    complemento: "",
  });

  const [logradouro, setLogradouro] = useState();
  const [bairro, setBairro] = useState();
  const [aceite, setAceite] = useState(false);
  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });
  const [telefoneMask, setTelefoneMask] =  useState("(99)99999-9999");
  const [processando, setProcessando] = useState(false);
  const [enviandoInformacoes, setEnviandoInformacoes] = useState(false);
  const history = useHistory();

  if(!processando){
    setProcessando(true);

    geografiaService.obtemEstados((erro, sucesso) => {
      if(erro){
        setErro({
          isWrong: true,
          mensagem: 'Falha ao obter estados, entre em contato com o suporte ou tente novamente mais tarde'
        })
        return;
      }
      if(sucesso){
        setEstados(sucesso);
      }
    })
  }

  useEffect(() => {
    document.getElementById("title").scrollIntoView()
    return () => {
      setRequest({})
      setCepEndereco({})
      setCidades([])
      setEstados([])
    }
  }, [])

  const getCidades = (event) => {
    let city;

    if(event.target){
      const { value } = event.target
      city = value
    } else {
      city = event
    }
    geografiaService.obtemMunicipios(city, (erro, sucesso) => {
      if(erro) {
        setErro({
          isWrong: true,
          mensagem: 'Falha ao obter municipios, entre em contato com o suporte ou tente novamente mais tarde'
        })
        return;
      }
      if(sucesso) {
        setCidades(sucesso)
      }
    })
  }

  const getCEP = (cep) => {
    geografiaService.buscaPorCEP(cep, (erro, sucesso) => {
      if(erro){
        setCepEndereco({
          bairro: "",
          nomeCidade: "",
          cidade: "",
          logradouro: "",
          nomeUf: "",
          siglaUf: ""
        })
        
        setDisabledEndereco({
          enderecoBairro: false,
          estadoMunicipio: false
        });
        setErro({
          isWrong: true,
          mensagem: 'CEP não localizado. Insira manualmente informações do endereço.'
        })
        
        return;
      }
      if(sucesso){
        setCepEndereco(sucesso)
        setCepEndereco({
          cep: sucesso.cep,
          bairro: sucesso.bairro,
          nomeCidade: sucesso.nomeCidade,
          cidade: sucesso.cidade,
          logradouro: sucesso.logradouro,
          nomeUf: sucesso.nomeUf,
          siglaUf: sucesso.siglaUf
        });
        setBairro(sucesso.bairro)
        setLogradouro(sucesso.logradouro)
        setCity(sucesso.cidade)
        setDisabledEndereco({
          enderecoBairro: sucesso.logradouro.trim() && sucesso.bairro.trim() !== null ? true : false,
          estadoMunicipio: true
        });
      }
      getCidades(sucesso.siglaUf)
    })
  }

	const onChange = (event) => {
    const { name, value } = event.target
    
    if(event._reactName === 'onFocus' && event.target.name === 'cpf'){
      event.target.setCustomValidity("Preencha este campo.");
      return
    } else {
      event.target.setCustomValidity("");
    }

    if(event._reactName === 'onFocus' && event.target.name === 'telefone'){
      event.target.setCustomValidity("Preencha este campo.");
      return
    } else {
      event.target.setCustomValidity("");
    }

		let req = request
    req[name] = value
    setRequest(req)
	};

  const onChangeSemNumero = (event) => {
    setSemNumero(event.target.checked)
    if(event.target.checked){
      numero.current.value = "S/N"
    } else {
      numero.current.value = ""
    }
  }

  const onChangeEndereco = (event) => {
    const { name, value } = event.target

    if(event._reactName === 'onFocus' && event.target.name === 'cep'){
      event.target.setCustomValidity("Preencha este campo.");
    } else {
      event.target.setCustomValidity("");
    }

		let req = request
    req[name] = value
    setCepEndereco(req)
	};

  const onChangeEnderecoLogradouro = (event) => {

    console.log(event.target.value)
    
    setLogradouro(event.target.value)
	};

  const onChangeEnderecoBairro = (event) => {
    
    setBairro(event.target.value)
	};
  
  const onChangeAceite = (event) => {
    setAceite(event.target.checked)
  };

  const onBlur = (event) =>{
    let cep = event.target.value;
    if(!cep.length){
      return
    }
    let formatedcep = cep.replace(/[^0-9]/g, "")
    return getCEP(formatedcep);

  };

  const onBlurVerificaSenha = (event) => {
    let password = event.target.value;
    if(!password) {
      return
    }
    const resultSenha = validacaoDa.senha(password);
    if(!resultSenha) {
      setErro({
        isWrong: true,
        mensagem: "A senha deve ter no mínimo 8 caracteres, possuir letras(maiúscula e minúsculas), números e caracteres especiais."
      })
      return
    }
  }

  const submitInscricao = (event) => {
    event.preventDefault();
    setProcessando(true)
    setEnviandoInformacoes(true)

    let formatedTel = request.telefone.replace(/[^0-9]/g, "")
    const resultTel = validarTelefone(formatedTel);
    if(!resultTel){
      setErro({
        isWrong: true,
        mensagem: "Telefone inválido."
      })
      setProcessando(false)
      setEnviandoInformacoes(false)
      return
    }

    if(request.email1 !== request.email2) {
      setErro({
        isWrong: true,
        mensagem: "Os E-mails digitados não conferem."
      })
      setProcessando(false)
      setEnviandoInformacoes(false)
      return
    }

    const resultEmail = validarEmail(request.email1);
    if(!resultEmail){
      setErro({
        isWrong: true,
        mensagem: "E-mail inválido."
      })
      setProcessando(false)
      setEnviandoInformacoes(false)
      return
    }

    if(request.password1 !== request.password2) {
      setErro({
        isWrong: true,
        mensagem: "As senhas digitadas não conferem."
      })
      setProcessando(false)
      setEnviandoInformacoes(false)
      return
    }

    if(request.cpf.length < 14 || request.cpf.length > 14 ){
      setErro({
        isWrong: true,
        mensagem: "CPF menor ou maior que o padrão"
      })
      setProcessando(false)
      setEnviandoInformacoes(false)
      return
    }

    let formatedcpf = request.cpf.replace(/[^0-9]/g, "")
    const resultCpf = validacaoDo.cpf(formatedcpf);
    if(!resultCpf){
      setErro({
        isWrong: true,
        mensagem: "CPF inválido."
      })
      setProcessando(false)
      setEnviandoInformacoes(false)
      return
    }

    if(request.cep.length < 9 || request.cep.length > 9 ){
      setErro({
        isWrong: true,
        mensagem: "CEP inválido."
      })
      setProcessando(false)
      setEnviandoInformacoes(false)
      return
    }

    if(!aceite) {
      setErro({
        isWrong: true,
        mensagem: "Para usar os serviços da FlexFrete é necessário aceitar os termos e condições."
      })
      setProcessando(false)
      setEnviandoInformacoes(false)
      return
    }

    const usuarioService = new UsuarioService();

    const usuarioRequest = {
      nome: request.nome,
      sobrenome: request.sobrenome,
      cpf: formatedcpf,
      endereco: {
        numero: !request.numero || semNumero === true ? "S/N" : request.numero,
        complemento: request.complemento,
        cep: cepEndereco.cep,
        logradouro: logradouro ? logradouro : cepEndereco.logradouro,
        bairro: bairro ? bairro : cepEndereco.bairro,
        cidade: !cepEndereco.cidade ? city : cepEndereco.cidade,
      },
      telefone: formatedTel,
      email: request.email1,
      senha: request.password1,
    }

      usuarioService.inscrever(usuarioRequest, (erro, sucesso)=>{
        if(erro){
          setProcessando(false)
          setEnviandoInformacoes(false)
          if (erro.response && erro.response.data && erro.response.data.message) {
            setErro({
              isWrong: true,
              titulo: 'Erro na inscrição',
              mensagem: erro.response.data.message
            })
          } else {
            setErro({
              isWrong: true,
              titulo: 'Erro na inscrição',
              mensagem: 'Falha ao tentar se registrar, entre em contato com o suporte ou tente novamente mais tarde'
            })
          }
          return
        } 

        if(sucesso) {
          setProcessando(false)
          setEnviandoInformacoes(false)
          tagueamentoService.eventoMarketingCadastro()
          if (sucesso) {
            dispatch(ActionCreators.register(sucesso))
            history.push("/login")
        } else {
          setErro({
            isWrong: true,
            titulo: 'Erro na inscrição',
            mensagem: 'Falha ao tentar se registrar, entre em contato com o suporte ou tente novamente mais tarde'
          })
        }
        }
      })
  }

  const handlePassword1Visibility = () =>{
    setHiddenPassword1(!hiddenPassword1)
  }

  const handlePassword2Visibility = () =>{
    setHiddenPassword2(!hiddenPassword2)
  }

  const callbackModal = () =>{
    if(erro.isWrong === true){
      setErro({isWrong: false})
    }
  }
  const onBlurTelefone = (e) => {
    const {value} = e.target
    if(value[value.length - 1] === "_"){
      setTelefoneMask("(99)9999-9999");
    }else{
      setTelefoneMask("(99)99999-9999")
    }
  }
  const onFocusTelefone = (e) =>{
     setTelefoneMask("(99)99999-9999");
  }

  const termosUso = ()=>{
    
    docservice.gerarTermosUso((err, sucess)=>{
      if(err){
          setErro({
              isWrong: true,
              titulo: 'Erro ao baixar arquivo',
              mensagem: 'Falha ao tentar realizar o download, entre em contato com o suporte ou tente novamente mais tarde.'
            })
      }else{
         downloadService.downloadFile({label: "termos de uso", data: sucess, filename: "Termos_de_uso.pdf", extension: "pdf"})
         setErro({
          isWrong: false,
           mensagem: 'Download realizado com sucesso!'
         })
      }
  });
  } 

	return (
    <>
    {
      enviandoInformacoes === true ?
      (
        <FlexSpinner />
      ) : null
    }
      {
        erro.isWrong === true ?
        (
          <Modal callbackModal={callbackModal} visivel={erro !== null} titulo={erro.titulo} conteudo={erro.mensagem} />
        ) : null
      }
    <div id="title">
		<RegisterStyle>
      
      
			<h1 className="title"  >Crie sua conta</h1>
			<h3>
				Já sou cadastrado.{" "}
				<strong>
					<Link to="/login">Fazer Login</Link>
				</strong>
			</h3>
			<FormRegisterContainerStyle>
				<form onSubmit={submitInscricao}>

        <Input type="text" name="nome" label="Nome*" required={true} onChange={onChange}/>
				<Input type="text" name="sobrenome" label="Sobrenome*" required={true} onChange={onChange}/>
        <Input type="text" mascara="999.999.999-99" name="cpf" label="CPF*" maxLength="14" required={true} onChange={onChange}/>
        <div>
            <label className="label">Senha*</label>
            <div className="field has-addons">
              <div className="control input-enfase">
                  <input className="input" type={hiddenPassword1 === true ? "password" : "text"}
                  name="password1" required={true} onChange={onChange} onBlur={onBlurVerificaSenha}
                  autoComplete="new-password"
                  />
                  <p className="help">A senha deve ter no mínimo 8 caracteres, possuir letras(maiúscula e minúsculas), números e caracteres especiais.</p>
              </div>
              <div className="control">
                  <span className="button is-large" onClick={handlePassword1Visibility}>
                  {
                    hiddenPassword1 === true ?
                      (<ion-icon name="eye-off-outline"></ion-icon>) : (<ion-icon name="eye-outline"></ion-icon>)
                  }
                  </span>
              </div>
			      </div>
          </div>
           
            <div>
            <label className="label">Confirme sua senha*</label>
            <div className="field has-addons">
              <div className="control input-enfase">
                  <input className="input" type={hiddenPassword2 === true ? "password" : "text"}
                  name="password2" required={true} onChange={onChange} onBlur={onBlurVerificaSenha}
                  autoComplete="new-password"
                  />
                  <p className="help">A senha deve ter no mínimo 8 caracteres, possuir letras(maiúscula e minúsculas), números e caracteres especiais.</p>
              </div>
              <div className="control">
                  <span className="button is-large" onClick={handlePassword2Visibility}>
                  {
                    hiddenPassword2 === true ?
                      (<ion-icon name="eye-off-outline"></ion-icon>) : (<ion-icon name="eye-outline"></ion-icon>)
                  }
                  </span>
              </div>
			      </div>
            </div>


            <hr />

          <Input type="text" mascara="99999-999" name="cep" label="CEP*" defaultValue={cepEndereco.cep} required={true} onChange={onChangeEndereco} onBlur={onBlur}/>
          <Input type="text" name="logradouro" label="Endereço*" value={logradouro} disabled={disabledEndereco.enderecoBairro === true ? true : false} required={true} onChange={onChangeEnderecoLogradouro}/>

            <div className="section-half">
                <div>
                  <div className="field">
                  <label className="label">Número*</label>
                  <div className="control">
                      <input className="input" type="text" name="numero" ref={numero} disabled={semNumero === true ? true : false} required={semNumero === true ? false : true} onChange={onChange}/>	
                  </div>
                </div>
                <small style={{textAlign: 'left'}}>
                  <div className="field">
                    <div className="control">
                    <label style={{fontWeight: '700', color: '#282828'}}>
                      <input type="checkbox" name="aceito" onChange={onChangeSemNumero}/> Sem número
                    </label>
                    </div>
                  </div>
                </small>
            </div>
                <Input type="text" name="complemento" label="Complemento" onChange={onChange}/>
          </div>

          <Input type="text" name="bairro" label="Bairro*" value={bairro} disabled={disabledEndereco.enderecoBairro === true ? true : false} required={true} onChange={onChangeEnderecoBairro}/>

  
          <div style={{marginBottom: '1.5em'}}>
            <label className="label">Cidade*</label>
            <div className="select" style={{width: "100%"}}>
              <select value={cepEndereco.cidade ? cepEndereco.cidade : undefined} name="cidade" style={{width: "100%"}} disabled={disabledEndereco.estadoMunicipio === true ? true : false} onChange={onChangeEndereco}>
                <option value=""></option>
                {
                  cidades.map((cidade, index) => {
                    return (
                      <>
                      <option key={cidade.nome + index}
                              value={cidade.uuid}
                        >
                                
                        {cidade.nome}

                      </option>
                      </> 
                    )
                  })
                }
              </select>
            </div>
          </div>

            <div style={{marginBottom: '1.5em'}}>
                <label className="label">Estado*</label>
                <div className="select" style={{width: "100%"}}>
                  <select value={cepEndereco.siglaUf ? cepEndereco.siglaUf : undefined} name="estado" style={{width: "100%"}} disabled={disabledEndereco.estadoMunicipio === true ? true : false} onChange={getCidades}>
                    <option value=""></option>
                      {
                        estados.map((estado, index) => {
                          return (
                            <>
                            <option key={estado.nome + index}
                                    value={estado.nome}
                            >

                              {estado.nomeExtenso} - {estado.nome}

                            </option>
                            </> 
                          )
                        })
                      }
                  </select>
              </div>
            </div>
        

          <hr />

          <Input type="tel" mascara={telefoneMask} name="telefone" label="Telefone*" required={true} onChange={onChange}  onFocus={onFocusTelefone} onBlur={onBlurTelefone}/>
					<Input type="email" name="email1" label="E-mail*" required={true} onChange={onChange}/>   
					<Input type="email" name="email2" label="Confirmar E-mail*" required={true} onChange={onChange}/>

          <label className="label-campo-obrigatorio">
            * Campo obrigatório
          </label>
        
          <div className="field radio-check-options">
            <div className="control">
            <label className="checkbox">
              <input type="checkbox" name="aceito" required={true} onChange={onChangeAceite}/>  
            </label>
            <label className="termos-button" onClick={termosUso}>Aceitar <span style={{textDecoration: 'underline'}}>Termos de uso</span></label>           
            </div>
          </div>

					<BtnLoginRegisterChangePasswordStyle aceite={aceite} className="button">
						Cadastrar
					</BtnLoginRegisterChangePasswordStyle>
				</form>


			</FormRegisterContainerStyle>
		</RegisterStyle>
    </div>
    </>
	);
}

export default Register;
