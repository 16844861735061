import styled from "styled-components";

export const BtnPerfilStyle = styled.button`
	margin-top: 5px;
	margin-bottom: 5px;
	background: rgb(247, 247, 247);
	background: linear-gradient(
		94deg,
		rgba(247, 247, 247, 1) 39%,
		rgba(255, 255, 255, 1) 100%
	);
	border: none;
	color: #9339a2;
	padding: 10px;
	font-weight: 600;
	font-size: 0.9em;
	letter-spacing: 0.3px;
	-webkit-box-shadow: 9px 7px 9px -5px rgb(97 96 96 / 32%);
    box-shadow: 9px 7px 9px -5px rgb(97 96 96 / 32%);
`;
