import React, {useState} from 'react';
import { moneyMask } from '../../utils/Masks';

function TransacaoItem(props) {
    const [toggle, setToggle] = useState(false);
    const handleVisibility = () =>{
        setToggle(!toggle)
    }
  
  return (
    <>
        <div className="column">
        <div className="resultadoBusca">
            <header>
                <p>Data: <span>{props.dataCriacao}</span></p>
                <p>ID da transação: <span>{props.uuid}</span></p>
                <p>Status: <span>{props.status}</span></p>
                {
                    props.tipoOperacao === "SAIDA" ?
                    (
                        <p>Saldo: <span className="saldo-saida">- {moneyMask(props.valor.toFixed(2)) || ""}</span></p>
                    ) :
                    (
                        <p>Saldo: <span className="saldo">+ {moneyMask(props.valor.toFixed(2)) || ""}</span></p>
                    )
                }

                <p>
                    <ion-icon onClick={handleVisibility} className="chevron-icon"
                    name={toggle? "chevron-up-outline": "chevron-down-outline"}></ion-icon>
                </p>
            </header>
            {
                toggle === true ?
                (
                    <>
                    <div className="resultadoBuscaOutrasInformacoes">                        
                        <p><strong>Tipo da transação:</strong> {props.tipoTransacao}</p>
                        {
                            props.tipoTransacao === 'ENTRADA'? (
                                <p><strong>Meio de pagamento:</strong> {props.meioPagamento.nome}</p>

                            ):""
                        }
                        <p><strong>Tipo de operaçao:</strong> {props.tipoOperacao}</p>
                        
                    </div>
                    </>
                ): null
            }

        </div>
    <hr />
    </div>
    </>
  );
}

export default TransacaoItem;
