import React, { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Input from "../../components/FlexFreteInput/Input";
import Modal from "../../components/FlexFreteModal/Modal";
import FlexSpinner from "../../components/FlexFreteSpinner/FlexSpinner";
import AutenticacaoService from '../../services/AutenticacaoService';
import { ActionCreators } from '../../_actions/ActionCreators';
import { BtnLoginRegisterChangePasswordStyle, FormLoginContainerStyle, LoginStyle } from "./style";


function Login(props) {
  const autenticacaoService = new AutenticacaoService();
  const isUserRegister = useStore().getState().isUserRegister;
  const dispatch = useDispatch();

  const [hiddenPassword, setHiddenPassword] = useState(true);
	const [credenciais, setCredenciais] = useState({
		username: null,
		password: null
	});

  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });

  const [linkEnviarEmail, setLinkEnviarEmail] = useState(false)
  const [linkEmailReenviado, setLinkEmailReenviado] = useState(false)
  const [erroEmailEnviado, setErroEmailEnviado] = useState(false)

  const [sucesso, setSucesso] = useState({
		isSuccess: isUserRegister,
		titulo: "Conta criada com sucesso!",
		mensagem:
			"Por favor, verifique seu e-mail para validá-lo",
		voltar: false,
	});
  const [processando, setProcessando] = useState(false);
  const history = useHistory();

  const handlePasswordVisibility = () =>{
    setHiddenPassword(!hiddenPassword)
  }

	const onChange = (event) => {
    const { name, value } = event.target
	let cred = credenciais

	if(event._reactName === 'onFocus' && event.target.name === 'username'){
		event.target.setCustomValidity("Preencha este campo.");
		return
	  } else {
		event.target.setCustomValidity("");
	}

    cred[name] = value
    setCredenciais(cred)
	};

  const submitLogin = (event) => {
    event.preventDefault();
    
    if(processando) {
      return
    }
    setProcessando(true)

	if(credenciais.username.length < 14 || credenciais.username.length > 14 ){
		setErro({
		  isWrong: true,
		  mensagem: "CPF inválido."
		})
		setProcessando(false)
		return
	  }

	let formatedcpf = credenciais.username.replace(/[\D]/g, "")


	const credenciaisRequest = {
		username: formatedcpf,
		password: credenciais.password,
		subscription: true
	}

    autenticacaoService.auth(credenciaisRequest, (erro, sucesso)=>{
      if(erro){
        setProcessando(false)
        if(erro.response && erro.response.data) {
			if(erro.response.data.code === 'AUTHENTICATION_EMAIL_PENDING'){
				setLinkEnviarEmail(true)
				setLinkEmailReenviado(false)
				setErroEmailEnviado(false)
			} else {
				setErro({
				  isWrong: true,
				  mensagem: erro.response.data.message
				})
			}
        } else {
          setErro({
            isWrong: true,
            titulo: "Erro ao fazer login",
            mensagem: "Usuário ou senha incorretos"
          })
        }
        return
      }

      if(sucesso){
        setProcessando(false)
        props.callbackLogin(sucesso)
        dispatch(ActionCreators.login(sucesso))
        history.push(process.env.REACT_APP_REDIRECT_AFTER_LOGIN)
      }

    })
  }

  const mandarEmailDeNovo = (event) =>{

	event.preventDefault();
	let formatedcpf = credenciais.username.replace(/[^0-9]/g, "")
	const documento = {
		cpf: formatedcpf,
	}
	
	  autenticacaoService.mandarNovamenteValidacaoEmail(documento, (erroAutenticar,sucesso) => {
		  if(erroAutenticar){
			  return;
		  }
		  if(sucesso){
			  if(sucesso.codigo === 'OK'){
				setLinkEnviarEmail(false)
				setLinkEmailReenviado(true)
				setErroEmailEnviado(false)
			  }
			  if(sucesso.codigo === 'ERRO'){
				setLinkEnviarEmail(false)
				setLinkEmailReenviado(false)
				setErroEmailEnviado(true)
			  }
		  }
	  })
  }

	const callbackModal = () => {
		if (erro.isWrong === true) {
			setErro({ isWrong: false });
		}
	};

	const callbackModalSucesso = () => {
		dispatch(ActionCreators.registerCloseMessage(false))
		if (sucesso.isSuccess === true) {
			setSucesso({ isSuccess: false });
		}
	};
	return (
	<>
		{processando? <FlexSpinner/>: null}
		{erro.isWrong === true? (
			<Modal
				callbackModal={callbackModal}
				visivel={erro !== null}
				titulo={erro.titulo}
				conteudo={erro.mensagem}
			/>
		) : null}
		{sucesso.isSuccess === true ? (
			<Modal
				callbackModal={callbackModalSucesso}
				sucesso={sucesso.isSuccess}
				visivel={sucesso !== null}
				titulo={sucesso.titulo}
				conteudo={sucesso.mensagem}
			/>
		) : null}

		<LoginStyle>
				<h1 className="title">Faça seu login</h1>
				<FormLoginContainerStyle>
				<form onSubmit={submitLogin} autoComplete="off">
				<Input type="text" mascara="999.999.999-99" name="username" label="CPF*" maxLength="14" required={true} onChange={onChange}/>
				<div>
				<label className="label">Senha</label>
				<div className="columns">
					<div className="column">
						<div className="field has-addons">
							<div className="control input-enfase">
								<input
									className="input"
									type={hiddenPassword === true ? "password" : "text"}
									name="password"
									onChange={onChange}
									required={true}
									autoComplete="new-password"
								/>
							</div>
							<div className="control">
								<span
									className="button is-large"
									onClick={handlePasswordVisibility}
								>
									{hiddenPassword === true ? (
										<ion-icon name="eye-off-outline"></ion-icon>
									) : (
										<ion-icon name="eye-outline"></ion-icon>
									)}
								</span>
							</div>
						</div>
					</div>
				</div>
				</div>

				<BtnLoginRegisterChangePasswordStyle className="button">
					Entrar
				</BtnLoginRegisterChangePasswordStyle>

			{
				linkEnviarEmail === true && linkEmailReenviado === false ?
				(
				<div className="notification is-danger">
					<p>Sua conta ainda não foi validada por e-mail.</p>
					<button className="cadastrar button" onClick={mandarEmailDeNovo}>Reenviar link</button>
				</div>
				) : ""
			}

			{
				linkEmailReenviado === true ?
				(
				<div className="notification is-success">
					<p>Verifique a sua caixa de e-mail</p>
				</div>
				) : ""
			}

			{
				erroEmailEnviado === true ?
				(
				<div className="notification is-danger">
					<p>
						Falha ao tentar reenviar o e-mail, entre em contato com o suporte ou tente novamente mais tarde.
					</p>
				</div>
				) : ""
			}

				<div className="esqueciSenha">
					<small>
						<Link to="recuperarSenha">Esqueci minha senha</Link>
					</small>
				</div>
				</form>
				</FormLoginContainerStyle>
				<div className="cadastrar-container">
					<h4>Ainda não tem conta?</h4>
					<Link to="/register" className="cadastrar button">Cadastrar</Link>
				</div>
		</LoginStyle>
		</>
	);

	
}

export default Login;
