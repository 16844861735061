import React, { useEffect, useState } from 'react';
import { useStore } from "react-redux";
import { Link } from "react-router-dom";
import FlexSpinner from "../../components/FlexFreteSpinner/FlexSpinner";
import UsuarioService from '../../services/UsuarioService';
import ValidaSenha from '../../utils/ValidaSenha';
import { ContaEditPasswordStyle, ContaInfoEditFormStyle, ContaInfoStyle } from './style';


const validaSenha = new ValidaSenha();

export function ContaEditPasswordComponent(){
    const usuario = useStore().getState().user;
    const {email} = usuario;
    const [body, setBody] = useState({
        senhaAtual: '',
        novaSenha: '',
        confirmaNovaSenha: '',
        email: email
    });
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputError, setInputError] = useState({field: '', message: ''});
    const [mensagemErro, setMesagemErro] = useState(null);
    const [mensagemSucesso, setMesagemSucesso] = useState(null);
    const [showFields, setShowFields] =  useState([false, false, false]);

    useEffect(()=>{
        if(body.confirmaNovaSenha.length){
            setMesagemErro(undefined)
            if (body.novaSenha !== body.confirmaNovaSenha){
                setInputError({field: "novaSenhaConfirmacao", message: "Senhas não coincidem."});
                setIsDisabled(true);
            }else if(!validaSenha.senha(body.novaSenha)){
                setIsDisabled(true);
                setInputError({field: "novaSenha", message: "A senha deve ter no mínimo 8 caracteres, possuir letras(maiúscula e minúsculas), números e caracteres especiais."});
            }else if(body.senhaAtual === body.novaSenha){
                setIsDisabled(true);
                setInputError({field: "novaSenha", message: "A nova senha não pode ser igual a anterior."});
            }else{
                setIsDisabled(false);
                setInputError(undefined);
            }
        }
        
    }, [body]);

    const setHiddenPasswordFields =  (index) => {
        const fields = showFields.map((field, idx) => idx === index? !field: field);

        setShowFields(fields);
    }

    const submit = (e) =>{
        e.preventDefault();
        
        if(isDisabled) return;
        
        setIsLoading(true);

        const usuarioService = new UsuarioService();

        usuarioService.trocaSenha(body, (err, sucess)=>{
            if(err){
                setMesagemErro("Não foi possível alterar a senha. Confira se a senha atual está correta.");
                setMesagemSucesso(undefined);
            }else{
                setMesagemErro(undefined);
                setMesagemSucesso("Senha alterada com sucesso.");
                setBody({
                    senhaAtual: '',
                    novaSenha: '',
                    confirmaNovaSenha: '',
                    email: email
                });
            }
            setIsLoading(false);
        });
        

    }
    
    return (
        <ContaInfoEditFormStyle onSubmit={submit} autoComplete="off" className="edit-password-form">
            {isLoading? <FlexSpinner/>: null}

            <ContaInfoStyle className="conta-section">

                <ContaEditPasswordStyle >
                    <div>
                        <label htmlFor="senhaAtual" className="label-grey">Senha Atual <span className="input-required">*</span></label>
                            <div className="input-container">
                                <input className="input" type={showFields[0]? "text": "password"} required name="senhaAtual" onChange= {(e)=> setBody({...body, senhaAtual: e.target.value})}/>
                                    <span className="button is-large" onClick={ () => { setHiddenPasswordFields(0)}}>
                                        <ion-icon name= {showFields[0] === true ? "eye-off-outline": "eye-outline"}></ion-icon>
                                    </span>
                               
                            </div>
                    </div> 
                    
                    <div>
                        <label htmlFor="novaSenha" className="label-grey">Nova Senha  <span className="input-required">*</span></label>
                        <div className="input-container">
                            <input className={`input ${inputError?.field==="novaSenha" ? "is-danger":""}`} type={showFields[1]? "text": "password"}  required name="novaSenha" onChange= {(e)=> setBody({...body, novaSenha: e.target.value})}/>
                                <span className="button is-large" onClick={ () => { setHiddenPasswordFields(1)}}>
                                    <ion-icon name= {showFields[1] === true ? "eye-off-outline": "eye-outline"}></ion-icon>
                                </span>					
                        </div>	
                        <p className="help">A senha deve ter no mínimo 8 caracteres, possuir letras(maiúscula e minúsculas), números e caracteres especiais.</p>

                    </div>
                    
                    <div>
                        <label htmlFor="novaSenhaConfirmacao" className="label-grey">Repetir Nova Senha <span className="input-required">*</span></label>
                        <div className="input-container">
                            <input className={`input ${inputError?.field==="novaSenhaConfirmacao" ? "is-danger":""}`} type={showFields[0]? "text": "password"}  required name="novaSenhaConfirmacao" onChange= {(e)=> setBody({...body, confirmaNovaSenha: e.target.value})}/>
                            <span className="button is-large" onClick={ () => { setHiddenPasswordFields(2)}}>
                                <ion-icon name= {showFields[2] === true ? "eye-off-outline": "eye-outline"}></ion-icon>
                            </span>
                        </div>

                    </div>

                    
                {mensagemSucesso?
                    (<div className="notification is-primary">
                        {mensagemSucesso}
                    </div>):""
                }
                 {mensagemErro || inputError?.message?
                    (<div className="notification is-warning">
                        {mensagemErro || inputError?.message}
                    </div>):""
                     }
                </ContaEditPasswordStyle>
            </ContaInfoStyle>
            <div className="actions">
                        <button className="button button-submit" type="submit" disabled={isDisabled || isLoading}>Salvar</button>
                        <Link to="/configuracoes/conta" className="button ">Voltar</Link>
            </div>     
                   
        </ContaInfoEditFormStyle>

    )

}