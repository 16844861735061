import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreators } from '../../_actions/ActionCreators';
import CarrinhoService from '../../services/CarrinhoService';
import FreteService from '../../services/FreteService';
import { moneyMask } from '../../utils/Masks';
import Modal from '../FlexFreteModal/Modal';
import FlexSpinner from '../FlexFreteSpinner/FlexSpinner';
import InserirSaldo from '../InserirSaldo/InserirSaldo';
import { CarrinhoPagamentoContentStyle } from './style';

function CarrinhoPagamento(props) {
    const dispatch = useDispatch();
    const freteService = new FreteService();
    const carrinhoService = new CarrinhoService();
    const [isDisabled, setIsDisabled] = useState(false);
    const [enviandoInformacoes, setEnviandoInformacoes] = useState(false);
    const [erro, setErro] = useState({
        isWrong: false,
        titulo: "",
        mensagem: "",
        voltar: false,
      });

    useEffect(() => {
        if(props.somaValorTotalFretes > props.saldo){
            setIsDisabled(true)
        }
    },[props.somaValorTotalFretes, props.saldo])

    const submit = (event) => {
        event.preventDefault();
        setEnviandoInformacoes(true)
        
        freteService.comprarFrete(props.uuidCarinho, (erroFrete, sucesso)=>{
            if(erroFrete){
                setEnviandoInformacoes(false)
                setErro({
                    isWrong: true,
                    mensagem: 'Falha ao comprar frete, entre em contato com o suporte ou tente novamente mais tarde'
                })
                return
            }
            if(sucesso){
                setEnviandoInformacoes(false);
                carrinhoService.deleteCalculadoraFormInfo();
                dispatch(ActionCreators.setFreteCompradoComSucesso(sucesso));
                dispatch(ActionCreators.setCarrinhoInformacoes([]));
                carrinhoService.setNumeroItensInfosParaCompra(0)
            }
        })
    }

    const callbackModal = () =>{
        if(erro.isWrong === true){
          setErro({isWrong: false})
        }
      }


  return (
      <>
        {
        enviandoInformacoes === true ?
        (
            <FlexSpinner />
        ) : null
        }
        {
            erro.isWrong === true ?
            (
                <Modal callbackModal={callbackModal} visivel={erro !== null} titulo={erro.titulo} conteudo={erro.mensagem} />
            ) : null
        }
    <CarrinhoPagamentoContentStyle>
        <section>
            <p><strong>Valor total das etiquetas: </strong>{moneyMask(props.somaValorTotalFretes.toFixed(2).toString())}</p>
        </section>
    </CarrinhoPagamentoContentStyle>

    <h2 className="form-title page-title" style={{marginTop: '50px'}}>Escolha a opção de pagamento</h2>
    <CarrinhoPagamentoContentStyle>
        <section>
            <p><strong>Saldo: </strong>R$ {props.saldo.toFixed(2)}</p>
        </section>
        {
            isDisabled === true ?
            (
            <div className="notification is-warning" style={{marginTop: '20px'}}>
                <p>Você não tem saldo suficiente para a compra. Por favor, insira mais créditos.</p>
            </div>
            ) : null
        }
        <button className="button finalizar-pagamento" onClick={submit} disabled={isDisabled}>Utilizar saldo</button>
    </CarrinhoPagamentoContentStyle>

    <h2 className="form-title page-title" style={{marginTop: '50px'}}>Ou, insira mais créditos</h2>
    <InserirSaldo compraFrete={true}/>
</>
  
  );
}

export default CarrinhoPagamento;
