import React from 'react';

function CarrinhoSteps(props) {
  return (
    <ul className="steps is-narrow is-medium is-centered has-content-centered">
      <li className={ props.finalizarPagamento === false ? `steps-segment is-active has-gaps` : `steps-segment` }>
          <span className="steps-marker">
            <span className="icon">
                <ion-icon name="cart"></ion-icon>
            </span>
          </span>
          <div className="steps-content">
            <p className="heading">Carrinho</p>
          </div>
      </li>
      <li className={ props.finalizarPagamento === true && props.confirmacaoCompraPagamento === false ? `steps-segment is-active has-gaps` : `steps-segment` }>
        <span className="steps-marker">
          <span className="icon">
            <ion-icon name="card"></ion-icon>
          </span>
        </span>
        <div className="steps-content">
          <p className="heading">Pagamento</p>
        </div>
      </li>
      <li className={ props.confirmacaoCompraPagamento === true ? `steps-segment is-active has-gaps` : `steps-segment` }>
        <span className="steps-marker">
          <span className="icon">
            <ion-icon name="checkmark"></ion-icon>
          </span>
        </span>
        <div className="steps-content">
          <p className="heading">Confirmação</p>
        </div>
      </li>
    </ul>
  );
}

export default CarrinhoSteps;
