import styled from 'styled-components';

export const ButtonLadingPageStyle = styled.button`
    font-size: 30px;
    line-height: 36.31px;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter';
    color: #FFFFFF;

    background-color: ${props => {return props.colorType || '#792783'}};
    border-color: ${props => {return props.colorType || '#792783'}};

    :hover{
        color: #FFFFFF;
    }

    @media screen and (min-width: 2560px) {
        font-size: 3em;
	}

	@media screen and (max-width: 1920px) {

	}

	@media screen and (max-width: 1440px) {

	}

    @media screen and (max-width: 700px) {
        font-size: 1.6em;
        /* width: 100%; */
        margin-top: 0;
        margin-bottom: 1em;
    }
`