import React from "react";
import { useHistory } from "react-router";
import { BtnModalSeguirStyle, BtnModalVoltarStyle, ModalContainerStyle, ModalContentStyle } from "../FlexFreteModal/style";

function Modal(props) {
	const history = useHistory();

	const ocultarModal = () =>{
		props.callbackModalCalculadora()
	}

	const irNovoFrete = () => {
		history.push('/calculadora')
		window.location.reload()
	}
	return (
		<>
		<div className={props.visivel === true ?` modal is-active` : `modal`}>
			<div className="modal-background"></div>
			<div className="modal-content">
			<ModalContainerStyle>
			<ModalContentStyle>
			<header>
				<button className="modal-close" aria-label="close" onClick={ocultarModal}><ion-icon name="close-circle"></ion-icon></button>
			</header>
            <div className="success">
				<ion-icon name="checkmark-circle-outline"></ion-icon>
			</div>

			<p>{props.titulo}</p>
			<p>{props.conteudo}</p>

			<BtnModalSeguirStyle className="button" obrigatorio={props.obrigatorio} onClick={() => {history.push("/carrinho")}}
			 disabled={props.agenciaOk === true && props.dadosDestinatarios === true && props.destinatarioEndereco === true && props.remetenteEndereco === true ? false : true}>Ir para carrinho</BtnModalSeguirStyle>
			{/* <BtnModalSeguirStyle onClick={() => {history.push("/carrinho")}}>Ir para carrinho</BtnModalSeguirStyle> */}
			<BtnModalVoltarStyle onClick={irNovoFrete}>Contratar novo frete</BtnModalVoltarStyle>
              
			</ModalContentStyle>
			</ModalContainerStyle>
			</div>
		</div>
		</>
	);
}

export default Modal;
