import React from "react";
import {ButtonLadingPageStyle} from './style';

function ButtonLadingPage(props) {
    return(
        <ButtonLadingPageStyle colorType={props.colorType} className={props.classeComplementar} onClick={props.funcao}>
            {props.texto}
        </ButtonLadingPageStyle>
    )
}

export default ButtonLadingPage;