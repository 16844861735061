import styled from "styled-components";

export const NotificacaoStyle = styled.div`
    display: flex;
    width: 100%;
    padding: 11px;
    @media (min-width: 1280px){
        padding: 0;
    }
   
`;
export const NotificacaoFormStyle = styled.form`
    background: #fff;
    border: none;
    border-bottom: solid 2px #ddd;
    margin-bottom: 10px;
    padding: 20px 0 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .notificacoes-input{
        text-align: initial;
        align-items: flex-end;
        width: 100%;
        >div{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        >div, select{
            margin-right: 15px;

        }

    }
    @media (min-width: 1280px){
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        width: 100%;     
        .notificacoes-input{
            display: flex;
        }
    }
`;
export const NotificaoFormHeader= styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
`

export const InputDate = styled.input`
    min-width: 200px;
    border: solid 1px #ddd;
    border-radius: 3px;
    height: 40px;
    color: #ccc;
    background: none;
    padding: 5px;
`;

export const SubmitButton = styled.button`
    margin-top: 20px;
    background-color: rgb(130,42,145);
    width: 90%;
    outline: 0;
    border: 0;
    border-radius: 3px;
    color: ${props => props.disabled? "#ccc": "#fff"};
    height: 35px;
    font-weight: bold;
    cursor: pointer;
    @media (min-width: 1280px){
        width: 150px;
        margin: 0;
    }
`;
export const SelectInput = styled.select`
    border-radius: 3px;
    border: solid 1px #ddd;
    background: none;
    width: 100px;
    height: 40px;
    padding: 7px 5px;
    margin-top: 10px;
    font-weight: 500;
`
