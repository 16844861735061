import styled from "styled-components";

export const ModalContainerStyle = styled.section`
	position: relative;
	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
	position: fixed;

	@media (max-width: 770px) {
		width: 25em;
	}

`;

export const ModalContentStyle = styled.section`
	border-radius: 10px;
	border: none;
	padding: 3em;
	background-color: white;
    -webkit-box-shadow: 13px 12px 9px -5px rgb(97 96 96 / 91%);
    box-shadow: 13px 12px 9px -5px rgb(97 96 96 / 91%);

	h2{
		margin-top: 10px;
	}

	.modal-close{
		background-color: #4a4a4a;
	}

	.modal-close:hover{
		color:orange;
		background-color: #848282;
	}

	footer{
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}

	@media (max-width: 2560px) {

	}

	@media (max-width: 1440px) {

	}

	@media (max-width: 770px) {
		padding: 2em;
		h1, h2{
			text-align: center;
		}

		.modal-close{
			top: 10px;
			right: 10px;
		}

		footer{
			margin-top: 20px;
		}
	}

`;

export const BtnModalSeguirStyle = styled.button`
	margin-top: 5px;
	margin-bottom: 5px;
	border: none;
	background: rgb(131, 43, 146);
	background: linear-gradient(
		267deg,
		rgba(131, 43, 146, 1) 39%,
		rgba(160, 69, 176, 1) 100%
	);
	color: white;
	padding: 10px;
	font-weight: 600;
	font-size: 0.9em;
	letter-spacing: 0.3px;
	cursor: pointer;
	height: 45px;

	:hover{
		background: #c986d8;
  		color: white;
	}

	@media (max-width: 770px) {
		font-size: 1em;
		padding-left: 15px;
		padding-right: 15px;
	}
`;

export const BtnModalVoltarStyle = styled.button`
	margin-top: 5px;
	margin-bottom: 5px;
	margin-right: 5px;
	background: rgb(247, 247, 247);
	background: linear-gradient(
		94deg,
		rgba(247, 247, 247, 1) 39%,
		rgb(230 229 229) 100%
	);
	border: none;
	color: #9339a2;
	padding: 10px;
	font-weight: 600;
	font-size: 0.9em;
	letter-spacing: 0.3px;
	height: 45px;
	cursor: pointer;

	:hover{
		background: rgba(255, 255, 255, 0.7);
		color: #282828;
	}
`;
