import api from './Api';

export default class CalculadoraService{

    setCalculadoraFormInfo(info){
        localStorage.setItem('calculadoraFormInfo', JSON.stringify(info))
    }
    deleteCalculadoraFormInfo(){
        localStorage.removeItem('calculadoraFormInfo');
    }
    getCalculadoraFormInfo(){
        let formInfo = localStorage.getItem('calculadoraFormInfo');
        if(typeof formInfo === "string" ) {
            return JSON.parse(formInfo);
        } else {
            return null;
        }
    }

    setCotacaoCarrinhoSelecionada(info){
        localStorage.setItem('cotacaoCarrinhoSelecionada', JSON.stringify(info))
    }

    getCotacaoCarrinhoSelecionada(){
        let formInfo = localStorage.getItem('cotacaoCarrinhoSelecionada');
        if(typeof formInfo === "string" ) {
            return JSON.parse(formInfo);
        } else {
            return null;
        }
    }
    calcularFrete(request, callback){ 
        api
        .post(this.host() + '/api/v1/calculo-frete', request)
        .then((response)=>{
            this.setCalculadoraFormInfo(request);
            callback(null, response.data);
        })
        .catch((err)=>{
            callback(err, null);
        })
    }
    setFreteCotacoes(info){
        localStorage.setItem('freteCotacoes', JSON.stringify(info))
    }
    getFreteCotacoes(){
        let formInfo = localStorage.getItem('freteCotacoes');
        if(typeof formInfo === "string" ) {
            return JSON.parse(formInfo);
        } else {
            return null;
        }
    }
    setFreteCotacoesCarrinho(info){
        localStorage.setItem('freteCotacoes', JSON.stringify(info))
    }
    getFreteCotacoesCarrinho(){
        let formInfo = localStorage.getItem('freteCotacoes');
        if(typeof formInfo === "string" ) {
            return JSON.parse(formInfo);
        } else {
            return null;
        }
    }
    addUltimaCotacao(dadosCalculadora, infosComplementares){
        let ultimasCotacoes = this.getDadosUltimasCotacoes();
        if(!ultimasCotacoes){
            ultimasCotacoes = [];
        }
        if(ultimasCotacoes.length === 5){
            ultimasCotacoes.shift()
        }
        
        ultimasCotacoes.push({dadosCalculadora, infosComplementares});

        localStorage.setItem('ultimasCotacoes', JSON.stringify(ultimasCotacoes));
    }
    
    getDadosUltimasCotacoes(){

        let formInfo = localStorage.getItem('ultimasCotacoes');
        if(typeof formInfo === "string" ) {
            return JSON.parse(formInfo);
        } else {
            return null;
        }
    }

    obtemUltimasCotacoes(callback){
        api
        .get(this.host() + '/api/v1/calculo-frete')
        .then((response)=>{
            callback(null, response.data);
        })
        .catch((err)=>{
            callback(err, null);
        })
    }

    host(){
        return process.env.REACT_APP_HOST_API
    }
}