import styled from "styled-components";


export const SideMenuStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 70px;
    padding-bottom: 20px;
    text-align: left;
    background: rgb(130,42,145);
    background: linear-gradient(173deg, rgba(130,42,145,1) 39%, rgba(162,70,178,1) 100%);
    transition: ${(props) => (props.toggle ? `width 200ms ease-in;` : `width 200ms ease-out;`)};
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: ${(props) => (props.toggle ? `290px` :`3.6em`)};
    max-width: 1080px;

    .selected-item{
        background-image:url('../../bg.png');
        display: flex;
    }

    .side-menu-title:hover {
        color: currentColor; /* Mantém a cor atual herdada */
        opacity: 0.8; /* Reduz a opacidade da cor herdada */
    }

    div{
        color: white;
        margin-left: 15px;
    }

    ion-icon {
        vertical-align: middle;
        font-size: 2.5rem;
    }

     a{
        color: white;
    }
    .sidebar-link{
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .number-notification{
        left: 14px;
        top: -10px;
    }
    .configs-side{
        cursor: pointer;
    }
    @media (max-width: 2560px) {
        div{
            margin-left: 15px;
        }

        .expanded-sidebar{
            display: none;
        }

        .side-menu-title{
            vertical-align: middle;
            letter-spacing: 0.3px;
            margin-left: 15px;
            visibility: visible;
            opacity: 1;
        }

        .selected-subConfigs-items{
            display: ${(props) => (props.showSubConfigs ? `block;` : `none;`)};
            background-color: #812990;
            margin: 0;
        }

        .selected-item{
            width: 100%;
            background-color: #f2f2f2;
            border-radius: 50px 0px 0px 50px;
            font-weight: 600;
            color: #e6b222;
        .configuracao-link{
            display:flex;
            align-items: center;
        }
        .number-notification{
            left: 23px;
            top: -14px;
        }
    }

    .configs-side-active{
        display: inline-block;
        background-color: #f2f2f2;
        border-radius: 50px 0px 0px 50px;
        font-weight: 600;
        color: #e6b222;
        background-image:url('../../bg.png');
    }

    .config-chevron{
        cursor: pointer;
    }

    .side-menu-title > ion-icon{
        padding-left: 0;
    }

    .selected-subConfigs{
        background-color: ${(props) => (props.toggle ? `transparent` : `#f2f2f2;`)};
        border-radius: 50px 0px 0px 50px;
        font-weight: 600;
        padding-right: ${(props) => (props.toggle ? `0;` : `25px;`)};
        color: ${(props) => (props.toggle ? `white;` : `#812990;`)};
    }

    .selected-subConfigs-items > div {
        padding: 5px 0 5px 35px;
    }
    .config-chevron{
        display: ${(props) => (props.toggle ? 'inline;' : 'none')};
        margin-left: ${(props) => (props.toggle ? '20px;' : '')};
    }
  .configuracao-link{
        display:flex;
        align-items: center;
        cursor: pointer;
    }

    .selected-item{
        width: 100%;
        background-color: #f2f2f2;
        border-radius: 50px 0px 0px 50px;
    }


@media (max-width: 1280px) {
    div{
        margin-left: 15px;
    }
    height: 100%;
    .side-menu-title{
        vertical-align: middle;
        letter-spacing: 0.3px;
        margin-left: ${(props) => (props.toggle ? `15px;` : `-190px`)};
        visibility: ${(props) => (props.toggle ? `visible;` : `hidden`)};
        opacity: ${(props) => (props.toggle ? `1;` : `0;`)};
        //transition: ${(props) => (props.toggle ? `margin-left 1s ease-in;` : `margin-left 1s ease-out;`)};
    }

    .selected-subConfigs-items{
        display: ${(props) => (props.showSubConfigs && props.toggle ? `block;` : `none;`)};
        background-color: #812990;
        margin: 0;
    }

    .number-notification{
        left: 14px;
        top: -14px;
    }
    .selected-item{
        width: 100%;
        background-color: #f2f2f2;
        border-radius: 50px 0px 0px 50px;
        font-weight: 600;
        color: ${(props) => (props.toggle ? `#e6b222` : `#812990;`)};
        .number-notification{
            left: 16px;
        }
    }

    .expanded-sidebar{
        display: ${(props) => (props.toggle? "flex":"block")};
        justify-content: flex-end;
        p{
            margin-right: 20px;
        }
    }

}

@media (max-width: 770px) {
    .selected-item{
        padding: 8px 0px 8px 5px;
    }


}


@media(max-width: 375px){
    .number-notification{
        top: -5px;
        left: 12px;
    }
}

@media (min-height: 1366px) {
    ion-icon {
        font-size: 4rem;
    }
    .number-notification{
        left: 27px;
        top: -10px;
    }
    .selected-item{
        .number-notification{
            left: 27px;
            top: -8px;
        }
    }

}

@media (max-height: 1024px) {
    ion-icon {
        font-size: 3.5rem;
    }
    .number-notification{
        left: 20px;
        top: -11px;
    }
    .selected-item{
        .number-notification{
            left: 27px;
            top: -2px;
        }
    }

}

@media (max-height: 825px) {
    ion-icon {
        font-size: 2.5rem;
    }
}

@media (max-height: 825px) {
    ion-icon {
        font-size: 2.5rem;
    }
    .number-notification{
        top: -8px;
        left: 14px;
    }
    .selected-item{
        .number-notification{
            left: 17px;
            top: 0px;
        }
    }
}

@media (max-height: 475px) {
    ion-icon {
        font-size: 2.0rem;
    }
    .number-notification{
        top: -5px;
        left: 12px;
    }


}


@media (min-width: 1280px){
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 290px;

    .selected-item{
        padding: 10px;
        background-image:url('../../bg.png');
        .number-notification{
            top: 1px;
            left: 25px;
        }
    }
    .selected-subConfigs-items{
        margin-top: -10px;
    }
    .expanded-sidebar{
        display: none;
    }
    .number-notification{
        left: 18px;
    }
    .config-chevron{
        margin-left: 20px;
        display:block;
    }
}

@media screen and (min-width: 1280px) and (min-height: 1366px) {
    .selected-item{
        .number-notification{
            top: 1px;
            left: 30px;
        }
    }
    .number-notification{
        left: 25px;
    }
}
`;
