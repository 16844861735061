import React, { useState, useEffect } from 'react';
import AutenticacaoService from '../../services/AutenticacaoService';
import CarteiraFlexService from '../../services/CarteiraFlexService';
import CarteiraFlexHeader from '../../components/CarteiraFlexHeader/CarteiraFlexHeader';
import Transacoes from '../../components/Transacoes/Transacoes';
import InserirSaldo from '../../components/InserirSaldo/InserirSaldo';
import Modal from '../../components/FlexFreteModal/Modal';
import {CarteiraContainerStyle} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from '../../_actions/ActionCreators';

const carteiraService = new CarteiraFlexService();

function Carteira() {
  const dispatch = useDispatch();
  const hideModal = useSelector((state) => state.modal);
  const autenticacaoService = new AutenticacaoService();
  const uuidAssinatura = autenticacaoService.usuarioCorrente();
 
  let uuid = uuidAssinatura.identificadorAssinatura == null ? "" : uuidAssinatura.identificadorAssinatura;

  const [processando, setProcessando] = useState(false);
  const [assinatura, setAssinatura] = useState({});
  const [showInserirCreditosComponent, setInserirCreditosComponent] = useState(false);
  const [dinheiro, setDinheiro] = useState("");
  const [centavos, setCentavos] = useState("");
  const [erro, setErro] = useState({
		isWrong: false,
		titulo: "",
		mensagem: "",
	})

  useEffect(() => {
    if(!processando) {
      setProcessando(true);
      carteiraService.obterAssinatura(uuid, (erro, sucesso) => {
        if(erro){
          setErro({
						isWrong: true,
						mensagem: 'Erro ao obter informações da assinatura, entre em contato com o suporte ou tente novamente mais tarde.'
					})
          return
        }
        if(sucesso) {
          setAssinatura(sucesso);
            let saldoSplited = sucesso.saldo.toString().split('.')
           if(saldoSplited.length === 2){
             let dinheiro = saldoSplited[0]
             let centavos = saldoSplited[saldoSplited.length - 1]
             setDinheiro(dinheiro)
             setCentavos(centavos)
           } else {
              setDinheiro(sucesso.saldo.toString())
              setCentavos("00")
           }
        }
      });

    }
  }, [processando, hideModal, uuid]);

  const callbackModal = () =>{
    if(erro.isWrong === true){
      setErro({isWrong: false})
    }
  }

  const onClick = () => {
    dispatch(ActionCreators.showModal(true))
   }
  const onClickInserirCreditos = () => { setInserirCreditosComponent(true) }

  return (
    <>
      {
        erro.isWrong === true ?
        (
          <Modal callbackModal={callbackModal} visivel={erro !== null} titulo={erro.titulo} conteudo={erro.mensagem} />
        ) : null
      }
    <div className="page-container-style"> 
    <CarteiraContainerStyle>
        <CarteiraFlexHeader dinheiro={dinheiro} centavos={centavos} assinatura={assinatura} onClickInserirCreditos={onClickInserirCreditos} />
        {
          showInserirCreditosComponent === true ?
          (
            <InserirSaldo />
          ) :
          (
            null
          )
        }

        <Transacoes onClick={onClick} />
    </CarteiraContainerStyle>
    </div>
    </>
  );
}

export default Carteira;