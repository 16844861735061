import axios from 'axios';
import instance from "./Api";

export default class UsuarioService{

    inscrever(request, callback){
        axios
            .post(this.host() + '/api/v1/usuario/registro', request)
            .then((response)=>{
                callback(null, response.data);
            })
            .catch((err)=>{
                callback(err, null);
            })
    }

    obterPerfisDeAcesso(uuid, callback){
        instance
            .get(this.host() + '/api/v1/usuario/' + uuid + '/perfil-acesso')
            .then((response) => {
                this.setPerfisCorrente(response.data);
                callback(null, response.data);
            })
            .catch((err) =>{
                callback(err, null);
            })
    }

    setPerfisCorrente(perfis){
        localStorage.setItem('perfis', JSON.stringify(perfis))
    }

    getPerfilSelecionadoCorente() {
        let valorPerfilSelecionado = localStorage.getItem('perfil-selecionado');
        if(typeof valorPerfilSelecionado === "string" ) {
            return JSON.parse(valorPerfilSelecionado);
        } else {
            return null;
        }
    }

    solicitarTrocaDeSenha(request, callback){
        instance
        .post(this.host() + '/api/v1/usuario/recuperacaoSenha', request)
        .then((response) => {
            callback(null, response.data);
        })
        .catch((err) =>{
            callback(err, null);
        })
    }
    restauroSenha(request, callback){
        instance
        .post(this.host() + '/api/v1/usuario/restauroSenha', request)
        .then((response) => {
            callback(null, response.data);
        })
        .catch((err) =>{
            callback(err, null);
        })
    }
    trocaSenha(request, callback){
        instance
        .post(this.host() + '/api/v1/usuario/troca-senha', request)
        .then((response) => {
            callback(null, response.data);
        })
        .catch((err) =>{
            callback(err, null);
        })
    }
    host(){
        return process.env.REACT_APP_HOST_API
    }
}