import React, { useCallback, useEffect, useState } from 'react';
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from '../../components/FlexFreteModal/Modal';
import FreteService from '../../services/FreteService';
import FlexSpinner from '../FlexFreteSpinner/FlexSpinner';
import MeusenviosFrete from './MeusEnviosFrete';
import DownloadService from '../../services/DownloadService';
import LoteService from '../../services/LoteService';
import { Container, FreteListSearchForm, SelectStatusFreteStyle } from './style';
import ModalEnvios from '../../components/Modal/ModalEnvios';

const freteService = new FreteService();
const downloadService = new DownloadService();
const etiquetaService = new LoteService();
export default function MeusEnviosFreteList({ section }) {
    const [isLoading, setIsLoading] = useState(false);
    const [checkedAll, setCheckedAll] = useState(false);
    const [fretes, setfretes] = useState([]);
    const [freteSelecionado, setFreteSelecionado] = useState([]);
    const [status, setStatus] = useState(section || "GERADO");
    const [showingItems, setShowingItems] = useState([false]);
    const [submitSearchForm, setSubmitSearchForm] = useState(false);
    const [comoFuncionaPosition, setComoFuncionaPosition] = useState(0)
    const [request, setRequest] = useState({
        pageSize: 10,
        page: 0,
        status: section || "GERADO",
        dataInicio: "",
        dataFim: ""
    });
    const [labelCheckAll, setLabelCheckAll] = useState('Selecionar todos');

    const [isModalVisible, setModalVisible] = useState(false);

    const [tipoFolha, setTipoFolha] = useState('A4');

    const [bloquearBotao, setBloquearBotao] = useState(false);

    const tiposImpressao = [
        { label: 'A4', value: 'A4' },
        { label: 'Térmica 10x15', value: 'A6' }
    ];

    const handleSelectChange = (event) => {
        setTipoFolha(event.target.value);
    };

    const handleFreteChange = (isChecked, frete) => {
        //
        if (isChecked) {
            frete.ischecked = true;
            //adicionando frete ao array de fretes selecionados se o frete ainda não estiver no array
            if (!freteSelecionado.find(f => f.uuid === frete.uuid)) {
                setFreteSelecionado([...freteSelecionado, frete]);
            }
            //se todos os fretes estiverem selecionados, marcar o checkbox de selecionar todos
            if (freteSelecionado.length === fretes.length - 1) {
                setCheckedAll(true);
                setLabelCheckAll('Desmarcar todos');
            }
        } else {
            frete.ischecked = false;
            const newFrete = freteSelecionado.filter(f => f.uuid !== frete.uuid);
            setFreteSelecionado([...newFrete]);
            setCheckedAll(false);
        }
    };

    const handleFreteChangeAll = (isChecked) => {
        const newFrete = fretes.map(f => {
            f.ischecked = isChecked;
            return f;
        });
        if (isChecked) {
            setCheckedAll(true);
            setFreteSelecionado([...newFrete]);
            setLabelCheckAll('Desmarcar todos');
        } else {
            setFreteSelecionado([]);
            setCheckedAll(false);
            setLabelCheckAll('Selecionar todos');
        }

    };

    const etiquetasLoteModal = () => {
        if (freteSelecionado.length === 0) {
            console.error("Nenhum frete selecionado.");
            return;
        }
        setBloquearBotao(true);
        setModalVisible(true);
    };

    const etiquetasLote = (event, acao) => {
        if (acao !== false) {
            const freteUuids = freteSelecionado.map(f => f.uuid);

            const uuids = freteUuids.join(',');
            const params = {
                uuids,
                tipoFolha
            }
            etiquetaService.gerarEtiquetaLote(params).then((response) => {
                const { data } = response;
                downloadService.downloadFile({ label: "etiqueta", data: data, filename: "etiquetas.pdf", extension: "pdf" })
                setBloquearBotao(false);
            }, error => {
                setErro({
                    isWrong: true,
                    titulo: 'Erro ao gerar etiquetas',
                    mensagem: 'Não foi possível gerar as etiquetas.',
                    autoCloseTime: 8000
                });
                console.error('Erro ao gerar etiquetas:', error);
                setBloquearBotao(false);
            });
        }
        setModalVisible(false);
        setTipoFolha('A4');
    };



    const declaracaoLote = () => {
        if (freteSelecionado.length === 0) {
            console.error("Nenhum frete selecionado.");
            return;
        }
        setBloquearBotao(true);
        const freteUuids = freteSelecionado.map(f => f.uuid);
        console.log('freteUuids', freteUuids);

        const uuids = freteUuids.join(',');

        etiquetaService.gerarDeclaracaoLote(uuids).then((response) => {
            const { data } = response;
            downloadService.downloadFile({ label: "declarações", data: data, filename: "declaracoes.pdf", extension: "pdf" })
            setBloquearBotao(false);
        }, error => {
            console.error('Erro ao gerar declarações:', error);
            setErro({
                isWrong: true,
                titulo: 'Erro ao gerar declarações',
                mensagem: 'Não foi possível gerar as declarações.',
                autoCloseTime: 8000
            });
            setBloquearBotao(false);
        });
    };


    const loadFretes = useCallback(() => {
        setIsLoading(true);
        freteService.listarMeusFretes(request, (err, sucesLoad) => {
            setIsLoading(false);
            if (err) {
                console.error(err);
            } else {
                setfretes([...sucesLoad]);
            }
        })
    }, [request]);
    const loadMoreFretes = () => {
        setIsLoading(true);
        freteService.listarMeusFretes({ ...request, page: request.page + 1 }, (err, sucessLoadMore) => {
            setIsLoading(false);
            if (err) {
                console.error(err);
            } else {
                if (sucessLoadMore.length) {
                    setRequest({ ...request, page: request.page + 1 });
                    setfretes([...fretes, ...sucessLoadMore]);
                }
            }
        })
    }
    const showHideElementCallback = (index) => {
        const showAux = showingItems;

        showAux[index] = !showingItems[index];

        setShowingItems([...showAux]);
    }

    useEffect(() => {
        loadFretes();
        // desabilitando devido ao carregar mais atualizar o request --> by Renan
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const callback = () => {
            loadFretes();
        }

        callback();
        // desabilitando devido ao carregar mais atualizar o request --> by Renan
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        const callback = () => {
            if (submitSearchForm) {
                loadFretes();
                setSubmitSearchForm(false);
            }
        }
        callback();
        // desabilitando devido ao carregar mais atualizar o request --> by Renan
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitSearchForm]);

    const submitForm = (e) => {
        e.preventDefault();
        setRequest({ ...request, page: 0 });
        setfretes([]);

        setSubmitSearchForm(true);
    }

    const onClickChangeStatusFrete = (statusFrete) => {
        if (statusFrete === request.status) {
            return;
        }
        setRequest({
            page: 0,
            pageSize: 10,
            status: statusFrete,
            dataInicio: "",
            dataFim: ""
        });
        setStatus(statusFrete)

    }

    const deleteFrete = (frete, index) => {
        if (frete.statusFrete === "CANCELADO" || frete.statusFrete === "EXPIRADO") {
            return;
        }
        if (!window.confirm("Tem certeza que deseja cancelar o frete?")) {
            return;
        }
        setIsLoading(true);
        const mensagem = "Cancelamento solicitado pelo assinante."
        freteService.deleteFrete(frete.uuid, mensagem, (err, sucessoFreteDelete) => {
            setIsLoading(false);
            if (err) {
                setErro({
                    isWrong: true,
                    titulo: 'Cancelamento de frete',
                    mensagem: 'Não foi possivel cancelar o frete.',
                    autoCloseTime: 8000
                })
            } else {
                const { cancelado, status } = sucessoFreteDelete.data
                const mensagem = cancelado === false && status === "PROCESSANDO_CANCELAMENTO" ? "O cancelamento está sendo processado, frete será atualizado em alguns minutos ..." : "Seu frete foi cancelado com sucesso."
                setSucesso({
                    isWrong: false,
                    titulo: 'Cancelamento de frete',
                    mensagem,
                    autoCloseTime: 8000
                })
                if (cancelado === true) {
                    fretes.splice(index, 1);
                }
            }
        });
    }

    const [erro, setErro] = useState({
        isWrong: false,
        titulo: "",
        mensagem: "",
        voltar: false,
        autoCloseTime: 8000
    });

    const [sucess, setSucesso] = useState({
        isWrong: true,
        titulo: "",
        mensagem: "",
        voltar: false,
        autoCloseTime: 8000
    });

    const callbackModal = () => {
        if (erro.isWrong === true) {
            setErro({ isWrong: false })
        }
    }

    const callbackModalSucesso = () => {
        if (sucess.isWrong === false) {
            setSucesso({ isWrong: true })
        }
    }

    const checkboxJsx = () => {
        return (<div className="columns is-vcentered is-multiline mt-4">
            <div className="column is-two-fifths  is-full-mobile is-narrow">
                <label class="checkbox ffl-custom-checkbox is-flex is-align-items-center">
                    <input
                        checked={checkedAll}
                        onChange={(e) => { handleFreteChangeAll(e.target.checked) }}
                        type="checkbox" />
                    <span className='ml-2'>{labelCheckAll}</span>
                </label>
            </div>
            <div className="column is-full-mobile">
                <button disabled={freteSelecionado.length === 0 || bloquearBotao} className={`button button-purple w-100 ${bloquearBotao ? "is-loading" : ""}`} onClick={etiquetasLoteModal} type="button">{bloquearBotao ? "Baixando etiquetas ....": "Imprimir Etiquetas"}</button>
            </div>
            <div className="column is-full-mobile">
                <button disabled={freteSelecionado.length === 0 || bloquearBotao} className={`button button-purple w-100 ${bloquearBotao ? "is-loading" : ""}`} onClick={declaracaoLote} type="button">{bloquearBotao ? "Baixando declarações ...": "Baixar Declaração"  }</button>
            </div>
        </div>)
    }


    return (

        <Container>
            <ModalEnvios
                visivel={isModalVisible}
                titulo="Imprimir etiquetas"
                labelConfirmButton="Imprimir"
                labelCancelButton="Cancelar"
                onChange={etiquetasLote}
            >
                <p>Selecione o formato de impressão das etiquetas:</p>
                <div class="select">
                    <select  value={tipoFolha} onChange={handleSelectChange}>
                        {tiposImpressao.map((tipo, index) => {
                            return <option key={index} value={tipo.value}>{tipo.label}</option>
                        })}
                    </select>
                </div>
            </ModalEnvios>
            {
                erro.isWrong === true ?
                    (
                        <Modal callbackModal={callbackModal} visivel={erro.isWrong === true} titulo={erro.titulo} conteudo={erro.mensagem} autoCloseTime={erro.autoCloseTime} />
                    ) : null
            }

            {
                sucess.isWrong === false ?
                    (
                        <Modal callbackModal={callbackModalSucesso} visivel={sucess.isWrong === false} titulo={sucess.titulo} conteudo={sucess.mensagem} autoCloseTime={sucess.autoCloseTime} />
                    ) : null
            }
            {isLoading ? <FlexSpinner className="spinner" /> : ""}
            <div className="meus-envios-cabecalho container-desktop">
                <ul>
                    <SelectStatusFreteStyle $selected={status === "PENDENTE"} to="envios?section=PENDENTE" onClick={e => { onClickChangeStatusFrete("PENDENTE") }} >Pendentes</SelectStatusFreteStyle>
                    <SelectStatusFreteStyle $selected={status === "GERADO"} to="envios?section=GERADO" onClick={e => { onClickChangeStatusFrete("GERADO") }} >Gerados</SelectStatusFreteStyle>
                    <SelectStatusFreteStyle $selected={status === "POSTADO"} to="envios?section=POSTADO" onClick={e => { onClickChangeStatusFrete("POSTADO") }}> Postados</SelectStatusFreteStyle>
                    <SelectStatusFreteStyle $selected={status === "ENTREGUE"} to="envios?section=ENTREGUE" onClick={e => { onClickChangeStatusFrete("ENTREGUE") }}> Entregues</SelectStatusFreteStyle>
                    <SelectStatusFreteStyle $selected={status === "EXPIRADO"} to="envios?section=EXPIRADO" onClick={e => { onClickChangeStatusFrete("EXPIRADO") }}> Expirados</SelectStatusFreteStyle>
                    <SelectStatusFreteStyle $selected={status === "CANCELADO"} to="envios?section=CANCELADO" onClick={e => { onClickChangeStatusFrete("CANCELADO") }}> Cancelados</SelectStatusFreteStyle>
                </ul>

            </div>
            <div className="container-mobile">
                <div className="mb-3">
                    <Swiper
                        pagination={true}
                        modules={[Pagination]}
                        onSlideChange={(swiper) => setComoFuncionaPosition(swiper.activeIndex)}
                    >
                        <SwiperSlide>
                            <div className='itens-mobile'>
                                <SelectStatusFreteStyle $selected={status === "PENDENTE"} to="envios?section=PENDENTE" onClick={e => { onClickChangeStatusFrete("PENDENTE") }} >Pendentes</SelectStatusFreteStyle>
                                <SelectStatusFreteStyle $selected={status === "GERADO"} to="envios?section=GERADO" onClick={e => { onClickChangeStatusFrete("GERADO") }} >Gerados</SelectStatusFreteStyle>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='itens-mobile'>
                                <SelectStatusFreteStyle $selected={status === "PENDENTE"} to="envios?section=PENDENTE" onClick={e => { onClickChangeStatusFrete("PENDENTE") }} >Pendentes</SelectStatusFreteStyle>
                                <SelectStatusFreteStyle $selected={status === "GERADO"} to="envios?section=GERADO" onClick={e => { onClickChangeStatusFrete("GERADO") }} >Gerados</SelectStatusFreteStyle>
                                <SelectStatusFreteStyle $selected={status === "POSTADO"} to="envios?section=POSTADO" onClick={e => { onClickChangeStatusFrete("POSTADO") }}> Postados</SelectStatusFreteStyle>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='itens-mobile'>
                                <SelectStatusFreteStyle $selected={status === "GERADO"} to="envios?section=GERADO" onClick={e => { onClickChangeStatusFrete("GERADO") }} >Gerados</SelectStatusFreteStyle>
                                <SelectStatusFreteStyle $selected={status === "POSTADO"} to="envios?section=POSTADO" onClick={e => { onClickChangeStatusFrete("POSTADO") }}> Postados</SelectStatusFreteStyle>
                                <SelectStatusFreteStyle $selected={status === "ENTREGUE"} to="envios?section=ENTREGUE" onClick={e => { onClickChangeStatusFrete("ENTREGUE") }}> Entregues</SelectStatusFreteStyle>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='itens-mobile'>
                                <SelectStatusFreteStyle $selected={status === "POSTADO"} to="envios?section=POSTADO" onClick={e => { onClickChangeStatusFrete("POSTADO") }}> Postados</SelectStatusFreteStyle>
                                <SelectStatusFreteStyle $selected={status === "ENTREGUE"} to="envios?section=ENTREGUE" onClick={e => { onClickChangeStatusFrete("ENTREGUE") }}> Entregues</SelectStatusFreteStyle>
                                <SelectStatusFreteStyle $selected={status === "EXPIRADO"} to="envios?section=EXPIRADO" onClick={e => { onClickChangeStatusFrete("EXPIRADO") }}> Expirados</SelectStatusFreteStyle>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='itens-mobile'>
                                <SelectStatusFreteStyle $selected={status === "EXPIRADO"} to="envios?section=EXPIRADO" onClick={e => { onClickChangeStatusFrete("EXPIRADO") }}> Expirados</SelectStatusFreteStyle>
                                <SelectStatusFreteStyle $selected={status === "CANCELADO"} to="envios?section=CANCELADO" onClick={e => { onClickChangeStatusFrete("CANCELADO") }}> Cancelados</SelectStatusFreteStyle>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="dots mobile-container" style={{ justifyContent: 'center' }}>
                    <span style={{ backgroundColor: comoFuncionaPosition === 0 ? '#EBB013' : 'white' }}></span>
                    <span style={{ backgroundColor: comoFuncionaPosition === 1 ? '#EBB013' : 'white' }}></span>
                    <span style={{ backgroundColor: comoFuncionaPosition === 2 ? '#EBB013' : 'white' }}></span>
                    <span style={{ backgroundColor: comoFuncionaPosition === 3 ? '#EBB013' : 'white' }}></span>
                    <span style={{ backgroundColor: comoFuncionaPosition === 4 ? '#EBB013' : 'white' }}></span>
                </div>

            </div>




            <FreteListSearchForm >
                <form onSubmit={e => { submitForm(e) }}>
                    <div className="formulario-busca-header">
                        <div className="formulario-titulo">
                            <h2 className="formulario-busca-titulo">Pesquisar</h2>
                        </div>

                        <div className="formulario-busca-inputs">
                            <div className="formulario-busca-input-area">
                                <label htmlFor="dataInicio">
                                    Período de início
                                </label>
                                <input type="date" className="input" name="dataIncio" onChange={e => { setRequest({ ...request, dataInicio: e.target.value }) }} value={request.dataInicio} />

                            </div>
                            <div className="formulario-busca-input-area">
                                <label htmlFor="dataFim">
                                    Período de término
                                </label>
                                <input type="date" className="input" name="dataFim" onChange={e => { setRequest({ ...request, dataFim: e.target.value }) }} value={request.dataFim} />
                            </div>

                            <div className="formulario-busca-input-area quantidade-fretes">
                                <label htmlFor="quantidade_fretes">Quantidade de fretes</label>
                                <div className="select">
                                    <select name="quantidade_fretes" defaultValue={10} onChange={e => { setRequest({ ...request, pageSize: Number(e.target.value) }) }} value={request.pageSize}>
                                        <option value="10"> 10 fretes</option>
                                        <option value="20"> 20 fretes</option>
                                        <option value="30"> 30 fretes</option>
                                    </select>
                                </div>

                            </div>

                            <div className="formulario-busca-input-area quantidade-fretes">
                                <button className="button buscar-button" type="submit">Buscar</button>
                            </div>
                        </div>


                        {
                            (status === "GERADO" || status === "POSTADO" || status === "ENTREGUE") && fretes.length > 0 && checkboxJsx()
                        }

                    </div>
                </form>
                <ul className="frete-list">
                    {
                        fretes.map((frete, index) =>
                        (<MeusenviosFrete frete={frete} key={Math.random().toFixed(10).toString()}
                            deleteFreteCallback={deleteFrete}
                            showBody={showingItems[index]}
                            setShowBodyCallback={showHideElementCallback}
                            index={index}
                            status={status}
                            handleFreteChange={handleFreteChange}

                        />
                        )
                        )
                    }
                </ul>

                {fretes.length >= request.pageSize ? <input className="button loadmore-button" value="Carregar mais" onClick={loadMoreFretes} /> : ""}

            </FreteListSearchForm>
        </Container>
    )
}
