import styled from "styled-components";

export const MeusEnviosStyle = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 3em;
    top: 57px;
    margin-bottom: 100px;
    width: calc(100% - 3em);
    min-height: 60vh;


    @media(min-width: 1280px){
        left: 290px;
        width: calc(100% - 290px);   
    }
`;