import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import {Provider} from 'react-redux';
import store from './_store/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />  
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if(process.env.NODE_ENV === "production" || process.env.NODE_ENV === "homol"){
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}
