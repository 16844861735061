import React from 'react';
import { NotificationStyle } from './style';

function NumberNotification(props) {

  return (
    <>
    <NotificationStyle className="number-notification" {...props}> 
        <p>{props.number}</p>
    </NotificationStyle>
    </>
  );
}

export default NumberNotification;
