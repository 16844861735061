import styled from "styled-components";

export const FreteCalcResultadoStyle = styled.section`
	padding-bottom: 3em;
	padding-top: 8px;
	background-color: #FFFFFF;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

  .transportadora-table-img{
    display: flex;
    justify-content: center;
    align-items: center;
  }

	h2 {
		padding-top: 40px;
		padding-bottom: 40px;
		font-size: 2em;
		color: #340040;
		font-weight: 700;
	}

	/* @media screen and (min-width: 700px){

		h2 {
			font-size: 2.3em;
		
		}
		
    }

    @media screen and (min-width: 1000px){
		
    }
	@media (min-width: 1279px) {

	} */
`;

export const FreteCalcResultadoContainerStyle = styled.section`
	border-radius: 10px;
	padding: 1em;
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);

  .transportadora-table-img{
    display: flex;
    justify-content: center;
    align-items: center;
  }
	

	.conteudo-info{
		font-size: 14px;
		margin: -20px 0px 30px 0px;
		color: #888888;
	}

	.custom-separator{
		margin: 0 8px;
	}

	.table{
		padding: 1em;
		margin: 0 auto;

		tr {
			border-bottom: 1px solid #EBEAED;
		}

		thead {
			border-top: 1px solid #EBEAED;
		}

	}

	.table td, th{
		border: none;
		padding: 0 2.7em;
		text-align: center;
		vertical-align: middle;
		margin: 0 auto;
		font-weight: 400;
		font-size: 1em;
		line-height: 162%;
		color: #340040;

		strike, .separador {
			color: #888888;
		}
	}

	.table th{
		color: rgba(21, 20, 57, 0.4);
		mix-blend-mode: normal;
		letter-spacing: 0.125em;
		text-transform: uppercase;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 1em;
		line-height: 1.625em;
		text-align: center;
		border: none;
		padding: 1.1em;
	}	
	.transportadora-logo{
		max-width: 120px;
	}
  
    td.img-calculadora{
      padding: 0;
    }

	.destaque{
		font-size: 1.25em;
		line-height: 1.5em;
		letter-spacing: 0.05em;
		text-transform: uppercase;

		color: #340040;
		font-weight: 700;
		background: #EBB013;
		border-radius: 10px;
		padding: 0.4em
	}

	strong{
		color: #340040;
	}

	.voltarCalculadora{
		margin-top: 20px;
		margin-bottom: 20px;
		background: rgb(131, 43, 146);
		background: linear-gradient(
			267deg,
			rgba(131, 43, 146, 1) 39%,
			rgba(160, 69, 176, 1) 100%
		);
		color: white;
		font-weight: 600;
		letter-spacing: 0.3px;
		border: none;
		width:205px;
	}

	.voltarCalculadora:hover{
		background: #c986d8;
		color: white;
	}

	p{
		color: #340040;
		font-size: 12px;
		text-align: left;
		/* padding-left: 24em; */
		line-height: 6em;
	}

	@media screen and (min-width: 2560px) {
		width: 60%;
	}
	@media screen and (max-width: 1920px) {
		width: 80%;
	}

	@media screen and (max-width: 1440px) {
		width: 87%;
	}
	
	@media (min-width: 1279px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@media screen and (min-width: 1000px) {
		margin: 1.5em 20em 1.5em 20em;
	}
	@media screen and (min-width: 1200px) {
		margin: 1.5em 25em 1.5em 25em;
	}
	@media screen and (min-width: 700px) {
		margin: 1.5em 10em 1.5em 10em;
	}	

	@media screen and (max-width: 700px) {
		width: 100%;
	}

	@media screen and (min-width: 2560px) {
		.mobile-container{
			display: none;
		}
	}
	
	@media screen and (max-width: 1920px) {
		.mobile-container{
			display: none;
		}
	}

	@media screen and (max-width: 1440px) {
		.mobile-container{
			display: none;
		}
	}

	@media screen and (max-width: 700px) {
		.mobile-container{
			display: inline;
		}
		.desktop-container{
			display: none;
		}
      .table td, th{
        padding: 0;
        vertical-align: top;
      }
	}

	.mobile-container{
		.destaque{
			font-size: 1em;
		}		
	}

	/* @media (max-width: 2560px) {
		margin: 1.5em 12em 1.5em 12em;
		width: 95em;
	}

	@media (max-width: 1440px) {
		margin: 1.5em 9em 1.5em 9em;
		width: 67em;
	}

	@media (min-width: 1279px) {

	}

	@media (max-width: 770px) {
		margin: 1.5em;
		width: 90%;
	} */


`;

export const FreteCalcResultadoFiltroStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: baseline;
	margin-bottom: 50px;
	margin-top: 20px;

	.selectionFilter{
		display: flex;
		align-items: baseline;

		label{
			font-weight: 700;
			margin-right: 20px;
		}
	}

	.control-checkbox{
		margin-left: 20px;
		label{
			font-size: 1.6em;
			font-weight: 300;
		}
	}


	@media (max-width: 770px) {
		.selectWidth{
		width: 9.5em;
	}
		.selectionFilter{
		label{
			margin-right: 10px;
		}
	}

	.control-checkbox{
		margin-left: 10px;
	}
	}

`;

export const FreteCalcResultadoItemContainerStyle = styled.div`
	.conteudo{
		width: 100%!important;	
	}

	/* @media (max-width: 2560px) {
		padding-left: 13em;
		padding-right: 13em;
	}

	@media (max-width: 1440px) {
		padding-left: 7em;
		padding-right: 5em;
	}

	@media (min-width: 1279px) {

	}

	@media (max-width: 770px) {
		padding-left: 0em;
		padding-right: 0em;
	} */

` 

export const BtnResultFreteStyle = styled.div`
	display: flex;
	justify-content: center;
	
	.voltar{
		margin-top: 20px;
		margin-bottom: 20px;
		background-color: white;
		color: #812990;
		font-weight: 400;
		letter-spacing: 0.3px;
	}
	
	.voltar:hover{
		background: rgba(255, 255, 255, 0.7);
	}
	
	.cadastrese{
		margin-top: 35px;
		margin-bottom: 20px;
		background: #792783;
		color: white;
		font-weight: 700;
		font-size: 20px;
		letter-spacing: 0.3px;
		border: none;
		border-radius: 6.25em;
	}

	.cadastrese:hover{
		background: #c986d8;
		color: white;
	}

	@media (max-width: 770px) {
		flex-direction: column;
		align-items: center;

			
	.voltar{
		margin-top: 10px;
		margin-bottom: 10px;
	}

		.cadastrese{
		margin-right: 0px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	}
`;

// FreteCalcResultadoItem.js

export const FreteCalcResultadoItemStyle = styled.div`
	display: flex;
	align-items: stretch;
	justify-items: center;
	
	p{
		text-align: left;
	}

	/* @media (max-width: 2560px) {
		gap: 80px;
		gap: 80px 100px;
		row-gap: 80px;
		column-gap: 100px;

		margin-bottom: 50px;

		.transportadora-logo{
			max-width: 120px;
		}
	}

	@media (max-width: 1440px) {
		gap: 70px;
		gap: 70px 90px;
		row-gap: 70px;
		column-gap: 90px;
	}

	@media (min-width: 1279px) {

	}

	@media (max-width: 770px) {
		gap: 1px;
		gap: 1px 2px;
		row-gap: 1px;
		column-gap: 2px;

		margin-bottom: 0px;

		.transportadora-logo{
			max-width: 80px;
		}

	} */
`

export const FreteSubInfosHeaderItemStyle = styled.div`

	/* @media (max-width: 2560px) {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	@media (max-width: 1440px) {

	}

	@media (min-width: 1279px) {

	}

	@media (max-width: 770px) {
		margin-right: 40px;
		display: flex;
		flex-direction: column-reverse;
	} */
`
export const FreteSubInfosItemStyle = styled.div`
	display: flex;
	flex-direction: column;

	p:first-child, .valorEconomiaTitle{
		font-weight: 200;
	}

	p:last-child{
		color: #282828;
	}

	#valorEconomiaSubtitle{
		color: #009f57;
		font-weight: 600;
	}


	/* @media (max-width: 2560px) {
		.hidePrazoDesktop{
			display: initial;
		}

		.showPrazoMobile{
			display: none;
		}


	}

	@media (max-width: 770px) {
		margin-right: 40px;

		.hidePrazoDesktop{
			display: none;
		}

		.showPrazoMobile{
			display: initial;
			text-align: left;
			margin-left: 9.6em;
			margin-bottom: 20px;
		}

	} */
`

export const FreteTagItemStyle = styled.span`
    /* background-color:rgba(227,175,31,1); */
	background-color:  ${(props) => (props.tipoTag === null ? `transparent;` : `rgba(227,175,31,1);;`)};;

    color: #282828;
	font-weight: 600;
	letter-spacing: 0.2px;
	padding: 0.8em;
	border-radius: 6px;
	min-width: 7em;

	/* @media (max-width: 2560px) {
	
		margin-right: 50px;
	}

	@media (max-width: 770px) {
		font-size: 0.9em;
		margin-right: 0px;
	} */
`

