import { useEffect, useState } from 'react';
import CardDocumento from '../../components/CardDocumento/CardDocumento';
import Modal from '../../components/FlexFreteModal/Modal';
import AutenticacaoService from '../../services/AutenticacaoService';
import DocumentosService from '../../services/DocumentosService';
import DelimitadorDeCards from '../../utils/DelimitadorDeCards';
import { DocumentoContainerStyle, DocumentoPageStyle } from './style';

const delimitaCards = new DelimitadorDeCards();
const docservice = new DocumentosService();
const service = new AutenticacaoService();

function Documentos() {
  
  const getAssinatura = service.usuarioCorrente();
  let uuidAssinatura = getAssinatura.identificadorAssinatura;
  const [processando, setProcessando] = useState(false);
  const [getDocs, setDocs] = useState([]);
  const [docsResposta, setDocsResposta] = useState([]);

  const [erro, setErro] = useState({
		isWrong: false,
		titulo: "",
		mensagem: "",
	})


  useEffect(() => {
    if(!processando) {
      setProcessando(true);
      docservice.obterTipoDocumento((erro, sucesso) => {
        if(erro) {
          setErro({
						isWrong: true,
						mensagem: 'Erro ao obter lista de documentos, entre em contato com o suporte ou tente novamente mais tarde.'
					})
          return
        }

        if(sucesso) {
          setDocs(sucesso)
        }
      })

      docservice.obterRespostaDoDocumento(uuidAssinatura, (erro, sucesso) => {
        if(erro){
          setErro({
						isWrong: true,
						mensagem: 'Erro ao obter historico de documentos, entre em contato com o suporte ou tente novamente mais tarde.'
					})
          return
        }

        if(sucesso) {
          setDocsResposta(sucesso)
        }
      })
    }
  }, [processando, uuidAssinatura ])

  let docColumns = delimitaCards.arrayChunks(getDocs, 2);

  const osDocumentos = docColumns.map((items, key, index) => {

    return (
      
      <div className="columns" key={Math.random() + index}>
      {
        items.map((item) => {
          let status
          let documentos = docsResposta
          console.log("item antes do for")
          console.log(item.uuid)
          for(let i = 0; i < documentos.length; i ++){
            if(documentos[i].tipo.uuid === item.uuid ){
              status = documentos[i].status
              for(let j = 0; j < documentos.length; j ++){
                if(i !== j && documentos[j].tipo.uuid === item.uuid){
                  let dataPrimeiroDoc = Date.parse(documentos[i].dataSubmissao)
                  let dataSegundoDoc = Date.parse(documentos[j].dataSubmissao)
                  if(dataPrimeiroDoc < dataSegundoDoc){
                    status = documentos[j].status
                  }
                } 
              }
              break
            }
          }
          return <CardDocumento key={item.uuid} doc={item} status={status} />
        })
      }
      </div>
      
    );
  })
  const callbackModal = () =>{
    if(erro.isWrong === true){
      setErro({isWrong: false})
    }
  }

  return (
    <>
    {
        erro.isWrong === true ?
        (
          <Modal callbackModal={callbackModal} visivel={erro !== null} titulo={erro.titulo} conteudo={erro.mensagem} />
        ) : null
    }
    <div className="page-container-style">
      <DocumentoPageStyle>
        <DocumentoContainerStyle>
        <h1 className="title">Documentos</h1>
          <h2>Envie seus documentos</h2>
          <h3>Para aumentar o número de fretes simultâneos, é necessário enviar alguns documentos. O prazo para aprovação é de 48 horas.</h3>  
          <h3>Somente é permitido o upload de arquivos nos formatos pdf, jpeg, jpg, png ou gif, com tamanho máximo de 15MB.</h3>    
          {osDocumentos}

        </DocumentoContainerStyle>
      </DocumentoPageStyle>
    </div>
    </>
  );
}

export default Documentos;