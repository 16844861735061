import { useState } from 'react';
import { Link } from 'react-router-dom';
import AssinaturaService from '../../services/AssinaturaService';
import { LimitesFreteProgrecao, LimitesFretesStyle } from './style';
const assinaturaService = new AssinaturaService();

export default function LimitesFretes({numeroFretes, fretesAguardaPostar, fretesAtivos}) {
                    
    const [solicitarAumentoSucesso, setSolicitarAumentoSucesso] = useState(false);
    const [enviouDocumentos, setEnviouDocumentos] = useState(null);
    const solicitarAumento = () =>{
        assinaturaService.solicitarAumentoFrete((err, sucess)=> {
            if(err){
                console.log(err);
            }
            if(sucess){
                setEnviouDocumentos(sucess.pedidoIncrementoSucesso)
                setSolicitarAumentoSucesso(true);
            }
        })
    }

    return (
        <LimitesFretesStyle>
            <header>
                <h1 className="limites-fretes-title">Limite de fretes simultâneos</h1>

                <LimitesFreteProgrecao total={numeroFretes} andamento={fretesAtivos}>
                    <div className="limites-frete-progrecao-limite-area">
                        <div className="fretes-porcentagem-limite">
                            <span>{fretesAtivos}/{numeroFretes}</span>
                        </div>
                    </div>
                </LimitesFreteProgrecao>
            </header> 
            <div className="limites-fretes-content">
                <div className="aumentar-limite">{
                    !solicitarAumentoSucesso? <button className="button" onClick={solicitarAumento}>Aumentar Limite</button> : (
                        <>
                        {
                            enviouDocumentos === false ?
                            (
                                <div className="notification is-sucess">
                                    Precisa de mais fretes consecutivos? <Link to="/configuracoes/documentos">Clique aqui</Link> e envie os documentos necessários.
                                </div>
                            ) :
                            (
                                <div className="notification is-sucess">
                                    Sua solicitação foi recebida com sucesso e será tratada dentro de poucas horas.
                                </div>
                            )
                        }
                        </>
                    )
                }
                </div>

             
                <div>
                    <div className="limites-fretes-info">
                        <span>Pode gerar: </span> <strong>{numeroFretes - fretesAtivos} fretes</strong>
                    </div>
                    <div className="limites-fretes-info">
                        <span>Aguarda postar: </span> <strong>{fretesAguardaPostar} fretes</strong>
                    </div>
                    <div className="limites-fretes-info">
                        <span>Limite total: </span> <strong>{numeroFretes} fretes</strong>
                    </div>
                </div>
            </div>
        </LimitesFretesStyle>
    )
}
