import styled from "styled-components";

export const DocumentoPageStyle = styled.section`
	color: #282828;
	padding-top: 50px;
	margin-top: 2%;

	h1 {
		font-size: 1.8em;
        font-weight: 600;
		letter-spacing: 0.2px;
        text-align: center;
	}

    h2{
        font-size: 1.2em;
    }

`;

export const DocumentoContainerStyle = styled.div`
	margin-left: 25%;
	/* margin-right: 25%; */
	margin-bottom: 5%;


    h2{
		color: #282828;
        font-weight: 600;
        letter-spacing: 0.3px;
		margin-bottom: 15px;
        margin-top: 15px;
		text-align: left;
    }

    h3 {
        text-align: left;
        font-weight: 300;
        letter-spacing: 0.3px;
		margin-bottom: 15px;
    }

@media (max-width: 2560px) {
	margin-left: 20%;
	margin-right: 5%;
}

@media (max-width: 1440px) {
    margin-left: 25%;
	margin-right: 5%;
}

@media (max-width: 1024px) {
    margin-left: 13%;
	margin-right: 5%;
}

@media (max-width: 770px) {
    margin-left: 15%;
	margin-right: 5%;
}

@media (max-width: 430px) {
	margin-left: 20%;
	margin-right: 3%;
}
`;