import styled from "styled-components";
export const ConfirmacaoContentStyle = styled.div`
    margin-bottom: 40px;
    background-color: #fff;
    width: 80%;
    max-width: 800px;
    border-radius: 10px;
    padding: 30px;
   

    p{
        text-align: center;
    }

    ion-icon{
        font-size: 40px;
        vertical-align: middle;
        margin-right: 10px;
    }


    @media (max-width: 770px) {
        padding: 20px;
        padding-top: 0;
        p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
   
        ion-icon{
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

`