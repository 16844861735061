import {WhatsAppStyle} from "./style";

export default function WhatsApp(){
    return(
        <WhatsAppStyle>
            <iframe width="100" height="50" title="WhatsApp"
                    src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&&size=standard&radius=4px&number=5511965991503">
            </iframe>
        </WhatsAppStyle>
    )
}
