import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AutenticacaoService from '../../services/AutenticacaoService';
import { EmailCardStyle, EmailContainerStyle } from "./style";

function EmailConfirmacao(props) {
    const service = new AutenticacaoService();
    const [processando, setProcessando] = useState(false);
    const [mensagem, setMensagem] = useState({
        conteudo: "",
        statusCode: undefined,
        erro: false,
    });
    const history = useHistory();

    const useQuery =() => {
        return new URLSearchParams(useLocation().search)
    }
    let query = useQuery();
    let token = query.get("tokenValidacao")

    if(!processando){
        setProcessando(true);
        service.emailValidacao(token, (erro, sucesso) => {
            if(erro){
                setMensagem({
                    conteudo: erro.response.data.message,
                    statusCode: erro.status,
                    erro: true
                })
                return
            }
            if(sucesso){
                console.log(sucesso)
                setMensagem({
                    conteudo: "Parabéns! Sua Conta Flex foi ativada com sucesso! ",
                    statusCode: sucesso.status,
                    erro: false,
                })
                return
            }
        })
      }

    const levarParaLogin = () => {
        history.push("/login")
    }

    const voltarParaTelaPrincipal = () => {
        history.push("/")
    }
    return(
        <>
        <EmailContainerStyle>
            <EmailCardStyle>
                <h1 className="title">
                    {mensagem.conteudo}
                </h1>

                {
                    mensagem.erro === true ?
                    (
                        <button className="button irParaLoginBtn" onClick={voltarParaTelaPrincipal}>Voltar</button>
                    )
                    :
                    (
                        <button className="button irParaLoginBtn" onClick={levarParaLogin}>Acessar conta</button>
                    )
                }
            </EmailCardStyle>
        </EmailContainerStyle>
        </>
    );
}

export default EmailConfirmacao;
