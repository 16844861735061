import React from "react";
import Loader from "react-loader-spinner";
import {ModalSpinner, ModalSpinnerContainerStyle} from './style';

function FlexSpinner() {

    return(
        <>
        <ModalSpinner>
            <ModalSpinnerContainerStyle>
                <Loader
                    type="TailSpin"
                    color="#812990"
                    height={100}
                    width={100}
                />
            </ModalSpinnerContainerStyle>
        </ModalSpinner>
        </>
    );
}

export default FlexSpinner;
