import styled from "styled-components";

export const CalculadoraStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 3em;
    width: calc(100% - 3em);

    min-height: 100vh;
    >.page-title{
        margin-top:0;
    }
    
    @media(min-width: 1280px){
        left: 290px;
        width: calc(100% - 290px);   
    }
   @media(max-width:1280px){
      >.page-title{
        margin-top: 3em;
      }
   }
`;