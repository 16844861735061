import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { ActionCreators } from '../../_actions/ActionCreators';
import CalculadoraLogadaForm from '../../components/CalculadoraLogado/CalculadoraLogadoForm';
import CalculadoraLogadoInfosComplementares from '../../components/CalculadoraLogadoInfosComplementares/CalculadoraLogadoInfosComplementares';
import CalculadoraResultadoLogado from '../../components/CalculadoraResultadoLogado/CalculadoraResultado';
import CarrinhoService from '../../services/CarrinhoService';
import TagueamentoService from '../../services/TagueamentoService';
import { CalculadoraStyle } from './style';

const carrinhoService = new CarrinhoService();
const tagueamentoService = new TagueamentoService();

function Calculadora(props) {
  const [cotacoes, setCotacoes] = useState([]);
  const [redirect, setRedirect] = useState({dest: '', payload: undefined});
  const [editInfoComplementaresDados, setEditInfoComplementaresDados ] = useState(undefined);
  const [queryParams] = useState(queryString.parse(props.location.search));
  const dispatch = useDispatch();

  useEffect(() => {
    const callback = () =>{
      const {action}  = queryParams;
      if ( action === "editInfosComplementaresCarrinho" ){        
        setRedirect({dest: "editInfosComplementares"});
        let objEdit = {}
        objEdit = carrinhoService.getEditInfosParaCompra()
        setEditInfoComplementaresDados(objEdit);
        dispatch(ActionCreators.setFreteCotacaoSelecionada(objEdit.cotacao));
      }
    }
    callback();
    // TODO: esse useEffect deve ser usado apenas quando a pagina é carregada --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  useEffect(() => {
    if(!cotacoes){
      setCotacoes([])
    }
  }, [cotacoes])
  useEffect(() => {
    const {dest, payload} = redirect

    if(dest === "cotacoes"){
      setCotacoes([...payload])
      tagueamentoService.eventoMarketingCotacaoLogado();
    }else if(dest === "editInfosComplementares"){
      if(payload){
        setEditInfoComplementaresDados({...payload});  
      }
    }
    else{
      setCotacoes([])
    }
     
  }, [redirect])
  return (
    <div className="page-container-style"> 
        <CalculadoraStyle >
        <h2 className="page-title">Calculadora</h2>

        {
          redirect.dest === "editInfosComplementares"?
          ( 
            <CalculadoraLogadoInfosComplementares  redirect={setRedirect} edit={editInfoComplementaresDados} /> 
          ): 
          redirect.dest === "cotacoes"?
          (
            <CalculadoraResultadoLogado cotacoes={cotacoes} setCotacoes={setCotacoes} redirect={setRedirect} payload={redirect.payload}/>
          ): 
            <CalculadoraLogadaForm callback={setCotacoes} redirect={setRedirect} setEditInfoComplementaresDados={setEditInfoComplementaresDados}/>

          
        }
        
      </CalculadoraStyle>
    </div>
  );
}

export default Calculadora;
