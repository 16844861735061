import styled from "styled-components";

export const PerfilFormStyle = styled.section`
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	
	.perfil-div {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-top: 10px;
		padding-bottom: 10px;
	}

`;

export const PerfilContainerStyle = styled.section`
	position: relative;
	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
	width: 40%;
	
	span {
		font-weight: 300;
		text-align: left;
		padding-bottom: 10px;
	}

@media (max-width: 430px) {
	width: 90%;
}
`;

export const PerfilContentStyle = styled.section`
	border-radius: 20px;
	border: none;
	padding: 1em;
	background-color: white;
    -webkit-box-shadow: 13px 12px 9px -5px rgba(193,193,193,0.91); 
    box-shadow: 13px 12px 9px -5px rgba(193,193,193,0.91);

`;