import styled from "styled-components";

export const ConfirmacaoDeSaldoStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    min-height: 60vh;

    @media (max-width: 2560px) {
        left: 3em;
        width: calc(100% - 3em);
	}

    @media(min-width: 1280px){
        left: 290px;
        width: calc(100% - 290px);   
    }

    @media (max-width: 770px) {
        left: 83px;
        width: calc(90% - 60px);
	}
`;

export const ConfirmacaoCardStyle = styled.div`
    background-color: white;
    border-radius: 20px;

    .button{
        margin-top: 50px;
    }

    .button{
        background: rgb(131, 43, 146);
        background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
        );
        border: none;
        color: white;
        font-weight: 600;
        letter-spacing: 0.2px;
    }

    .button:hover{
        background: #c986d8;
        color: white;
    }

    @media (max-width: 2560px) {
		padding: 5em;
        .subTitle{
            margin-top: 50px;
            font-size: 1.2em;
        }
	}

	@media (max-width: 1440px) {
        padding: 3em;
		
	}

	@media (max-width: 770px) {
        padding: 2em;
        .title{
            font-size: 1.6em;
        }
        .subTitle{
            margin-top: 40px;
        }
	}
`;