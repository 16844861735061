import instance from "./Api";

export default class LojaService {
    getMeiosDePagamento(callback){
        instance
            .get(this.host() + "/api/v1/meiopagamento?ativo=true")
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) => {
                callback(erro, null)
            });
    }
    host() {
		return process.env.REACT_APP_HOST_API;
	}
}