import React from 'react';
import { moneyMask } from '../../utils/Masks';
import {CarteiraCardStyle} from './style';

function CarteiraFlexHeader({dinheiro, centavos, onClickInserirCreditos, assinatura}) {

  return (
    <>
        <CarteiraCardStyle>
            <div className="carteira-background">
              <h1>Carteira Flex</h1>
              <h2>Saldo Disponível:</h2>
                <p className="saldoTitle">R$ <span>{dinheiro}</span>,{centavos}</p>
                {
                  assinatura? (
                    <h3 className="saldoReservado"><ion-icon name="lock-closed-outline"></ion-icon> Saldo reservado: <span> {assinatura.saldoReservado ? moneyMask(assinatura.saldoReservado.toFixed(2)) : "R$ 0,00"}</span></h3>

                  ):""
                }
              <button className="button" onClick={onClickInserirCreditos}>Inserir créditos</button>

            {
              assinatura? (
                <div className="economizouChamado">
                  <p>Com Flex Frete você economizou:</p>
                  <span>R$ {assinatura.totalEconomia}</span>
                </div> 
              ): ""
            }
            </div>
        </CarteiraCardStyle>
    </>
  );
}

export default CarteiraFlexHeader;
