import styled from "styled-components";

export const CarteiraCardStyle = styled.div`
	h1 {
		color: #282828;
        font-size: 1.5em;
        font-weight: 600;
		letter-spacing: 0.2px;
	}

	h2{
		padding-top: 3rem;
	}
	h2, h3{
		color: #282828;
        letter-spacing: 0.3px;
    }

	h3{
		font-size: 0.9em;
	}
	margin: 1.5em;

	.carteira-background{
		border-radius: 20px;
		border: none;
		padding: 1em;
		background-color: #dddddd;
	}

    .carteira-background > form > label {
        display: block;
        text-align: left;
    }

	.saldoReservado > span{
		font-weight: 600;
	}

	ion-icon{
        font-size: 20px;
		color: #9339a2;
        vertical-align: middle;
    }

	.button{
		margin-top: 2rem;
		margin-bottom: 2rem;
        background: rgb(131, 43, 146);
	    background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
	    );
        border: none;
        color: white;
        font-weight: 600;
		letter-spacing: 0.2px;
    }

	.button:hover{
		background: #c986d8;
  		color: white;
	}

    .saldoTitle{
        color: #009f57;
    }

	.saldoTitle > span{
		font-size: 5rem;
		font-weight: 600;
	}

	.economizouChamado{
		border-radius: 15px;
		border: none;
		padding: 1em;
		background-color: white;
	}

	.economizouChamado > p{
		text-align: left;
	}

	.economizouChamado > span{
		display: block;
		font-weight: 600;
		text-align: left;
	}

    /* .resultadoBusca > p > .totalValorEconomizou{
        color: #e6b222;
    } */

@media (max-width: 2560px) {
	
	.economizouChamado{
		margin-left: 30%;
		margin-right: 30%;
	}
}
	
@media (max-width: 1024px) {
	
	.economizouChamado{
		margin-left: 20%;
		margin-right: 20%;
	}
}

@media (max-width: 770px) {
	.economizouChamado{
		margin-left: 0;
		margin-right: 0;
	}
}
.economizouChamado{
	display: none;
}
	
`;