import instance from "./Api";

export default class DocumentosService {
    obterTipoDocumento(callback){
        instance
            .get(this.host() + "/api/v1/tipo-documento")
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    gerarTermosUso(callback){
        instance
            .get(this.host() + "/api/v1/termosAdesao", {responseType : 'blob'})
            .then((response) => {
                callback(null, response.data)
            })
            .catch((err) => {
                callback(err, null)
            })
    }

    gerarContratoPdf(callback) {
        instance
        .get(this.host() + "/api/v1/contrato", { responseType: 'blob'})
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    obterRespostaDoDocumento(uuidAssinatura, callback){
        instance
        .get(this.host() + "/api/v1/documento?account=" + uuidAssinatura)
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    registrarDocumento(file, docUuid, assinaturaUuid, callback) {
        const arquivo = new FormData();
        arquivo.append('arquivo', file);
        
        instance
            .post(this.host() + "/api/v1/registro/documento", arquivo, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'assinatura': assinaturaUuid,
                    'tipo-documento': docUuid
                },
                onUploadProgress: uploadResult => {
                    callback(null, null, uploadResult)
                },
            })
            .then((response) => {
                callback(null, response.data ,null)
            })
            .catch((erro) => {
                callback(erro, null, null)
            })

    }

    registrarAssinaturaDigital(assinaturaDigital, callback){
        instance
        .post(this.host() + "/api/v1/registro/documento/assinaturadigital", assinaturaDigital)
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    host() {
		return process.env.REACT_APP_HOST_API;
	}
}