import styled from "styled-components";

export const NotificationStyle = styled.span`
		background-color: #e6b222;
        color: white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
		border-radius: 50%;
        width: 18px;
        height: 18px;
        font-size:1.25rem;
		line-height: 22px;
        position: absolute;
        left: 35px;
        z-index: 1;

        p{
            text-align: center;
        }
     

       @media(max-width: 375px){
            font-size: 1.1rem;
            width: 14px;
            height: 14px;
        }
        @media(max-height: 475px){
            font-size: 1rem;
            width: 12px;
            height: 12px;
       }
`;

