import React, {useState, useEffect} from "react";
import { NotificacaoStyle, NotificacaoFormStyle, InputDate, 
    SubmitButton, NotificaoFormHeader, SelectInput } from "./style";
import NotificacaoService from '../../services/NotificacaoService';
import FlexSpinner from "../FlexFreteSpinner/FlexSpinner";


export  function NotificacaoForm({callBack}){
    const [loading, setLoading] = useState(false);

    const [request, setRequest]  = useState({
        status: "nao_lidas",
        datainicio: undefined,
        datafim: undefined,
        pageSize: 10,
        page: 0

    });
    
    async function submitForm(e){
        e.preventDefault();

        setLoading(true);          
    }
    useEffect( () => {
        if(loading) {
            const service = new NotificacaoService();

            service.obterNotificacoes(request, (erro, sucess) => {    
                if(erro){
                    console.log(erro);
                }else{
                    callBack(sucess);
                }
                setLoading(false);
            });
           
        }
    }, [loading, callBack, request]);
    return (
        <NotificacaoStyle>
            <NotificacaoFormStyle onSubmit={submitForm}>
                {
                    loading? <FlexSpinner/> :""
                }
                    <NotificaoFormHeader>
                        <span>Pesquisar</span>
                    </NotificaoFormHeader>

                    <div className="notificacoes-input">
                        <div>
                            <label htmlFor="datainicio"> Período de início:</label>
                            <InputDate type="date" name="datainicio" onChange = {(e) => setRequest({...request, datainicio: e.target.value})}/>
                        </div>
                        <div>
                            <label htmlFor="datafim"> Período de término:</label>
                            <InputDate type="date" name="datafim" onChange = {(e) => setRequest({...request, datafim: e.target.value})}/>
                        </div>
                        
                        <SelectInput name="status" onChange = {(e) => setRequest({...request, status: e.target.value})}>
                            <option value="nao_lidas">Não lidas</option>
                            <option value="lidas">Lidas</option>
                            <option value="todas">Todas</option>
                        </SelectInput>

                         <SubmitButton type="submit" disabled= {loading} >Buscar</SubmitButton>

                </div>
                

            </NotificacaoFormStyle>
        </NotificacaoStyle>
       
    )
}