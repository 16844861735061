import api from './Api';

export default class AgenciaService{

    obterAgenciasDeUmaTransportadora(transportadoraUuid, cep, callback){
        api
            .get(this.host() + '/api/v1/transportadora/' + transportadoraUuid + "/agencia?cep=" + cep, {
            })
            .then((response)=>{
                callback(null, response.data);
            })
            .catch((err)=>{
                console.log(err)
                callback(err, null);
            })
    }
  
    host(){        
		return process.env.REACT_APP_HOST_API;
    }
}