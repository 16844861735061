import React from "react";
import { ModalContainerStyle, ModalContentStyle, BtnModalSeguirStyle,BtnModalVoltarStyle } from "./style";

/**
 *
 * @param {Object} props - Propriedades passadas para o componente Modal.
 * @param {boolean} props.visivel - Controla a visibilidade do modal.
 * @param {string} props.titulo - Título do modal.
 * @param {React.ReactNode} props.children - Conteúdo do modal inserido entre as tags do componente.
 * @param {string} props.labelConfirmButton - Texto do botão de confirmação.
 * @param {string} props.labelCancelButton - Texto do botão de cancelamento.
 * @param {Function} props.onChange - Função chamada ao interagir com o botão de confirmação.
 *
 * @returns {React.Component} - Um componente Modal com estilo e funcionalidade básicos.
 */
function ModalEnvios({ visivel, titulo, children, labelConfirmButton, labelCancelButton, onChange }) {
    const modalClass = visivel ? "modal is-active" : "modal";
    return (
        <div className={modalClass}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <ModalContainerStyle>
                    <ModalContentStyle>
                        <header>
                            <h1>{titulo}</h1>
                            <button className="modal-close" aria-label="close" onClick={(e) => onChange(e, false)}></button>
                        </header>
                        <section>
                            {children} {/* Componentes ou elementos passados como filhos do Modal serão renderizados aqui */}
                        </section>
                        <footer className="is-flex-direction-row is-justify-content-flex-end">
                            <BtnModalVoltarStyle className="button mr-6" onClick={(e) => onChange(e, false)}>
                                {labelCancelButton}
                            </BtnModalVoltarStyle>
                            <BtnModalSeguirStyle className="button" onClick={(e) => onChange(e, true)}>
                                {labelConfirmButton}
                            </BtnModalSeguirStyle>
                        </footer>
                    </ModalContentStyle>
                </ModalContainerStyle>
            </div>
        </div>
    );
}

export default ModalEnvios;
