import axios from 'axios';
export default class AutenticacaoService {

        updateUsuarioCorrente(success){
            const usuarioStorage = localStorage.getItem('usuario');
            const usuarioCorrente = JSON.parse(usuarioStorage);

            const usuarioAtualizado = {
                ...usuarioCorrente,
                name: success.nome,
                sobrenome: success.sobrenome
            };

            this.setUsuarioCorrente(usuarioAtualizado);
        }

       /**
     * Retorna o usuário atualmente autenticado na aplicação
     */
        usuarioCorrente(){
            let isConteudo = localStorage.getItem('usuario')
            if(isConteudo){
                return JSON.parse(isConteudo)
            } else{
                return null;
            }
        }

        enderecoCorrente(){
            let isConteudo = localStorage.getItem('usuario-endereco')
            if(isConteudo){
                return JSON.parse(isConteudo)
            } else{
                return null;
            }
        }


        /**
         * Cria o armazenamento local das informações do usuário
         * @param {*} usuario
         */
        setUsuarioCorrente(usuario){
            localStorage.setItem('usuario', JSON.stringify(usuario))
        }

        setEnderecoCorrente(endereco){
            localStorage.setItem('usuario-endereco', JSON.stringify(endereco))
        }


        token(){
            let isCorrente = this.usuarioCorrente()
            if(isCorrente){
                return isCorrente.token
            } else {
                return null
            }
        }


        /**
         * Realiza o logout do usuário na aplicação e limpa o localstorage
         * @param {*} callback
         */
        logout(callback){
            localStorage.removeItem('usuario')
            localStorage.removeItem('usuario-endereco')
            localStorage.removeItem('loja')
            localStorage.removeItem('perfil-selecionado')
            localStorage.removeItem('assinatura-selecionada')
            localStorage.removeItem('perfis')
            localStorage.removeItem('valorEncomenda')
            localStorage.removeItem('calculadoraFormInfos')
            callback()
        }


        /**
         * Autentica o usuário no sistema
         * @param {*} credenciais
         * @param {*} callback
         */
        auth(credenciais, callback){
            axios.post(this.host() + '/api/v1/auth', credenciais)
                .then((response)=>{
                    const {data}  = response;
                    this.setUsuarioCorrente(data);
                    this.setEnderecoCorrente(data.endereco)
                    callback(null, response.data);
                })
                .catch((err)=>{
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('usuario-endereco')
                    callback(err, null)
                })
        }

        mandarNovamenteValidacaoEmail(documentoCpf, callback){
            axios.post(this.host() + '/api/v1/auth/emailValidation', documentoCpf)
                .then((response)=>{
                    callback(null, response.data);
                })
                .catch((err)=>{
                    callback(err, null)
                })
        }

        /**
         *
         * Confirma se o email do usuário foi validado com sucesso
         *
         */

        emailValidacao(token, callback){
            axios.get(this.host() + '/api/v1/usuario/registro/email/' + token)
            .then((response) => {
                callback(null, response)
            })
            .catch((err)=>{
                callback(err, null)
            })
        }

    host() {
		return process.env.REACT_APP_HOST_API;
	}
}
