export default class DownloadService {
        
    list = {
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'jpg': 'image/jpg',
        'pdf': 'application/pdf',
        'csv': 'text/csv',
        'xml': 'application/xml'
    }
    getMimeFromExtension(extension){
        const mime = this.list[extension];
        if(!mime){
            console.log('Format not suported.');
        }
        return mime;
    }
    getFileExtension(mime){
        for(let key in this.list){
            if(this.list[key] === mime){
                return key
            }
        }
        console.log('Mime not suported.');
    }
    downloadFile({label, data, filename, extension, mime} ) {
        let mimeAux, extensionAux;
        if(mime){
            mimeAux = mime;
            extensionAux = this.getFileExtension(mimeAux);
        }else{
            mimeAux  = this.getMimeFromExtension(extension);
            extensionAux = extension
        }
        if(!mimeAux){
            return;
        }
        const blob = new Blob([data], { type: mimeAux });
        const url = window.URL.createObjectURL(blob);
        
        const anchor = document.createElement("a");

        anchor.download =`FlexFrete_${label || ""}_${new Date().getTime()}.${extensionAux}`
        anchor.href = url;
        anchor.click();
    }
    
    host() {
		return process.env.REACT_APP_HOST_API;
	}
}