export default class TagueamentoService {
  eventoMarketingCadastro() {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "generate_lead", // Nome do evento
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google", ex);
    }
  }

  eventoMarketingCotacao(cotacao) {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "simulate_shipping", // Nome do evento
        CEP_origin_city: cotacao.cepOrigem, // CEP da cidade de origem
        CEP_destination_city: cotacao.cepDestino, // CEP da cidade de destino
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google", ex);
    }
  }

  eventoMarketingInserirSaldo(tipo, valor) {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "confirm_value", // Nome do evento
        payment_type: tipo, // Informear meio de pagamento: crédito ou boleto
        value: valor, // Informar o valor de crédito inserido pelo usuário
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google", ex);
    }
  }

  eventoMarketingCreditoInserido() {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "credit_inserted", // Nome do evento
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google");
    }
  }

  eventoMarketingCotacaoLogado() {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "calculate_shipping", // Nome do evento
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google", ex);
    }
  }

  eventoMarketingItemSelecionado(cotacao) {
    try {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "select_item",
        deadline: cotacao.prazoDeEntrega.tempoMaximo, // Inserir informação de prazo de entrega
        ecommerce: {
          items: [
            {
              item_id: cotacao.uuid, // Inserir código do frete
              price: cotacao.valorFrete, // Inserir valor do frete
              item_brand: cotacao.transportadora.nomeTransportadora, // Inserir empresa responsável pelo frete
              item_category: cotacao.produto.nomeProduto, // Inserir a modalidade do frete
              quantity: "1", // Inserir a quantidade selecionada para o item
            },
          ],
        },
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google", ex);
    }
  }

  eventoMarketingAdicionarAoCarrinho(cotacao) {
    try {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_to_cart",
        deadline: cotacao.prazoDeEntrega.tempoMaximo, // Inserir informação de prazo de entrega
        ecommerce: {
          items: [
            {
              item_id: cotacao.uuid, // Inserir código do frete
              price: cotacao.valorFrete, // Inserir valor do frete
              item_brand: cotacao.transportadora.nomeTransportadora, // Inserir empresa responsável pelo frete
              item_category: cotacao.produto.nomeProduto, // Inserir a modalidade do frete
              quantity: "1", // Inserir a quantidade selecionada para o item
            },
          ],
        },
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google", ex);
    }
  }

  eventoMarketingCheckoutCarrinho(fretesCarinho) {
    if (fretesCarinho) {
      try {
        const itens = [];
        for (let frete of fretesCarinho) {
          itens.push({
            item_id: frete.uuid, // Inserir código do frete
            price: frete.valorFrete, // Inserir valor do frete
            item_brand: frete.transportadora.nomeTransportadora, // Inserir empresa responsável pelo frete
            item_category: frete.produto.nomeProduto, // Inserir a modalidade do frete
            quantity: "1", // Inserir a quantidade selecionada para o item
            origin_city: frete?.remetente?.endereco?.cidade, // Cidade de Origem
            CEP_origin_city: frete.cotacao.cepOrigem, // CEP cidade de Origem
            destination_city: frete?.destinatario?.endereco?.cidade, // Cidade de Destino
            CEP_destination_city: frete.cotacao.cepDestino, // CEP cidade de destino
            deadline: frete.cotacao.prazoDeEntrega.tempoMaximo, // Inserir prazo de entrega
          });
        }

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            items: itens,
          },
        });
      } catch (ex) {
        console.log("Tivemos problemas com o Google", ex);
      }
    }
  }

  eventoMarketingConfirmacaoCompraPagamento(
    fretesCarinho,
    somaValorTotalFretes,
    uuidCarinho
  ) {
    try {
      const itens = [];
      for (let frete of fretesCarinho) {
        itens.push({
          item_id: frete.uuid, // Inserir código do frete
          price: frete.valorFrete, // Inserir valor do frete
          item_brand: frete.transportadora.nomeTransportadora, // Inserir empresa responsável pelo frete
          item_category: frete.produto.nomeProduto, // Inserir a modalidade do frete
          quantity: "1", // Inserir a quantidade selecionada para o item
        });
      }

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "BR",
          value: somaValorTotalFretes, // Valor final da compra
          transaction_id: uuidCarinho, // Informar o ID da transação
          items: itens,
        },
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google", ex);
    }
  }

  eventoMarketingFinalizarPagamento(fretesCarinho) {
    try {
      const itens = [];
      for (let frete of fretesCarinho) {
        itens.push({
          item_id: frete.uuid, // Inserir código do frete
          price: frete.valorFrete, // Inserir valor do frete
          item_brand: frete.transportadora.nomeTransportadora, // Inserir empresa responsável pelo frete
          item_category: frete.produto.nomeProduto, // Inserir a modalidade do frete
          quantity: "1", // Inserir a quantidade selecionada para o item
          deadline: frete.cotacao.prazoDeEntrega.tempoMaximo, // Inserir prazo de entrega
        });
      }

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
          shipping_tier: "FlexFrete",
          items: itens,
        },
      });
    } catch (ex) {
      console.log("Tivemos problemas com o Google", ex);
    }
  }
}
