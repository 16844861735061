import React,{useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Input from '../../components/FlexFreteInput/Input'
import {ResetPasswordStyle, FormContainerStyle, BtnLoginRegisterChangePasswordStyle} from './style';
import UsuarioService from '../../services/UsuarioService';

import ValidaSenha from '../../utils/ValidaSenha';
const validaSenha = new ValidaSenha();

function NewPassword() {
  const [body, setBody] = useState({
    token: '',
    novaSenha: '',
    confirmaNovaSenha: ''
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState({field: '', message: ''});
  const [mensagemErro, setMesagemErro] = useState(null);
  const [sucesso, setSucesso] = useState(null);

  useEffect(()=>{
    if(body.confirmaNovaSenha.length && body.novaSenha !== body.confirmaNovaSenha){
        setInputError({field: "novaSenhaConfirmacao", message: "Senhas não coincidem."});
        setIsDisabled(true);
    }else{
        if( body.novaSenha.length && !validaSenha.senha(body.novaSenha)){
            setIsDisabled(true);
            setInputError({field: "novaSenha", message: "A senha deve ter no mínimo 8 caracteres, possuir letras(maiúscula e minúsculas), números e caracteres especiais."});
        }else if(body.novaSenha.length){
            setIsDisabled(false);
            setInputError(undefined);
        }

    }
    
  }, [body]);
 
  const searchParams = useLocation().search;

  useEffect(()=>{

    const url = new URLSearchParams(searchParams);
    setBody(body => {
      return {...body, 
      token: url.get("token")}
    });
    if(!url.get("token")){
      setIsLoading(true);
      setMesagemErro("Impossível proseguir com a solicitação.")
    }
  }, [searchParams]);

  const submit = (e) =>{
    e.preventDefault();

    if(isDisabled) return;
  
    setIsLoading(true);

    const usuarioService = new UsuarioService();
    usuarioService.restauroSenha(body, (err, sucess)=>{
        if(err){
            if(err?.response?.data?.message) {
              setMesagemErro(err?.response?.data?.message);
            }else{
              setMesagemErro("Erro ao proseguir com a solicitação. Por favor, tente novamente mais tarde.");
            }
            console.log(err);
        }else{
            setMesagemErro(undefined);
            setSucesso(true);
        }
        setIsLoading(false);
    });

}
  return (
    <ResetPasswordStyle> 
        <h1 className="title">Nova Senha</h1>
        <FormContainerStyle>
        {!sucesso? (

          <form autoComplete="off" onSubmit={(e) => { submit(e)}}>
              <Input type="password" name="novaSenha" label="Inserir Nova Senha" required={true} onChange= {(e)=> setBody({...body, novaSenha: e.target.value})}/>
              <p className="help">A senha deve ter no mínimo 8 caracteres, possuir letras(maiúscula e minúsculas), números e caracteres especiais.</p>
              
              <Input type="password" name="confirmaNovaSenha" label="Confirmar Senha" required={true} onChange= {(e)=> setBody({...body, confirmaNovaSenha: e.target.value})}/>

            <BtnLoginRegisterChangePasswordStyle className="button" disabled={isDisabled || isLoading} >Salvar</BtnLoginRegisterChangePasswordStyle>

            {mensagemErro || inputError?.message?
              (<div className="notification is-warning">
                {mensagemErro || inputError?.message}
              </div>):""
            }      
          </form>
        ):(
          <>
            <h3>Parabéns!</h3>

            <h3>Senha alterada com sucesso!</h3>

          <Link to="/login">  
            <BtnLoginRegisterChangePasswordStyle className="button">Acessar</BtnLoginRegisterChangePasswordStyle>
          </Link>

          </>
        )}
        </FormContainerStyle>
    </ResetPasswordStyle>
  );
}

export default NewPassword;
