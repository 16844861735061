import styled from "styled-components";

export const showDesktopMobile = styled.div`

	@media screen and (min-width: 2560px) {
			.mobile-container{
			display: none;
		}
		}
		
		@media screen and (max-width: 1920px) {
			.mobile-container{
			display: none;
		}
		}

		@media screen and (max-width: 1440px) {
		.mobile-container{
			display: none;
		}
		}

		@media screen and (max-width: 700px) {
			.mobile-container{
				display: flex;
			}
			.desktop-container{
				display: none;
			}
		}
`

export const LandingPageContainerStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	
	.fundo-amarelo{
		background: #EBB013;
		color: #340040;
	}

	.texto-inicio li{
		color: #340040;
		font-family: 'Inter';
		font-weight: 700;
		letter-spacing: -0.025em;
		/* font-size: 1.2em; */
		text-align: left;
	}

	@media screen and (max-width: 1200px) {
		.texto-inicio li{
			font-size: 0.9em;
		}
		.title-principal{
			font-size: 1.5em!important;
		}
		.landing-woman{
			top: 13em;
		}
		.flex-frete-logo-bg{
			top:18em;
		}
		/* .center{
			margin-bottom: 10em;
		} */
	}

	@media screen and (min-width: 1280px) and (max-width: 1800px) {
		 .landing-woman-card-1{
			transform: scale(1.1);
		}
	}

`

export const LandingPageStyle = styled.div`
.desktop-container{
	background-color: #FFFFFF;
	color: #ffffff;
	padding-top: 60px;
	padding-bottom: 25vh;
	padding-right: 1.25em;
	padding-left: 1.25em;
	position: relative;

	height: 90vh;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	padding: 0;
	
	h1 {
		color: white;
		font-weight: 700;
		margin-bottom: 40px;
	}

	ul {
		margin: 30px;
		font-size: 2.5em;
	}

	.title-principal{
		letter-spacing: -0.025em;
		color: #340040;
		font-family: 'Inter';
		line-height: 4.5em;
		font-size: 3.125em;
	}

	.desktop.center{
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		ul{
			margin: 0;
			margin-top: 60px;
		}
		z-index: 2;
		/* margin-left: 40em; */
	}

	.images{
		margin-right: 1em;
	}
	
	.flex-frete-logo-bg{
		position: absolute;
		top: 18em;
		opacity: 0.2;
		z-index: 0;
		left: 3em;
		transform: scale(1.2);
		> img{
			
			height: auto;
		}
	}

	/* .landing-woman{
		position: absolute;
		left: 147px;
		bottom: 142px;
		transform: scale(1.6);
	} */

	.landing-woman > img{	
		height: auto;
	}

	@media screen and (min-width: 2560px) {
		height: 82vh;

		ul {
			margin: 30px;
			font-size: 4em;
		}

		.title-principal{
			font-size: 6em;
		}

		.desktop.center{
			margin-top: 10em;
			margin-left: 78em;
		}

		.flex-frete-logo-bg{
			left: 17em;
			bottom: 96em;
			transform: scale(1.7);
		}
		.landing-woman{
			left: 16em;
			bottom: 10em;
			transform: scale(1.7);
		}
	}
	
	@media screen and (max-width: 1920px) {
			.desktop.center{
				margin-left: 40em;
			}

			.landing-woman{
				position: absolute;
				left: 147px;
				bottom: 142px;
				transform: scale(1.6);
			}
	}

	@media screen and (max-width: 1440px) {
		padding-top: 4em;
		/* height: 60%; */
		.flex-frete-logo-bg{
			top: 7em;
			left: -5em;
			transform: scale(1.1);
		}
		/* .landing-woman{
			left: -3em;
			top: 1em;
			transform: scale(0.9);
		} */

		.landing-woman{
			position: absolute;
			left: 3em;
			top: 4em;
			transform: scale(1.2);
		}

		ul {
			font-size: 2em;
		}

		.title-principal{
			font-size: 3em;
		}

		button{
			margin-bottom: 3.5em;
		}
	}
	
}

@media screen and (min-width: 1280px) {
		.card-1{
			height: 95vh;
		}
	}


	@media screen and (min-width: 2560px) {
		.mobile-container{
		display: none;
	}
	}
	
	@media screen and (max-width: 1920px) {
		.mobile-container{
		display: none;
	}
	}

	@media screen and (max-width: 1440px) {
		.mobile-container{
		display: none;
	}
	}

	@media screen and (max-width: 700px) {
		.mobile-container{
			display: flex;

			background-color: #FFFFFF;
			color: #ffffff;
			padding-top: 60px;
			padding-bottom: 25vh;
			padding-right: 1.25em;
			padding-left: 1.25em;
			position: relative;
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			align-items: center;
			padding: 0;
			min-width: 100%;

			ul {
				/* margin: 30px; */
				font-size: 1.5em;
			}

			.title-principal{
				letter-spacing: -0.025em;
				color: #340040;
				font-family: 'Inter';
				line-height: 4.5em;
				font-size: 2.5em;
			}

			.desktop.center{
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				ul{
					margin: 0;
					margin-top: 60px;
				}
				z-index: 2;

				margin-bottom: 20em;
			}

			/* .images{
				margin-right: 1em;
			} */
			
			.flex-frete-logo-bg{
				position: absolute;
				top: 18em;
				opacity: 0.2;
				z-index: 0;
				left: 2em;
				transform: scale(1.2);
				> img{
					
					height: auto;
				}
			}

			.landing-woman{
				position: absolute;
				top: 11em;
				left: -2em;
				bottom: 5px;
				transform: scale(0.9);
			}

			.landing-woman > img{	
				height: auto;
			}
		}

		button{
			margin-top: 1.5em;
			margin-bottom: 1em;
		}

		.desktop-container{
			display: none;
		}
	}

`;

export const ComoFuncionaStyle = styled.div`
	background: #340040;
	color: white;
	position: relative;

	.title {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 2.5em;
		line-height: 50px;
		color: #EBB013;
		text-align: center;
		letter-spacing: -0.025em;
		margin: 0!important;
	}

	.flex-frete-logo-bg{
		position: absolute;
		opacity: 0.5;
		z-index: 0;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
		transform: scale(1.2);
		margin-right: 12em;

		.image{
			transform: scale(1.1);
		}
	}

	h4{
		margin-top: 3em;
		color: #F2F2F2;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 2em;
		/* line-height: 2em; */
	}

	.box{
		background: #340040;
		display: flex;
		flex-direction: row;
		margin-top: 2em;
		margin-bottom: 2em;
	}

	.elemento{
		padding: 0.75em;
		display: flex;
		flex-direction: row;
		width: 20%;
		z-index: 1;
		text-align: center;

		img {
			padding-top: 3em;
		}
	}
	.box h1{
		color: #EBB013;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 6.5em;
		line-height: 1.75em;
		text-align: right;
		margin-right: 0.125em;
	}

	.box p{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 1.1em;
		line-height: 1.8em;
		color: #FFFFFF;
		/* margin-right: 0.5em; */
		margin-right: 1.5em;
		text-align: center;
	}

	p{
		font-size: 1.2em;
		letter-spacing: 0.2px;
	}

	.flex-frete-logo-bg{
			.image{
				right: 5em;
				/* transform: scale(1.2); */
			}
		}
		@media screen and (min-width: 1440px) {
			.flex-frete-logo-bg{
			.image{
				right: 5em;
			   transform: scale(1.4); 
			}
		}
		}
	@media screen and (min-width: 2560px) {
		padding: 6.25em 6.25em 6.25em 6.25em;

		h4{
			margin-top: 3em;
		}
		.box{
			margin-top: 6em;
			margin-bottom: 6em;
			margin-left: 35em;
			margin-right: 35em;
		}

		.box p{
			font-size: 1.2em;
		}

		.flex-frete-logo-bg{
			.image{
				right: 5em;
				/* transform: scale(1.2); */
			}
		}
		.mobile-container{
				display: none;
			}
	}

	@media screen and (max-width: 1920px) {
		padding: 4em 6.25em 3em 6.25em;
		.box{
			margin-top: 4em;
			margin-bottom: 4em;
			margin-left: 17em;
			margin-right: 17em;
		}

		.flex-frete-logo-bg{
			.image{
				right: 5em;
				/* transform: scale(1.2); */
			}
		}
		.mobile-container{
				display: none;
			}
	}

	@media screen and (max-width: 1440px) {
		.box{
			margin-left: 0px;
			margin-right: 0px;
		}

		.flex-frete-logo-bg{
			.image{
				top: 4em;
				right: 2em;
				transform: scale(1.35);
			}
		}

		.mobile-container{
				display: none;
			}
	}

	@media screen and (max-width: 700px) {
		padding: 2em 2em 1em 2em;

		.flex-frete-logo-bg{
			.image{
				top: 6em;
				right: 1em;
				/* right: 5em; */
				transform: scale(12);
			}
		}

		.title{
			font-size: 1.4em;
			line-height: 25px;
		}

		h4{
		font-size: 1.5em;
	}

	.box-mobile{
		background: #340040;
		display: flex;
		/* flex-direction: column; */
		/* margin-top: 2em; */
		/* margin-bottom: 2em; */
	}

	.elemento-mobile{
		padding: 0.75em;
		display: flex;
		flex-direction: row;
		/* width: 20%; */
		z-index: 1;
		text-align: center;

		img {
			padding-top: 3em;
		}
	}
	.box-mobile h1{
		color: #EBB013;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 6.5em;
		line-height: 1.75em;
		text-align: right;
		margin-right: 0.125em;
	}

	.box-mobile p{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 1.3em;
		line-height: 1.8em;
		color: #FFFFFF;
		/* margin-right: 0.5em; */
		margin-right: 1.5em;
		text-align: center;
	}

	.dots{
		margin-bottom: 2em;
		span{
			height: 10px;
			width: 10px;
			background-color: white;
			border-radius: 50%;
			display: inline-block;
			margin-right: 1em;

		}
	}

		.desktop-container{
			display: none;
		}

		.mobile-container{
			display: flex;
		}
	}
	
`;

export const TransportadorasStyle = styled.div`
	background: #F0F0F0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 3em;
	padding-top: 5em;

	section{
		display: flex;
	}

	h2 {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 2.5em;
		line-height: 125%;
		text-align: center;
		letter-spacing: -1px;
		color: #340040;
	}

	.content {
		margin-top:1.5em;
		margin-bottom:2em;
	}
	.fundo-amarelo {
		background: #EBB013;
	}

	.logo img {
		width: 10em!important;
	}

	.logo-jadlog img {
		width: 8em!important;
	}

	.transportadoras{
		display: flex;
		justify-content: right;
	}

	.logo{
		position:absolute;
		top: -7em;
		left: 6em;
		z-index: 1;
	}

	.logo-jadlog{
		top: -6em;
		left: 7em;
	}

	.box-transportadoras{
		height: fit-content;
		text-align: left;
		padding: 1em;
		border: 1px solid #EBB013;
		box-sizing: border-box;
		border-radius: 1.25em;
		margin-left: 3em;
		width: 25%;
		position: relative;
		margin-top: 3em;
		z-index: 0;
		border-top-style:revert;

		padding-right: 3em;
	}

	.box-transportadoras span{
		margin-bottom: 1.4em;
		display: block;
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 1.3em;
		line-height: 186%;
		letter-spacing: 2px;
		text-transform: uppercase;

		color: #340040;
	}

	.box-transportadoras p{
		margin-bottom: 2em;
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 1.2em;
		line-height: 162% ;
		color: #340040;
		mix-blend-mode: normal;
	}

	h3{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 2em;
		margin-top: 3em;
		margin-bottom: 2em;
		color: #340040;
	}
	@media screen and (min-width: 1400px) {
		.box-transportadoras{
			width: 32%;
		}
	}

	@media screen and (min-width: 2560px) {
		h2{
			margin-bottom: 5em;
		}

		.logo {
			left: 5em;
		}

		.logo-jadlog{
			left: 6.5em;
		}

		.transportadoras {
			margin-left: 12em;
			justify-content: center;
		}

		.box-transportadoras {
			margin-top: -46px;
			width: 22%;

			/* padding-right: 9em; */
		}

		.box-transportadoras span{
			font-size: 1.8em;
		}

		.box-transportadoras p{
			font-size: 1.6em;
		}
		.entregador {
			margin-top: 2em;
		}

		.entregador img {
			transform: scale(1.8);
			left: 13em;
		}

	}

	@media screen and (max-width: 1920px) {

		h2 {
			margin-bottom: 2em;
		}

		h3 {
			margin-top: 4em;
		}

		.transportadoras{
			display: flex;
			justify-content: center;
			margin-left: 2em;
		}

		.entregador {
			margin-top: 2em;
		}

		.entregador img {
			transform: scale(1);
			left: 0em;
		}
		.image{
			top:-4em;
		}
	}

	@media screen and (max-width: 1440px) {
		h2 {
			margin-bottom: 1.7em;
		}
		h3{
			margin-top: 0px;
			font-size: 1.8em;
			margin-bottom: 1em;
		}
		.logo {
			left: 3em;
		}

		.logo-jadlog{
			left: 4em;
		}

		.box-transportadoras span{
			font-size: 1.1em;
		}

		.box-transportadoras p{
			font-size: 1em;
		}

		.transportadoras{
			margin-left: 2em;
			justify-content: center;
		}

		.box-transportadoras{
			width: 35%;
		}

		.image{
			top:-4em;
		}

	}

		@media screen and (min-width: 2560px) {
			.mobile-container{
			display: none;
		}
		}
		
		@media screen and (max-width: 1920px) {
			.mobile-container{
			display: none;
		}
		}

		@media screen and (max-width: 1440px) {
		.mobile-container{
			display: none;
		}
		}

		@media screen and (max-width: 700px) {
			align-items: initial;
			padding-top: 2em;
			padding-bottom: 2em;
			margin-left: 0;
			
			.mobile-container{
				display: flex;
			}
			.desktop-container{
				display: none;
			}
			.transportadoras{
				margin-left: 0;
			}
			
			h2{
				font-size: 1.5em;
				margin-bottom: 2em;
			}

			h3{
				font-size: 1.4em;
				margin-bottom: 0.8em;
			}

			.logo-v2-mobile img {
				width: 17em!important;
			}

			.logo-jadlog-v2-mobile img {
				width: 13em!important;
			}

			.dots{
				margin-bottom: 2em;
				span{
					height: 10px;
					width: 10px;
					background-color: white;
					border-radius: 50%;
					display: inline-block;
					margin-right: 1em;

				}
			}

			.logo-mobile {
				left: 5em;
			}

			.logo-jadlog-mobile{
				left: 6em;
			}

			/* .transportadoras{
				margin-left: 0px;
			} */

			.box-transportadoras-mobile{
				height: fit-content;
				text-align: left;
				padding: 1em;
				border: 1px solid #EBB013;
				box-sizing: border-box;
				border-radius: 1.25em;
				margin-left: 8vh;
				margin-right: 0px;
				width: 70vw;
				position: relative;
				margin-top: 6em;
				z-index: 0;
				border-top-style:revert;
				padding-right: 3em;
			}

			.box-transportadoras-mobile span{
				margin-bottom: 1.4em;
				display: block;
				font-family: 'DM Sans';
				font-style: normal;
				font-weight: 700;
				font-size: 1em;
				line-height: 186%;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: #340040;
			}

			.box-transportadoras-mobile p{
				margin-bottom: 2em;
				font-family: 'DM Sans';
				font-style: normal;
				font-weight: 400;
				font-size: 0.9em;
				line-height: 162% ;
				color: #340040;
				mix-blend-mode: normal;
			}
			button{
				margin-bottom: 0;
				font-size: 1.5em;
				width: 80%;
    		margin: auto;
			}
		}

`;

export const CepStyle = styled.div`
	background-color: white;
	display: flex;
	justify-content: space-between;
	padding-top: 0.3em;
	padding-bottom: 5em;

	section{
		display: flex;
		flex-direction: column;
	}

	.text{
		margin-top: 12%;
		position: relative;
	}

	section p, span{
		text-align: left;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 3.2em;
		line-height: 125%;
		font-feature-settings: 'liga' off;

		color: #340040;
	}

	section  span{
		background: #EBB013;
	}

	.logo{
		z-index: 1;
	}	

	.imagens{
		position: relative;
	}

	.flex-frete-logo-bg{
		position: absolute;
		z-index: 0;
		> img{
			width: 800px;
			height: auto;
		}
	}

	@media screen and (min-width: 2560px) {
		height: 70vh;

		.text{
			margin-top: 10%;
			position: relative;
			left: 20%;
			z-index: 2;
		}

		.flex-frete-logo-bg{
			img{
				transform: scale(1.5);
				right:13em;
				top: 5em;
			}
		}

		.image {
			transform:scale(1.5);
			right: 13em;
			left: auto;
			top: 8em;
			bottom: auto;
		}

		section p, span{
			font-size: 4em;

		}
	}

	@media screen and (max-width: 1920px) {
		.text{
			margin-top: 12%;
			position: relative;
			left: 20%;
			z-index: 2;
		}

		.flex-frete-logo-bg{
			img{
				transform: scale(1.1);
				right: 2em;
				top: 1em;
			}
		}
	}

	@media screen and (max-width: 1440px) {
		.text{
			margin-top: 18%;
			position: relative;
			left: 5%;
			z-index: 2;
		}

		section p, span{
			font-size: 2.4em;

		}
	}

	@media screen and (max-width: 700px) {
		flex-direction: column;
		padding-top: 2em;
		margin-bottom: 5em;

		.text{
			font-size: 0.6em;
			margin-top: 0px;
			margin-bottom: 5em;
			margin-left: 1.2em;
		}
		.flex-frete-logo-bg{
			width: 99%;
			transform: scale(1);
		}
	}

`;

export const PropagandaCelularStyle = styled.div`
	background-color: #FFFFFF;
	padding: 0px 10em 20px 10em;
	display: flex;
	flex-direction: row;
	section{
		display: flex;
		flex-direction: column;
	}

	.text{
		text-align: left;
		padding: 0.5em;
		margin-right: 5em;
		div {
			margin-top: 5em;
			margin-bottom: 4em;
		}
	}

	.text span{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 1.875em;
		line-height: 140%;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;
		color: #340040;
		>strong{
			color: #340040;
		}
	}
	.text p {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;
		color: #340040;
	}

	.text-inicio{
		font-size: 3em;
		line-height: 100%;
	}

	.text-fim{
		font-size: 2.5em;
		line-height: 162%;
	}

	.image{
		bottom: 6em;
	}

	.logo{
		left: 0;
		bottom: 5px;
		> img{
			width: 800px;
			height: auto;
		}
	}

	@media screen and (min-width: 2560px) {
		/* height: 0; */
		padding: 0px 25em 20px 25em;
		height: 83vh;

		.logo{
			img{
				bottom: 11em;
				left: 7em;
			}
		}
		.text-inicio{
			font-size: 3.6em;
			line-height: 117%;
		}

		.text-fim{
			font-size: 3em;
			line-height: 162%;
		}

		.text span{
			font-size: 2.3em;
		}

	}

	@media screen and (max-width: 1920px) {
		height: 120vh;
		.logo{
			transform: scale(0.8);
			img{
				bottom: 19em;
			}
		}

		.btn-container{
			margin-left: 3em;
		}
	}


	@media screen and (max-width: 1440px) {
		padding-left: 5em;
		padding-right: 5em;
		min-height: 125vh;
		.logo{
			img{
				bottom: 15em;
			}
		}
		.text{
			flex-basis: 60%;
		}
		.text span{
			font-size: 1.6em;
		}

		.text-inicio{
			font-size: 2em;
		}

		.text-fim{
			font-size: 1.8em;
		}

		.text{
		text-align: left;
		div {
			margin-top: 5em;
			margin-bottom: 0px;
		}
	}

		.btn-container{
			margin-left: 0px;
		}
	}

	@media screen and (min-width: 2560px) {
			.mobile-container{
			display: none;
		}
		}
		
		@media screen and (max-width: 1920px) {
			.mobile-container{
			display: none;
		}
		}

		@media screen and (max-width: 1440px) {
		.mobile-container{
			display: none;
		}

		.image{
			transform: scale(1.15);
			left: 5em;
		  }
		}
  @media screen and (max-width: 1370px) {
    .logo{
      img{
        bottom: 3em;
      }
    }
  }

	@media screen and (max-width: 700px) {
		padding: 0px 2em 0px 2em;
		flex-direction: column;
		height: 68em;
		
		.text{
			margin-right: 0px;
			text-align: center;

			div{
				margin-top: 1em;
			}

			.primeiraParte{
				padding-left: 2em;
				padding-right: 2em;
			}

			.segundaParte{
				padding-left: 2em;
				padding-right: 2em;
				margin-top: 2em;
				margin-bottom: 2em;
				font-size: 0.9em;
			}


			.terceiraParte{
				margin-top: 1em;
				margin-bottom: 2em;
				padding-left: 2em;
				padding-right: 2em;

				.margin{
					margin-top: 9em;
				}
			}

			.image{
			transform: scale(1.3);
			left: 1em;
			top: -10em;
		}
			.text-fim{
				line-height: 1em;
			}

			.btn-container{
				margin: 0px;
				padding: 0px;
				margin-top: 6em;
				margin-bottom: 1em;
			}

			button{
				position:relative;
				top: -8em;
				left: -1em;
				width: fit-content;
			}
		}

		.text span{
			font-size: 1.5em;
		}
		.mobile-container{
			display: flex;
		}
		.desktop-container{
			display: none;
		}
	}
`;

export const QuemSomosStyle = styled.div`
	background: #EBB013;
	display: flex;
	color: white;

	.flex{
		display: flex;
		flex-direction: column;
		/* margin-left: 3em; */
		padding: 1em;
		margin-top: 6em;
	}

	.flex span{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 3.75em;
		line-height: 123%;
		text-align: center;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;
		background: #FFFFFF;
		color: #340040;
		padding-left: 20px;
		padding-right: 20px;
	}

	.flex p{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 2.625em;
		line-height: 105%;
		text-align: center;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;

		color: #FFFFFF;

		margin-top: 1em;
		margin-bottom: 1em;
	}

	.flex p strong{
		color: #340040;
	}

	h2 {
		font-size: 1.6em;
		color: white;
		font-weight: 600;
		margin-bottom: 40px;
	}

	p{
		text-align: left;
		margin-bottom: 25px;
		width: 75%;
		margin: auto;
	}

	strong{
		color: white;
	}

	picture{
		flex-grow: 1;
	}

	.flex{
		width:50%;
	}

	.texto-meio{
		margin-top: 3em;
		margin-bottom: 3em;
	}

	.btn{
		margin-bottom: 2em;
	}

	@media screen and (min-width: 2560px) {
		flex-direction: row;
		.flex span{
			font-size: 6em;
		}

		.flex p{
			font-size: 4em;
		}

		.image{
			top: 7.9em;
			transform: scale(1.35);
			left: 9.2em;
		}

		.btn{
			margin-bottom: 3em;
		}
	}

	@media screen and (max-width: 1920px) {
		flex-direction: row;
	}

	@media screen and (max-width: 1440px) {
		flex-direction: row;
		/* .image{
			top: 5.2em;
			transform: scale(1.35);
			left: 6em;
		} */

		.texto-meio{
			margin-top: 1em;
			margin-bottom: 1em;
		}
		.flex span{
			font-size: 3em;
			line-height: 1em;
		}

		.flex p{
			font-size: 2em;
		}

		.flex{
			margin-top: 2em;
			width:80%;
		}

		.btn{
			margin-bottom: 0px;
		}
	}

	@media screen and (max-width: 700px) {
		flex-direction: column;

		.flex{
			width: 100%;
			span{
			font-size: 2em;
			line-height: 0;
		}
		p{
			font-size: 1.3em;
		}
		}

		

		.btn{
			margin-bottom: 2em;
		}
	}

`;

export const DepoimentoStyle = styled.div`

	background: #340040;
	color: white;
	padding: 70px 30px 70px 30px;
	/* padding-bottom: 0px!important; */
	position: relative;


	.titulo{
		margin-right: 9em;
		margin-bottom: 4em;
		text-align: left;
		z-index: 1;
	}

	.conteudo{
		display: flex;
		flex-direction: column;
		margin-left: 12em;
		margin-right: 12em;
	}

	h3{
		
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 3.125em;
		line-height: 120%;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;
		color: #FFFFFF;
		z-index: 1;
		>strong{
			color: #FFFFFF;
			z-index: 1;	
		}
	}

	.confira{
		text-align: left;
		display: block;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 2em;
		line-height: 93%;

		font-feature-settings: 'liga' off;

		color: #EBB013;
	}

	.investir{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 1.875em;
		line-height: 200%;
		text-align: center;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;

		color: #FFFFFF;
		margin-top: 2em;
		margin-bottom: 1em;
	}

	.container-depoimento{
		display: flex;
		/* flex-direction: row; */
		align-items: center;
	}

	.flex{
		display: flex;
		flex-direction: column;
	}

	.card-esquerda{
		margin-left: 6em;
		margin-right: 1em;
		margin-top: 5em;
		margin-bottom: 9em;
		height: 50%;
		width: 50%;
	}

	.card-direita-top{
		margin-bottom: 1em;
		width: 65%;
	}

	.card-direita-botton{
		width: 50%;
	}
	.card{
		display: flex;
		width: 30%;
		padding: 2em;
		background: #FFFFFF;
		box-shadow: 0px 4px 8px -2px rgba(17, 24, 39, 0.1), 0px 2px 4px -2px rgba(17, 24, 39, 0.06);
		border-radius: 8px;
		align-items: flex-start;
	}

	.text-card{
		display: flex;
		flex-direction: column;
	}

	.text-card p{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 1.125em;
		line-height: 156% ;
		font-feature-settings: 'liga' off;
		color: #340040;
		text-align: left;

		text-align: left;
		margin-bottom: 25px;
		width: 97%;
		margin: auto;
		margin-left: 1em;
	}

	.text-card span{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 1em;
		line-height: 186%;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-feature-settings: 'liga' off;

		color: #340040;
		text-align: left;
		display: block;
		margin-top: 2em;
		margin-left: 1em;
	}

	.flex-frete-logo-bg{
		position: absolute;
		top: 3em;
		opacity: 0.5;
		z-index: 0;
		left: 9em;
		> img{
			width: 8em;
			height: auto;
		}
	}

	.flex-frete-aspas{
		position: absolute;
		top: 2em;
		left: 1em;
		> img{
			width: 1em;
			height: auto;
		}
	}

	@media screen and (min-width: 2560px) {
		.confira{
			font-size: 2em;
		}

		.text-card p{
			font-size: 1.5em;
		}

		.text-card span{
			font-size: 1.2em;
		}

		.container-depoimento{
			margin-left: 12em;
		}

		.titulo{
			margin-right: 9em;
			margin-bottom: 4em;
			text-align: left;
			z-index: 1;
		}
	}

	@media screen and (min-width: 1920px) {
		.card{
			width: auto;
		}
		.titulo{
			margin-right: 0;
			text-align: center;
		}
	}

	@media screen and (max-width: 1440px) {

		.titulo{
			margin-right: 0px;
		}

		.card-esquerda{
			width: 100%;
		}

		.card-direita-top{
			width: 100%;
		}

		.card-direita-botton{
			width: 90%;
		}
	}

	@media screen and (min-width: 2560px) {
			.mobile-container{
			display: none;
		}
		}
		
		@media screen and (max-width: 1920px) {
			.mobile-container{
			display: none;
		}
		}

		@media screen and (max-width: 1440px) {
		.mobile-container{
			display: none;
		}
		}

	@media screen and (max-width: 700px) {
		/* padding: 2em 2em 2em 2em; */

		.flex-frete-logo-bg{
			left: 1em;
		}

		h3{
			font-size: 2em;
			margin-left: 1em;
		}
		.confira{
			margin-left: 1em;
		}


		.investir{
			line-height: 1em;
			margin-top: 0;
			margin-bottom: 0;
			font-size: 1.4em;
		}

		.dots{
			margin-bottom: 2em;
			span{
				height: 10px;
				width: 10px;
				background-color: white;
				border-radius: 50%;
				display: inline-block;
				margin-right: 1em;

			}
		}

		.card{
			width: 92vw;
			margin-top: 2.5em;
			margin-bottom: 2em;
			margin-left: 2vw;
			margin-right: 2vw;
		}

			button{
				position:relative;
				top: -8em;
				left: -1em;
			}

			.button{
				margin:0;
			}

		.conteudo{
			margin: 0px;
			margin-top: 1em;
		}
		.mobile-container{
			display: flex;
		}
		.desktop-container{
			display: none;
		}
/* 
		.footer-depoimento-mobile{
			display: flex;
			flex-direction: column;
		} */

		button{
			top: 1em;
			font-size: 1.4em;
		}
		
	}

`;



export const PropagandaStyle = styled.div`
	display: flex;
	background: linear-gradient(1.11deg, rgba(187, 90, 0, 0.5) 0.92%, rgba(255, 202, 63, 0) 65.92%), #EBB013;

	color: white;
	padding: 0px 30px 70px 30px;

	.imagem{
		position: relative;	
	}

	.content {
		margin-left: 6em;
	}
	.logo{
		/* margin-left: 13em; */
	}

	.content div {
		margin-top: 4em;
	}

	.content div span{
		display: block;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 3.75em;
		line-height: 117%;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;
		color: #340040;
		background: #FFFFFF;
		text-align: left;
		margin-bottom: 0.2em;
		padding-left: 5px;
	}
	.content ul {
		margin-top: 5em;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 600;
		font-size: 2em;
		line-height: 1.1em;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;
		color: #340040;
		list-style: disc;
		text-align: left;
		list-style-position: inside;
	}

	.text-propaganda{
		width: fit-content;
	}

	@media screen and (min-width: 2560px) {
		padding-right: 20%;
		padding-top: 2em;
		padding-bottom: 4.5em;

		.content ul {
			margin-top: 2.5em;
			margin-bottom: 2.5em;
			font-size: 2.4em;
		}

		.content {
			margin-left: 10em;
		}

		.text-propaganda{
		width: fit-content;
	}

		.content div span{
			font-size: 5em;
		}

		.imagem{
			margin-left: 30em;
			transform: scale(1.5);
			bottom: -2em;
		}

		button{
			margin-bottom: 3em;
		}

	}

	@media screen and (max-width: 1920px) {
		height: 80vh;

		.content div {
			margin-top: 2em;
		}

		.content ul {
			margin-top: 1.5em;
			margin-bottom: 1.5em;
			margin-left: 0;
		}

		.imagem{
			margin-left: 16em;
			transform: scale(1.0);
			bottom: 5.8em;
		}
		
	}
	@media screen and (min-width: 1920px){
		.imagem{
			transform: scale(0.93);
		}
	}

	@media screen and (max-width: 1440px) {
		height: 109vh;
		padding: 0px;

		.content div span{
			font-size: 2.6em;
			width:fit-content;
		}

		.content div {
			margin-top: 2em;
		}

		.content ul {
			margin-top: 2em;
			margin-left: 0;
		}

		.imagem{
			margin-left: 6em;
			transform: scale(0.9);
			bottom: 6.85em;
		}

		.text-propaganda{
		width: fit-content;
        }  
    }
  @media screen and (max-width: 1370px) {
    .imagem{
      transform: scale(1);
      bottom: 3em;
    }

  }

	@media screen and (max-width: 700px) {
		flex-direction: column;
		 height: 60em; 
		/* padding-top: 2em; */
		width: 100%;
		.imagem{
			margin-left: 0px;
			transform: scale(0.7);
			bottom: 11em;
			width: 97%;
		}

		.text-propaganda{
		width: fit-content;
	}

		.text{
			/* margin: auto; */
			text-align: center;
			width: fit-content!important;
		}

		.content-text{
			margin: auto;
    	display: flex;
    	flex-direction: column;
    	align-items: center;
		}

		.content{
			position: relative;
			top: -19em;
			margin-left: 1em;

			div{
				width: 100% ;
			}
		}
		button{
			width: 90%;
			font-size: 1.2em;
			margin-bottom: 0;
		}
		.content div span{
			font-size: 2.3em;
			text-align: center;
			width: 95%;
		}

		.content div {
			margin-top: 0px;
		}

		.content ul {
			font-size: 1.4em;
			margin-top: 1.5em;
		}
	}
`;

export const FAQStyle = styled.div`
	background: #FFFFFF;

	display: flex;
	flex-direction: column;


	.titulo {
		margin-bottom: 3em;
		margin-top: 5em;
	}

	h3{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 2.625em;
		line-height: 114% ;
		text-align: center;
		letter-spacing: -0.4px;
		color: #340040;
		/* margin-left:15%; */
		/* margin-right: 15%; */
	}

	span{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 1.875em;
		line-height: 173%;
		text-align: center;
		letter-spacing: -0.4px;
		color: #340040;
		background: #EBB013;
		padding: 0.2em;
	}

	/* .text-fundo-amarelo{
		margin-bottom: 3em;
	} */

	.item{
		padding: 0.2em;
		width: 18em;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.item p{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 1.375em;
		line-height: 145%;
		text-align: center;
		color: #340040;
		margin-top: 0.75em;
	}

	.box-pagamento{
		align-items: center;
	}
	.box-pagamento h2{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 3.75em;
		line-height: 108%;
		text-align: center;
		letter-spacing: -1px;

		color: #340040;
	}

	.box-pagamento p{
		margin: 2em;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 1.875em;
		line-height: 133%;
		text-align: center;
		letter-spacing: -1px;
		color: #340040;
	}

	.pagamentos{
		margin-top: 3em;
		justify-content: space-between;
	}

	.logo-item{
		width: 4em;
		height: 4em;
	}

	.box-item-pagamento span{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 1.875em;
		line-height: 97%;
		text-align: center;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: #EBB013;
		background: #FFFFFF;
		display: block;
		background: #F5F5F5!important;
		border-radius: 30px;

	}

	hr {
		width: 50%;
		border-top: 2px solid #340040;
		margin: 0 auto 2em auto;
	}

	.box-item-pagamento{
		padding: 2em;
		background: #F5F5F5!important;
		border-radius: 30px;
		margin-bottom: 3em;
		width: 70%;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	}

	.box-itens{
		display: flex;
		justify-content: space-around;
		align-items: center;
		/* padding: 1em; */
		padding-top: 0em;
		padding-bottom: 0em;
	}

	.box-itens > .logo{
		margin-left: 1em;
	}

	.item p strong{
		color: #340040;
	}

	.flex{
		display: flex!important;
	}

	.flex-column{
		display: flex;
		flex-direction: column;
	}

	.justify{
		justify-content: space-around;
	}

	.margin{
		margin-top: 6em;
		margin-bottom: 1em;
	}

	@media screen and (min-width: 2560px) {
		.image{
			transform: scale(1.2);
			bottom: 6.3em;
		}
	}

	@media screen and (max-width: 1920px) {
		.image{
			transform: scale(1.1);
			bottom: 3em;
		}
	}

	@media screen and (min-width: 2560px) {
			.mobile-container{
			display: none;
		}
		}
		
		@media screen and (max-width: 1920px) {
			.mobile-container{
			display: none;
		}

		.image{
			transform: scale(1);
			bottom: 0em;
		}
		}

		@media screen and (max-width: 1440px) {
		.mobile-container{
			display: none;
		}
		.image{
			transform: scale(1);
			bottom: 0em;
		}
		}

	@media screen and (max-width: 700px) {
		height: 110em;
		margin-left: 1em!important;
			margin-right: 1em!important;
			margin-bottom: 0;
		h3{
			font-size: 1.4em;
			
			margin: auto;
		}

		
		.titulo{
			margin-top: 2.5em;
			margin-bottom: 2.5em;
		}

		.text-fundo-amarelo{
			
			span{
				margin-left: 0.8em!important;
			margin-right: 0.8em!important;
				text-align: center;
				line-height: 1.8em;
				font-size: 1.6em;
			}
		}

		.margin {
			margin-top: 3em;
		}

		.item {
			width: 50%;
			display: flex;
    flex-direction: column;
    align-content: space-between;
		min-height: 12em;
			p{
				font-size: 1.1em;
		}
		}
		buttom{
			width: 80%!important;
			margin-bottom: 0;
		}



		.image{
			transform: scale(0.8);
			bottom: 4em; 
			left: -3em;
		}

		/* .box-pagamento h2{
			font-size: 1.5em!important;
		} */

		.pagamentos {
			margin-top: 6em;
		}

		.box-item-pagamento {
			padding: 1em;
			width: 90%;
		}

		.box-item-pagamento span {
			font-size: 1.3em;
		}

		.mobile-position{
			position: relative;
			bottom: 10em;
			margin-top: 2em;
		}
		.mobile-container{
			display: flex;
			margin-top: 1em;

			.box-pagamento h2{
				font-size: 1.6em;
				text-align: right;
			}

			.box-pagamento p{
				margin-top: 2em;
				margin-bottom: 2em;
				margin-right: 0px;
				margin-left: 0px;
				font-size: 1.4em;
				text-align: right;
			}

		}
		.desktop-container{
			display: none;
		}
	}

`;

export const InscrevaseStyle = styled.div`
	background-color: #812990;
	color: white;
	padding: 20px 50px 20px 50px;

	h2 {
		font-size: 1.8em;
		color: white;
		font-weight: 600;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.newsLetter{
		border-radius: 10px;
		background-color: #8d3e9b;
		padding: 20px;
		margin-bottom: 20px;
	}

	.newsLetter > p{
		color: grey;
		background-color: white;
		font-weight: 400;
		margin-top: 15px;
		margin-bottom: 25px;
	}

	/* .newsLetter > button{
		background: rgb(194, 144, 3);
		background: linear-gradient(
			266deg,
			rgba(194, 144, 3, 1) 35%,
			rgba(227, 175, 31, 1) 100%
		);
		border: none;
		color: white;
		font-weight: 600;
	} */

	@media screen and (min-width: 700px) {
		h2 {
			margin-bottom: 20px;
		}
	}

	@media screen and (min-width: 1280px) {
		h2 {
			margin-bottom: 20px;
		}
		padding: 20px 400px 20px 400px;
	}

	@media screen and (min-width: 1200px) {
		h2 {
			margin-top: 20px;
			margin-bottom: 40px;
		}
		padding: 20px 400px 20px 400px;
	}

`;
