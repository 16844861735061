import axios from 'axios';
import api from './Api';

export default class FreteService {

    requisitarCotacaoFrete(request, callback){
        axios
            .post(this.host() + "/api/v1/calculo-frete", request)
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    comprarFrete(request, callback){
        api
            .post(this.host() + "/api/v1/frete/" + request)
            .then((response) => {
                callback(null, response)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    setFreteComprado(info){
        localStorage.setItem('freteComprado', JSON.stringify(info))
    }

    getFreteComprado(){
        let freteComprado = localStorage.getItem('freteComprado');
        if(typeof freteComprado === "string" ) {
            return JSON.parse(freteComprado);
        } else {
            return null;
        }
    }
    listarMeusFretes(request, callback){
        let params = "";
        for(const key in request ){
            const value = request[key];
            if(!value){
                continue;
            }
            params = params + `${key}=${value}&`;  
        }

        api
        .get(this.host() + "/api/v1/frete?" + params)
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    gerarEtiqueta(uuid, callback){
        api
        .get(this.host() + "/api/v1/frete/" + uuid+ "/etiqueta",{ responseType: 'blob'})
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    gerarDeclaracaoConteudoPdf(uuid, callback) {
        api
        .get(this.host() + "/api/v1/frete/" + uuid + "/declaracao-conteudo", { responseType: 'blob'})
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    deleteFrete(uuid,mensagem, callback){
            api
            .put(this.host() + "/api/v1/frete/" + uuid + "?justificativa=" + mensagem)
            .then((response) => {
                callback(null, response)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    deleteFreteComprado(){
        localStorage.removeItem('freteComprado');
    }
    getValorEmAberto(callback){
        api
        .get(this.host() + "/api/v1/frete/valorEmAberto")
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }
    
    getFretePagamento(request, callback, formato){
        let params = "";
        for(const key in request ){
            const value = request[key];
            if(!value){
                continue;
            }
            params = params + `${key}=${value}&`;  
        }
        api
        .get(this.host() + "/api/v1/frete/pagamento?"+ params, {
            headers: {
                'Content-Type': formato || 'application/json',
                'accept': formato || 'application/json',
            },
        })
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }
   
    host() {
		return process.env.REACT_APP_HOST_API;
	}
}