import { Link } from 'react-router-dom';
import styled from "styled-components";


export const Container = styled.div`
    width: 900px;
    .spinner{
        left: 0;
        top: 0;
    }
    .meus-envios-cabecalho{
        ul{
            width: 100%;
            height: 40px;
            justify-content: space-evenly;
            font-size: 1.5rem;
            display: flex;
            li{
                list-style: none;
                margin-right: 40px;
            }
            margin-bottom: 20px;
        }
        margin-bottom: 100px;
    }
    .frete-list{
        margin-top: 50px;
    }

    .ffl-custom-checkbox{
        input[type="checkbox"]{
            width: 18px;
            height: 18px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .w-100{
        width: 100%;
    }

    @media(max-width: 1200px){
        width: 400px;
        .meus-envios-cabecalho{

            ul{
                height: 60px;
            }
        }
    }

    @media(max-width: 700px){
        .container-desktop{
            display: none;
        }
        .dots{
		margin-bottom: 2em;
		span{
			height: 10px;
			width: 10px;
			background-color: white;
			border-radius: 50%;
			display: inline-block;
			margin-right: 1em;

		}
	}
        .itens-mobile{
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
    }

    @media(min-width: 700px){
        .container-mobile{
            display: none;
        }
    }

    @media(max-width: 510px){
        max-width: 280px;
    }


`;

export const SelectStatusFreteStyle = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    color: ${props => props.$selected? "rgba(136,48,152,1)": "#4a4a4a"};
    font-weight: ${props => props.$selected? "bold": ""};
    border-bottom: ${props => props.$selected? "solid 3px rgba(136,48,152,1)": ""};
    /* @media(max-width: 1200px){
        font-size: small;
    } */
    @media(max-width: 700px){

    }

`;

export const FreteListSearchForm = styled.div`
    background-color: #fff;
    padding: 25px;

    .formulario-titulo{
        h2{
           font-size: 1.8rem;
            font-weight: bold;
        }
        display: flex;
        justify-content: flex-start;
        margin-bottom: 35px;

    }
    .formulario-busca-inputs{
        display: flex;
        justify-content: space-between;
        input{
        }
        .formulario-busca-input-area{
          text-align: left;
          width: 180px;
          label{
            white-space: nowrap;
          }
        }
        .formulario-busca-input-area.quantidade-fretes{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            select{
                width: 100%;
            }
        }

    }
    .buscar-button, .loadmore-button{
        background-color: rgba(136,48,152,1);
        color: #fff;
        font-weight: bold;
    }
    li{
        list-style: none;
        margin-right: 40px;
    }

    @media(max-width: 1200px){
        .formulario-busca-inputs{
            flex-direction: column;
            .formulario-busca-input-area{
                margin-bottom: 25px;
            }
            .quantidade-fretes{
                width: 90%;
            }

        }

    }
`;
export const MeusEnviosFreteStyle = styled.li`

    border-top: solid 1.5px #ddd;
    margin: 30px 0;
    header{
        margin: 20px 0;
        font-size: 1.4rem;
    }
    .header-label{
        margin-right: 10px;
    }
    .meus-envios-frete-cabecalho-aguardando, .meus-envios-frete-cabecalho-postado{
        display: flex;
        justify-content: space-between;
        div{
            display: flex;

        }
        .show-frete-desktop{
            display:block;
            width: 20px;
            cursor: pointer;
        }
        .show-frete-mobile{
            display:none;
        }
        .delete-frete{
            cursor:pointer
        }
        .icon-delete{
            color: #f14668;
            font-size: 1.8rem;
        }
        .icon-warning{
            color: red;
            font-size: 1.8rem;

        }
        .icon-danger{
            color: #ffe08a;
            font-size: 1.8rem;

        }
        .expiracao-postado{
            margin-right: 10px;
        }
    }

    strong{
        margin-right: 10px;
    }
    .meus-envios-frete-cabecalho-gerado{
        display: flex;
        margin-top: 20px;
        align-items: center;
        cursor: pointer;
        ion-icon{
            margin-right: 5px;
        }
        span{
            margin-right: 20px;
        }
    }
    .rastreio-frete-container-area{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        width: 800px;
    }

    .meus-envios-frete-container-area, .meus-envios-frete-container-area-pessoa, .volumes{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        width: 400px;
    }
    .meus-envios-frete-section{
        display: flex;
        width: 100%;
        margin: 10px 0;

    }
    .line-label{
        margin-right: 10px;
        white-space: nowrap;
    }
    .container-area-line{
        display: flex;
        justify-content: flex-start;
        font-size: 1.3rem;

    }
    .pessoa-titulo{
        text-align: left;
        h4{
            font-size: 1.7rem;
            font-weight: bold;
        }

        margin: 20px 0;
    }
    .declaracao-conteudo-titulo{
        text-align: left;
        h4{
            font-size: 1.7rem;
            font-weight: bold;
        }

        margin: 20px 0;
    }
    .nota-fiscal-titulo{
        text-align: left;
        h4{
            font-size: 1.7rem;
            font-weight: bold;
        }

        margin: 20px 0;
    }
    .container-volumes-etiqueta{
        display: flex;
        align-items: flex-start;

    }
    .container-declaracao-conteudo{
        margin: 20px 0;
    }
    .container-nota-fiscal{
        margin: 20px 0;
    }
    .etiqueta{
        width: 400px;
        display: flex;
        justify-content: flex-start;


        .declaracao{
            margin-left: 10px;
        }
    }

    @media(max-width: 1200px){
        .meus-envios-frete-cabecalho-aguardando, .meus-envios-frete-cabecalho-postado, .meus-envios-frete-cabecalho-gerado, .container-volumes-etiqueta {
            flex-direction: column;
            .show-frete-desktop{
                display:none;

            }
            .show-frete-mobile{
                display:block;
                width: 20px;
                cursor: pointer;
            }
        }
        .meus-envios-frete-container-area, .meus-envios-frete-container-area-pessoa, .volumes{
            width: 100%;
        }

        .meus-envios-frete-section{
            flex-direction: column;
        }
        .container-area-line{
        }

        .etiqueta{
            width: 100%;
            margin-top: 20px;

            flex-direction: column;

            .declaracao{
                margin-left: 0;
                margin-top: 5px;
            }
        }

    }

    @media (max-width: 770px) {
        .etiqueta{
            flex-direction: column;

            .declaracao{
                font-size: 0.7em;
            }
        }
	}

    .volumes{
        text-align: left;
        p{
            font-size: 1.3rem;
        }
    }

`;
