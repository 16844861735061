import React from 'react';

function Configuracoes() {

  return (
    <div className="page-container-style"> 
  
        <div style={{marginTop: '20em', fontWeight: '800'}}>
        <h1 style={{fontSize: '3em', fontWeight: '800'}}>
        Configs
          <br />
          PÁGINA EM CONSTRUÇÃO!!!!!!
          </h1>
      </div>
    </div>
  );
}

export default Configuracoes;
