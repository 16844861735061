import styled from "styled-components";

export const MoreLojaStyle = styled.section`
	padding-top: 40px;
	margin-left: 5%;
	margin-right: 5%;
	margin-bottom: 5%;
	margin-top: 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100vw;

	.ffl-custom-checkbox{
		display: flex;
		align-items: center;
	}

	.field{
		margin-bottom: 0 !important;
	}
	.select {
		width: 100%;
	}
	h1 {
		color: #282828;
        font-size: 1.5em;
        font-weight: 600;
		letter-spacing: 0.2px;
	}

	h2, h3{
		color: #282828;
        font-weight: 600;
        letter-spacing: 0.3px;
		margin-bottom: 30px;
		text-align: left;
		font-size: 1.3em;
    }

	h3{
		font-size: 0.9em;
	}

	form {
		width: 100%;
	}

	a{
		color: #812990;
	}
	.btn-loja-nova{
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-items: center;
		justify-items: center;
	}

@media (max-width: 2560px) {
	margin-left: 20%;
	margin-right: 5%;
}

@media (max-height: 1024px) {
	margin-left: 25%;
	margin-right: 15%;
}

@media (max-width: 770px) {
	margin-left: 15%;
	margin-right: 5%;
	.is-flex{
		flex-direction: column;
	}
	.select{
		margin-bottom: 3rem;
	}
	.btn-loja-nova{
		flex-direction: column;

	}
	.btn-loja-nova > button{
		width: 100%;
		margin-bottom: 1rem;
	}

}

@media (max-width: 430px) {
	margin-left: 20%;
	margin-right: 5%;
}

`;

export const FormContainerStyle = styled.div`
	margin-top: 1.5em;

	.box{
		padding: 5rem;
	}

	form{
		width: 65vw;
	}

	small{
		font-size: 1.1em;
	}

	.form-background{
		border-radius: 20px;
		border: none;
		padding: 1em;
		background-color: white;
	}

	.button.is-static{
        border-color: none;
        background-color: white;
        border-left: none;
    }

	.columns > .column > .field{
		margin-bottom: 0;
	}

	.control-checkbox{
		text-align: left;
		padding-top: 15px;
		padding-bottom: 15px;
		cursor: pointer;
	}

	.pesoMedidasInput{
		margin-right: 5em;
	}

	.pesoMedidasInput > .label{
		font-size: 1.8rem;
	}

	.add-more-pacotes{
		color: #9339a2;
        vertical-align: middle;
		font-weight: 600;
		letter-spacing: 0.2px;
	}

	ion-icon{
        font-size: 30px;
		color: #9339a2;
        vertical-align: middle;
		margin-right: 15px;
    }

	.btn-loja-nova{
		margin-top: 30px;
	}

	.btn-loja-nova > p{
        background: white;
        width: 15%;
		margin-right:  5%;
    }

	.btn-loja-nova > button:last-child{
        background: rgb(131, 43, 146);
	    background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
	    );
        border: none;
        width: 15%;
        color: white;
        font-weight: 600;
		letter-spacing: 0.2px;

    }

	.btn-loja-nova > button:last-child:hover{
		background: rgb(160, 69, 176);
		background: linear-gradient(
			267deg,
			rgba(160, 69, 176, 1) 39%,
			rgba(131, 43, 146, 1) 100%
		);
	}


	.field{
		margin-bottom: 1em;
	}

	.field > .control > .input{
		font-size: 1.8rem;
	}

	.field > .control > input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{
		-webkit-appearance: none;
  		margin: 0;
	}

	.field > .control > input[type=number]{
		-moz-appearance: textfield;
	}

	.control > .button{
		font-size: 1.8rem;
	}

	.field > .label{
		text-align: left;
		font-size: 1em;
	}

	.label{
		text-align: left;
		font-size: 1em;
	}

	.section-half{
		display: flex;
		justify-content: space-between;
	}

	.section-half > div:first-child{
		margin-right: 2em;
		flex-grow: 1;
	}

	.section-half > div:last-child{
		flex-grow: 1;
	}

	.select > select{
		font-size: 1.8rem;
	}

	.select > select > option{
		font-size: 1.8rem;
	}

	hr {
		margin-top: 3em;
		margin-bottom: 3em;
	}

@media (max-width: 770px) {
    .pesoMedidasInput{
		margin-right: 1em;
	}
	.btn-loja-nova > p{
        background: white;
		margin-right:  0;
		margin-bottom: 5%;
        width: 80%;
    }

	.btn-loja-nova > button:last-child{
        width: 80%;
    }

}
`;

export const ButtonContainerStyle = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 3em;
`;
