import styled from "styled-components";

export const ModalContainerStyle = styled.section`
	position: relative;
	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
	width: 50%;
	position: fixed;

	a {
		color: #e6b222;
		font-weight: 600;
		letter-spacing: 0.2px;
		text-align: center;
	}

	p{
		text-align: center;
		font-size: 2.0rem;
		padding-bottom: 30px;
	}


@media (max-width: 430px) {
	width: 90%;
}


`;

export const ModalContentStyle = styled.section`
	border-radius: 20px;
	border: none;
	padding: 1em;
	background-color: white;
    -webkit-box-shadow: 13px 12px 9px -5px rgb(97 96 96 / 91%);
    box-shadow: 13px 12px 9px -5px rgb(97 96 96 / 91%);

	span {
		font-weight: 300;
		text-align: left;
		padding-bottom: 10px;
	}

	.success > ion-icon{
		font-size: 64px;
		color: #9339a2;
	}

	.attention > ion-icon{
		font-size: 64px;
		color: #e6b222;
	}


	.modal-close{
		background-color: #4a4a4a;
	}

	.modal-close:hover{
		color:orange;
		background-color: #848282;
	}

`;

export const BtnModalVoltarStyle = styled.button`
	margin-top: 5px;
	margin-bottom: 5px;
	margin-right: 5px;
	background: rgb(247, 247, 247);
	background: linear-gradient(
		94deg,
		rgba(247, 247, 247, 1) 39%,
		rgb(230 229 229) 100%
	);
	border: none;
	color: #9339a2;
	padding: 10px;
	font-weight: 600;
	font-size: 0.9em;
	letter-spacing: 0.3px;
	cursor: pointer;
`;

export const BtnModalSeguirStyle = styled.button`
	margin-top: 5px;
	margin-bottom: 5px;
	border: none;
	background: rgb(131, 43, 146);
	background: linear-gradient(
		267deg,
		rgba(131, 43, 146, 1) 39%,
		rgba(160, 69, 176, 1) 100%
	);

	color: white;
	padding: 10px;
	font-weight: 600;
	font-size: 0.9em;
	letter-spacing: 0.3px;
	cursor: pointer;

	:hover{
		background: rgba(136,48,152, 0.7);
	}
`;
