import React from 'react';
import { ConfirmacaoContentStyle } from './style';


function CarrinhoConfirmacaoDeCompraFrete() {
  return (
      <>

    <ConfirmacaoContentStyle>
    <div className="notification is-success" style={{marginTop: '20px'}}>
        <p><ion-icon name="checkmark-circle-outline"></ion-icon>Compra realizada com sucesso</p>

       
    </div>
    <div className="notification is-warning">
      <p >
        Para imprimir sua etiqueta e declaração de conteúdo, <a href="/envios?section=GERADO">clique aqui.</a>          
      </p>
      <p>
        Caso o pagamento ainda esteja pendente, você pode consultar o seu frete <a href="/envios?section=PENDENTE">aqui</a>.
      </p>
    </div>

    <div className="notification is-warning">
      <p >
      Caso a comunicação entre a Flex Frete e as transportadoras apresente instabilidades, o seu frete poderá ser incluído na aba <a href="/envios?section=PENDENTE">'Pendentes'</a>   em 'Meus Fretes', e tentaremos automaticamente efetivar a compra. Assim que for concluída, seu frete será automaticamente enviado à aba <a href="/envios?section=GERADO">'Gerados'.</a> 
      </p>
    </div>
   
    </ConfirmacaoContentStyle>
</>
  
  );
}

export default CarrinhoConfirmacaoDeCompraFrete;
