import React, { useEffect, useState } from "react";
import CurrencyInput from 'react-currency-masked-input';
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import Input from '../../components/FlexFreteInput/Input';
import FlexSpinner from '../../components/FlexFreteSpinner/FlexSpinner';
import FreteService from "../../services/FreteService";
import GeografiaService from "../../services/GeografiaService";
import {cepMask, handleDecimalPeso} from '../../utils/Masks';
import { ActionCreators } from '../../_actions/ActionCreators';
import { BtnFreteStyle, FreteFormContainerStyle, FreteFormStyle, SectionFormStyle } from "./style";

const freteService = new FreteService();
const geografiaService = new GeografiaService();

const limiteEncomenda = parseFloat(process.env.REACT_APP_LIMITE_ENCOMENDA);
const limiteMedida = Number(process.env.REACT_APP_VALOR_MAXIMO_DIMENSAO);
const limitePeso = 30

const cotacacaoBase = {
    "cepOrigem": "",
    "cepDestino": "",
    "isPacotePadrao": false,
    "avisoRecebimento":false,
    "dimensoes": {
        "alturaPacote": null,
        "larguraPacote": null,
        "comprimentoPacote": null,
        "pesoPacote": null,
        "diametroPacote": null
    },
    "formato": "CAIXA",
    "valorEncomenda": "",
    "logisticaReversa": false
}

function FreteCalcForm(props) {
	const dispatch = useDispatch();
	const [formSection, setFormSection] = useState(0)
	const [processando, setProcessando] = useState(false);
	const [showDiametro, setShowDiametro] = useState(false)
	const [formato, setFormato] = useState("CAIXA")
	const [erro, setErro] = useState({
		isWrong: false,
		titulo: "",
		mensagem: "",
		voltar: false,
		index: ""
	});

	let state = useSelector((state) => state);

	const lojaUuid = (state && state.loja && state.loja.uuid) ? state.loja.uuid : null;

	const [request, setRequest] = useState({
		cepOrigem: "",
		cepDestino: "",
		valorEncomenda: "",
		isPacotePadrao: "",
		uuidConfiguracaoPacoteLoja: "",
		avisoRecebimento: false,
		logisticaReversa: false,

		alturaPacote: "",
		larguraPacote: "",
		comprimentoPacote: "",
		pesoPacote: "",
		diametroPacote: "",

		"uuidLoja": lojaUuid,
        "cotacoes": [
           {...cotacacaoBase}
        ]

	});

	const [cepValidado, setCepValidado] = useState([]);

	function showAvisoAcimaValor() {
        setErro({
            isWrong: true,
            titulo: "Aviso",
            mensagem: `Este valor deve ser de até ${limiteEncomenda.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
        });
    }

	useEffect(() => {
		return () => {
		  setRequest({})
		  setErro({})
		  setShowDiametro([])
		  setProcessando()
		}
	  }, [])

	const getCEP = (cep, index) => {
		geografiaService.buscaPorCEP(cep, (erroCep, sucesso) => {
		  if(erroCep){
			  if(index === 1){
				setErro({
					isWrong: true,
					mensagem: 'CEP de origem não localizado',
					index: index
				  })
			  }
			  if(index === 2){
				setErro({
					isWrong: true,
					mensagem: 'CEP de destino não localizado',
					index: index
				  })
			  }
			return;
		  }
		  if(sucesso){
			if(erro.index === index){
				setErro({isWrong: false})
			}

			setCepValidado([...cepValidado, true])
		  }
		})
	}

	const parseValorToFloat = (valor) => {
        let aux = Number(valor)
        return parseFloat(aux).toFixed(2);
    }

    const onChangeValor = (valor, novo) => {
       let v = parseValorToFloat(novo);

		if(v > limiteEncomenda){
			showAvisoAcimaValor()
	   	} else {
			setErro({
				isWrong: false
			})
		}

        setRequest({
            ...request,
            valorEncomenda: v
        });
    }

	const onBlur = (valor, index) =>{
		let cep = valor
		if(!cep.length){
		  return
		}
		let formatedcep = cep.replace(/[\D]/g, "")
		return getCEP(formatedcep, index);

	};

	const onChangeDiametro = (e) => {
		if(e.target.value === 'ROLO_CILINDRO'){
			setShowDiametro(true)
		} else{
			setShowDiametro(false)
		}
		setFormato(e.target.value)
		setRequest({...request, formato: e.target.value})
	}

	const clearForm = (e) => {
		document.getElementById("calculadora-landing-page").reset()
		setRequest({
			cepOrigem: "",
			cepDestino: "",
			valorEncomenda: "",
			uuidLoja: "",
			isPacotePadrao: "",
			uuidConfiguracaoPacoteLoja: "",
			avisoRecebimento: false,
			logisticaReversa: false,
			formato: "",

			alturaPacote: "",
			larguraPacote: "",
			comprimentoPacote: "",
			pesoPacote: "",
			diametroPacote: ""
		});

		setErro({
			isWrong: false
		})
	}

	const getPesoPacote = () => {
		return request.pesoPacote ? request.pesoPacote : 0.3
	}

	const getComprimentoPacote = () => {
		return request.comprimentoPacote ? request.comprimentoPacote : 17
	}

	const getLarguraPacote = () => {
		return request.larguraPacote ? request.larguraPacote : 12
	}

	const getAlturaPacote = () => {
		return request.alturaPacote ? request.alturaPacote : 2
	}

	const getValorEncomenda = () => {
		return request.valorEncomenda ? request.valorEncomenda : parseFloat(100).toFixed(2)
	}

	const getDiametroPacote = () => {
		return !showDiametro ? "" : request.diametroPacote ? request.diametroPacote : 18  
	}

	const onSubmit = (event) => {
		event.preventDefault();
		const pesoPacoteFloat = parseFloat(getPesoPacote().toString().replace(',', '.'));
        if(pesoPacoteFloat > limitePeso){
            setErro({
                isWrong: true,
                mensagem: `Somente podem ser contratados fretes com peso ate ${limitePeso}kg.`
              })
            return;
        }

		if (getAlturaPacote() > limiteMedida || getLarguraPacote() > limiteMedida || getComprimentoPacote() > limiteMedida || getDiametroPacote() > limiteMedida) {
			let campoComErro = '';
		
			if (getAlturaPacote() > limiteMedida) {
				campoComErro = 'Altura';
				document.getElementById("alturaPacote").focus()
			} else if (getLarguraPacote() > limiteMedida) {
				campoComErro = 'Largura';
				document.getElementById("larguraPacote").focus()
			} else if (getComprimentoPacote() > limiteMedida) {
				campoComErro = 'Comprimento';
				document.getElementById("comprimentoPacote").focus()
			} else if (getDiametroPacote() > limiteMedida) {
				campoComErro = 'Diâmetro';
				document.getElementById("diametroPacote").focus()
			}
		
			setErro({
				isWrong: true,
				mensagem: `O campo ${campoComErro} deve ser entre 1 e ${limiteMedida} cm.`,
			});
		
			return;
		}
		
        let somarDimensoes = 0;
        somarDimensoes = Number(getAlturaPacote()) + Number(getLarguraPacote())  +
        Number(getComprimentoPacote()) + Number(getDiametroPacote());

        if(somarDimensoes > 240){
            setErro({
                isWrong: true,
                mensagem: 'A soma das dimensões não pode ultrapassar 240cm.'
              })

            return;
        }
		setProcessando(true)

		let formatedcepOrigem = request.cepOrigem.replace(/[\D]/g, "")
		let formatedcepDestino = request.cepDestino.replace(/[\D]/g, "")

		if(cepValidado.length < 2){
			setErro({
				isWrong: true,
				mensagem: 'Um dos CEPS digitados não foi localizado, por favor insira um CEP válido.'
			});
			setProcessando(false)
			return;
		}

		const requestCotacaoFrete = {
			uuidLoja: "",
			cotacoes: [
				{
					cepOrigem: formatedcepOrigem,
					cepDestino: formatedcepDestino,
					valorEncomenda: getValorEncomenda(),
					isPacotePadrao: false,
					uuidConfiguracaoPacoteLoja: "",
					avisoRecebimento: false,
					logisticaReversa: false,
					formato: formato,

					dimensoes: {
						alturaPacote: getAlturaPacote(),
						larguraPacote: getLarguraPacote(),
						comprimentoPacote: getComprimentoPacote(),
						pesoPacote: pesoPacoteFloat,
						diametroPacote: getDiametroPacote()
					}

				}
			]
		}

		console.log(pesoPacoteFloat)

		freteService.requisitarCotacaoFrete(requestCotacaoFrete, (errorCotacao, sucesso)=>{
			if(errorCotacao){
				setProcessando(false)
				if(errorCotacao && errorCotacao.response && errorCotacao.response.data && errorCotacao.response.data.message) {
					setErro({
					  isWrong: true,
					  mensagem: errorCotacao.response.data.message
					})
				  } else {
					setErro({
					  isWrong: true,
					  titulo: "Erro ao obter cotações",
					  mensagem: "Falha ao tentar obter cotações de frete, entre em contato com o suporte ou tente novamente mais tarde."
					})
				  }
				return
			}
			if(sucesso){
				setErro({isWrong: false})
				dispatch(ActionCreators.freteCalcResultLandingPage(sucesso))
				setProcessando(false)
				document.getElementById("resultado-cotacao").scrollIntoView();
			}
		})

	}

	const setCepOrigem = (eventCep) => {
		const cep = cepMask(eventCep.target.value)
		setRequest({...request, cepOrigem: cep})

		// 9 é o tamanho do cep com a máscara
		if(cep && cep.length === 9){
			onBlur(cep, 1)
		}
	}

	const setCepDestino = (eventCep) => {
		const cep = cepMask(eventCep.target.value)
		setRequest({...request, cepDestino: cep})
		// 9 é o tamanho do cep com a máscara
		if(cep && cep.length === 9){
			onBlur(cep, 2)
		}
	}
	const getMedida = (campo, valor) => {
		const numero = parseInt(valor.replace(/[^\d]/g, ""));
		if (typeof numero === "number" && erro.isWrong && erro.mensagem.includes(campo) && !(numero > limiteMedida || numero < 1)) {
		  setErro({ isWrong: false });
		}else if(typeof numero === "number" && erro.isWrong && erro.mensagem === 'A soma das dimensões não pode ultrapassar 240cm.'){
		let somarDimensoes = 0;
        somarDimensoes = (campo  === 'Altura' ? numero:Number(getAlturaPacote())) + (campo === 'Largura' ? numero: Number(getLarguraPacote()))  +
        (campo === 'Comprimento' ? numero:Number(getComprimentoPacote())) + Number(getDiametroPacote());
		console.log(somarDimensoes)
        if(somarDimensoes > 240){
            setErro({
                isWrong: true,
                mensagem: 'A soma das dimensões não pode ultrapassar 240cm.'
              })
        }else{
			setErro({isWrong: false, mensagem: ''});
		}

		}
		return numero
	}

	return (
		<>
		    {
				processando === true ?
				(
					<FlexSpinner />
				) : null
			}

		<FreteFormStyle style={props.style}>
			<div className="container desktop-container">
				<section style={{width: '100%'}}>
						<h1 className="titulo" style={{marginTop: '1em'}}>Entenda como a Flex Frete</h1>
						<h1 className="titulo">consegue condições</h1>
						<h1 className="titulo">diferenciadas</h1>

						<div>
							<p>
							Negociamos direto com as maiores transportadoras do Brasil,
							</p>
							<p>
							e <strong>garantimos tarifas mais baratas</strong> para você, pequeno e
							</p>
							<p>médio empreendedor que vende online.</p>
						</div>
						<h2>
						Compare e comprove na prática!
						</h2>
				</section>

				<section className="img-section">
					<picture className="landing-woman">
						<img src="/persona_inicio.png" className="image image-frete-form" alt="homem-landing-page" />
					</picture>
				</section>
			</div>

			<div className="mobile-container">
				<div className="container">
					<section>
							<h1 className="titulo" style={{marginTop: '1em'}}>Entenda como a Flex Frete</h1>
							<h1 className="titulo">consegue condições</h1>
							<h1 className="titulo">diferenciadas</h1>


							<div className="mobile-picture">
								<div>
									<div className="mobile-main-text" style={{width: '12em'}}>
										<p>
										Negociamos direto com as maiores transportadoras do Brasil, e <strong>garantimos tarifas mais baratas</strong> para você, pequeno e médio empreendedor que vende online.</p>
									</div>
									<div>
										<h2>
										Compare e comprove na prática!
										</h2>
									</div>
								</div>
								<section className="img-section">
									<picture className="landing-woman">
										<img src="/persona_inicio.png" className="image" alt="homem-landing-page" />
									</picture>
								</section>
							</div>
					</section>

				</div>
			</div>
			<FreteFormContainerStyle>
			<>
			{
				erro.isWrong === true && erro.mensagem ?
				(
					<div className="notification is-warning" style={{marginBottom: '50px'}}>
						{erro.mensagem}
					</div>
				) : null

            }
			</>
			<form onSubmit={onSubmit} id="calculadora-landing-page">

			<h1 style={{marginBottom: "30px", color: "#340040"}}>Dados da Cotação</h1>
				<div className="desktop-container">

					<SectionFormStyle>
						<div class="field is-grouped">
							<div className="field padding">
								<label htmlFor="cepOrigem" className="label" >CEP de origem</label>
								<div className="control">
									<input className="input" type="text"  maxLength="9" placeholder="00000-000"
									required={true} name="cepOrigem" value={request.cepOrigem}
									onChange={setCepOrigem}
									onBlur={(e) => { onBlur(e.target.value, 1)}}

									/>
								</div>
								{(!request.cepOrigem && erro.isWrong) && (
									<p className="help is-danger" style={{ fontSize: '11.3px' }}>Preenchimento obrigatório</p>
								)}
							</div>
							<div className="field padding">
								<label htmlFor="cepDestino" className="label">CEP de destino</label>
								<div className="control">
									<input className="input" type="text"  maxLength="9" placeholder="00000-000"
									required={true} name="cepDestino" value={request.cepDestino}
									onChange= {setCepDestino}
									onBlur={(e) => { onBlur(e.target.value, 2)}}
									/>
								</div>
								{(!request.cepDestino && erro.isWrong) && (
									<p className="help is-danger" style={{ fontSize: '11.3px' }}>Preenchimento obrigatório</p>
								)}
							</div>
							<div className="field padding" >
								<label className="label " htmlFor="valor" style={{ whiteSpace: 'nowrap'}}>
									Valor da encomenda (R$)
								</label>
								<div className="control">
									<CurrencyInput name="valorEncomenda" placeholder="100,00" min="0"
									className="input" separator="."   onChange={(e, valorNovo) => { onChangeValor (e.target.value, valorNovo)}}value={request.valorEncomenda} />
								</div>
							</div>
							
							<div className="field padding">
								<label className="label" htmlFor="formato">Tipo de pacote</label>
								<div className="select opcoesTransacoes">
									<select name="formato" className="selectWidth" onChange={onChangeDiametro} >
										<option value="CAIXA" defaultValue>Caixa/Pacote</option>
										<option value="ENVELOPE">Envelope</option>
										<option value="ROLO_CILINDRO">Rolo/Cilindro</option>
									</select>
								</div>
							</div>
						</div>
					</SectionFormStyle>

					<SectionFormStyle style={{paddingTop: '25px'}}>
						<div class="field is-grouped">
							<Input 
								classField="padding" 
								type="text" 
								id="alturaPacote"
								name="alturaPacote" 
								value={ request.alturaPacote } 
								label="Altura (cm)" 
								placeholder="2" 
								onChange={(e) => {
									setRequest({ ...request, alturaPacote: getMedida("Altura", e.target.value) || "" });
								}}	
							/>

							<Input 
								classField="padding" 
								type="text" 
								id="larguraPacote"
								name="larguraPacote" 
								value={ request.larguraPacote } 
								label="Largura (cm)"
								placeholder="12" 
								onChange={(e) => {
									setRequest({ ...request, larguraPacote: getMedida("Largura", e.target.value) || "" });
								}}	
							/>

							<Input 
								classField="padding" 
								id="comprimentoPacote" 
								value={ request.comprimentoPacote } 
								type="text" 
								name="comprimentoPacote" 
								label="Comprimento (cm)" 
								placeholder="17" 
								onChange={(e) => {
									setRequest({ ...request, comprimentoPacote: getMedida("Comprimento", e.target.value) || "" });
								}}	
							/>


							<Input
								classField="padding"
								type="text"
								id="pesoPacote"
								name="pesoPacote"
								label="Peso (Kg)"
								placeholder="0,3"
								value={request.pesoPacote}
								onChange= {(e) => {
									const numero = handleDecimalPeso(e.target.value, 3)
									if (erro.isWrong && erro.mensagem.includes("peso") && !(numero > limitePeso)) {
										setErro({ isWrong: false });
									  }
									setRequest({...request, pesoPacote: numero});
								} }
							/>
						</div>
					</SectionFormStyle>
					<SectionFormStyle>
						{
							showDiametro === true ?
							(
								<Input classField="padding" type="number" id="diametroPacote" name="diametroPacote" label="Diametro (cm)" placeholder="18" onChange= {(e)=> {setRequest({...request, diametroPacote: e.target.value});} }/>
							) : null
						}
					</SectionFormStyle>
				</div>

				<div className="mobile-container-form">
					<Swiper
						pagination={true}
						modules={[Pagination]}
						onSlideChange={(swiper) => setFormSection(swiper.activeIndex)}
						// onSwiper={(swiper) => console.log(swiper)}
					>
						<SwiperSlide>
						<SectionFormStyle>
							<div class="field is-grouped">
								<div className="field margin">
									<label htmlFor="cepOrigem" className="label">CEP de origem*</label>
									<div className="control">
									<input className="input" type="text"  maxLength="9" placeholder="00000-000"
									required={true} name="cepOrigem" value={request.cepOrigem}
									onChange= {(e)=> {setRequest({...request, cepOrigem: cepMask(e.target.value)});} }
									onBlur={(e) => { onBlur(e.target.value, 1)}} />
									</div>
								{!request.cepOrigem && (
 									<p className="help is-danger" style={{ fontSize: '11.3px' }}>*Preenchimento obrigatório</p>
								)}
								</div>
								<div className="field margin">
									<label htmlFor="cepDestino" className="label">CEP de destino*</label>
								<div className="control">
									<input className="input" type="text"  maxLength="9" placeholder="00000-000"
									required={true} name="cepDestino" value={request.cepDestino}
									onChange= {(e)=> {setRequest({...request, cepDestino: cepMask(e.target.value)});} }
									onBlur={(e) => { onBlur(e.target.value, 2)}} />
								</div>
								{!request.cepDestino && (
  								<p className="help is-danger" style={{ fontSize: '11.3px' }}>*Preenchimento obrigatório</p>
								)}
								</div>
								<div>
									<label className="label" htmlFor="valor">
										Valor total da encomenda
									</label>
									<div className="valor-input-mobile">
								<CurrencyInput name="valorEncomenda" placeholder="R$ 100,00" min="0"
							    className="input" separator="."  onChange = {(e, valorNovo)=> { onChangeValor (e.target.value, valorNovo)}}value={request.valorEncomenda} />
								</div>
								</div>
								<div className="selectionFilter">
								<label className="label">Tipo de pacote:</label>
								<div className="select opcoesTransacoes">
									<select name="formato" className="selectWidth" onChange={onChangeDiametro} disabled>
											<option value="" disabled defaultValue>Selecione</option>
											<option value="CAIXA">Caixa/Pacote</option>
											<option value="ENVELOPE">Envelope</option>
											<option value="ROLO_CILINDRO">Rolo/Cilindro</option>
									</select>
								</div>
							</div>
							</div>
						</SectionFormStyle>
						</SwiperSlide>
						<SwiperSlide>
							<SectionFormStyle>
							<div class="field is-grouped">
								<Input decoration={"cm"} classField="margin" type="number" name="alturaPacote" value={ request.alturaPacote } label="Altura" placeholder="2" onKeyPress={(e) => {
   						 if (e.key === "." || e.key === ",") {
      					e.preventDefault();
 						   }
  							}}
 					 onChange={(e) => {
   					 setRequest({ ...request, alturaPacote: parseInt( e.target.value) });
 					 }}	/>

								<Input decoration={"cm"} classField="margin" type="number" name="larguraPacote" value={ request.larguraPacote } label="Largura" placeholder="12"  onKeyPress={(e) => {
   						 if (e.key === "." || e.key === ",") {
      					e.preventDefault();
 						   }
  							}}
 					 onChange={(e) => {
   					 setRequest({ ...request, larguraPacote: parseInt( e.target.value) });
 					 }}	/>
								<Input decoration={"cm"} classField="margin" id="comprimentoPacote" value={ request.comprimentoPacote } type="number" name="comprimentoPacote" label="Comprimento" placeholder="17" onKeyPress={(e) => {
   						 if (e.key === "." || e.key === ",") {
      					e.preventDefault();
 						   }
  							}}
 					 onChange={(e) => {
   					 setRequest({ ...request, comprimentoPacote: parseInt( e.target.value) });
 					 }}	/>
								<Input  classField="margin" type="text" name="pesoPacote" label="Peso (Kg)" placeholder="0,3" value={request.pesoPacote} onChange= {(e) => {
									const numero = handleDecimalPeso(e.target.value, 3)
									if (erro.isWrong && erro.mensagem.includes("peso") && !(numero > limitePeso)) {
										setErro({ isWrong: false });
									}

									setRequest({...request, pesoPacote: numero});
								} } />
							</div>
							</SectionFormStyle>
							<SectionFormStyle>
								{
									showDiametro === true ?
									(
										<Input classField="margin" type="number" name="diametroPacote" label="Diametro" placeholder="18 cm" onChange= {(e)=> {setRequest({...request, diametroPacote: e.target.value});} }/>
									) : null
								}
							</SectionFormStyle>
						</SwiperSlide>

					</Swiper>
				</div>
				<SectionFormStyle>
					<div className="dots mobile-container" style={{justifyContent: 'center'}}>
						<span style={{backgroundColor: formSection === 0 ? '#792783' : '#EFEFEF'}}></span>
						<span style={{backgroundColor: formSection === 1 ? '#792783' : '#EFEFEF'}}></span>
					</div>
				</SectionFormStyle>

				<BtnFreteStyle>
					<button className="button is-rounded calcular" disabled={request.valorEncomenda > limiteEncomenda}>Calcular</button>
					<button className="button is-rounded clean" type="reset" onClick={clearForm}>Limpar</button>
				</BtnFreteStyle>

			</form>



				<div className="mobile-container">
					<SectionFormStyle style={{display: 'flex', flexDirection: "column", paddingTop: '30px', paddingBottom: '25px'}}>
						<p className="text">
						*Seu frete contará com seguro! Para definir a cobertura, é só informar o valor da mercadoria!
						</p>

						<p className="text">
						*Não esqueça de conferir se os dados estão completos para que a cotação apresente o preço certo, combinado?
						</p>
					</SectionFormStyle>
				</div>
			</FreteFormContainerStyle>
		</FreteFormStyle>
		</>
	);
}


export default FreteCalcForm;
