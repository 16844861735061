import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { ActionCreators } from '../../_actions/ActionCreators';
import FlexSpinner from "../../components/FlexFreteSpinner/FlexSpinner";
import AssinaturaService from '../../services/AssinaturaService';
import AutenticacaoService from '../../services/AutenticacaoService';
import GeografiaService from '../../services/GeografiaService';
import { cpfMask, removeMask } from '../../utils/Masks';
import validarEmail from '../../utils/ValidaEmail';
import validarTelefone from '../../utils/validarTelefone';
import Input from '../FlexFreteInput/Input';
import { ContaInfoEditFormStyle, ContaInfoStyle } from './style';

const geografiaService = new GeografiaService();
const assinaturaService = new AssinaturaService();
export function ContaInfoEdit(){
  const dispatch = useDispatch();
  const history = useHistory();
  let {assinaturaSelecionada} = useSelector((state) => {
    return {
      assinaturaSelecionada: state.assinaturaSelecionada
    };
    
  });
  let {endereco} = useSelector((state) => {
    return {
      endereco: state.endereco
    };
  });
  
  const [body, setBody] = useState({
    nome: '',
    sobrenome: '',
    documento: '',
    email: '',
    telefone: '',
    alertaRestricoesCorreios: false,
    alertaConfirmacaoImpressao: false,
    alertaConfirmacaoDeDeclaracaoConteudo: false,
    alertaPagamento: false,
    logradouro: '',
    numero: '',
    bairro: '',
    complemento: '',
    cep: '',
    siglaUf: '',
    cidade: ''
  });
  const [cepEndereco, setCepEndereco] = useState({
    cep:  "",
    bairro:  "",
    nomeCidade: "",
    cidade: undefined,
    logradouro:  "",
    siglaUf: undefined,
    numero:  "",
    complemento: "",
  });
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [disabledEndereco, setDisabledEndereco] = useState({
    enderecoBairro: false,
    estadoMunicipio: false
  });
  const [semNumero, setSemNumero] = useState(false)
  const [telefoneMask, setTelefoneMask] =  useState("(99)99999-9999");
  const [cepMask, setCepMask] =  useState("99999-999");

  const prevBody = usePrevious(body);

  const maxCaracteresComplementoEndereco = 20;

  const maxCacteresNumeroEndereco =  5;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]); 
    return ref.current;
  }


const [atualizou, setAtualizou] = useState(false);  
const [isLoading, setIsLoading] = useState(false);
const [mensagemErro, setMesagemErro] = useState(undefined);
const [mensagemSucesso, setMesagemSucesso] = useState(undefined);
const [isFieldInvalid, setisFieldInvalid] = useState(
    {
      telefone: false,
      email: false,
      numero: false,
      complemento: false,

    }
);
  useEffect(()=>{
      
    if( body?.email !== prevBody?.email){ 
      if(!validarEmail(body?.email)){
        setisFieldInvalid({...isFieldInvalid, email: true});
      }else{
        setisFieldInvalid({...isFieldInvalid, email: false});
      }
    }
    if(body?.telefone !== prevBody?.telefone){
      if(!validarTelefone(body?.telefone)){
        setisFieldInvalid({...isFieldInvalid, telefone: true});
      }else{
        setisFieldInvalid({...isFieldInvalid, telefone: false});
      }    
    }

    if(body?.numero !== prevBody?.numero){
       if(body.numero.length > 5){
          setisFieldInvalid({...isFieldInvalid, numero: true});
       }else{
            setisFieldInvalid({...isFieldInvalid, numero: false});
       }
    }

    if(body?.complemento !== prevBody?.complemento){
        if(body.complemento.length > 20){
             setisFieldInvalid({...isFieldInvalid, complemento: true});
        }else{
             setisFieldInvalid({...isFieldInvalid, complemento: false});
        }
    }

    }, [body, isFieldInvalid, prevBody]);

  useEffect(()=>{
    const {assinante} = assinaturaSelecionada;
    setBody({
      nome: assinante?.nome || '',
      sobrenome: assinante?.sobrenome || '',
      documento: assinante?.documento? cpfMask(assinante.documento): '',
      email: assinante?.email || '',
      telefone: assinante?.telefone || '',
      alertaRestricoesCorreios: assinaturaSelecionada?.alertaRestricoesCorreios || false,
      alertaConfirmacaoImpressao: assinaturaSelecionada?.alertaConfirmacaoImpressao || false,
      alertaConfirmacaoDeDeclaracaoConteudo: assinaturaSelecionada?.alertaConfirmacaoDeDeclaracaoConteudo || false,
      alertaPagamento: assinaturaSelecionada?.alertaPagamento || false,
      logradouro: endereco?.logradouro || '',
      numero: endereco?.numero?.substring(0,maxCacteresNumeroEndereco) || '',
      bairro: endereco?.bairro || '',
      complemento: endereco?.complemento?.substring(0,maxCaracteresComplementoEndereco) || '',
      cep: endereco?.cep || '',
      siglaUf: endereco?.siglaUf || '',
      cidade: endereco?.cidade || ''
    });
  }, [assinaturaSelecionada, endereco]);

  useEffect(() => {
    setIsLoading(true)
    geografiaService.obtemEstados((erro, sucesso) => {
      if(erro){
      setMesagemErro('Falha ao obter estados, entre em contato com o suporte ou tente novamente mais tarde'); 
      return;
    }
    if(sucesso){
      setEstados(sucesso);
    }
  })
    assinaturaService.loadUsuarioAssinatura((err, sucess)=>{
      if(err){
        setIsLoading(false)
      }else{
        dispatch(ActionCreators.addAssinaturaSelecionada(sucess));
        
        setCepEndereco({
          cep: sucess.endereco?.cep,
          bairro: sucess.endereco.bairro,
          nomeCidade: sucess.endereco.cidade,
          cidade: sucess.endereco.nomeCidade,
          siglaUf: sucess.endereco.estado,
          logradouro: sucess.endereco.logradouro.trim(),
          nomeUf: sucess.endereco.nomeUf,
          numero: sucess.endereco.numero ? sucess.endereco.numero:"",
          complemento: sucess.endereco.complemento ? sucess.endereco.complemento:""
        });
        setDisabledEndereco({
          enderecoBairro: !!(sucess.endereco.logradouro && sucess.endereco.bairro),
          estadoMunicipio: true
        });
        getCidades(sucess.endereco.estado);
        setIsLoading(false)
        assinaturaService.setEnderecoAssinatura(sucess.endereco)
       
      }
    });
    
    // TODO: esse useEffect deve ser usado apenas quando a pagina é carregada --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCidades = (event) => {
    let city;

    if(event.target){
      const { value } = event.target
      city = value
    } else {
      city = event
    }
    geografiaService.obtemMunicipios(city, (erro, sucesso) => {
      if(erro) {
        setMesagemErro('Falha ao obter municipios, entre em contato com o suporte ou tente novamente mais tarde');
        return;
      }
      if(sucesso){
        setCidades(sucesso)
      }
     
    })
  }

  const getCEP = (cep) => {
    if(!cep){
      return;
    }
    geografiaService.buscaPorCEP(cep, (erro, sucesso) => {
      if(erro){
        setMesagemErro('CEP não localizado. Insira manualmente informações do endereço.');
        return;
      }
      if(sucesso){
        
        setCepEndereco({
          ...cepEndereco,
          cep: sucesso.cep,
          bairro: sucesso.bairro ? sucesso.bairro:endereco?.bairro,
          nomeCidade: sucesso.nomeCidade,
          cidade: sucesso.cidade,
          siglaUf: sucesso.siglaUf,
          logradouro: sucesso.logradouro.trim() ? sucesso.logradouro:endereco?.logradouro,
          nomeUf: sucesso.nomeUf,
          
        });
        setDisabledEndereco({
          enderecoBairro: !!(sucesso.logradouro && sucesso.bairro),
          estadoMunicipio: true
        });
      }

      getCidades(sucesso.siglaUf);
    });
  }

  const onChangeSemNumero = (event) => {
    setSemNumero(event.target.checked)
    if(event.target.checked){
      setisFieldInvalid({...isFieldInvalid, numero: false});
      setCepEndereco({...cepEndereco, numero: "S/N"})
    } else {
        setCepEndereco({...cepEndereco, numero: ""})
    }
  }

  const onBlurTelefone = (e) => {
    const {value} = e.target
    if(value[value.length - 1] === "_"){
      setTelefoneMask("(99)9999-9999");
    }else{
      setTelefoneMask("(99)99999-9999")
    }
  }
  const onFocusTelefone = (e) =>{
     setTelefoneMask("(99)99999-9999");
  }

  const onBlurCep = (e) => {
    const {value} = e.target
    if(value[value.length - 1] === "_"){
      setCepMask("99999-999");
    }else{
      setCepMask("99999-999")
      let cep = e.target.value;
      if(!cep.length){
        return
      }
      let formatedcep = cep.replace(/[^0-9]/g, "")
      return getCEP(formatedcep);
  
    }
  }
  const onFocusCep = (e) =>{
     setCepMask("99999-999");
  }
  setTimeout(() => {
    setMesagemSucesso();
}, 5000);

  function redirect(){
    
    if(atualizou){
      setTimeout(() => {
        history.push("/configuracoes/conta");
    }, 4000);
      
    }
    
  }

  function submit(e){
    e.preventDefault();
    
    
    setIsLoading(true);
    const uuid = assinaturaSelecionada.uuid;
    const assinaturaService = new AssinaturaService();
    const autenticacaoService = new AutenticacaoService();

    const request = {
      nome: body.nome || '',
      sobrenome: body.sobrenome,
      documento: body.documento || '',
      email: body.email || '',
      telefone: body.telefone || '',
      alertaRestricoesCorreios: body.alertaRestricoesCorreios || false,
      alertaConfirmacaoImpressao: body.alertaConfirmacaoImpressao || false,
      alertaConfirmacaoDeDeclaracaoConteudo: body.alertaConfirmacaoDeDeclaracaoConteudo || false,
      alertaPagamento: body.alertaPagamento || false,
      endereco: {
        logradouro: cepEndereco.logradouro || '',
        numero: !body.numero || semNumero === true ? "S/N" : body.numero,
        bairro: cepEndereco.bairro || '',
        complemento: cepEndereco.complemento || '',
        cep: cepEndereco.cep || '',
        siglaUf: cepEndereco.siglaUf || '',
        cidade: cepEndereco.cidade || ''
      }
    }
    assinaturaService.put(uuid, request, (err, sucess) =>{
      if(err){
        setMesagemErro("Não foi possível proseguir a requisição.");
      }else{
        
        setMesagemErro(undefined);
        dispatch(ActionCreators.addAssinaturaSelecionada(sucess));
        dispatch(ActionCreators.changeEndereco(sucess.endereco))
        autenticacaoService.setEnderecoCorrente(sucess.endereco);
        autenticacaoService.updateUsuarioCorrente(sucess.assinante);
        setCepEndereco({...cepEndereco,
          cep: sucess.endereco.cep,
          bairro: sucess.endereco.bairro,
          logradouro: sucess.endereco.logradouro,
          complemento: sucess.endereco.complemento,
          numero: sucess.endereco.numero
        })
        setBody({...body, complemento: sucess.endereco.complemento})
        setMesagemSucesso('Informações alteradas com sucesso! Redirecionando ...');
        setAtualizou(true)
      }
      setIsLoading(false);
      
    });
  }

    function hasFieldInvalid(){
      return Object.keys(isFieldInvalid).some((key) => isFieldInvalid[key]);
    }

    return (
      <ContaInfoEditFormStyle onSubmit = {submit}>
        {isLoading? <FlexSpinner/>: null}
        {atualizou ? redirect(): null}

        <ContaInfoStyle className="conta-section">
          <h3 className="dados-title">Meus dados</h3>
          <div className="dados mx-auto">

            <div>
              <label htmlFor="nome" className="label-grey">Nome <span className="input-required"></span></label>
              <input className="input input-text" type="text" required name="nome" value={body.nome} onChange={
                (e) => {
                  setBody({...body, nome: e.target.value});
                }
              }/>
            </div>

            <div>
              <label htmlFor="nome" className="label-grey">Sobrenome <span className="input-required"></span></label>
              <input className="input input-text" type="text" required name="sobrenome" value={body.sobrenome}
                onChange={(e) => {
                  setBody({...body, sobrenome: e.target.value});
                }}
              />
            </div>
            <div>
              <label htmlFor="CPF" className="label-grey">CPF<span className="input-required"></span></label>
              <input className="input input-text" type="text" required name="cpf" disabled={true}
                     value={body.documento}/>
            </div>

            <div>
              <label htmlFor="telefone" className="label-grey">Telefone <span
                  className="input-required">*</span></label>
              <Input type="tel" mascara={telefoneMask}
                     class={` input input-text ${isFieldInvalid.telefone ? "is-danger" : ""}`} name="telefone"
                     value={body.telefone}
                     required={true}
                     onChange={(e) => {
                       setBody({...body, telefone: removeMask(e.target.value)});
                     }} onFocus={onFocusTelefone} onBlur={onBlurTelefone}/>
            </div>

            <div className="full-width">
              <label htmlFor="email" className="label-grey">Email <span className="input-required">*</span></label>
              <input className={`input input-text ${isFieldInvalid.email ? "is-danger" : ""}`} type="text"
                     required name="email" value={body.email} onChange={(e) => {
                setBody({...body, email: e.target.value});
              }}/>
            </div>


            <div className="full-width">
              <label htmlFor="cep" className="label-grey">CEP <span className="input-required">*</span></label>
              <Input type="text" mascara={cepMask} name="cep" value={cepEndereco.cep} required={true}
                     class={`input input-text`}
                     onChange={(e) => {
                       setCepEndereco({...cepEndereco, cep: e.target.value});
                       setBody({...body, cep: removeMask(e.target.value)});
                     }} onFocus={onFocusCep} onBlur={onBlurCep}
              />
            </div>


          </div>
          <h3 className="dados-title mt-5">Endereço</h3>
          <div className="dados mx-auto">

            <div>
              <label htmlFor="endereco" className="label-grey">Endereço <span
                  className="input-required">*</span></label>
              <input type="text" name="logradouro" value={cepEndereco.logradouro}
                     disabled={disabledEndereco.enderecoBairro === true} required={true}
                     className={`input input-text`}
                     onChange={(e) => {
                       setCepEndereco({...cepEndereco, logradouro: e.target.value})
                       setBody({...body, logradouro: e.target.value});
                     }}
              />
            </div>

            <div>
              <label htmlFor="numero" className="label-grey">Número <span className="input-required">*</span></label>
              <input className={`input input-text ${isFieldInvalid.numero ? "is-danger" : ""}`} type="text"
                     name="numero"
                     disabled={semNumero}
                     value={cepEndereco.numero}
                     required={semNumero}
                     onChange={(e) => {
                       setBody({...body, numero: e.target.value});
                       setCepEndereco({...cepEndereco, numero: e.target.value})
                     }}
                     maxLength={maxCacteresNumeroEndereco}

              />
              <small style={{textAlign: 'left'}}>
                <div className="field mt-3">
                  <div className="control">
                    <label style={{fontWeight: 700, color: '#282828'}}>
                      <input type="checkbox" name="numero" onChange={onChangeSemNumero}/> Sem número
                    </label>
                  </div>
                </div>
              </small>
            </div>


            <div>
              <label htmlFor="complemento" className="label-grey">Complemento</label>
              <input type="text" name="complemento" value={body.complemento}
                     className={`input input-text ${isFieldInvalid.complemento ? "is-danger" : ""}`}
                     onChange={(e) => {
                       setCepEndereco({...cepEndereco, complemento: e.target.value})
                       setBody({...body, complemento: e.target.value});
                     }}
                        maxLength={maxCaracteresComplementoEndereco}
              />
            </div>

            <div>
              <label htmlFor="bairro" className="label-grey">Bairro <span className="input-required">*</span></label>
              <input type="text" name="bairro" value={cepEndereco.bairro}
                     disabled={disabledEndereco.enderecoBairro === true}
                     required={true} className={`input input-text`}
                     onChange={(e) => {
                       setCepEndereco({...cepEndereco, bairro: e.target.value})
                       setBody({...body, bairro: e.target.value});
                     }}
              />
            </div>

            <div>
              <label htmlFor="cidade" className="label-grey">Cidade <span className="input-required">*</span></label>
              <div className="select" style={{width: "100%"}}>
                <select value={cepEndereco.cidade || undefined} name="cidade" style={{width: "100%", padding: '4px'}}
                        disabled={disabledEndereco.estadoMunicipio} className={`input input-text`}
                        onChange={(e) => {
                          setBody({...body, cidade: e.target.value});
                        }}
                >
                  {
                    cidades.map((cidade, index) => {
                      return (
                          <option key={Math.random() + index}
                                  value={cidade.uuid}
                          >

                            {cidade.nome}

                          </option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

            <div>
              <label htmlFor="estado" className="label-grey">Estado <span className="input-required">*</span></label>
              <div className="select" style={{width: "100%"}}>
                <select value={cepEndereco.siglaUf || undefined} name="siglaUf" style={{width: "100%", padding: '4px'}}
                        disabled={disabledEndereco.estadoMunicipio} className={`input input-text`}
                        onChange={(e) => {
                          setBody({...body, siglaUf: e.target.value});
                        }}
                >
                  {
                    estados.map((estado, index) => {
                      return (
                          <option key={Math.random() + index}
                                  value={estado.nome}
                          >

                            {estado.nomeExtenso} - {estado.nome}

                          </option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          {hasFieldInvalid() ?
              (<div className="notification is-warning">
                Campo(s) inválidos.
              </div>) : ""
          }

        </ContaInfoStyle>
        {/*
        <ContaInfoStyle className="conta-section">
           <h3 className="dados-title">Preferencias</h3>
                <div className="dados">
                
                  
                    
                    <div className="dado">
                        <label className="checkbox"> 
                        <input type="checkbox" checked={body.alertaRestricoesCorreios} onChange={e => setBody({
                          ...body, alertaRestricoesCorreios: !body.alertaRestricoesCorreios
                        })}/>
                             Receber alerta de restrições do serviço Correios - mini-envios
                        </label>
                    </div>
                    <div className="dado">
                        <label className="checkbox">
                             <input type="checkbox" checked={body.alertaConfirmacaoDeDeclaracaoConteudo} onChange={e => setBody({
                               ...body, alertaConfirmacaoDeDeclaracaoConteudo: !body.alertaConfirmacaoDeDeclaracaoConteudo
                             })}/>
                             Receber alerta de confirmação de termos de declaração de conteúdo</label>
                    </div>
                    <div className="dado">
                        <label className="checkbox"> 
                        <input type="checkbox" checked={body.alertaConfirmacaoImpressao} onChange={e => setBody({
                          ...body, alertaConfirmacaoImpressao: !body.alertaConfirmacaoImpressao
                        })}/>

                        Receber alerta de confirmação de impressão</label>
                    </div>
                    <div className="dado">
                        <label className="checkbox"> 
                        <input type="checkbox" checked={body.alertaPagamento} onChange={e => setBody({
                          ...body, alertaPagamento: !body.alertaPagamento
                        })}/>

                        Receber alerta de pagamento</label>
                    </div>
                </div>
        </ContaInfoStyle> */}

        <div>
          <div className="actions">
            <button className="button button-submit" type="submit" disabled={isLoading || hasFieldInvalid()}>Salvar
            </button>
            <Link to="/configuracoes/conta" className="button ">Voltar</Link>
          </div>
          {mensagemErro ?
              (<div className="notification is-warning">
                {mensagemErro}
              </div>) : ""
          }
          {mensagemSucesso ?
              (<div className="notification is-primary">
                {mensagemSucesso}
              </div>) : ""
          }
        </div>
      </ContaInfoEditFormStyle>
    )
}
