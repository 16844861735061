import { cepMask, cpfMask, telefoneMask } from '../../utils/Masks';
import { ContaInfoStyle } from './style';

export function ContaInfo(props){

    return (
        <>
            <ContaInfoStyle className="conta-section">
            <h3 className="dados-title">Meus dados</h3>
                    <div className="dados">
                    <div className="dado">
                        <span className="dado-atributo">
                            Nome:
                        </span>
                        <span>
                            {props.assinante?.nome}

                        </span>
                    </div>

                    <div className="dado">
                        <span className="dado-atributo">
                        Sobrenome:
                        </span>
                        <span>
                            {props.assinante?.sobrenome}
                        </span>
                    </div>

                    <div className="dado">
                        <span className="dado-atributo">
                        CPF:
                        </span>
                        <span>
                            {cpfMask(props.assinante?.documento)}
                        </span>
                    </div>
                    <div className="dado">
                        <span className="dado-atributo">
                        <strong>Email:</strong>
                        </span>
                        <span>
                            {props.assinante?.email}
                        </span>
                    </div>
                    <div className="dado">
                        <span className="dado-atributo">
                        <strong>Telefone:</strong>
                        </span>
                        <span>
                            {telefoneMask (props.assinante?.telefone)}
                        </span>
                    </div>
                    </div>
                    <hr />
                    <h3 className="dados-title">Endereço</h3>
                    <div className="dados">
                    <div className="dado">
                        <span className="dado-atributo">
                            Rua/Avenida:
                        </span>
                        <span>
                            {props.endereco?.logradouro || ""}
                            , {props.endereco?.numero || ""}
                            {props.endereco?.complemento ? "," + props.endereco?.complemento : ""}
                            

                        </span>
                    </div>

                    <div className="dado">
                        <span className="dado-atributo">
                        Bairro:
                        </span>
                        <span>
                        {props.endereco?.bairro || ""}
                        </span>
                    </div>
                    <div className="dado">
                        <span className="dado-atributo">
                        <strong>Cidade/Estado:</strong>
                        </span>
                        <span>
                        {props.endereco?.cidade || ""} - {props.endereco?.estado || ""}
                        </span>
                    </div>
                    <div className="dado">
                        <span className="dado-atributo">
                        <strong>CEP:</strong>
                        </span>
                        <span>
                            {cepMask(props.endereco?.cep) || ""}
                        </span>
                    </div>
                    </div>
            </ContaInfoStyle>
            {/* <ContaInfoStyle className="conta-section">
                <h3 className="dados-title">Preferencias</h3>
                        <div className="dados">
                        
                        
                            
                            <div className="dado">
                                <label className="checkbox"> 
                                <input type="checkbox" checked={props.alertaRestricoesCorreios} disabled={true}/>
                                    Receber alerta de restrições do serviço Correios - mini-envios
                                </label>
                            </div>
                            <div className="dado">
                                <label className="checkbox">
                                    <input type="checkbox" checked={props.alertaConfirmacaoDeDeclaracaoConteudo} disabled={true}/>
                                    Receber alerta de confirmação de termos de declaração de conteúdo</label>
                            </div>
                            <div className="dado">
                                <label className="checkbox"> 
                                <input type="checkbox" checked={props.alertaConfirmacaoImpressao} disabled={true}/>

                                Receber alerta de confirmação de impressão</label>
                            </div>
                            <div className="dado">
                                <label className="checkbox"> 
                                <input type="checkbox" checked={props.alertaPagamento} disabled={true}/>

                                Receber alerta de pagamento</label>
                            </div>
                        </div>
            </ContaInfoStyle> */}
        </>


    )

}