import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import TagueamentoService from '../../services/TagueamentoService';
import { ConfirmacaoCardStyle, ConfirmacaoDeSaldoStyle } from './style';

const tagueamentoService = new TagueamentoService(); 

function ConfirmacaoInsercaoDeSaldo() {
    useEffect(() => {
      tagueamentoService.eventoMarketingCreditoInserido()
    })

    const history = useHistory();

    const onClick = () => {
        history.push("/carteira")
    }
  return (
    <div className="page-container-style"> 
      <ConfirmacaoDeSaldoStyle>
          <ConfirmacaoCardStyle>
            <h1 className="title"> Compra registrada!</h1>

            <h2 className="subTitle">Assim que o pagamento for aprovado, o crédito será inserido na sua conta Flex Frete.</h2>

            <button className="button" onClick={onClick}>Voltar para Carteira Flex</button>
          </ConfirmacaoCardStyle>
      </ConfirmacaoDeSaldoStyle>
    </div>
  );
  
}

export default ConfirmacaoInsercaoDeSaldo;
