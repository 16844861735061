import React, { useEffect, useState } from 'react';
import {ContaStyle} from './style';
import { ContaInfoEdit } from '../../components/Conta/ContaInfoEdit';
import { useStore } from "react-redux";

function Conta() {
  const assinaturaSelecionada = useStore().getState().assinaturaSelecionada;

  const [conta, setConta] = useState(
    {
    nome:"",
    cpf: "",
    email: "",
    telefone: ""
  });
 
  useEffect(()=>{
    const {assinante} = assinaturaSelecionada || {};
    setConta({
      nome:assinante?.nome || "",
      cpf: assinante?.documento || "",
      email:assinante?.email || "",
      telefone:assinante?.telefone || "",

    });
  },[assinaturaSelecionada]);
  
  return (
    <div className="page-container-style">

      <ContaStyle>
        <h2 className="page-title">Minha Conta</h2>
        <ContaInfoEdit {...conta}/>

      </ContaStyle>
    </div>
  );
}

export default Conta;