import instance from "./Api";

export default class LojaService {
    obterLojas(callback){
        instance
            .get(this.host() + "/api/v1/loja")
            .then((response) => {
                callback(null, response.data)            
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    criarNovaLoja(request, callback){
        instance
            .post(this.host() + "/api/v1/loja", request)
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    editarLoja(uuid, request, callback){
        instance
            .put(this.host() + `/api/v1/loja/${uuid}`, request)
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) =>{
                callback(erro, null)
            })
    }
    obterLojaConvites(uuid, callback){
        instance
            .get(this.host() + '/api/v1/loja/' + uuid + "/convite")
            .then((response)=>{
                callback(null, response.data);
            })
        .catch((err)=>{
            callback(err, null);
        });
    }   
    enviarLojaConvites(uuidLoja, request, callback){
        instance
            .post(this.host() + '/api/v1/loja/' + uuidLoja + "/convite", request)
            .then((response)=>{
                callback(null, response.data);
            })
        .catch((err)=>{
            callback(err, null);
        });
    } 
    deletarLojaConvite(uuidLoja, uuidConvite, callback){
        if(!uuidConvite){
            return;
        }
        instance
            .delete(this.host() + '/api/v1/loja/' + uuidLoja + "/convite/" + uuidConvite)
            .then((response)=>{
                callback(null, response.data);
            })
        .catch((err)=>{
            callback(err, null);
        });
    }  
    setLojaPadrao(loja){
        localStorage.setItem('lojaPadrao', JSON.stringify(loja))
    }

    getLojaPadrao() {
        let valorLojaPadrao = localStorage.getItem('lojaPadrao');
        if(valorLojaPadrao) {
            return JSON.parse(valorLojaPadrao);
        } else {
            return null;
        }
    }
    deleteLoja(uuid, callback){
        instance
        .delete(this.host() + '/api/v1/loja/' + uuid)
        .then((response)=>{
            callback(null, response.data);
        })
        .catch((err)=>{
            callback(err, null);
        });
    }

    removeLojaPadrao(){
        localStorage.removeItem('lojaPadrao')
    }

    host() {
		return process.env.REACT_APP_HOST_API;
	}
}