import styled from "styled-components";

export const RegisterStyle = styled.section`
	padding-top: 6em;
	padding-bottom: 3em;
	margin-left: 5%;
	margin-right: 5%;
	h1 {
		color: #282828;
		font-size: 1.8em;
	}

	form {
		width: 100%;
	}

	a{
		color: #812990;
	}

@media (max-width: 2560px) {
	margin-left: 20%;
	margin-right: 20%;
}

@media (max-width: 1024px) {
	margin-left: 15%;
	margin-right: 15%;
}

@media (max-width: 770px) {
	margin-left: 0;
	margin-right: 0;
}
`;

export const FormRegisterContainerStyle = styled.section`
	border-radius: 20px;
	border: none;
	margin: 1.5em;
	margin-bottom: 1em;
	padding: 3em 2em 3em 2em;
	background-color: white;

	ion-icon {
        font-size: 2rem;
    }

	input:disabled{
		color: #5f5e5e;
	}

	select:disabled{
		color: #5f5e5e;
	}

	.input-enfase{
		flex-grow: 1;
	}

	.field{
		margin-bottom: 1.5em;
	}

	.termos-button{
		background: none;
		border: none;
		margin: 1em 0em;
    font-size: 1.8rem;
		cursor: pointer;
		line-height: 1.25;
		margin-left: 0.2em;
	}

	.link-uso{
	
	}
	.field > .control > .input{
		font-size: 1.8rem;
		
	}

	.field > .control > .input:required:focus{
		border-color: red;
	}

	.input:required:focus{
		border-color: red;
	}

	.control > .button{
		font-size: 1.8rem;
	}

	.field > .label{
		text-align: left;
		font-size: 1em;
	}

	.label{
		text-align: left;
		font-size: 1em;	
	}

	.columns > .column > .field{
		margin-bottom: 0;
	}

	.control > .checkbox {
		margin: 1em 0em 1em 0em;
		font-size: 1.8rem;
	}

	.radio-check-options{
		margin-top: 1.3rem;
		margin-bottom: 0;
	}

	.radio-check-options > .control{
		margin-top: 1.3rem;
		margin-bottom: 0;
	}

	.section-half{
		display: flex;
		justify-content: space-between;
	}

	.section-half > div:first-child{
		margin-right: 2em;
		flex-grow: 1;
	}

	.section-half > div:last-child{
		flex-grow: 1;
	}

	.select > select{
		font-size: 1.8rem;
	}

	.select > select > option{
		font-size: 1.8rem;
	}

	.help{
		font-size: 1em;
		text-align: left;
	}

	hr {
		margin-top: 3em;
		margin-bottom: 3em;
	}
	h2{
		font-size: 1.2em;
	}

	@media (max-width: 770px) {
		padding: 3em 1.5em 3em 1.5em;
		margin-bottom: 0;
		.section-half > div:first-child{
			margin-right: 1em;
		}

	}
	@media (min-width: 1280px) {
		.recuperar-senha-container{
			width: 400px;
		}
	}	
`;

export const BtnLoginRegisterChangePasswordStyle = styled.button`
	margin: 1em 0em 1em 0em;
	background: rgb(131, 43, 146);
	background: linear-gradient(
		267deg,
		rgba(131, 43, 146, 1) 39%,
		rgba(160, 69, 176, 1) 100%
	);
	color: white;
	font-size: 1.3em;
	font-weight: 600;
	letter-spacing: 0.3px;
	border: none;
	width: 60%;

	:hover{
		background: #c986d8;
		color: white;
	}

`;
