import { useState } from 'react';
import AutenticacaoService from '../../services/AutenticacaoService';
import DocumentosService from '../../services/DocumentosService';
import DownloadService from '../../services/DownloadService';
import { DocCardStyle } from './style';

function CardDocumento(props) {
    const docservice = new DocumentosService();
    const service = new AutenticacaoService();
    const getAssinatura = service.usuarioCorrente();
    let uuidAssinatura = getAssinatura.identificadorAssinatura;
    const downloadService = new DownloadService();
    const [processando, setProcessando] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null)
    const [filePreview, setFilePreview] = useState(null);
    const [isSelected, setIsSelected] = useState(false);
    const [progresso, setSetProgresso] = useState(0);
    const [updateDocumentoSituacao, setUpdateDocumentoSituacao] = useState(null)
    const [erro, setErro] = useState({
      isWrong: false,
      titulo: "",
      mensagem: "",
      disableButton: false
    });
    const [sucesso, setSucesso] = useState({
      isSucesso: false,
      titulo: "",
      mensagem: "",
    });

  const onChange = (event) => {
    const [file] = event.target.files;
    if(!file){
      return;
    }
    setErro({
      isWrong: false,
      titulo: "",
      mensagem: "",
      disableButton: false
    });


    const {size, type } = file;
    
    if(size > 15728640 ){
      setErro({
        isWrong: true,
        mensagem: 'O tamanho  máximo para envio de documento é de 15mb',
        disableButton: true
      })
      return;
    }
    if(type === "image/png" || type === "image/jpeg" || type === "image/jpg" || type === "image/gif" || type === "application/pdf"){
        setErro({
          isWrong: false,
        });
        setSelectedFile(file);
        setIsSelected(true);
        if(type === "application/pdf") {
          setFilePreview("pdf")
        } else{
          let url = URL.createObjectURL(file)
          setFilePreview(url)
        }
      } else{
        
        setErro({
          isWrong: true,
          mensagem: 'É permitido o upload apenas de arquivos pdf, jpeg/jpg, png ou gif',
          disableButton: true
      })
    }

  }
    
  const onClick = () => {
    setProcessando(true);
    setSetProgresso(0);
        docservice.registrarDocumento(selectedFile, props.doc.uuid, uuidAssinatura, (erroDoc, sucesso, uploadFile) => {
          if(uploadFile){
            setSetProgresso(Math.round((100 * uploadFile.loaded) / uploadFile.total))
          }
          if(erroDoc){
            setProcessando(false);
            setErro({
              isWrong: true,
              mensagem: 'Erro ao enviar documento, verifique se o tamanho máximo de 15mb e a extensão do arquivo estão sendo respeitados e tente novamente. Se o problema persistir, entre em contato com o suporte.'
          })
            return
          }
          if(sucesso) {
            setProcessando(false)
            setUpdateDocumentoSituacao(sucesso)
            setSucesso({
              isSucesso: true,
              mensagem: 'Arquivo enviado com sucesso!'
            })
            setSelectedFile(null)
            
          }
        });
  }

  const gerarContrato =  () => {
    docservice.gerarContratoPdf((err, sucess)=>{
        if(err){
            setErro({
                isWrong: true,
                titulo: 'Erro ao baixar arquivo',
                mensagem: 'Falha ao tentar realizar o download, entre em contato com o suporte ou tente novamente mais tarde.'
              })
        }else{
           downloadService.downloadFile({label: "contrato", data: sucess, filename: uuidAssinatura + ".pdf", extension: "pdf"})
           setProcessando(false)
           setUpdateDocumentoSituacao(sucesso)
           setSucesso({
             isSucesso: true,
             mensagem: 'Contrato enviado com sucesso!'
           })
           setSelectedFile(null)
           
        }
    });
}

  setTimeout(
    () => setSucesso({isSucesso: false}),
    10000
  )

  function renderSucesso(){
    if(sucesso.isSucesso=== true){
      return (
        <div className="notification is-primary" style={{marginBottom: '50px'}}>
          {sucesso.mensagem}
        </div>
      )
    }
    
  }

  function renderErro(){
    if(erro.isWrong === true){
      return (
        <div className="notification is-warning" style={{marginTop: '90px'}}>
              {erro.mensagem}
        </div>
      )
    }else{
      return(
        <>
            {
              updateDocumentoSituacao ?
              (
                <h1 style={{color: "#e6b222"}} className="statusDocumento">{updateDocumentoSituacao.status  === "AVALIACAO" ? "EM AVALIAÇÃO" : ""}</h1>
              ) :
              (
              <>
                {
                  props.status ?
                  (
                  <>
                    <h1 className="statusDocumento">{props.status === "AVALIACAO" ? "EM AVALIAÇÃO" : props.status}</h1>
                  </>
                  ) : null
                  
                }
  
                {
                  props.status === 'APROVADO' ? null :
                  (
                    <div className="loading-imagem-section">
                    <div className="image-container">
                      {
                        filePreview !== null && filePreview !== "pdf" ?
                        (
                          <figure className="image is-128x128">
                          <img src={filePreview} alt="documento"/>
                          </figure>
                        ) :
                        (  
                          <> 
                          {
                            filePreview === "pdf" ? null :
                            (
                              <figure className="image is-128x128">
                              <img src="/128x128.png" alt="imagePlaceholder" />
                              </figure>
                            )
                          } 
                          </> 
                        )
                      }
                    {selectedFile != null ? (<h3>{selectedFile.name}</h3>) : null }
                    </div>
                  </div>
                  )
                }
              </>
          )

        }


            </>
      ) 
    }
    
  }
  
  return (
    <>
    <div className="column is-6 column-doc-gap">
    <DocCardStyle status={props.status}>
      {
        props.doc.nome !== 'Contrato' ?
        (
          <>
          <h2>{props.doc.nome}</h2>
          <p className="doc-descricao">{props.doc.descricao}</p>
          
          <hr />

          {
            renderSucesso()
          }

        {
          renderErro()
        
        }

          <div className="btn-doc-container">
            {
              processando === true ?
              (
                <>
                <h3>{progresso}%</h3>
                <progress className="progress is-warning" value={progresso} max="100">{progresso}%</progress>
                </>
              ) : ""
            }

          {
            props.status === 'APROVADO' ? null :
            (
              <div className="file is-centered">
              <label className="file-label">
                <input className="file-input" type="file" onChange={onChange} />
                <span className={`${isSelected === true || props.status ? 'file-cta-again' : 'file-cta'}`}>
                    <span className="file-label">
                      {
                        props.status || updateDocumentoSituacao?.status ? 
                        (
                          "Substituir documento carregado"
                        ) :
                        (
                          <>
                          {isSelected === true ? "Substituir documento selecionado" : "Selecionar Documento"}
                          </>
                        )
                      }
                      </span>
                </span>
              </label>
            </div>
            )
          }
            {
              selectedFile && !erro.disableButton?
              (
                <button className={`button ${processando === true ? 'is-loading' : ''}`} disabled={erro.disableButton} onClick={onClick}>Enviar documento</button>
              ) : 
              (
                <>
                </>
              )
            }

            {
              props.doc.assinaturaDigital === true ?
              (
                <button className="button assinaturaDigital">Assinar digitalmente</button>
              ) : null
            }
            
          </div>
          </>
        ) :
        (
          <>
          <h2>{props.doc.nome}</h2>
          <p className="doc-descricao">{props.doc.descricao}</p>
          
          <hr />
          {
            sucesso.isSucesso=== true ?
            (
              <div className="notification is-primary" style={{marginBottom: '50px'}}>
                {sucesso.mensagem}
              </div>
            ) : null
          }
          {
            updateDocumentoSituacao ?
            (
              <h1 style={{color: "#e6b222"}} className="statusDocumento">{updateDocumentoSituacao.status  === "AVALIACAO" ? "EM AVALIAÇÃO" : ""}</h1>
            ) :
            (
              <>
              {
                props.status ? <h1 className="statusDocumento">{props.status === "AVALIACAO" ? "EM AVALIAÇÃO" : props.status}</h1> : null
              }
            </>
            )
          }

          <div className="btn-doc-container">
          {
            props.status === 'APROVADO' ? null :
            (
              <div>
              <input type="button" className="button" value="Baixar contrato" onClick={e => { gerarContrato()}}/>
              <div className="loading-imagem-section">
                    <div className="image-container">
                      {
                        filePreview !== null && filePreview !== "pdf" ?
                        (
                          <figure className="image is-128x128">
                          <img src={filePreview} alt="documento"/>
                          </figure>
                        ) :
                        (  
                          <> 
                          {
                            filePreview === "pdf" ? null :
                            (
                              <figure className="image is-128x128">
                              <img src="/128x128.png" alt="imagePlaceholder" />
                              </figure>
                            )
                          } 
                          </> 
                        )
                      }
                    {selectedFile != null ? (<h3>{selectedFile.name}</h3>) : null }
                    </div>
                  </div>
              <div className="file is-centered">
              <label className="file-label">
                <input className="file-input" type="file" onChange={onChange} />
                <span className={`${isSelected === true || props.status ? 'file-cta-again' : 'file-cta carregarContrato'}`}>
                    <span className="file-label">
                      {
                        props.status || updateDocumentoSituacao?.status ? 
                        (
                          "Substituir documento carregado"
                        ) :
                        (
                          <>
                          {isSelected === true ? "Substituir documento selecionado" : "Selecionar Documento"}
                          </>
                        )
                      }
                      </span>
                </span>
              </label>
            </div>
            {
              selectedFile && !erro.disableButton ?
              (
                <>
                <button className={`button ${processando === true ? 'is-loading' : ''}`} disabled={erro.disableButton} onClick={onClick}>Enviar contrato</button>

                  {
                      processando === true ?
                      (
                        <>
                        <h3>{progresso}%</h3>
                        <progress className="progress is-warning" value={progresso} max="100">{progresso}%</progress>
                        </>
                      ) : ""
                  }
              </>
              ) : 
              (
                <>
                </>
              )
            }
              {
              props.doc.assinaturaDigital === true ?
              (
                <button className="button assinaturaDigital">Assinar digitalmente</button>
              ) : null
            }
            </div>
            )
          }
          </div>
          </>
        )
      }
    </DocCardStyle>
    </div>
    </>
  );
}

export default CardDocumento;