import api from './Api';

export default class NotificacaoService{

    obterNotificacoes(request, callback){
        api
            .get(this.host() + '/api/v1/notificacao', {
                params: {
                    ...request
                }
            })
            .then((response)=>{
                callback(null, response.data);
            })
            .catch((err)=>{
                callback(err, null);
            })
    }

    obterQuantidadeDeNotificacoes(callback){
        api
        .get(this.host() + '/api/v1/notificacao/quantidade')
        .then((response)=>{
            callback(null, response.data);
        })
        .catch((err)=>{
            callback(err, null);
        })
    }
  
    host(){        
		return process.env.REACT_APP_HOST_API;
    }
}