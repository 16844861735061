import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from '../../_actions/ActionCreators';
import AutenticacaoService from '../../services/AutenticacaoService';
import CarteiraFlexService from '../../services/CarteiraFlexService';
import UsuarioService from '../../services/UsuarioService';
import DadosBaixarModal from '../DadosBaixarModal/DadosBaixarModal';
import Input from '../FlexFreteInput/Input';
import FlexSpinner from '../FlexFreteSpinner/FlexSpinner';
import TransacaoItem from './TransacaoItem';
import { TransacoesCardStyle } from './style';

const carteiraService = new CarteiraFlexService();
const autenticacaoService = new AutenticacaoService();
const usuarioService = new UsuarioService();

function Transacoes(props) {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.modal);
  const uuidAssinatura = autenticacaoService.usuarioCorrente();
  let uuid = uuidAssinatura.identificadorAssinatura;

  const uuidPerfil = usuarioService.getPerfilSelecionadoCorente();
  let uuidPerfilAcesso =  uuidPerfil?.uuid;
  
  const [processando, setProcessando] = useState(false);
  const [transacoes, setTransacoes] = useState([]);
  const [statusDaTransacao, setStatusDaTransacao] = useState([]);
  const [tiposDeTransacao, setTiposDeTransacao] = useState([]);
  const [request, setRequest] = useState({
      dataInicio: "",
      dataFim: "",
      pageSize: "",
      page: "",
      tipoTransacao: "",
      statusTransacao: "",
  });
  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
})

  useEffect(() => {
    if(!processando) {
      setProcessando(true);
      carteiraService.obterStatusTransacao((erroCarteira, sucesso) => {
          if(erroCarteira) {
            console.log(erro);
          }else{
            setStatusDaTransacao(sucesso);
          }
      })

      carteiraService.obterTipoTransacao((erroCarteira, sucesso) => {
          if(erroCarteira) {
            console.log(erro)
          }else{
            setTiposDeTransacao(sucesso)
          }
      });

      carteiraService.obterTransacoes(uuid, request, 'application/json', uuidPerfilAcesso, (erroCarteira, sucesso) => {
        setProcessando(false);
        if(erroCarteira) {
            setErro({
                isWrong: true,
                mensagem: 'Erro ao obter lista de transações, entre em contato com o suporte ou tente novamente mais tarde.'
            })
        }else{
          setTransacoes(sucesso);
        }
      })

    }
    // TODO: esse useEffect deve ser usado apenas quando a pagina é carregada --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFile = (data, type) =>{
    let blob = new Blob([data], {type: type});
    let url = window.URL.createObjectURL(blob);
    let tempAnchor = document.createElement("a");
    if(type === "application/xml") {
      tempAnchor.download = 'transacoes.xml';
    } else {
      tempAnchor.download = 'transacoes.csv';
    }
    tempAnchor.href = url;
    tempAnchor.click();
}

const submitForm=(event, formato)=>{
  event.preventDefault();
  setProcessando(true);
  carteiraService.obterTransacoes(uuid, request, formato, uuidPerfilAcesso, (erroCarteira, sucesso) => {
    setProcessando(false);

    if(erroCarteira) {
      setErro({
          isWrong: true,
          mensagem: 'Erro ao baixar lista de transações, entre em contato com o suporte ou tente novamente mais tarde.'
      })
      return
    }
    if(sucesso) {
      downloadFile(sucesso, formato)
      dispatch(ActionCreators.hideModal(false))
    }
  })
}

  const onChange = (event) => {
    const {name, value} = event.target;
    let req = request;
    req[name] = value

    setRequest(req)
}

const onSubmit = (event) => {
    event.preventDefault();
    setProcessando(true);

      carteiraService.obterTransacoes(uuid, request, 'application/json', uuidPerfilAcesso, (erroCarteira, sucesso) => {
        setProcessando(false);
        if(erroCarteira) {
            setErro({
                isWrong: true,
                mensagem: 'Erro ao obter lista de transações, entre em contato com o suporte ou tente novamente mais tarde.'
            })
            return;
        }
        if(sucesso) {
          setTransacoes(sucesso);
        }
      })

}
      const result = transacoes.map((i, index) => {

        return (
          <div key={Math.random() + index}>
            <TransacaoItem key={i.uuid}
            uuid={i.uuid}
            dataCriacao={i.dataCriacao}
            tipoTransacao={i.tipoTransacao.descricao}
            tipoOperacao={i.tipoTransacao.tipoOperacao}
            status={i.status.descricao}
            valor={i.valor}
            valorDesconto={i.valorDesconto}
            meioPagamento={i.meioPagamento?.nome}
            />
            </div>
        )
    })
  return (
    <>
    {processando ? <FlexSpinner/>  : ""}
        {
        showModal === true ?
        (
            <DadosBaixarModal visivel={showModal !== null} onChange={submitForm} />
        ) : null
    }
        <TransacoesCardStyle>
            <div className="form-background">
            <h2 className="transacaoTitle">Transações realizadas</h2>
            <form onSubmit={onSubmit}>
                <section className="sectionSearchHeader">

                <div className="formSection">
                    <div className="formChildren childrenDate">
                        <label>Período de início:</label>
                        <Input class="dateWidth" type="datetime-local" name="dataInicio" placeholder="00/00/0000" onChange={onChange} />                     
                    </div>
                    <div className="formChildren childrenDate">
                        <label>Período de término:</label>
                        <Input class="dateWidth" type="datetime-local" name="dataFim" placeholder="00/00/0000" onChange={onChange} />
                    </div>
                </div>

                <div className="formSection">
                    <div className="formChildren formChildrenBottom">
                        <label>Transação</label>
                        <div className="select opcoesTransacoes">
                            <select name="tipoTransacao" className="selectWidth" onChange={onChange} value={request.tipoTransacao}>
                                <option value="">Selecione...</option>
                                {
                                    tiposDeTransacao.map((i, index) => {
                                        return (
                                           <option value={i.codigo} key={index + Math.random()}>{i.descricao === "Frete"? "Compra de frete": i.descricao}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="formChildren formChildrenBottom">
                        <label>Status</label>
                        <div className="select">
                            <select name="statusTransacao" className="selectWidth" onChange={onChange} value={request.statusTransacao}>
                                <option value="">Selecione...</option>
                                {
                                    statusDaTransacao.map((i, index) => {
                                        return (
                                        
                                           <option value={i.codigo} key={index + Math.random()}>{i.descricao}</option>
                                        
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                </section>
                
                <br />
                <button className="button" type="submit" >Buscar</button>

            </form>

            <br />
            {/* <h2 className="totalTransacoes">Total das transações: <span className="totalValor">+ R$ {somaValorTotalTransacoes.toFixed(2)}</span></h2>
 */}
            <div className="sectionSelectAll">
                <input type="button" className="button baixarTabela" onClick={props.onClick} value="Baixar Tabela"/>
            </div>

            <hr />
            <div>
                {
                    erro.isWrong === true ?
                    (
                        <div className="notification is-warning">
                            {erro.mensagem}
                        </div>
                    ) : null

                }
                {result}
            </div>
            </div>
        </TransacoesCardStyle>
    </>
  );
}

export default Transacoes;