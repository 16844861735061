import React, { Component } from "react";
import InputMask from "react-input-mask";

export default class Input extends Component {
	inputNumber = () => {
		return (
			<input
				// step="1"
				className={"input "+ (this.props.class || "" )}
				min={this.props.min}
				max={this.props.max}
				type="number"
				name={this.props.name}
				defaultValue={this.props.defaultValue}
				placeholder={this.props.placeholder}
				value={this.props.value}
				onChange={this.props.onChange}
				required={this.props.required}
				disabled={this.props.disabled}
				onFocus={this.props.onFocus}

			/>
		);
	};

	inputPadrao = () => {
		return (
			<>
			

			{!this.props.decoration ? (<input
				className={"input "+ (this.props.class || "" )}
				type={this.props.type}
				name={this.props.name}
				defaultValue={this.props.defaultValue}
				value={this.props.value}
				placeholder={this.props.placeholder}
				onChange={this.props.onChange}
				required={this.props.required}
				maxLength={this.props.maxLength !== null ? this.props.maxLength : ""}
				disabled={this.props.disabled}
				onFocus={this.props.onFocus}
				id={this.props.id}
			/>): (
				<label htmlFor={this.props.id} className="labelInputDecoration"><input
				className={"input "+ (this.props.class || "" )}
				type={this.props.type}
				name={this.props.name}
				defaultValue={this.props.defaultValue}
				value={this.props.value}
				placeholder={this.props.placeholder}
				onChange={this.props.onChange}
				required={this.props.required}
				maxLength={this.props.maxLength !== null ? this.props.maxLength : ""}
				disabled={this.props.disabled}
				onFocus={this.props.onFocus}
				id={this.props.id}
			/><span style={{color: '#340040'}} className="span-decoration">{this.props.decoration}</span></label>
			)}
			</>
			
		);
	};

	inputDateTimeLocal = () => {
		return (
			<>
			<input
				className={"input "+ (this.props.class || "" )}
				type={this.props.type}
				name={this.props.name}
				value={this.props.value}
				defaultValue={this.props.defaultValue}
				placeholder={this.props.placeholder}
				onChange={this.props.onChange}
				required={this.props.required}
				maxLength={this.props.maxLength !== null ? this.props.maxLength : ""}
				step="1"
				onFocus={this.props.onFocus}
			/>

			
			</>
			
		);
	}


	render() {
		let input = null;
		switch (this.props.type) {
			case "number":
				if (this.props.max || this.props.min) {
					input = this.inputNumber();
				} else {
					input = this.inputPadrao();
				}
				break;
			case "datetime-local":
				input = this.inputDateTimeLocal();
				break;
			default:
				input = this.inputPadrao();
		}

		return (
			<div className={'field ' + this.props.classField} style={this.props.style}>
				{
					this.props.label ?
					(
						<label className="label">{this.props.label}</label>
					)
					:
					(
						<></>
					)
				}
				<div className="control">
					{this.props.mascara ? (
						<>
							<InputMask
								className={"input "+ (this.props.class || "" )}
								mask={this.props.mascara}
								name={this.props.name}
								defaultValue={this.props.defaultValue}
								value={this.props.value}
								placeholder={this.props.placeholder}
								onChange={this.props.onChange}
								onBlur={this.props.onBlur}
								required={this.props.required}
								disabled={this.props.disabled}
								
								onFocus={this.props.onFocus}

							/>
							{this.props.legenda ? (
								<div>
									<span className="legenda-input">{this.props.legenda}</span>
								</div>
							) : null}
						</>
					) : (
						input
					)}
				</div>
			</div>
		);
	}
}
