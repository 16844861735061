import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ActionCreators } from '../../_actions/ActionCreators';
import { ContaInfo } from '../../components/Conta/ContaInfo';
import FlexSpinner from '../../components/FlexFreteSpinner/FlexSpinner';
import AssinaturaService from '../../services/AssinaturaService';
import AutenticacaoService from '../../services/AutenticacaoService';
import { ContaStyle } from './style';

const assinaturaService = new AssinaturaService();
const autenticacaoService = new AutenticacaoService();
function Conta() {
  const dispatch = useDispatch();

  let {endereco} = useSelector((state) => {
    return {
      endereco: state.endereco
    };
  });

  const enderecoCorrente = endereco || autenticacaoService.enderecoCorrente() || {};

  const [conta, setConta] = useState(
    {
    nome:"",
    documento: "",
    email: "",
    telefone: "",
    alertaRestricoesCorreios: false,
    alertaConfirmacaoImpressao: false,
    alertaConfirmacaoDeDeclaracaoConteudo: false,
    alertaPagamento: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [enderecoUser, setEndereco] = useState(enderecoCorrente)

  const [mensagemErro, setMensagemErro] = useState(undefined);

  useEffect(()=>{
    setIsLoading(true)
    assinaturaService.loadUsuarioAssinatura((err, sucess)=>{
      if(err){
        setMensagemErro("Problema ao fazer a requisição. Contate o suporte, por favor.");
        setIsLoading(false)
      }else{
        setMensagemErro(undefined);
        dispatch(ActionCreators.addAssinaturaSelecionada(sucess));
        setEndereco(sucess.endereco)
        setConta (sucess);
        setIsLoading(false)
        assinaturaService.setEnderecoAssinatura(sucess.endereco)
      }
    });
    // TODO: esse useEffect deve ser usado apenas quando a pagina é carregada --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (



    <div className="page-container-style">
      <ContaStyle >
      {isLoading  ? <FlexSpinner/> :""}
        <h2 className="page-title">Conta Flex</h2>
        {mensagemErro?
          (<div className="notification is-warning">
              {mensagemErro}
            </div>):
            (
              <>
                <ContaInfo {...conta} endereco={enderecoUser}/>
                <div className="actions margin-top" >
                  <Link to="/configuracoes/conta-editar" className="button page-navegation-button button-submit">Editar perfil</Link>
                  <Link to="/configuracoes/restaurar-senha" className="button page-navegation-button is-mudar-senha-button" >Mudar senha</Link>
                  <Link to="/configuracoes/minha-loja" className="button page-navegation-button is-mudar-senha-button" >Minha Loja</Link>

                </div>
              </>
            )
        }

      </ContaStyle>
    </div>

  );
}

export default Conta;
