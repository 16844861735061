import React, {useState} from 'react';
import {NotificacoesStyle, NotificacoesContainerStyle} from './style'
import {NotificacaoForm} from '../../components/NotificacaoForm/NotificacaoForm';
//import {NotificacaoLista} from 
import {NotificacaoLista} from '../../components/NotificacaoLista/NotificacaoLista';

function Notificacoes() {
const [notificacoes, setNoticacoes] = useState([])


  return (
    <div className="page-container-style">

    <NotificacoesStyle > 
        <h2 className="page-title">Notificações</h2>

      <NotificacoesContainerStyle>
        <NotificacaoForm callBack ={setNoticacoes}/>
        <NotificacaoLista lista = {notificacoes} />
      </NotificacoesContainerStyle>
    </NotificacoesStyle>
    </div>

  );
}

export default Notificacoes;
