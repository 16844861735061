import React from "react";
import {useHistory} from 'react-router-dom';
import { BtnPerfilStyle } from "./style";

function PerfilSelecao(props) {
	const history = useHistory();
	
	const handlePerfilClick = () => {
		localStorage.setItem('perfil-selecionado', JSON.stringify(props.item))
		history.push('/home')
	}

	return (
		<>

		<BtnPerfilStyle onClick={handlePerfilClick} className="button">{props.item.papel} na {props.item.nomeDaLoja}</BtnPerfilStyle>
						
		</>
	);
}

export default PerfilSelecao;
