import styled from "styled-components";

export const CarrinhoDeComprasStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 3em;
    width: calc(100% - 3em);

    min-height: 60vh;

    .section-carrinho-content{
        max-width: 800px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .carrinho-content{
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
    }

    .numero-tag{
        background-color: rgba(227,175,31,1);;
        color: #282828;
        font-weight: 600;
        letter-spacing: 0.2px;
        padding: 6px;
        border-radius: 6px;
        min-width: 7em;
    }

    .btn-doc{
        cursor: pointer;
    }
    .etiqueta-section{
        width: 80%:
    }
    @media (max-width: 2560px) {
        .section-half-carrinho{
            display: flex;
            justify-content: flex-start;
        }

        .section-half-carrinho > div:first-child{
            margin-right: 2em;
            flex-grow: 1;
        }

        .section-half-carrinho > div:last-child{
            flex-grow: 1;
        }

        .carrinho-numero-itens{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    @media(min-width: 1280px){
        left: 290px;
        width: calc(100% - 290px);   
    }

    @media (max-width: 1024px) {
    
    }

    @media (max-width: 770px) {
        .section-half-carrinho{
            flex-direction: column;
        }

        .section-half-carrinho > div:first-child{
            margin-right: 0;
        }

        .carrinho-content{
            padding: 20px;
            margin-bottom: 20px;
        }
    }
`;

export const BtnSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button{
        font-size: 1.6rem;
        ion-icon{
            margin-right: 10px;
        }
    }

    .add-carrinho{
        margin-top: 20px;
		/*
		margin-bottom: 20px; */
		background-color: white;
		color: #812990;
		font-weight: 600;
		letter-spacing: 0.3px;
	}

	.add-carrinho:hover{
  		background: rgba(255, 255, 255, 0.7);
	}

	.finalizar-pagamento{
        margin-top: 10px;
		/*
		margin-bottom: 20px; */
		background: rgb(131, 43, 146);
		background: linear-gradient(
			267deg,
			rgba(131, 43, 146, 1) 39%,
			rgba(160, 69, 176, 1) 100%
		);
		color: white;
		font-weight: 600;
		letter-spacing: 0.3px;
		border: none;
	}

	.finalizar-pagamento:hover{
		background: #c986d8;
		color: white;
	}

`