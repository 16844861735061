import styled from 'styled-components';

export const CalculadoraResultadoLogadoStyle = styled.div`
    background-color: #fff;
    width: 80%;
    max-width: 1200px;
    border-radius: 10px;
    padding: 40px;
  
  .transportadora-table-img{
    display: flex;
    justify-content: center;
    align-items: center;
  }

    h1{
        color: #461B4B;
    }

    .suporte{
		font-size: 12px;
        margin: 10px 30px;
        text-align: left;
        a {
            color: #461b4b;
        }
    }

	.conteudo-info{
		font-size: 14px;
		margin: 25px 0px;
		color: #888888;
	}

	.custom-separator{
		margin: 0 8px;
	}

	.table{
		padding: 1em;
		margin: 0 auto;

		tr {
			border-bottom: 1px solid #EBEAED;
		}

		thead {
			border-top: 1px solid #EBEAED;
		}

	}

	.table td, th{
		border: none;
		padding: 0 1.5em;
		text-align: center;
		vertical-align: middle;
		margin: 0 auto;
		font-weight: 400;
		font-size: 1em;
		line-height: 162%;
		color: #340040;

		strike, .separador {
			color: #888888;
		}
	}

	.table th{
		color: rgba(21, 20, 57, 0.4);
		mix-blend-mode: normal;
		letter-spacing: 0.125em;
		text-transform: uppercase;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 1em;
		line-height: 1.625em;
		text-align: center;
		border: none;
		padding: 1.1em;
	}	

	.transportadora-logo{
		max-width: 120px;
	}

    .header{
        padding: 30px;
        display: inline-block;
        max-width: 20%;
    }

	.is-mobile-visibility{
		display: none !important;
	}
     .correios-limits-message {
        margin-top: 20px;
        padding: 15px;
        font-size: 16px;
        line-height: 1.6;
        text-align: left;
        background-color: rgba(240, 240, 240, 0.9);
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    .correios-limits-message ul {
        padding-left: 20px;
    }

    .correios-limits-message li {
        list-style-type: disc;
        margin-bottom: 5px;
    }

    .correios-limits-message p {
        margin-bottom: 10px;
    }

    .header-alerta{
        display: flex;
        align-items: center;
        justify-content: center;
        ion-icon{
            font-size: 32px;
            margin-right: 20px;
        }

    }
    .voltar-action{
        margin-top: 30px;
        text-align: center;
        a{
            background: rgba(136,48,152,1);
            color: #fff;
            font-weight: bold;
        }
        a:hover{
            background: rgba(136,48,152, 0.7);
        }
    }
    .header-ordenacao{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0 40px 0;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .select{
            margin-right: 20px;
            label{
                font-weight: bold;
                margin-right: 10px;
            }

            select{
                width: 100%;
            }
        }
        .checkbox{
            input{
                margin-right: 10px;
            }
        }
    }
    .selecionar-volume{
        width: 300px;
        margin-bottom: 70px;
        select{
            width: 100%;
            font-weight: bold;
            color: rgba(136,48,152,1);
        }

    }

	.contação-block{
		margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
	}

    .cotacao{
        display: flex;
        align-items: center;
        justify-items: center;
        .cotacao-info{
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            >div{
                flex-grow: 0.3;
            }
        }
        img{
            max-width: 140px;
        }
        .modalidade{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 20px;

            .modalidade-label{
                color: hsl(0, 0%, 48%);
            }
        }
        .flexfrete-economia-label{
            font-weight: bold;
            color: #000;
            span{
                display: block;
                text-align: left;
            }
        }
        .prazo-coluna{
            align-self: baseline;
        }
        .button.selecionar{
            background: rgba(136,48,152,1);
            color: #fff;
            font-weight: bold;
            width: 100%;
        }
        .button.selecionar:hover{
            background: rgba(136,48,152, 0.7);
        }
    }
    .tag-qualidade{
        white-space: nowrap;
        background-color: rgb(194,144,3);
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        position: relative;
        border-radius: 10px;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        height: 50px;
        position: relative;
        right: 51px;
        margin: 10px 0 30px 0;
    }
    .selecionar-frete{
        align-self: center;
        width: 120px;
        flex-grow: initial;
    }

  @media screen and (max-width: 1400px) {
    width: 88%;
    td.text-result-cot{
      font-size: 1em;
    }
  }
    @media(max-width: 1000px){
        padding: 10px;
        width: auto;

        .voltar-action{
            text-align: center;
        }

        .cotacao, .cotacao-info{
            justify-content: center;
            width: 100%;
        }
        .cotacao-info{
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
        }
        .button{
            margin-bottom: 30px;
        }
        .selecionar-volume{
            width: 200px;
        }
        .selecionar-frete{
            align-self: flex-start;
        }

    }
    .flex{
        display: flex;
        justify-content: space-between;

    }

	.nota-roda-pe{
		font-size: 1.2rem;
		text-align: left;
	}
    @media(max-width: 600px){
        width: 85%;

		.is-mobile-visibility{
			display:block !important;
		}

		.is-desktop {
			display: none !important;
		}
        .mobile-select{
            width: fit-content;
        }
        .header-ordenacao .select select{
            width: fit-content;
        }
        .select:not(.is-multiple):not(.is-loading)::after{
			right: 0.75em;
        }

        .cotacao img{

            max-width: 4.5em;
        }
        .transportadora-logo {
            margin-right: 0px;
        }

        .cotacao .cotacao-info{
            width: 80%;
            font-size: 0.9em;
        }

		.selecionar-frete{
            padding: 0 20vw;
        }
    }
`;

