export default function validarEmail(email) {
    if(email){
      let components = email.split("@")
      if (components.length === 2) {
        let sub_components = components[1].split(".")
        return sub_components.length > 1
      } else {
        return false
      }
    }
    return false
    
  }
  
