import styled from "styled-components";

export const EtiquetaStyle = styled.li`

    border-top: solid 1.5px #ddd;
    margin: 30px 0;
    header{
        margin: 20px 0;
        font-size: 1.4rem;
    }
    .header-label{
        margin-right: 10px;
    }
    .transportadora-logo{
		max-width: 150px;
	}
    .meus-envios-frete-cabecalho-aguardando, .meus-envios-frete-cabecalho-postado{
        display: flex;
        justify-content: space-between;
        div{
            display: flex;

        }
        .show-frete-desktop{
            display:block;
            width: 20px;
            cursor: pointer;
        }
        .show-frete-mobile{
            display:none;
        }
        .delete-frete{
            cursor:pointer
        }
        .icon-delete{
            color: #f14668;
            font-size: 1.8rem;
        }
        .icon-warning{
            color: #ffe08a;
            font-size: 1.8rem;

        }
        .expiracao-postado{
            margin-right: 10px;
        }
    }
    
    .meus-envios-frete-cabecalho-gerado{
        display: flex;
        margin-top: 20px;
        align-items: center;
        cursor: pointer;
        ion-icon{
            margin-right: 5px;
        }
        span{
            margin-right: 20px;
        }
    }

    .meus-envios-frete-container-area, .meus-envios-frete-container-area-pessoa, .volumes{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        width: 400px;
    }
    .meus-envios-frete-section{
        display: flex;
        width: 100%;
        margin: 10px 0;
       
    }
    .line-label{
        margin-right: 10px;
        white-space: nowrap;
    }
    .container-area-line{
        display: flex;
        justify-content: flex-start;
        font-size: 1.3rem;

    }
    .pessoa-titulo{
        text-align: left;
        h4{
            font-size: 1.7rem;
            font-weight: bold;
        }
      
        margin: 20px 0;
    }
    .container-volumes-etiqueta{
        display: flex;
        align-items: flex-start;
        
    }
    .etiqueta{
        width: 400px;
        display: flex;
        justify-content: flex-start;


        .declaracao{
            margin-left: 10px;
        }
    }

    @media(max-width: 1200px){
        .meus-envios-frete-cabecalho-aguardando, .meus-envios-frete-cabecalho-postado, .meus-envios-frete-cabecalho-gerado, .container-volumes-etiqueta {
            flex-direction: column;
            .show-frete-desktop{
                display:none;
               
            }
            .show-frete-mobile{
                display:block;
                width: 20px;
                cursor: pointer;
            }
        }
        .meus-envios-frete-container-area, .meus-envios-frete-container-area-pessoa, .volumes{
            width: 100%;
        }

        .meus-envios-frete-section{
            flex-direction: column;
        }
        .container-area-line{
        }

        .etiqueta{
            width: 100%;
            margin-top: 20px;

            flex-direction: column;

            .declaracao{
                margin-left: 0;
                margin-top: 5px;
            }
        }
    
    }

    @media (max-width: 770px) {
        .etiqueta{
            flex-direction: column;

            .declaracao{
                font-size: 0.7em;
            }
        }
	}

    .volumes{
        text-align: left;
        p{
            font-size: 1.3rem;
        }
    }
    
`;

// export const EtiquetaStyle = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     left: 67px;
//     width: calc(100% - 67px);

//     min-height: 100vh;
//     >.page-title{
//         margin-top:100px;
//     }
    
//     @media(min-width: 1280px){
//         left: 290px;
//         width: calc(100% - 290px);   
//     }
// `;

// export const EtiquetaContentStyle = styled.div`
//     /* margin-top: 40px; */
//     background-color: #fff;
//     max-width: 800px;
//     border-radius: 10px;
//     padding: 30px;
   

//     p{
//         text-align: left;
//     }


//     .transportadora-logo{
// 		max-width: 150px;
// 	}
    
//     .remove-conteudo{
//         cursor: pointer;
//         display: block;
//         text-align: right;
//         color: #812990;
//         ion-icon{
//             font-size: 3rem;
//         }
//     }

//     @media (max-width: 2560px) {

//         header{
//             display: flex;
//             /* justify-content: center; */
//             justify-content: space-between;
//             align-items: center;
//             padding-bottom: 20px;

//             div{
//                 margin-right: 50px;
//             }
//         }

//         ion-icon{
//             font-size: 40px;
//             color: rgba(136,48,152,1);
//             cursor: pointer;
//         }
        
//         .section-half{
//             display: flex;
//             justify-content: space-between;
//         }

//         .section-half > div:first-child{
//             margin-right: 2em;
//             flex-grow: 1;
//         }

//         .section-half > div:last-child{
//             flex-grow: 1;
//         }
//     }

//     @media (max-width: 1024px) {
    
//     }

//     @media (max-width: 770px) {

//         header{
//             div{
//                 margin-right: 0px;
//             }
//         }
        
//         .section-half{
//             flex-direction: column;
//         }

//         .section-half > div:first-child{
//             margin-right: 0;
//         }
//     }
// `

// export const BtnSection = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     .button{
//         font-size: 1.6rem;
//         ion-icon{
//             margin-right: 10px;
//         }
//     }

//     .alterar-transportadora{
//         margin-top: 20px;
//         border: none;
//         background-color: transparent;
//         color: #812990;
// 		font-weight: 600;
//         letter-spacing: 0.3px;
//     }

//     .add-carrinho{
//         margin-top: 20px;
// 		/*
// 		margin-bottom: 20px; */
// 		background-color: white;
// 		color: #812990;
// 		font-weight: 600;
// 		letter-spacing: 0.3px;
// 	}

// 	.add-carrinho:hover{
//   		background: rgba(255, 255, 255, 0.7);
// 	}

// 	.finalizar-pagamento{
//         margin-top: 10px;
// 		/*
// 		margin-bottom: 20px; */
// 		background: rgb(131, 43, 146);
// 		background: linear-gradient(
// 			267deg,
// 			rgba(131, 43, 146, 1) 39%,
// 			rgba(160, 69, 176, 1) 100%
// 		);
// 		color: white;
// 		font-weight: 600;
// 		letter-spacing: 0.3px;
// 		border: none;
// 	}

// 	.finalizar-pagamento:hover{
// 		background: #c986d8;
// 		color: white;
// 	}

// `