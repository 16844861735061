import styled from "styled-components";

export const EmailContainerStyle = styled.section`
	padding-top: 60px;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;

	h1 {
		color: #282828;
		font-size: 2.5em;
        text-align: center;
	}


@media (max-width: 2560px) {
	margin-left: 20%;
	margin-right: 20%;
}

@media (max-height: 1024px) {
	margin-left: 15%;
	margin-right: 15%;
}

@media (max-width: 770px) {
	margin-left: 0;
	margin-right: 0;
}
	
`;

export const EmailCardStyle = styled.section`
	border-radius: 20px;
	border: none;
	margin: 1.5em;
	padding: 3em 2em 3em 2em;

    .irParaLoginBtn{
        margin: 1em 0em 1em 0em;
        background: rgb(131, 43, 146);
        background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
        );
        color: white;
        font-size: 1.5em;
        font-weight: 600;
        letter-spacing: 0.3px;
        border: none;
    }

@media (max-width: 770px) {
	padding: 3em 1.5em 3em 1.5em;

}	
`;
