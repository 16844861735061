import React, { useState, useEffect, useRef } from 'react';
import Input from '../FlexFreteInput/Input';
import LojaService from "../../services/LojaService";
import GeografiaService from '../../services/GeografiaService';
import { useHistory } from 'react-router-dom';
import { useDispatch, useStore } from "react-redux";
import { ActionCreators } from '../../_actions/ActionCreators';
import Modal from '../FlexFreteModal/Modal';
import { MoreLojaStyle, FormContainerStyle } from './style';
import ValidaCNPJ from "../../utils/ValidaCNPJ";
import notificationService from '../../services/NotificationService';

function MinhaLoja(props) {
  const lojaService = new LojaService();
  const geografiaService = new GeografiaService();
  const validacaoDo = new ValidaCNPJ();
  const user = useStore().getState().user;
  const dispatch = useDispatch();
  const history = useHistory();

  const [processando, setProcessando] = useState(false);
  const numero = useRef();


  const [request, setRequest] = useState({
    uuid: null,
    nome: null,
    razaoSocial: null,
    telefone: null,
    email: null,
    site: null,
    cnpj: null,
    padraoAssinante: false,
    logradouro: null,
    numero: null,
    cep: null,
    bairro: null,
    cidade: null,
    nomeCidade: null,
    complemento: null,
    referencia: null,
    ativo: true,
    estado: null,
  });
  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false
  });

  if (!processando) {
    setProcessando(true);
  }

  useEffect(() => {
    setRequest({});
    lojaService.obterLojas((erro, sucesso) => {
      if (erro) {
        setErro({
          isWrong: true,
          mensagem: 'Falha ao buscar por lojas, entre em contato com o suporte ou tente novamente mais tarde'
        })
        return;
      }
      if (sucesso) {
        if (sucesso.length > 0) {
          fromLoja(sucesso[0]);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fromLoja = (loja) => {
    setRequest({
      uuid: loja.uuid || null,
      nome: loja.nome || null,
      razaoSocial: loja.razaoSocial || null,
      telefone: loja.telefone || null,
      email: loja.email || null,
      site: loja.site || null,
      cnpj: loja.cnpj || null,
      padraoAssinante: loja.isLojaPadraoAssinante || false,
      logradouro: loja.endereco?.logradouro || null,
      numero: loja.endereco?.numero || null,
      cep: loja.endereco?.cep || null,
      bairro: loja.endereco?.bairro || null,
      cidade: loja.endereco?.cidade || null,
      nomeCidade: loja.endereco?.nomeCidade || null,
      estado: loja.endereco?.estado || null,
      complemento: loja.endereco?.complemento || null,
      referencia: loja.endereco?.referencia || null,
      ativo: loja.ativo
    });
  };

  const getCEP = (cep) => {
    //limpa as informações de endereço
    limparEndereco();
    geografiaService.buscaPorCEP(cep, (erro, sucesso) => {
      if (erro) {
        setErro({
          isWrong: true,
          mensagem: 'Falha ao buscar por CEP, entre em contato com o suporte ou insira seu endereço manualmente'
        })
        return;
      }
      if (sucesso) {
        setRequest(prevState => ({
          ...prevState,
          logradouro: sucesso.logradouro,
          bairro: sucesso.bairro,
          cidade: sucesso.cidade,
          nomeCidade: sucesso.nomeCidade,
          estado: sucesso.siglaUf,
          numero: prevState.numero,
          complemento: prevState.complemento
        }));
      }
    })
  }


  const onChange = (event) => {
    const { name, value, type, checked } = event.target

    if (event._reactName === 'onFocus' && event.target.name === 'cnpj') {
      event.target.setCustomValidity("Preencha este campo.");
      return
    } else {
      event.target.setCustomValidity("");
    }

    if (event._reactName === 'onFocus' && event.target.name === 'telefone') {
      event.target.setCustomValidity("Preencha este campo.");
      return
    } else {
      event.target.setCustomValidity("");
    }

    let req = request
    type === 'checkbox' ? req[name] = checked : req[name] = value;
    setRequest({
      ...req
    })
  };

  const onChangeEndereco = (event) => {
    const { name, value } = event.target

    if (event._reactName === 'onFocus' && event.target.name === 'cep') {
      event.target.setCustomValidity("Preencha este campo.");
    } else {
      event.target.setCustomValidity("");
    }

    let req = request
    req[name] = value;
    onChange(event);
  };

  const onBlur = (event) => {
    let cep = event.target.value;
    if (!cep.length) {
      return
    }
    let formatedcep = cep.replace(/[^0-9]/g, "")
    return getCEP(formatedcep);
  };


  const onGetEnderecoUsuario = (event) => {
    const { checked } = event.target;
    if (checked) {
      limparEndereco();
      setRequest(prevState => ({
        ...prevState,
        logradouro: user.endereco.logradouro,
        bairro: user.endereco.bairro,
        nomeCidade: user.endereco.cidade,
        estado: user.endereco.estado,
        cep: user.endereco.cep,
        numero: user.endereco.numero,
        complemento: user.endereco.complemento
      }))
      getCEP(user.endereco.cep);
    }
  }

  const limparEndereco = () => {
    setRequest(prevState => ({
      ...prevState,
      logradouro: '',
      bairro: '',
      cidade: '',
      estado: '',
    }))
  }

  const submitForm = (event) => {
    event.preventDefault();

    setProcessando(true)
    if (request.cep.length < 8) {
      setErro({
        isWrong: true,
        mensagem: "CEP inválido."
      })
      setProcessando(false)
      return
    }
    let formatedcnpj = request.cnpj.replace(/[^0-9]/g, "")
    const resultCnpj = validacaoDo.cnpj(formatedcnpj);

    //remover o - do cep
    const formatedcep = request.cep.replace(/[^0-9]/g, "")

    if (!resultCnpj) {
      setErro({
        isWrong: true,
        mensagem: "CNPJ inválido."
      })
      setProcessando(false)
      return
    }

    const lojaRequest = {
      nome: request.razaoSocial,
      razaoSocial: request.razaoSocial,
      telefone: request.telefone,
      email: request.email,
      site: request.site,
      cnpj: formatedcnpj,
      ativo: request.ativo,
      padraoAssinante: request.padraoAssinante ? request.padraoAssinante : false,
      usuarioAssinante: {
        nome: user.nome,
        roles: user.roles,
        cpf: user.username,
      },
      endereco: {
        logradouro: request.logradouro,
        numero: !request.numero ? "S/N" : request.numero,
        cep: formatedcep,
        bairro: request.bairro,
        cidade: request.cidade,
        nomeCidade: request.nomeCidade,
        complemento: request.complemento,
        referencia: request.referencia
      },
    }

    if (request.uuid) {
      editarLoja(lojaRequest)
    } else {
      criarNovaLoja(lojaRequest)
    }
  }

  const editarLoja = (lojaRequest) => {
    lojaService.editarLoja(request.uuid, lojaRequest, (erro, sucesso) => {
      if (erro) {
        setProcessando(false)
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            isWrong: true,
            mensagem: erro.response.data.message
          })
        } else {
          setErro({
            isWrong: true,
            titulo: 'Erro ao adicionar nova loja',
            mensagem: 'Falha ao tentar se adicionar nova loja, entre em contato com o suporte ou tente novamente mais tarde'

          })
        }
        return
      }
      if (sucesso) {
        setProcessando(false)
        setRequest(prevState => ({
          ...prevState,
          uuid: sucesso.uuid
        }))
        notificationService.showNotification("Loja editada com sucesso", "success");
        setLojaLocalStorege(sucesso);

      }
    })
  }

  const criarNovaLoja = (lojaRequest) => {
    lojaService.criarNovaLoja(lojaRequest, (erro, sucesso) => {
      if (erro) {
        setProcessando(false)
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            isWrong: true,
            mensagem: erro.response.data.message
          })
        } else {
          setErro({
            isWrong: true,
            titulo: 'Erro ao adicionar nova loja',
            mensagem: 'Falha ao tentar se adicionar nova loja, entre em contato com o suporte ou tente novamente mais tarde'

          })
        }
        return
      }
      if (sucesso) {
        setProcessando(false)
        setRequest(prevState => ({
          ...prevState,
          uuid: sucesso.uuid
        }))
        notificationService.showNotification("Loja criada com sucesso", "success");
        setLojaLocalStorege(sucesso);
      }
    })
  }

  const voltaParaMinhasLojasPage = () => {
    dispatch(ActionCreators.showModal(false))
    history.push("/home");
  }

  const setLojaLocalStorege = (loja) => {
    //setar no store
    dispatch(ActionCreators.setLoja(loja));
    localStorage.setItem('loja', JSON.stringify(loja));
  }


  const callbackModal = () => {
    if (erro.isWrong === true) {
      setErro({ isWrong: false })
    }
  }
  return (
    <>
      {
        erro.isWrong === true ?
          (
            <Modal callbackModal={callbackModal} visivel={erro !== null} titulo={erro.titulo} conteudo={erro.mensagem} />
          ) : null
      }
      <MoreLojaStyle>
        <h1>Minha Loja</h1>

        <FormContainerStyle>
          <form onSubmit={submitForm}>
            <div className="box">
              <h2>Dados da Loja</h2>
              <div className='is-flex columns'>
                <div className="field column">
                  <div className="control">
                    <Input maxLength={255} type="text" name="razaoSocial" placeholder="Razão social" value={request.razaoSocial} required={true} onChange={onChange} />
                  </div>
                </div>

                <div className="field column">
                  <div className="control">
                    <Input type="text" mascara="99.999.999/9999-99" value={request.cnpj} name="cnpj" placeholder="CNPJ*" maxLength="18" required={true} onChange={onChange} />
                  </div>
                </div>
              </div>
              <div className="column mb-4">
                <div className="control">
                  <label className="checkbox ffl-custom-checkbox ">
                    <input type="checkbox" name="mesmoEndereco" onChange={onGetEnderecoUsuario} />
                    &nbsp;<strong>Mesmo endereço da Conta Flex</strong>
                  </label>
                </div>

              </div>
              <div className='is-flex columns'>
                <div className="field column">
                  <div className="control">
                    <Input type="text" mascara="99999-999" name="cep" value={request.cep} placeholder="CEP*" required={true} onChange={onChangeEndereco} onBlur={onBlur} />
                  </div>
                </div>

                <div className="field column">
                  <div className="control">
                    <Input maxLength={255} className="input" type="text" name="logradouro" placeholder="Endereço" required={true} onChange={onChange} value={request.logradouro} />
                  </div>
                </div>
              </div>

              <div className='is-flex columns'>
                <div className="field column">
                  <div className="control">
                    <input maxLength={128} className="input" type="text" name="numero" ref={numero} value={request.numero} placeholder='número' onChange={onChange} />
                  </div>
                </div>
                <div className="field column">
                  <div className="control">
                    <input maxLength={255} className="input" type="text" name="complemento" value={request.complemento} placeholder="Complemento" onChange={onChange} />
                  </div>
                </div>
              </div>

              <div className='is-flex columns'>
                <div className="field column is-half">
                  <div className="field">
                    <div className="control">
                      <Input maxLength={255} className="input" type="text" name="bairro" value={request.bairro} placeholder="Bairro" required={true} onChange={onChange} />
                    </div>
                  </div>
                </div>

                <div className="field column">
                  <div className="control">
                    <div className='select'>
                      <select value={request.cidade} name="cidade" disabled placeholder="Cidade" style={{ width: "100%" }}>
                        <option value=""></option>
                        {
                          <option value={request.cidade}>{request.nomeCidade}</option>
                        }
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field column">
                  <div className="control">
                    <div className='select'>
                      <select value={request.estado} name="estado" placeholder='Estado' style={{ width: "100%" }} disabled>
                        <option value=""></option>
                        {
                          <option value={request.estado}>{request.estado}</option>
                        }
                      </select>
                    </div>
                  </div>
                </div>

              </div>

              <div className="column mb-4">
                <div className="control">
                  <label className="checkbox ffl-custom-checkbox">
                    <input
                      type="checkbox"
                      name="ativo"
                      checked={request.ativo} // Use 'checked' para checkboxes
                      onChange={onChange} />
                    &nbsp; <strong>Ativa loja</strong>
                  </label>
                </div>
              </div>

              <div className="btn-loja-nova ">
                <p onClick={voltaParaMinhasLojasPage} className="button">Cancelar</p>
                <button className="button">Salvar</button>
              </div>
            </div>
          </form>
        </FormContainerStyle>
      </MoreLojaStyle>

    </>
  );
}

export default MinhaLoja;
