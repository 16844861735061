import styled from "styled-components";


export const ContaInfoStyle = styled.section`
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media(max-width: 1280px){
       width: 350px;
    } 
    @media(max-width: 475px){
       max-width: 250px;
    }

   
    .dado{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }
    .title{
        font-weight: bold;
        font-size: 1.7rem;
        margin-bottom: 10px;
    }
    .dados{
        display: flex;
        flex-direction: column;
        width: 90%;
        >div{
            display: flex;
            flex-direction: column;
            align-items: self-start;
            width: 100%;
        }
    }
    .dados-title{
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 20px;
        color: #000;
    }
    .dado-atributo{
        margin-right: 5px;
        font-weight: bold;
        font-size: 1.5rem;
    }
    @media(min-width: 1280px){
        .container{
            width: 80%;
        }
        .dados{
           // width: 100%;
            max-width: 800px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            justify-items: start;
        }

        .full-width {
            grid-column: span 2;
        }
        
    }

    .checkbox{
        text-align: initial;
        input{
            margin-right: 3px;
        }
    }
    .input-required{
        color: #000;
    }
    .input-text{
        height: 30px;
        width: 100%;
    }
    
`;

export const ContaEditPasswordStyle = styled.div`
    >div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        label{
        }
        margin-bottom: 20px;
    }
    .notification{
        margin-top: 20px;
    }
    .input-container{
        display:flex;
        width: 100%;
        input{
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
        }
        .button{
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
            width: auto;
            display: flex;
            justify-content: center;
       }
       ion-icon{
            color: #363636;
            font-size: 1.8rem;
       }
    }
   
`;

export const ContaInfoEditFormStyle = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    p{
        text-align: left;
        font-size: 1rem;
    }
    .actions{
        width:90%;
        display: flex;
        justify-content: center
    }
   .field{
       width: 100%;
   }
    
`
