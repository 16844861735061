import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

const slideInDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOutUp = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const NotificationContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;

  .notification {
    width: 320px;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
    margin-bottom: 10px;
    animation: ${slideInDown} 0.5s ease-out;

    &.exit {
      animation: ${slideOutUp} 0.5s ease-in forwards;
    }
  }

  .delete {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    cursor: pointer;
  }
`;

const NotificationService = () => {
  let notificationElement = null;

  const showNotification = (message, type, duration = 3000) => {
    if (!notificationElement) {
      notificationElement = document.createElement('div');
      document.body.appendChild(notificationElement);
      ReactDOM.render(<NotificationContainer />, notificationElement);
    }

    const notification = document.createElement('div');
    notification.className = `notification is-${type}`;
    notification.innerHTML = `
      <button class="delete"></button>
      ${message}
    `;

    notificationElement.firstChild.appendChild(notification);

    const deleteButton = notification.querySelector('.delete');
    deleteButton.addEventListener('click', () => {
      notification.classList.add('exit');
      setTimeout(() => {
        notification.remove();
      }, 500);
    });

    setTimeout(() => {
      notification.classList.add('exit');
      setTimeout(() => {
        notification.remove();
      }, 500);
    }, duration);
  };

  return {
    showNotification,
  };
};

const notificationService = NotificationService();

export default notificationService;
