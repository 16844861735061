import api from './Api';

export default class LoteService {
	/**
     * Realiza a requisição GET para gerar um lote de etiquetas.
     * @param {Object} request - Contém os parâmetros necessários para a requisição da etiqueta.
     * @param {string} request.uuids - UUIDs dos itens para os quais as etiquetas serão geradas.
     * @param {string} request.tipoFolha - Tipo de folha a ser usado na impressão das etiquetas.
     * @returns {Promise} - Retorna uma promessa que resolve com a resposta do servidor.
     */
	gerarEtiquetaLote(request) {
		if (request) {
			// Adicionar parâmetro de lote
			const params = new URLSearchParams();

			// Atribui os parâmetros da URL de acordo com o request
			params.append('uuids', request.uuids);
			params.append('tipoFolha', request.tipoFolha);

			// Realiza a requisição GET com os parâmetros
			return api.get(this.host() + "/api/v1/frete/etiqueta/lote", {
				params: params,
				responseType: 'blob'  // Espera-se uma resposta em formato blob
			});
		}
	}


	gerarDeclaracaoLote(request){
		if(request){
			return api.get(this.host() + "/api/v1/frete/declaracao-conteudo/lote?uuids=" + request,{ responseType: 'blob'})
		}
	}

	host(){
		return process.env.REACT_APP_HOST_API
	}
}
