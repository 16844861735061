import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-masked-input';
import { useSelector } from "react-redux";
import FlexSpinner from "../../components/FlexFreteSpinner/FlexSpinner";
import CarteiraFlexService from '../../services/CarteiraFlexService';
import TagueamentoService from '../../services/TagueamentoService';
import MeioDePagamentoService from '../../services/meioDePagamentoService';
import { moneyMask } from '../../utils/Masks';
import ModalGenerico from '../ModalGenerico/ModalGenerico';
import { InserirSaldoCardStyle } from './style';


const meioDePagamentoService = new MeioDePagamentoService();
const carteiraFlexService = new CarteiraFlexService();
const tagueamentoService = new TagueamentoService();

const TIPO =  process.env.REACT_APP_TRANSACAO_TIPO_COMPRA_CREDITOS;

function InserirSaldo(props) { 
    const [pagHiperResponse, setPagHiperResponse] = useState([]);
    const [pagHiperModal, setPagHiperModal] = useState(false)


    let {identificadorAssinatura} = useSelector((state) => state.user)

    const [request, setRequest] = useState({
        postContent: {
            carteira: identificadorAssinatura,
            tipo: TIPO,
            valor: 0,
            meioPagamento: '',
            loja: undefined,
            frete: undefined
        },
        meioDePagamento: {
            uuid: '',
            valorMinimo: 0.00,
            valorMaximo: 0.00,
        }

    });
    const [meiosDePagamento, setMeiosDePagamento] = useState([
        {
            uuid: '',
            nome: '',
            valorMinimo: 0,
            valorMaximo: 0,
            modalidadePagamento: [
                {
                uuid: '',
                nome: ''
                }
            ],
            prioridade: 0,
            atributosMeioPagamento: [
                {
                nomeAtributo: '',
                valorAtributo: '',
                seguro: true
                }
            ],
            ativo: true
        }

    ]);
    const [meiosDePagamentoExists, setMeiosDePagamentoExists] = useState(true);
    const [brokenLimitValueRole, setBrokenLimitValueRole] = useState(undefined);
    const [warningMessage, setWarningMessage] = useState(undefined);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(!meiosDePagamento?.length){
            setMeiosDePagamentoExists(false);
        }else{
            setMeiosDePagamentoExists(true);
            if(!request.meioDePagamento){
                setRequest({...request, meioDePagamento: meiosDePagamento[0]})
            }
        }
    }, [meiosDePagamento, request]);


    useEffect(() => {
        const {postContent, meioDePagamento} = request;

        if(!meioDePagamento?.uuid?.length){
            setBrokenLimitValueRole(undefined);
            return;
        };

        setIsDisabled(false);
        setBrokenLimitValueRole(undefined);

        if(postContent.valor > meioDePagamento.valorMaximo){
            setBrokenLimitValueRole({
                type: "max", message: `O limite  máximo para essa operação é: ${moneyMask(meioDePagamento.valorMaximo.toFixed(2)) }`
            });
            setIsDisabled(true);
       }else if(postContent.valor < meioDePagamento.valorMinimo){
            setBrokenLimitValueRole({
                type: "min", message: `O limite  mínimo para essa operação é: ${moneyMask(meioDePagamento.valorMinimo.toFixed(2)) }`
            });
            setIsDisabled(true);
       }
    }, [request])
    
    const loadMeiosDePagamento = () =>{
        meioDePagamentoService.getMeiosDePagamento((err, sucess)=>{
            if(err){
                console.log(err);
            }else{
                setMeiosDePagamento(sucess);
                if(sucess[0]){
                    const {postContent} = request;
                    postContent.meioPagamento = sucess[0].uuid;
                    setRequest({postContent, meioDePagamento: sucess[0]})
                }
            }
        })
    }
    useEffect(() => {
        const callLoadMeiosDePagamento = () =>{
            loadMeiosDePagamento();
        }
        callLoadMeiosDePagamento();
        // TODO: esse useEffect deve ser usado apenas quando a pagina é carregada --> By Renan
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(brokenLimitValueRole){
            setIsDisabled(true);       
        }else{
            setIsDisabled(false);
        }
        
    }, [brokenLimitValueRole]);
  
    const parseValorToFloat = (valor) => {
        let aux = Number(valor)
        return parseFloat(aux).toFixed(2);
    }
  
    const onChangeValor = (valor, novo) => {
       let v = parseValorToFloat(novo);
        
        setRequest({
            ...request,
            postContent: {...request.postContent, valor: v}
        });
    }
    const onChangeMeioDePagamento= (meioDePagamento) => {
        setWarningMessage(false);

        const {postContent} = request;

        postContent.meioPagamento = meioDePagamento.uuid;

        setRequest({postContent, meioDePagamento });
    }

    const submit=(e)=>{
        e.preventDefault();

        const {postContent} = request;

        if(brokenLimitValueRole){
            return;
        }

        if(!request.meioDePagamento?.uuid.length){
            setWarningMessage("Por favor, selecione um meio de pagamento.");
            setIsDisabled(true);
            return;
        }
        setIsLoading(true);

        carteiraFlexService.inserirCredito(postContent, (err, sucess)=>{
            setIsLoading(false);
            if(err){
                setWarningMessage("Ocorreu um problema ao processar a requisição. Por favor, tente novamente mais tarde.");
            }else{
                
                const action = carteiraFlexService.getAction(sucess);
                if(action === ""){
                    setWarningMessage("Meio de pagamento ainda não implementado.");
                    return;
                }

                const {info} = sucess.meioPagamento;

                if(action === "OPEN_NEW_WINDOW"){

                    tagueamentoService.eventoMarketingInserirSaldo("Crédito", postContent.valor)

                    let hasError = false;
                    info.forEach(er => {
                        if(er.chave === 'error'){
                            setWarningMessage("Ocorreu um problema ao processar a requisição. Por favor, tente novamente mais tarde.");
                            hasError = true;
                        }
                    });

                    if(hasError){
                        return;
                    }
                    const cieloInfo = info[0];


                    if(!cieloInfo){
                        setWarningMessage("Ocorreu um problema ao processar a requisição. Por favor, tente novamente mais tarde.");
                    }
                    const {valor} = cieloInfo;
                    if(!valor){
                        setWarningMessage("Ocorreu um problema ao processar a requisição. Por favor, tente novamente mais tarde.");
                    }
                    window.location = valor;
                }

                if(action === "OPEN_BOLETO_POPUP"){

                    tagueamentoService.eventoMarketingInserirSaldo("Boleto", postContent.valor)

                    info.forEach(er => {
                        if(er.chave === 'error'){
                            setWarningMessage("Ocorreu um problema ao processar a requisição. Por favor, tente novamente mais tarde.");
                        } else {
                            setPagHiperModal(true)
                        }
                    });

                    setPagHiperResponse(info);
                }
            }
        })
       
    }

    const callbackModal = () =>{
        if(pagHiperModal === true){
          setPagHiperModal(false)
        }
      }

  return (
    <>
        {isLoading? <FlexSpinner className="spinner-inserir-saldo"/>: ""}
        {
            pagHiperModal === true ?
            (
                <ModalGenerico visivel={pagHiperModal !== null} callbackModal={callbackModal} pagHiper={pagHiperResponse} />
            ) : null
        }
        <InserirSaldoCardStyle valor={request.valor} compraFrete={props.compraFrete}>
            <div className="form-background">
                <h2 className="inserir-saldo-title">Inserir saldo</h2>

                {
                    !meiosDePagamentoExists ? (
                        <div className="notification is-warning">
                            <p c>Não foi possível localizar nenhum meio de pagamento.</p>
                        </div>
                    ):
                    (
                        <form onSubmit={submit}>

                            <div className="valor-input">
                                <label htmlFor="valor">
                                    <strong> Inserir valor</strong>
                                </label>
                                <span className="moeda" >R$</span>
                                <div>
                                <CurrencyInput placeholder="0,00" required
                                    className="input input-carteira" separator="."  onChange = {(e, valorNovo)=> { onChangeValor (e.target.value, valorNovo)}} />
                                </div>
                            </div>
                            <div className="meios-de-pagamento">
                                <strong className="meios-de-pagamento-title"> Selecione o meio de pagamento</strong>
                                    <div className="meios-de-pagamento-list" >
                                    
                                            { 
                                                    meiosDePagamento.map( (meio, index)=>{
                                                        return (
                                                            
                                                            meio.uuid !== "flexfrete" ?  (
                                                            <div className="meio-de-pagamento" key={index}>
                                                                <label className="checkbox" htmlFor={"meioDePagamento "+ meio.nome}  onClick={ () => {onChangeMeioDePagamento(meio)}}>
                                                                    <input type="checkbox" className="meio-depagamento-logo" name={"meioDePagamento "+ meio.nome} value={meio.uuid} checked={request.meioDePagamento?.uuid=== meio.uuid} 
                                                                       />
                                                                    <img src={meio.logotipo} alt={`${meio.nome} logo`} className="meio-depagamento-logo"/>
                                                                </label>
                                                            </div>):""
                                                            
                                                            
                                                        )
                                                    })
                                        }
                    
                                    </div>
                                
                            </div>
                            {
                                brokenLimitValueRole || warningMessage ? (
                                    <div className="notification is-warning">
                                        <p>{brokenLimitValueRole?.message || warningMessage}</p>
                                    </div>
                                ):""
                            }
                            <button className="button" disabled={isDisabled}>Confirmar</button>
                                    
                            
                    </form>
                )}
            </div>
        </InserirSaldoCardStyle>
    </>
  );
}

export default InserirSaldo;
