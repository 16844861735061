import { Link } from 'react-router-dom';
import styled from "styled-components";

export const HeaderStyle = styled.header` 
	background-color: white;

    .header-container{
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        position: fixed;
        z-index: 6;
    }

    .header-container img{
        width: 215px;
        margin-left: 50px;
        padding: 15px 0;
    }

    @media screen and (min-width: 2560px) {
        .mobile-container{
            display: none;
        }
    }
    
    @media screen and (max-width: 1920px) {
        .mobile-container{
            display: none;
        }
    }

    @media screen and (max-width: 1440px) {
        .mobile-container{
            display: none;
        }
    }
    
    @media (min-width: 1280px) {
        .mobile{
            display: none;
        }
        .header-top{
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 1.5em 40px 1.5em 0;
            height: 12vh;
            font-weight: 500;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        }
        .logo-container{
            display:flex;
            align-items: center;
        }
    }
    @media (min-width: 1800px) {
        .header-top{
            height: 8.5vh;
        }
    }

    @media screen and (max-width: 700px) {
        .mobile-container{
            display: flex;
        }
        .desktop-container{
            display: none;
        }

        .header-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 40px 0 0;
            /* height: 15vh; */
            font-weight: 500;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        }
        .logo-container{
            display:flex;
            align-items: center;
        }

        .header-container{
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: auto;
            position: fixed;
            z-index: 6;
        }

        .header-container img{
            margin-left: 1em;
            padding: 15px 0;
        }
    }

`;

export const BurguerButtonStyle = styled.button`
    border: none;
	background-color: transparent;
    /* padding-top: 6px; */
    padding-right: 1em;
    position: fixed;
    right: 0;
    top: 0;
    cursor: pointer;

    ion-icon {
		color: #812990;
		font-size: 3em;
	}
    @media (min-width: 1280px){
        display: none;
    }

    @media(max-width: 700px) {
        ion-icon {
            font-size: 5em;
        }
    }

`;

export const BtnAreaLogadaContainer = styled.div`
    display: flex;
    align-items: center;
    .supportLink{
        margin-right: 12em;
        padding: 12px 12px 12px 12px;

        .header-link{
            text-decoration: none;
            color: rgba(136,48,152, 1);
        }

    }
`

export const BtnUserNameStyle = styled.div`
    border: ${(props) => (props.open ? `#792783;` : `none;`)};
    position: fixed;
    right: 0;
    top: 0;
    border-radius: 3px;
    cursor: pointer;
    transition: ${(props) => (props.toggle ? `background-color 0.5s ease-in;` : `background-color 0.5s ease-out;`)};

    .user-exit-link{
        display: ${(props) => (props.open ? `block;` : `none;`)};
        margin: 15%;
        letter-spacing: 0.2px;
        font-weight: 600;
        vertical-align: middle;
    }

    .user-exit-link a{
        text-decoration: none;
        color: white;
    }

    ion-icon{
        vertical-align: middle;
    }

    @media screen and (min-width: 2560px) {
        margin-top: 3.4em;
	}


    @media(max-width: 2560px){
        font-weight: 600;
        background:#792783;
        border: none;   
        color: #fff;
        /* margin-top: 20px; */
        margin-right: 50px;
        padding: 12px 12px 12px 12px;

        .header-username{
            color: #fff;
        }

        .header-username > ion-icon{
            margin-left: 10px;
        }
    }

    @media screen and (max-width: 1920px) {
        margin-top: 20px;
    }

    @media screen and (max-width: 1440px) {
        margin-top: 8px;
    }

`;

export const HeaderLinksStyle = styled.section` 
    padding: 0.5em;
    
    .button{
        background: #812990;
    }
    .btn-branco{
        background: #FFFFFF!important;
        color: #792783!important;
        border: 1px solid #792783!important;
    }
    
    .header-link{
        text-decoration: none;
    }
    @media (min-width: 1280px){
        .mobile{
            display: none;
        }
        display: flex;
        justify-content: space-around;
        width: ${(props) => (props.logged ? `60%` : `30%`)};
        align-items: center;
    
        display: flex;
        flex-direction: row;
        background-color: none;
        padding: 1em;

        .line-mobile-links{
            display: none;
        }
        .header-link{
            color: rgba(136,48,152, 1);
        }
    }

    @media screen and (max-width: 1440px) {
        .space{
            margin-right: 2em;
        }
    }
    @media(max-width: 1400px){
        width: 60%;
    }

    @media(min-width:1401px && max-width: 2560px){
        width: ${(props) => (props.logged ? `60%` : `30%`)};
        .suporte{
            margin-right: ${(props) => props.logged === true ? `15em;` : `0`};
        }
    } 

    @media(max-width: 700px) {
        display: ${(props) => (props.open ? `flex;` : `none;`)};
        flex-direction: ${(props) => (props.open ? `column;` : `none;`)};
        width: 100%;
        position: fixed;
        left: 0em;
        top: 0em;
        bottom: 0em;
        right: 0em;
        z-index: -1;
        padding-top: 8em;
        padding-bottom: 0px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        .register{
            background-color: #792783;
            border-radius: 8px;
            color: #FFFFFF!important;
        }

        .header-link{
            color: #340040;
            font-weight: 600px;
            font-size: 15px;
            line-height: 24px;
            margin: 0.5em 1em 0em 1em;
            padding: 1em;
            text-align: left;
            color: #340040;
        }
    }

`;

export const LinkUserArea = styled(Link)`
    background:rgba(227,175,31,1);

    @media(max-width: 1280px){
        display: none;
    } 
    border: none;   
    color: #fff;
    :hover{
        color: #fff;
    }
    
`;