import React, { useState, } from "react";
import { BtnModalGenericoStyle, ModalGenericoContainerStyle, ModalGenericoContentStyle } from "./style";

function ModalGenerico(props) {
    const [message, setMessage] = useState(false);

    setTimeout(() => {
        setMessage(false);
    }, 3000)

	return (
		<>
		<div className={props.visivel === true ?` modal is-active` : `modal`}>
			<div className="modal-background"></div>
			<div className="modal-content">
			<ModalGenericoContainerStyle>
				<ModalGenericoContentStyle>
					<header>
						<button className="modal-close" aria-label="close" onClick={props.callbackModal}></button>
                        <picture className="paghiper-logo">
                            <img src="/paghiper.png" alt="mulher-landing-page" />
                        </picture>
					</header>
					<h1>Boleto gerado com sucesso!</h1>
                    <h2>Por favor, escolha uma das opções de boleto:</h2>
                    <footer>
                        <BtnModalGenericoStyle className="button" onClick={() => { window.open(props.pagHiper[0]?.valor)}} >HTML</BtnModalGenericoStyle>
                        <BtnModalGenericoStyle className="button" onClick={() => { window.open(props.pagHiper[1]?.valor)}} >PDF</BtnModalGenericoStyle>
                    </footer>
                    <h2>Ou, copie e cole o código de barras:</h2>
						<div>
							<div>
                                <p className="digitableLine">{props.pagHiper[2]?.valor}</p>
							</div>
							<div className="btnCopy">
								<span
									className="button is-large"
									onClick={() => {
                                        navigator.clipboard.writeText(props.pagHiper[2]?.valor)
                                        setMessage(true)
                                    }}
								>
								{
                                    message === true ?
                                    (
                                        <span class="copy copiado">
                                        <ion-icon name="checkmark-circle-outline"></ion-icon> Copiado!
                                        </span>
                                    ) :
                                    (
                                        <span class="copy">
                                        <ion-icon name="copy"></ion-icon> Copiar
                                        </span>
                                    )
                                }
									
								</span>
							</div>
						</div>
			</ModalGenericoContentStyle>
			</ModalGenericoContainerStyle>
			</div>
		</div>
		</>
	);
}

export default ModalGenerico;
