import React from 'react';
import { Link } from "react-router-dom";
import DocumentosService from '../../services/DocumentosService';
import DownloadService from '../../services/DownloadService';
import { FooterLogadoStyle, FooterStyle } from './style';


function Footer(props) {
  const docservice = new DocumentosService();
  const downloadService = new DownloadService();

  // const [erro, setErro] = useState({
  //   isWrong: false,
  //   titulo: "",
  //   mensagem: "",
  //   disableButton: false
  // });

  const termosUso = ()=>{
    
    docservice.gerarTermosUso((err, sucess)=>{
      if(err){
          // setErro({
          //     isWrong: true,
          //     titulo: 'Erro ao baixar arquivo',
          //     mensagem: 'Falha ao tentar realizar o download, entre em contato com o suporte ou tente novamente mais tarde.'
          //   })
        console.error(err)
      }else{
         downloadService.downloadFile({label: "termos de uso", data: sucess, filename: "Termos_de_uso.pdf", extension: "pdf"})
        
      }
  });
  } 
  return (
    <>
    {
      props.isLogged === false ?
      (
          <FooterStyle isLogged = {props.isLogged}>
          <div className={props.isLogged === true ? `private` : ``}>
            <div>
              <ul className='informacoes'>
                <li className='logoFlexFrete'>
                  <Link to={ props.isLogged === true ? `/home` : `/`}>
                      <img className="image" src="/flexfrete_footer.png" alt="logo" />
                  </Link>
                </li>
                <li className='flex'>Investida por: <img className="image img-first" src="/orca.png" alt="logo" /></li>
                <li className='flex'>Reconhecida por: <img className="image" src="/zendesk.png" alt="logo" /> <img className="image mobile-container" src="/aws.png" alt="logo" /></li>
                <li><img className="image desktop-container" src="/aws.png" alt="logo" /></li>
              </ul>
                
            </div>
            <hr/>
            <div className="version-media">
                <div className="suporte-termos">
                  <a href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer">Suporte</a>
                  <span className="link-uso"><button className="termos-button" onClick={termosUso}>Termos de uso</button></span>
                </div>

              <div className='version desktop-container'>
                <p>Flex Frete Copyright &copy; {new Date().getFullYear()}</p>
                <p className="version">Versão {process.env.REACT_APP_VERSION}</p>
              </div>
              <div className='desktop-container'>
                <ul className="redes-sociais">
                  <li><a href="https://youtube.com/channel/UC6w4_75fTdWwOTsiUMSGN4Q"><ion-icon name="logo-youtube" ></ion-icon></a></li>  
                  <li><a href="https://m.facebook.com/flexfreteoficial/"><ion-icon name="logo-facebook" ></ion-icon></a></li>
                  <li><a href="https://instagram.com/flexfrete?igshid=YmMyMTA2M2Y="><ion-icon name="logo-instagram" ></ion-icon></a></li>
                  <li><a href="https://twitter.com/flexfrete?s=21&t=-ct72IBxSKkJtvvzwilQhQ"><ion-icon name="logo-twitter" ></ion-icon></a></li>
                  <li><a href="https://www.linkedin.com/company/flex-frete/"><ion-icon name="logo-linkedin" ></ion-icon></a></li>
                </ul>
              </div>

              <div className='mobile-container' style={{flexDirection: 'column-reverse'}}>
                <div className='version'>
                  <p>Flex Frete Copyright &copy; {new Date().getFullYear()}</p>
                  <p className="version">Versão {process.env.REACT_APP_VERSION}</p>
                </div>
                <div>
                  <ul className="redes-sociais">
                    <li><a href="https://youtube.com/channel/UC6w4_75fTdWwOTsiUMSGN4Q"><ion-icon name="logo-youtube" ></ion-icon></a></li>  
                    <li><a href="https://m.facebook.com/flexfreteoficial/"><ion-icon name="logo-facebook" ></ion-icon></a></li>
                    <li><a href="https://instagram.com/flexfrete?igshid=YmMyMTA2M2Y="><ion-icon name="logo-instagram" ></ion-icon></a></li>
                    <li><a href="https://twitter.com/flexfrete?s=21&t=-ct72IBxSKkJtvvzwilQhQ"><ion-icon name="logo-twitter" ></ion-icon></a></li>
                    <li><a href="https://www.linkedin.com/company/flex-frete/"><ion-icon name="logo-linkedin" ></ion-icon></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
            </FooterStyle>
        ) :
        (
          <div className='containerFooterLogado' style={{paddingTop: '5em'}}>
          <FooterLogadoStyle isLogged = {props.isLogged}>

            <div className={props.isLogged === true ? `private` : ``}>
              <div className="version-media">
                  <div className="suporte-termos">
                    <a href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer">Suporte</a>
                    <span className="link-uso"><button className="termos-button" onClick={termosUso}>Termos de uso</button></span>
                  </div>

                  <div className='version desktop-container'>
                  <p>Flex Frete Copyright &copy; {new Date().getFullYear()}</p>
                  <p className="version">Versão {process.env.REACT_APP_VERSION}</p>
                </div>
                <div className='desktop-container'>
                  <ul className="redes-sociais">
                    <li><a href="https://youtube.com/channel/UC6w4_75fTdWwOTsiUMSGN4Q"><ion-icon name="logo-youtube" ></ion-icon></a></li>  
                    <li><a href="https://m.facebook.com/flexfreteoficial/"><ion-icon name="logo-facebook" ></ion-icon></a></li>
                    <li><a href="https://instagram.com/flexfrete?igshid=YmMyMTA2M2Y="><ion-icon name="logo-instagram" ></ion-icon></a></li>
                    <li><a href="https://twitter.com/flexfrete?s=21&t=-ct72IBxSKkJtvvzwilQhQ"><ion-icon name="logo-twitter" ></ion-icon></a></li>
                    <li><a href="https://www.linkedin.com/company/flex-frete/"><ion-icon name="logo-linkedin" ></ion-icon></a></li>
                  </ul>
                </div>
      
                <div className='mobile-container' style={{flexDirection: 'column-reverse'}}>
                  <div className='version'>
                    <p>Flex Frete Copyright &copy; {new Date().getFullYear()}</p>
                    <p className="version">Versão {process.env.REACT_APP_VERSION}</p>
                  </div>
                  <div>
                    <ul className="redes-sociais">
                      <li><a href="https://youtube.com/channel/UC6w4_75fTdWwOTsiUMSGN4Q"><ion-icon name="logo-youtube" ></ion-icon></a></li>  
                      <li><a href="https://m.facebook.com/flexfreteoficial/"><ion-icon name="logo-facebook" ></ion-icon></a></li>
                      <li><a href="https://instagram.com/flexfrete?igshid=YmMyMTA2M2Y="><ion-icon name="logo-instagram" ></ion-icon></a></li>
                      <li><a href="https://twitter.com/flexfrete?s=21&t=-ct72IBxSKkJtvvzwilQhQ"><ion-icon name="logo-twitter" ></ion-icon></a></li>
                      <li><a href="https://www.linkedin.com/company/flex-frete/"><ion-icon name="logo-linkedin" ></ion-icon></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </FooterLogadoStyle>
          </div>
        )
      }
    </>
  );
}

export default Footer;