export default function validarTelefone (telefoneInput) {
    //remember, is without mask and spaces=> example 11931160009 will returns true, (11)93116-0009 returns false
    if(!telefoneInput){
        return;
    }
    let isValid = false;

    const length = telefoneInput.length;

    if(length !== 11 && length !== 10){
        return false;
    }

    let regex = new RegExp('^((1[1-9])|([2-9][0-9]))((2[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$');
    if(telefoneInput.length === 12 ){
        isValid =  regex.test(telefoneInput);
        return isValid;
    }

    regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$');
    isValid =  regex.test(telefoneInput);
    if(isValid){
        return isValid;
    }
    regex = new RegExp('^((1[1-9])|([2-9][0-9]))((4[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'); 
    isValid =  regex.test(telefoneInput);
    if(isValid){
        return isValid;
    }
    regex = new RegExp('^((1[1-9])|([2-9][0-9]))((5[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$'); 
    isValid = regex.test(telefoneInput);
    if(isValid){
        return isValid;
    }
    return false;
}