import React from "react";
import { useDispatch } from "react-redux";
import { ActionCreators } from '../../_actions/ActionCreators';
import { DadosBaixarModalContainerStyle, DadosBaixarModalContentStyle, BtnModalSeguirStyle } from "./style";

function DadosBaixarModal(props) {
	const dispatch = useDispatch();

	return (
		<>
		<div className={props.visivel === true ?` modal is-active` : `modal`}>
			<div className="modal-background"></div>
			<div className="modal-content">
			<DadosBaixarModalContainerStyle>
				<DadosBaixarModalContentStyle>
					<header>
						<button className="modal-close" aria-label="close" onClick={() => {dispatch(ActionCreators.hideModal(false))}} ></button>
					</header>
					<h1>Fazer download dos dados</h1>
                    <h2>Por favor, selecione um dos formatos abaixo</h2>
                    <footer>
                        <BtnModalSeguirStyle className="button" onClick={(e) => {props.onChange(e, "application/xml")}}>Arquivo XML</BtnModalSeguirStyle>
                        <BtnModalSeguirStyle className="button" onClick={(e) => {props.onChange(e, "text/csv")}}>Arquivo CSV</BtnModalSeguirStyle>
                    </footer>
			</DadosBaixarModalContentStyle>
			</DadosBaixarModalContainerStyle>
			</div>
		</div>
		</>
	);
}

export default DadosBaixarModal;
