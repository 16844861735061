import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import AutenticacaoService from "../../services/AutenticacaoService";
import { ActionCreators } from '../../_actions/ActionCreators';
import { BtnAreaLogadaContainer, BtnUserNameStyle, BurguerButtonStyle, HeaderLinksStyle, HeaderStyle, LinkUserArea } from './style';

const usuario = new AutenticacaoService();

function Header(props) {
    const userName = useSelector((state) => state.user);
    const closeModal = useSelector((state) => state.headerClicked);
    const [open, setOpen] = useState(false)
    let location = useLocation();
    const dispatch = useDispatch();

    useEffect(()=>{
        document.getElementById("top").scrollIntoView()
        if(!closeModal){
            setOpen(false)
        }
    }, [closeModal]);

    const sair = () => {
      usuario.logout(() => {
          window.location = "/";
      })
};

const onClick = () => {
    setOpen(!open)
    dispatch(ActionCreators.isHeaderSubMenuClicked(true))
}


    return (
        <>
        {
            location.pathname === '/perfil' ?
            (
                <>
                </>
            ) :
            (
                <HeaderStyle open={open}>

                <div id="top" className="header-container">
                <div className="header-top">
                    <div className="logo-container">
                        <Link to={ props.isLogged === true ? `/home` : `/`}>
                            <img className="logo-img" src="/logo-flex-frete-s-Slogan-horizontal.png" alt="logo" />
                        </Link>
                    </div>

                    {
                        props.isLogged === false ?
                        (
                            <>
                                <BurguerButtonStyle onClick = {onClick}>
                                    {
                                        open ? 
                                        (
                                            <ion-icon name="close-outline"></ion-icon>
                                        ) :
                                        (
                                            <ion-icon name="menu-outline"></ion-icon>
                                        )
                                    }
                                </BurguerButtonStyle>

                                <HeaderLinksStyle open={open} logged={props.isLogged}>
                                    <Link onClick = {onClick} open={open} className="header-link mobile register" to ="/register">Cadastre-se</Link>
                                    <Link onClick = {onClick} className="header-link mobile" to ="/login">Login</Link>
                                    <a className="header-link suporte space" href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer">Suporte</a>
        
                                    <LinkUserArea to="/login" className="button is-rounded button-landing btn-branco">Minha Conta</LinkUserArea>
                                    <LinkUserArea to="/register" onClick = {onClick} open={open} className="button is-rounded" style={{textTransform: 'uppercase'}}>Cadastre-se</LinkUserArea>
                                </HeaderLinksStyle>
                            </>
                        ) :
                        (
                            <>
                            <div className='mobile-container'>
                                <BurguerButtonStyle onClick = {onClick}>
                                    {
                                        open ? 
                                        (
                                            <ion-icon name="close-outline"></ion-icon>
                                        ) :
                                        (
                                            <ion-icon name="menu-outline"></ion-icon>
                                        )
                                    }
                                </BurguerButtonStyle>

                                <HeaderLinksStyle open={open} logged={props.isLogged}>
                                    <a className="header-link suporte space" href="/" style={{fontSize: '1.8em', paddingLeft: '0px', paddingTop: '0px', marginTop: '0px'}}>Olá, {userName.name}</a>
                                    <a className="header-link suporte space" href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer">Suporte</a>
                                    <Link onClick={sair} className="header-link mobile" to ="/login">Desconectar</Link>
                                </HeaderLinksStyle>
                            </div>

                                    <BtnAreaLogadaContainer>
                                    <div className='desktop-container'>
                                        <div className='supportLink'>
                                            <a className="header-link suporte space" href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer">Suporte</a>
                                        </div>
                                        <BtnUserNameStyle onClick = {onClick} open={open}>
                                            <p className="header-username">
                                                Olá, {userName.name}
                                                {
                                                open ?
                                                <ion-icon name="caret-up-outline"></ion-icon> :
                                                <ion-icon name="caret-down-outline"></ion-icon>
                                            }
                                            </p>

                                            <p className="user-exit-link" onClick={sair}>
                                                <Link to="/logout">Sair <ion-icon name="exit-outline"></ion-icon></Link>
                                            </p>

                                        </BtnUserNameStyle>
                                    </div>
                                    </BtnAreaLogadaContainer>
                            </>
                        )
                    }
    
                    {/* {
                        props.isLogged === false ?
                        (
                        <BurguerButtonStyle onClick = {onClick}>
                            {
                                open ? 
                                (
                                    <ion-icon name="close-outline"></ion-icon>
                                ) :
                                (
                                    <ion-icon name="menu-outline"></ion-icon>
                                )
                            }
                        </BurguerButtonStyle>
                        ) :
    
                        (
                            <BtnAreaLogadaContainer>
                                <div className='supportLink'>
                                    <a className="header-link suporte space" href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer">Suporte</a>
                                </div>
                                <BtnUserNameStyle onClick = {onClick} open={open}>
                                    <p className="header-username">
                                        Olá, {userName.name}
                                        {
                                        open ?
                                        <ion-icon name="caret-up-outline"></ion-icon> :
                                        <ion-icon name="caret-down-outline"></ion-icon>
                                    }
                                    </p>

                                    <p className="user-exit-link" onClick={sair}>
                                        <Link to="/logout">Sair <ion-icon name="exit-outline"></ion-icon></Link>
                                    </p>

                                </BtnUserNameStyle>
                            </BtnAreaLogadaContainer>
                        )
                    }

                    {
                        props.isLogged === false ?
                        (
                         <HeaderLinksStyle open={open} logged={props.isLogged}>
                            <Link onClick = {onClick} open={open} className="header-link mobile" to ="/register">Cadastre-se</Link>
                            <Link onClick = {onClick} className="header-link mobile" to ="/login">Login</Link>
                            <a className="header-link suporte space" href="https://suporte.flexfrete.com.br" target="_blank" rel="noreferrer">Suporte</a>

                            <LinkUserArea to="/login" className="button is-rounded button-landing btn-branco">Minha Conta</LinkUserArea>
                            <LinkUserArea to="/register" onClick = {onClick} open={open} className="button is-rounded" style={{textTransform: 'uppercase'}}>Cadastre-se</LinkUserArea>
                         </HeaderLinksStyle>
                        ) : ""
                    } */}
                    
                </div>        
                </div>

            </HeaderStyle>
            )
        }
        {
           
        }
        </>
    );
}

export default Header;