import queryString from 'query-string';
import React, { useState } from 'react';
import MeusEnviosFreteList from '../../components/MeusEnviosFreteList/MeusEnvios';
import { MeusEnviosStyle } from './style';

function MeusEnvios(props) {
  // const [section, setSection] = useState(queryString.parse(props.location.search).section || "AGUARDANDO_PAGAMENTO")
  const [section] = useState(queryString.parse(props.location.search).section || "GERADO")
  return (
    <div className="page-container-style"> 
      <MeusEnviosStyle>
        
        <h2 className="page-title">Meus fretes</h2>

        <MeusEnviosFreteList section={section}/>

      </MeusEnviosStyle>
    </div>
  );
}

export default MeusEnvios;
