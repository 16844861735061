import instance from "./Api";

export default class CarteiraFlexService {

    obterAssinatura(uuid, callback){
        instance
            .get(this.host() + "/api/v1/assinatura/" + uuid)
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    inserirCredito(request, callback){
        instance
            .post(this.host() + "/api/v1/transacao", request)
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    obterStatusTransacao(callback){
        instance
            .get(this.host() + "/api/v1/statustransacao?ativo=true")
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    obterTipoTransacao(callback){
        instance
            .get(this.host() + "/api/v1/tipotransacao?ativo=true")
            .then((response) => {
                callback(null, response.data)
            })
            .catch((erro) => {
                callback(erro, null)
            })
    }

    obterTransacoes(uuid, request, type, uuidPerfilAcesso, callback){

        let params = {
            dataInicio: request.dataInicio != null ? request.dataInicio.replace("T", " ") : request.dataInicio,
            dataFim: request.dataFim != null ? request.dataFim.replace("T", " ") : request.dataFim,
            tipoTransacao: request.tipoTransacao,
            statusTransacao: request.statusTransacao,
            pageSize: request.pageSize,
            page: request.page,
        }
        
        instance
        .get(this.host() + "/api/v1/assinatura/" + uuid + "/transacao", {
            headers: {
                'Content-Type': type,
                'accept': type,
                'identificador-perfil-acesso': uuidPerfilAcesso
            },
            params
        })
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    getAction(inserirCreditoResponse){
        if(!inserirCreditoResponse) return;

        const {meioPagamento} = inserirCreditoResponse;

        const nome = meioPagamento.nome?.toLowerCase();

        if(nome === "cielo"){
            return "OPEN_NEW_WINDOW";
        }else if(nome === "paghiper"){
            return "OPEN_BOLETO_POPUP";
        }

        return "";
    }

    host() {
		return process.env.REACT_APP_HOST_API;
	}
}