import styled from "styled-components";

export const DocCardStyle = styled.div`
    background-color: white;
    padding: 3rem;
    border-radius: 15px;
    -webkit-box-shadow: 13px 12px 9px -5px rgb(97 96 96 / 35%);
    box-shadow: 13px 12px 9px -5px rgb(97 96 96 / 35%);
    height: 100%;

    h2{
		color: #282828;
        font-weight: 600;
        letter-spacing: 0.3px;
		margin-bottom: 15px;
        margin-top: 0;
		text-align: left;
    }

    .doc-descricao {
        text-align: left;
        font-weight: 300;
        letter-spacing: 0.3px;
		margin-bottom: 15px;
    }
    .button{
        margin-top: 15px;
        margin-bottom: 15px;
        background: rgb(131, 43, 146);
	    background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
	    );
        border: none;
        color: white;
        font-weight: 600;
		letter-spacing: 0.2px;
    }

    .button:hover{
        background: #c986d8;
        color: white;
    }

    .file-cta{
        margin-top: 15px;
        margin-bottom: 15px;
        background: rgb(131, 43, 146);
	    background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
	    );
        border: none;
        color: white;
        font-weight: 600;
		letter-spacing: 0.2px;
    }

    .file-cta:hover{
        background: #c986d8;
        color: white;
    }

    .file-cta-again{
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: transparent;
        border: none;
        color: rgb(131, 43, 146);;
        font-weight: 600;
		letter-spacing: 0.2px;
        font-size: 0.9em;
    }

    .assinaturaDigital{
        background: rgb(247, 247, 247);
	    background: linear-gradient(
            94deg,
            rgba(247, 247, 247, 1) 39%,
            rgba(255, 255, 255, 1) 100%
	    );
        color: #9339a2;
        -webkit-box-shadow: 9px 7px 9px -5px rgb(97 96 96 / 32%);
        box-shadow: 9px 7px 9px -5px rgb(97 96 96 / 32%);
    }

    .loading-imagem-section{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }

    .loading-imagem-section > .progress{
        margin-left: 2em;
        margin-right: 2em;
    }

    .image-container{
        text-align: center;
    }
    .image img{
        max-height: 128px;
    }

    .statusDocumento{
        color: ${(props) => (props.status === "AVALIACAO" ? `#e6b222;` : "")};
        color: ${(props) => (props.status === "APROVADO" ? `#009f57` : "")};
        color: ${(props) => (props.status === "REPROVADO" ? `red;` : "")};
        margin-top: 2em;
        margin-bottom: 2em;
    }

    .btn-contrato-container{
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .btn-contrato-container > button{
        border: none;
        font-weight: 600;
		letter-spacing: 0.2px;
    }

    .btn-contrato-container > button:first-child{
        background: rgb(131, 43, 146);
	    background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
	    );
        color: white;
    }

    .carregarContrato{
        background: rgb(194, 144, 3);
		background: linear-gradient(
			266deg,
			rgba(194, 144, 3, 1) 35%,
			rgba(227, 175, 31, 1) 100%
		);
        color: white;
    }


@media (max-width: 1440px) {
    padding: 2rem;
}

@media (max-width: 770px) {
    .loading-imagem-section{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .loading-imagem-section > .progress{
        margin-top: 2em;
    }

    /* .file-cta{
       width: 18em;
    } */

    .file-label{
        font-size: 0.91em;
        overflow: visible;
    }
}
    .btn-doc-container{
        display: flex;
        flex-direction: column-reverse;
    }

`

