import styled from "styled-components";

export const HomeStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 3em;
    width: calc(100% - 3em);

    min-height: 60vh;

    @media(min-width: 1280px){
        left: 290px;
        width: calc(100% - 290px);   
    }
    .header-home{
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 100px;
        width: 90%;
        max-width: 900px;
        
        >div{
            margin: 0;
        }
        .carteira-background{
            height: 100%;
        }  
    }
    .fretes-list{
        width: 900px;
        margin: 50px 0;
        background-color: #fff;
        padding: 40px;
        border-radius: 5px;
        .formulario-busca-titulo{
            text-align: left;
            font-size: 1.8rem;
            font-weight: bold;
        }
        .button-load-more{
            color: rgba(136,48,152,1);
            width: 200px;
            font-weight: 800;
        }
        .button-load-more:hover{
            color: rgba(136,48,152,0.7);
        }
    }

    @media(max-width: 1200px){
        .header-home{
            grid-template-columns: 1fr;
            row-gap: 80px;
            max-width: 400px;
        }
        .fretes-list{
            width: 400px;
            .meus-envios-cabecalho{
    
                ul{
                    height: 60px;
                }
            }
        }
       
    }

    @media(max-width: 510px){
        .fretes-list{
            max-width: 280px;
        }
    }

`;