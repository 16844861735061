export function  cpfMask(cpfInput){
    if(!cpfInput) return;

    let cpf = cpfInput
    cpf = cpf.replace(/\D/g,"")
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    return cpf
}

export function cnpjMask(cnpj) {
  if(!cnpj) return;
  let str = cnpj.toString()
  let final = str.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  return final;
}

export function telefoneMask (telefoneInput){
    if(!telefoneInput) return;
    let telefone = telefoneInput;
     telefone = telefone.replace(/\D/g, "");
    telefone= telefone.replace(/^0/, "");
  
    if (telefone.length > 11) {
      telefone= telefone.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if(telefone.length === 10){
      telefone= telefone.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    }
    else if (telefone.length > 7) {
      telefone= telefone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
    } else if (telefone.length > 2) {
      telefone= telefone.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (telefoneInput.trim() !== "") {
      telefone= telefone.replace(/^(\d*)/, "($1");
    }
    return telefone;
}

export function cepMask (cepInput){
  if(!cepInput) return;
  let cep = cepInput;
   cep = cep.replace(/\D/g, "");

  if (cep.length === 8) {
    cep = cep.replace(/^(\d{5})(\d{3}).*/, "$1-$2");
  }
  return cep;
}

export function moneyMask(money){
  if(!money){
    return;
  }


  let [reais, centavos] = money.split('.');
  reais = reais.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return "R$ " + reais + "," + (centavos || '00');
}

export function removeMask(telefoneInput){
    const telefone = telefoneInput.replace(/[^0-9]+/g,'');
    return telefone;
}

export function dataAtualFormatada(){
  var data = new Date(),
      dia  = data.getDate().toString(),
      diaF = (dia.length === 1) ? '0'+dia : dia,
      mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length === 1) ? '0'+mes : mes,
      anoF = data.getFullYear();
  return diaF+"/"+mesF+"/"+anoF;
}

export function notaFiscalMask(numeroDanfCte){
  const regex = /(\d{4})/g;
  return numeroDanfCte.match(regex).join(' ');;
}

export function handleDecimalPlaces(value, number){

  // Passo 1: Remover tudo que não for algarismo ou ponto
  value = value.toString().replace(/[^0-9.]/g, '');

  // Passo 2: Substituir ocorrências de zeros à esquerda (exceto zero seguido de ponto) por nada
  value = value.replace(/^0+(?=0)/g, '');

  // Passo 3: Remover qualquer ponto que vier após o primeiro ponto
  value = value.replace(/(.*\..*)\./g, '$1')

  // Passo 4: Inserir um zero no início da palavra, caso ela comece com um ponto
  value = value.replace(/^\./, '0.');

  if(value && value.indexOf(".") > 0){
    value = value.slice(0, value.indexOf(".") + number + 1)
  }

  return value;
}

export function handleDecimalPeso(value, number) {

  if(value === '0'){
    return '0'
  }

  if(value.length === 2 && value.startsWith('0') && value[1] !== ','){
    return value[0] + ',' + value[1]
  }

  // Passo 1: Remover tudo que não for algarismo ou vírgula
  value = value.toString().replace(/[^0-9,]/g, '');

  // Passo 2: Substituir ocorrências de zeros à esquerda (exceto zero seguido de vírgula) por nada
  value = value.replace(/^0+(?=0)/g, '');

  // Passo 3: Remover qualquer vírgula que vier após a primeira vírgula
  value = value.replace(/(.*,.*),/g, '$1')

  // Passo 4: Inserir um zero no início da palavra, caso ela comece com uma vírgula
  value = value.replace(/^,/, '0,');

  if (value && value.indexOf(",") > 0) {
    value = value.slice(0, value.indexOf(",") + number + 1);
  }

  return value;
}

