import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CarrinhoConfirmacaoDeCompraFrete from '../../components/CarrinhoConfirmacaoDeCompraFrete/CarrinhoConfirmacaoDeCompraFrete';
import CarrinhoEtiqueta from '../../components/CarrinhoEtiqueta/CarrinhoEtiqueta';
import CarrinhoPagamento from '../../components/CarrinhoPagamento/CarrinhoPagamento';
import CarrinhoSteps from '../../components/CarrinhoSteps/CarrinhoSteps';
import FlexSpinner from '../../components/FlexFreteSpinner/FlexSpinner';
import AutenticacaoService from '../../services/AutenticacaoService';
import CarrinhoService from '../../services/CarrinhoService';
import CarteiraFlexService from '../../services/CarteiraFlexService';
import TagueamentoService from '../../services/TagueamentoService';
import { BtnSection, CarrinhoDeComprasStyle } from './style';

function CarrinhoDeCompras() {
  const history = useHistory();
  const carrinhoService = new CarrinhoService();
  const tagueamentoService = new TagueamentoService();
  const carteiraService = new CarteiraFlexService();
  const authService = new AutenticacaoService();
  const [assinatura, setAssinatura] = useState({});
  const [saldo, setSaldo] = useState(0.00)
  const [processando, setProcessando] = useState(false);
  const [finalizarPagamento, setFinalizarPagamento] = useState(false);
  const [confirmacaoCompraPagamento, setConfirmacaoCompraPagamento] = useState(false)
  
const [erro, setErro] = useState({
  isWrong: false,
  titulo: "",
  mensagem: "",
})

const callbackModal = () =>{
  if(erro.isWrong === true){
    setErro({isWrong: false})
  }
}

const [isLoading, setIsLoading] = useState(false);

const[uuidCarinho, setUuidCarinho] = useState()

const[fretesCarinho, setFretesCarinho] = useState([])

const[numeroFretesCarinho, setNumeroFretesCarinho] = useState()

const carrinhoLocalStorage = carrinhoService.getCarrinhoInfosParaCompra() || []

const[somaValorTotalFretes, setSomaValorTotalFrete] = useState()

  useEffect(() => {
    setIsLoading(true);
    const carrinhoService = new CarrinhoService();
    carrinhoService.getCarrinho((erro, sucesso) => {
        if(erro){
            setNumeroFretesCarinho(0);
            setIsLoading(false);
            return;
        }
        if(sucesso){
          setUuidCarinho(sucesso.uuid)
          setFretesCarinho(sucesso.fretes)
          setNumeroFretesCarinho(sucesso.fretes.length)
          setSomaValorTotalFrete(sucesso.total)
          carrinhoService.setNumeroItensInfosParaCompra(sucesso.fretes.length)

          setIsLoading(false);
        }
    })
 
 }, [])
  let {freteCompradoComSucesso} = useSelector((state) => {
    return {
      freteCompradoComSucesso: state.freteCompradoComSucesso
    };
  });

  if(!processando){
    setProcessando(true);
    carrinhoService.getCarrinho((erro, sucesso) => {
      if(erro){
          console.log(erro)
          setNumeroFretesCarinho(0);
          setIsLoading(false);
          return;
      }
      if(sucesso){
        setUuidCarinho(sucesso.uuid)
        setFretesCarinho(sucesso.fretes)
        setNumeroFretesCarinho(sucesso.fretes.length)
        carrinhoService.setNumeroItensInfosParaCompra(sucesso.fretes.length)
        
        setIsLoading(false);
        return
      }
  })
    carteiraService.obterAssinatura(authService.usuarioCorrente()?.identificadorAssinatura, (erro, sucesso) => {
      if(erro){
        console.log(erro)
        return
      }
      if(sucesso) {
        setAssinatura(sucesso);
        setSaldo(sucesso.saldo);
      }
    });

  }
  useEffect(() => {
    const tagueamentoService = new TagueamentoService();
    if(Object.keys(freteCompradoComSucesso).length !== 0){
      setConfirmacaoCompraPagamento(true)
      tagueamentoService.eventoMarketingConfirmacaoCompraPagamento(fretesCarinho, somaValorTotalFretes, uuidCarinho);
    }
    
  },[freteCompradoComSucesso, fretesCarinho, somaValorTotalFretes, uuidCarinho]);

  useEffect(() => {

    return () => {
      setAssinatura({})
    }
  }, []);

  const showFinalizarPagamento = () => {
    tagueamentoService.eventoMarketingCheckoutCarrinho(fretesCarinho);
    tagueamentoService.eventoMarketingFinalizarPagamento(fretesCarinho);
    setFinalizarPagamento(true)
    window.scrollTo(0, 0)
  }

  const voltar = () => {
    setFinalizarPagamento(false)
    carrinhoService.deleteCalculadoraFormInfo();
    window.scrollTo(0, 0)
  }

  const irDocumentacao = ()  => {
    history.push("/configuracoes/documentos")
  }

  let limiteFretes;
  if(assinatura){
    limiteFretes = assinatura.numeroFretes - assinatura.fretesAtivos
  }

  const removerDoCarrinho = (key) => {
    let fretes = fretesCarinho;
    fretes.splice(fretes.indexOf(key), 1);
    let finalArrayRequest = []
    if(fretes.length > 0){
      fretes.forEach((frete) =>{
        const fretes = {
          identificadorCotacao: frete.identificadoCotacao,
          numeroPedidoUsuario: [authService.usuarioCorrente()?.identificadorAssinatura],
          destinatario: frete.destinatario,
          remetente: frete.remetente,
          conteudo: frete.conteudo !== null ? frete.conteudo : null ,
          notaFiscal: frete.notaFiscal || null,
          agenciaEndereco: frete.agencia,
          agenciaTipo: frete.agenciaTipo || "",
    }
    finalArrayRequest.push(fretes)  
      })
    }

    const finalobjCarrinho = {fretes: finalArrayRequest}
    setIsLoading(true);
    carrinhoService.postCriarEditarCarrinho(finalobjCarrinho, (erro, sucesso)=>{
      if(erro){
        setErro({
          isWrong: true,
          mensagem: 'Erro ao remover frete, por favor tente mais tarde.'
        })
        setIsLoading(false)
        return
    }
    if(sucesso){
       carrinhoService.setCarrinhoInfosParaCompra(sucesso)
       carrinhoService.setNumeroItensInfosParaCompra(sucesso.fretes.length)
       setIsLoading(false)
    }})

    setFretesCarinho(fretes)
    setNumeroFretesCarinho(fretes.length)
    setIsLoading(false)
  }
  
  const editarItemCarrinho = (key) => {
    let frete = fretesCarinho[key];
    removerDoCarrinho(key);
    carrinhoService.setEditInfosParaCompra(frete);
    window.scrollTo(0, 0)
    history.push("/calculadora?action=editInfosComplementaresCarrinho&key=" + frete.uuid)
  }

  return (
    <div className="page-container-style">
      {isLoading? <FlexSpinner className="spinner"/>: ""}
      <CarrinhoDeComprasStyle>
        <h1 style={{marginTop: '50px', marginBottom: '50px', fontSize: '1.4em', fontWeight: '800'}}>Carrinho</h1>

        {
          numeroFretesCarinho !== 0 ?
          
          (
            <>
              <CarrinhoSteps finalizarPagamento={finalizarPagamento} confirmacaoCompraPagamento={confirmacaoCompraPagamento} />

                  {
                    finalizarPagamento?
                    (
                      <>
                      {
                        confirmacaoCompraPagamento?
                        (
                          <>
                            <CarrinhoConfirmacaoDeCompraFrete />
                          </>
                        ) :
                        (
                          <>
                          <CarrinhoPagamento etiqueta={carrinhoLocalStorage} somaValorTotalFretes={somaValorTotalFretes} saldo={saldo} assinatura={authService.usuarioCorrente()?.identificadorAssinatura} assinante={assinatura.assinante} uuidCarinho = {uuidCarinho}/>
                          </>
                        )
                      }
                      </>
                    ):
                    (
                      <>
                      <div className="section-carrinho-content">

                        <div className="section-half-carrinho">
                            <div className="carrinho-content">
                              <div className="carrinho-numero-itens">
                                  <p style={{marginRight: '10px'}}><strong>Carrinho de compras </strong></p>
                                  <p className="numero-tag"> 
                                  {numeroFretesCarinho}
                                  {
                                    numeroFretesCarinho > 1 ? ' itens' : ' item'
                                  }
                                  </p>
                              </div>
                            </div>
                  
                            <div className="carrinho-content">
                            <div className="carrinho-numero-itens">
                                <p style={{marginRight: '10px'}}><strong>Fretes que pode gerar </strong></p>
                                <p className="numero-tag">{limiteFretes}</p>
                            </div>
                            </div>
                        </div>
                        {
                          limiteFretes === 0 ?
                          (
                          <div className="notification is-warning" style={{marginTop: '20px'}}>
                              <p>Você não tem número de fretes suficiente para concluir a sua compra. Para aumentar o número de fretes, basta incluir nova documentação. Para verificar quais as documentações faltam para você, favor visite: <strong className="btn-doc" onClick={irDocumentacao}>Documentação</strong>.</p>
                          </div>
                          ) : null
                        }
                    </div>
                    </>
                    )
                  }
                {
                  !confirmacaoCompraPagamento ? 
                  (
                    <section className="etiqueta-section">
                    <h2 className="page-title">Etiqueta</h2>
                      <div style={{backgroundColor: '#fff', padding: '40px'}}>
                        {
                          // carrinhoLocalStorage.map((item, index) => {
                          fretesCarinho.map((item, index) => {  
                            return(
                              <ul key={index}>
                                <CarrinhoEtiqueta etiqueta={item} assinante={assinatura.assinante}
                                  removerDoCarrinho={removerDoCarrinho}
                                  editarItemCarrinho={editarItemCarrinho}
                                  erro = {erro}
                                  index={index}
                                  callbackModal ={callbackModal}
                                /> 
                              </ul>
                            )
                          })
                        }
                      </div>

                    <footer style={{marginTop: '30px'}}>
                      <section>
                          <p><strong>Valor total: </strong>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(somaValorTotalFretes)}</p>
                      </section>
                    </footer>
                    
                    <BtnSection>
                      {
                        finalizarPagamento === true ?
                        (
                          <button className="button add-carrinho" onClick={voltar}>Voltar</button>
                        ) :
                        (
                          <>
                            <button className="button add-carrinho" onClick={() => {
                                  history.push('/calculadora')
                                  window.scrollTo(0, 0)
                                  window.location.reload()
                            }}>Contratar novo frete</button>
                            <button className="button finalizar-pagamento" onClick={showFinalizarPagamento} disabled={limiteFretes === 0 ? true : false}>Finalizar pagamento</button>
                          </>
                        )
                      }
                    </BtnSection>
                </section>

                  ): ""
                }
                
            </>
          ) :
          (
            <div className="notification is-warning" style={{marginTop: '20px'}}>
                <p>Carrinho vazio</p>
            </div>
          )
        }

      </CarrinhoDeComprasStyle>
    </div>
  );
}

export default CarrinhoDeCompras;
