 import api from './Api';

export default class CarrinhoService{

    setCarrinhoInfosParaCompra(info){
        localStorage.setItem('carrinhoInfos', JSON.stringify(info))
    }

    getCarrinhoInfosParaCompra(){
        let carrinhoInfos = localStorage.getItem('carrinhoInfos');
        if(typeof carrinhoInfos === "string" ) {
            return JSON.parse(carrinhoInfos);
        } else {
            return null;
        }
    }

    setNumeroItensInfosParaCompra(info){
        localStorage.setItem('numeroCarrinho', JSON.stringify(info))
    }

    getNumeroItensParaCompra(){
        let carrinhoInfos = localStorage.getItem('numeroCarrinho');
        if(typeof carrinhoInfos === "string" ) {
            return JSON.parse(carrinhoInfos);
        } else {
            return null;
        }
    }

    setEditInfosParaCompra(info){
        localStorage.setItem('carrinhoEdit', JSON.stringify(info))
    }

    getEditInfosParaCompra(){
        let carrinhoInfos = localStorage.getItem('carrinhoEdit');
        if(typeof carrinhoInfos === "string" ) {
            return JSON.parse(carrinhoInfos);
        } else {
            return null;
        }
    }


    deleteCalculadoraFormInfo(){
        localStorage.setItem('carrinhoInfos', JSON.stringify([]))
        // localStorage.removeItem('carrinhoInfos');
    }

    postCriarEditarCarrinho(request, callback){
        api
        .post(this.host() + "/api/v1/carrinho", request)
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    getCarrinho(callback){
        api
        .get(this.host() + "/api/v1/carrinho")
        .then((response) => {
            callback(null, response.data)
        })
        .catch((erro) => {
            callback(erro, null)
        })
    }

    host(){
        return process.env.REACT_APP_HOST_API
    }
}
