import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import TagueamentoService from '../../services/TagueamentoService';
import { moneyMask, cepMask, handleDecimalPlaces } from "../../utils/Masks";
import { BtnResultFreteStyle, FreteCalcResultadoContainerStyle, FreteCalcResultadoStyle } from "./style";
/**
 * @typedef {Object} Transportadora
 * @property {string} uuid - O UUID da transportadora.
 * @property {string} nomeTransportadora - O nome da transportadora.
 * @property {string} urlLogo - A URL do logotipo da transportadora.
 * @property {boolean} agenciaObrigatoria - Indica se a agência é obrigatória.
 * @property {boolean} ativa - Indica se a transportadora está ativa.
 */

/**
 * @typedef {Object} PrazoEntrega
 * @property {number} tempoMinimo - O tempo mínimo de entrega.
 * @property {number} tempoMaximo - O tempo máximo de entrega.
 */

/**
 * @typedef {Object} Dimensoes
 * @property {number} alturaPacote - A altura do pacote.
 * @property {number} larguraPacote - A largura do pacote.
 * @property {number} comprimentoPacote - O comprimento do pacote.
 * @property {number} pesoPacote - O peso do pacote.
 * @property {number} pesoCubagem - O peso de cubagem do pacote.
 * @property {null|number} diametroPacote - O diâmetro do pacote (pode ser nulo).
 */

/**
 * @typedef {Object} Produto
 * @property {string} uuid - O UUID do produto.
 * @property {string} nomeProduto - O nome do produto.
 * @property {string} codigoProdutoTransportadora - O código do produto da transportadora.
 */


/**
 * @typedef {Object} ShowFreteResult
 * @property {string} uuid - O UUID do resultado do cálculo do frete.
 * @property {string|null} identificadorLoja - O identificador da loja (pode ser nulo).
 * @property {string|null} identificadorAssinatura - O identificador da assinatura (pode ser nulo).
 * @property {string} cepOrigem - O CEP de origem.
 * @property {string} cepDestino - O CEP de destino.
 * @property {string} formato - O formato do pacote.
 * @property {Transportadora} transportadora - A transportadora associada ao frete.
 * @property {PrazoEntrega} prazoDeEntrega - O prazo de entrega do frete.
 * @property {Dimensoes} dimensoes - As dimensões do pacote.
 * @property {Produto} produto - O produto relacionado ao frete.
 * @property {number} valorFrete - O valor do frete.
 * @property {number} valorEconomia - O valor de economia.
 * @property {number} valorSeguro - O valor do seguro.
 * @property {boolean} coleta - Indica se há coleta.
 * @property {number} valorEncomenda - O valor da encomenda.
 * @property {boolean} avisoRecebimento - Indica se há aviso de recebimento.
 * @property {boolean} logisticaReversa - Indica se há logística reversa.
 * @property {string} data - A data do resultado do cálculo do frete.
 * @property {boolean} maisBarato - Indica se é o mais barato.
 * @property {null|boolean} menorPrazo - Indica se é o menor prazo (pode ser nulo).
 * @property {number} markup - O markup.
 * @property {null|string} tipoTag - O tipo de tag (pode ser nulo).
 */


function FreteCalcResultado() {
    const history = useHistory();

	/**
 * Variável que armazena o resultado do cálculo do frete.
 *
 * @type {Array<ShowFreteResult>}
 */

    const showFreteResult = useSelector((state) => state.freteCalcResultLandingPage);


    const [freteResultObject, setFreteResultObject] = useState(showFreteResult);
    const [ordenacao] = useState();

    const getFraseDiasUteis = (numeroDias) => {
        const frase = numeroDias === 1 ? 'dia útil' : 'dias úteis'  
        return `${numeroDias} ${frase}`
    }

    useEffect(() => {
        if(showFreteResult){
            new TagueamentoService().eventoMarketingCotacao(showFreteResult[0]);
            setFreteResultObject(showFreteResult)
        }
        if(ordenacao === "MAIS_BARATO"){
            const freteMaisBarato = [...freteResultObject].sort((a, b) => {
                return parseFloat(a.valorFrete) - parseFloat(b.valorFrete)
            });
            setFreteResultObject(freteMaisBarato)
        }
        if(ordenacao === "MAIS_RAPIDO"){
            const freteMaisRapido = [...freteResultObject].sort((a, b) => {
                if(a.prazoDeEntrega.tempoMaximo === a.prazoDeEntrega.tempoMinimo){
                    return parseFloat(a.prazoDeEntrega.tempoMaximo) - parseFloat(b.prazoDeEntrega.tempoMaximo)
                }
                if(a.prazoDeEntrega.tempoMaximo !== a.prazoDeEntrega.tempoMinimo){
                    return parseFloat(a.prazoDeEntrega.tempoMaximo) - parseFloat(b.prazoDeEntrega.tempoMaximo)
                }
                return 0;
            });
            setFreteResultObject(freteMaisRapido)
        }
        if(ordenacao === "MAIS_BARATO_E_RAPIDO"){
            const freteMaisBaratoEMaisRapido = [...freteResultObject].sort((a, b) => {
            if (a.valorFrete !== b.valorFrete) {
				return parseFloat(a.valorFrete) - parseFloat(b.valorFrete);
            } else {
                return parseFloat(a.prazoDeEntrega.tempoMaximo) - parseFloat(b.prazoDeEntrega.tempoMaximo);
            }
            });
            setFreteResultObject(freteMaisBaratoEMaisRapido)
        }
    }, [ordenacao, showFreteResult, freteResultObject])

    const cadastrese = () => {
        history.push("/register");
    }

    const CustomSeparator = () => {
        return <span className="custom-separator">|</span>;
    };

    const { cepOrigem, cepDestino, valorEncomenda, dimensoes } = freteResultObject[0];

	return (

        <>
		<FreteCalcResultadoStyle id="resultado-cotacao">
			<FreteCalcResultadoContainerStyle>
			<h2 >Resultado da Cotação</h2>
            <div >
                <div className="conteudo-info">
                    <span >
                        Origem: {cepMask(cepOrigem)}
                        <CustomSeparator />
                        Destino: {cepMask(cepDestino)}
                        <CustomSeparator />
                        Valor da encomenda: {moneyMask(valorEncomenda.toFixed(2))}
                    </span>
                    <br></br>
                    <span>
                        {dimensoes.alturaPacote} cm x {dimensoes.larguraPacote} cm x {dimensoes.comprimentoPacote} cm
                        <CustomSeparator />
                        Peso: {handleDecimalPlaces(dimensoes.pesoPacote, 3)?.replace(".", ",")} kg
                    </span>
                </div>
                <div className="conteudo">
                <table className="table desktop-container">
                    <thead>
                        <tr>
                            <th>TRANSPORTADORA</th>
                            <th>MODALIDADE</th>
                            <th>PRAZO</th>
                            <th>VALOR</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        [...freteResultObject].map((item,i) => {
                            return (
                                <tr key={item.uuid}>
                                    <td className="img-calculadora transportadora-table-img">
                                        <img src={`${item.transportadora.urlLogo}`}
                                        alt={`${item.transportadora.nomeTransportadora} logo`}
                                        className="transportadora-logo"/>
                                    </td>
                                    <td>
                                        {item.produto.nomeProduto}
                                    </td>
                                        {
                                            item.prazoDeEntrega.tempoMinimo === item.prazoDeEntrega.tempoMaximo ?
                                            (
                                                <td>{getFraseDiasUteis(item.prazoDeEntrega.tempoMinimo)}</td>
                                            ) : (
                                                <td>{item.prazoDeEntrega.tempoMinimo} - {item.prazoDeEntrega.tempoMaximo} dias úteis</td>
                                            )
                                        }
                                    <td> 
                                        <strong className="is-size-3">{moneyMask(item.valorFrete.toFixed(2)) || "R$ 0,00"}</strong>
                                    </td>
                                </tr>
                            )

                        })
                    }
                    </tbody>


                </table>

                <table className="table mobile-container">
                    <thead>
                        <tr>
                            <th style={{textAlign: 'left',  fontSize: '0.8em'}} >TRANSPORTADORAS</th>
                            {/* <th>MODALIDADE</th> */}
                            {/* <th>PRAZO ESTIMADO</th> */}
                            <th style={{textAlign: 'left',  fontSize: '0.8em'}}>VALOR*</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        [...freteResultObject].map((item,i) => {
                            return(
                        <tr key={item.uuid + i}>
                            <td style={{textAlign: 'left'}}>
                                <div>
                                    <img src={`${item.transportadora.urlLogo}`}
                                         alt={`${item.transportadora.nomeTransportadora} logo`}
                                         className="transportadora-logo"
                                    />
                                </div>

                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <th style={{textAlign: 'left', paddingLeft: '0px', marginLeft: '0px', fontSize: '0.8em'}}>MODALIDADE</th>
                                    <td style={{textAlign: 'center', paddingLeft: '1em', marginLeft: '0px'}}>{item.produto.nomeProduto}</td>
                                </div>
                            </td>
                            <td style={{textAlign: 'left'}}>
							<div className="mt-6" > <div className="has-text-left">
							    <strong className="is-size-3">{moneyMask(item.valorFrete.toFixed(2)) || "R$ 0,00"}</strong>
                            </div>
                            {/*<div>*/}
                            {/*    <div>*/}
                            {/*            <small className="is-block has-text-left has-text-grey">*/}
                            {/*            Economize*/}
                            {/*            </small>*/}

                            {/*            <small className="is-block has-text-left has-text-grey">*/}
                            {/*            {moneyMask(*/}
                            {/*    item.valorEconomia < 0*/}
                            {/*      ? (0).toFixed(2)*/}
                            {/*      : item.valorEconomia.toFixed(2)*/}
                            {/*            )}*/}

                            {/*            </small>*/}

                            {/*    </div>*/}


							{/*</div>*/}
							</div>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <th style={{textAlign: 'left', paddingLeft: '0px', marginLeft: '0px', fontSize: '0.8em', marginTop: '2rem'}}>PRAZO ESTIMADO</th>
                                    <td style={{textAlign: 'left', paddingLeft: '0px', marginLeft: '0px'}}>

                                        {
                                        item.prazoDeEntrega.tempoMinimo === item.prazoDeEntrega.tempoMaximo ?
                                        (
                                            <td style={{textAlign: 'left', paddingLeft: '0px', marginLeft: '0px'}}>{item.prazoDeEntrega.tempoMinimo} dias úteis</td>
                                        ) :
                                        (
                                            <td style={{textAlign: 'left', paddingLeft: '0px', marginLeft: '0px'}}>{item.prazoDeEntrega.tempoMinimo} - {item.prazoDeEntrega.tempoMaximo} dias úteis</td>
                                        )
                                    }
                                    </td>
                                    <td style={{textAlign: 'left', paddingLeft: '0px', marginLeft: '0px', paddingBottom: '1em'}}>{item.maisBarato ? (<span className="destaque">+ BARATO</span>):""}{item.menorPrazo ? (<span className="destaque">+ RAPIDO</span>):""}</td>
                                </div>
                            </td>
                        </tr>
                            )

                        })
                    }
                    </tbody>


                </table>
                <BtnResultFreteStyle>
                    <button className="button is-rounded cadastrese" onClick={cadastrese}>Cadastre-se e pague</button>
                </BtnResultFreteStyle>
                </div>
            </div>
			</FreteCalcResultadoContainerStyle>
		</FreteCalcResultadoStyle>

        </>
	);

}


export default FreteCalcResultado;
