import React from 'react';
import {TratativasStyle} from './style';

function Tratativas() {

  return (
    <div className="page-container-style"> 
        <TratativasStyle >
        <h1 style={{fontSize: '1.5em', fontWeight: '800'}}>
        Tratativas
          <br />
          PÁGINA EM CONSTRUÇÃO!!!!!!
          </h1>
      </TratativasStyle>
    </div>
  );
}

export default Tratativas;
