import React, { useState } from 'react';
import DownloadService from '../../services/DownloadService';
import FreteService from '../../services/FreteService';
import { cepMask, cnpjMask, cpfMask, moneyMask, telefoneMask, notaFiscalMask } from '../../utils/Masks';
import Modal from '../FlexFreteModal/Modal';
import FlexSpinner from '../FlexFreteSpinner/FlexSpinner';
import { MeusEnviosFreteStyle } from './style';

const freteService = new FreteService();
const downloadService = new DownloadService();

export default function MeusEnviosFrete({
    frete,
    deleteFreteCallback, showBody,
    setShowBodyCallback,
    index,
    handleFreteChange,
    status
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [erro, setErro] = useState({
        isWrong: false,
        titulo: "",
        mensagem: "",
        voltar: false,
    });

    const gerarDeclaracao = () => {
        setIsLoading(true);
        freteService.gerarDeclaracaoConteudoPdf(frete.uuid, (err, sucess) => {
            setIsLoading(false);
            if (err) {
                console.error(err);
                setErro({
                    isWrong: true,
                    titulo: 'Erro ao baixar arquivo',
                    mensagem: 'Falha ao tentar realizar o download, entre em contato com o suporte ou tente novamente mais tarde.'
                })
            } else {
                downloadService.downloadFile({ label: "declaracao-de-conteudo", data: sucess, filename: frete.uuid + ".pdf", extension: "pdf" })
            }
        });
    }

    const cancelarFrete = (frete) => {
        deleteFreteCallback(frete, index);
    }

    const callbackModal = () => {
        if (erro.isWrong === true) {
            setErro({ isWrong: false })
        }
    }

    const handleCheckboxChange = (event) => {
        handleFreteChange(event.target.checked,frete);
    };

    return (
        <MeusEnviosFreteStyle>
            {isLoading ? <FlexSpinner /> : ""}
            {
                erro.isWrong === true ?
                    (
                        <Modal callbackModal={callbackModal} visivel={erro !== null} titulo={erro.titulo} conteudo={erro.mensagem} />
                    ) : null
            }

            <div className='is-flex is-align-items-center'>

                { (status === "GERADO" || status === "POSTADO" || status === "ENTREGUE" )  &&
                    <div className='mr-3'>
                    <label className="checkbox ffl-custom-checkbox is-flex is-align-items-start">
                        <input
                        type="checkbox"
                        checked={frete?.ischecked}
                        onChange={handleCheckboxChange}
                        />
                    </label>
                    </div>
                }


                {
                    frete.statusFrete === "AGUARDANDO_PAGAMENTO" ? (
                        <header className="meus-envios-frete-cabecalho-aguardando">
                            <div>
                                <span className="header-label">Transportadora: </span>
                                <strong>{frete?.transportadora?.nomeTransportadora}</strong>

                                <div className="show-frete-mobile" onClick={() => { setShowBodyCallback(index) }} >
                                    <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                                </div>
                            </div>

                            <div>
                                <span className="header-label">Destinatário: </span>
                                <strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
                            </div>

                            <div>
                                <span >Expira: </span>
                                <strong>{frete?.prazoExpiracao || ""}</strong>
                            </div>

                            <div className="delete-frete" onClick={e => { cancelarFrete(frete) }}>
                                <ion-icon name="warning-outline" class="icon-warning"></ion-icon>
                                <ion-icon name="trash-bin-outline" class="icon-delete"></ion-icon>

                            </div>

                            <div className="show-frete" onClick={e => { setShowBodyCallback(index) }} >
                                <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>

                            </div>
                        </header>
                    )
                        : frete.statusFrete === "PENDENTE" ? (
                            <header className="meus-envios-frete-cabecalho-aguardando">
                                <div>
                                    <span className="header-label">Transportadora: </span>
                                    <strong>{frete?.transportadora?.nomeTransportadora}</strong>

                                    <div className="show-frete-mobile" onClick={e => { setShowBodyCallback(index) }} >
                                        <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                                    </div>
                                </div>

                                <div>
                                    <span className="header-label">Destinatário: </span>
                                    <strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
                                </div>

                                <div className="delete-frete" onClick={e => { cancelarFrete(frete) }}>
                                    <ion-icon name="warning-outline" class="icon-warning"></ion-icon>
                                    <ion-icon name="trash-bin-outline" class="icon-delete"></ion-icon>

                                </div>

                                <div className="show-frete" onClick={e => { setShowBodyCallback(index) }} >
                                    <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>

                                </div>
                            </header>
                        )
                            : frete.statusFrete === "CARRINHO" || frete.statusFrete === "CARRINHO_EXPIRADO" ? (
                                <header className="meus-envios-frete-cabecalho-aguardando">
                                    <div>
                                        <span className="header-label">Transportadora: </span>
                                        <strong>{frete?.transportadora?.nomeTransportadora}</strong>

                                        <div className="show-frete-mobile" onClick={e => { setShowBodyCallback(index) }} >
                                            <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                                        </div>
                                    </div>

                                    <div>
                                        <span className="header-label">Destinatário: </span>
                                        <strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
                                    </div>
                                    <div>
                                        <span className="header-label">Status Frete:</span>
                                        <strong>{frete?.statusFrete === "CARRINHO" ? "Frete no carrinho" : "Frete não contratado"}</strong>
                                    </div>

                                    <div className="show-frete-desktop" onClick={e => { setShowBodyCallback(index) }} >
                                        <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>

                                    </div>
                                </header>
                            )
                                : frete.statusFrete === "POSTADO" ? (
                                    <header className="meus-envios-frete-cabecalho-postado">
                                        <div>
                                            <span className="header-label">Transportadora: </span>
                                            <strong>
                                                {frete?.transportadora?.nomeTransportadora}
                                            </strong>

                                            <div className="show-frete-mobile" onClick={e => { setShowBodyCallback(index) }} >
                                                <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                                            </div>
                                        </div>


                                        <div>
                                            <span className="header-label">Destinatário: </span>
                                            <strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
                                        </div>

                                        <div>
                                            <span className="header-label">Cód.: </span>
                                            <strong>{frete?.codigoEncomenda}</strong>
                                        </div>

                                        <div className="show-frete-desktop" onClick={e => { setShowBodyCallback(index) }} >
                                            <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>

                                        </div>
                                    </header>
                                ) : frete.statusFrete === "ENTREGUE" ? (
                                    <header className="meus-envios-frete-cabecalho-postado">
                                        <div>
                                            <span className="header-label">Transportadora: </span>
                                            <strong>
                                                {frete?.transportadora?.nomeTransportadora}
                                            </strong>

                                            <div className="show-frete-mobile" onClick={e => { setShowBodyCallback(index) }} >
                                                <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                                            </div>
                                        </div>


                                        <div>
                                            <span className="header-label">Destinatário: </span>
                                            <strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
                                        </div>

                                        <div>
                                            <span className="header-label">Cód.: </span>
                                            <strong>{frete?.codigoEncomenda}</strong>
                                        </div>

                                        <div className="show-frete-desktop" onClick={e => { setShowBodyCallback(index) }} >
                                            <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>

                                        </div>
                                    </header>
                                ) : frete.statusFrete === "GERADO" ? (
                                    <header className="meus-envios-frete-cabecalho-postado">
                                        <div>
                                            <span className="header-label">Transportadora: </span>
                                            <strong>
                                                {frete?.transportadora?.nomeTransportadora}
                                            </strong>

                                            <div className="show-frete-mobile" onClick={e => { setShowBodyCallback(index) }} >
                                                <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                                            </div>
                                        </div>


                                        <div>
                                            <span className="header-label">Destinatário: </span>
                                            <strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
                                        </div>

                                        <div>
                                            <span className="header-label">Cód.: </span>
                                            <strong>{frete?.codigoEncomenda}</strong>
                                        </div>
                                        <div>
                                            <span className="header-label">Expira: </span>
                                            <strong className="expiracao-postado">{frete?.prazoExpiracao || ""}</strong>
                                            <ion-icon name="warning-outline" class="icon-danger"></ion-icon>
                                            <div className="delete-frete" onClick={e => { cancelarFrete(frete) }}>
                                                <ion-icon name="trash-bin-outline" class="icon-delete"></ion-icon>
                                            </div>
                                        </div>


                                        <div className="show-frete-desktop" onClick={e => { setShowBodyCallback(index) }} >
                                            <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>

                                        </div>

                                    </header>
                                ) : frete.statusFrete === "EXPIRADO" ? (
                                    <header className="meus-envios-frete-cabecalho-postado">
                                        <div>
                                            <span className="header-label">Transportadora: </span>
                                            <strong>
                                                {frete?.transportadora?.nomeTransportadora}
                                            </strong>

                                            <div className="show-frete-mobile" onClick={e => { setShowBodyCallback(index) }} >
                                                <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                                            </div>
                                        </div>


                                        <div>
                                            <span className="header-label">Destinatário: </span>
                                            <strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
                                        </div>

                                        <div>
                                            <span className="header-label">Cód.: </span>
                                            <strong>{frete?.codigoEncomenda}</strong>
                                        </div>
                                        <div>
                                            <span className="header-label">Expirado em: </span>
                                            <strong className="expiracao-postado">{frete?.prazoExpiracao || ""}</strong>
                                            <ion-icon name="warning-outline" class="icon-warning"></ion-icon>

                                        </div>

                                        <div className="show-frete-desktop" onClick={e => { setShowBodyCallback(index) }} >
                                            <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>

                                        </div>
                                    </header>
                                ) : frete.statusFrete === "CANCELADO" ? (
                                    <header className="meus-envios-frete-cabecalho-postado">
                                        <div>
                                            <span className="header-label">Transportadora: </span>
                                            <strong>
                                                {frete?.transportadora?.nomeTransportadora}
                                            </strong>

                                            <div className="show-frete-mobile" onClick={e => { setShowBodyCallback(index) }} >
                                                <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>
                                            </div>
                                        </div>


                                        <div>
                                            <span className="header-label">Destinatário: </span>
                                            <strong>{frete?.destinatario?.nome} {frete?.destinatario?.sobrenome || ""}</strong>
                                        </div>

                                        <div>
                                            <span className="header-label">Cód.: </span>
                                            <strong>{frete?.codigoEncomenda}</strong>
                                        </div>
                                        <div>
                                            <span className="header-label">Cancelado em: </span>
                                            <strong className="expiracao-postado">{frete?.dataCancelamento || ""}</strong>
                                            <ion-icon name="warning-outline" class="icon-warning"></ion-icon>

                                        </div>

                                        <div className="show-frete-desktop" onClick={e => { setShowBodyCallback(index) }} >
                                            <ion-icon name={showBody ? "chevron-up-outline" : "chevron-down-outline"}> </ion-icon>

                                        </div>
                                    </header>
                                ) : ""
                }
            </div>
            {showBody ? (
                <div className="meus-envios-frete-container">

                    <dir className="meus-envios-frete-section">
                        <div className="meus-envios-frete-container-area">
                            <div className="container-area-line">
                                {
                                    frete.statusFrete === "CANCELADO" ?
                                        (
                                            <>

                                                <strong className="line-label">Data de cancelamento:</strong>
                                                <span>{frete?.dataCancelamento || ""}</span>

                                            </>

                                        ) : ""

                                }
                            </div>
                            <div className="container-area-line">
                                {
                                    frete.statusFrete === "CANCELADO" ?
                                        (
                                            <>
                                                <strong className="line-label">Justificativa cancelamento:</strong>
                                                <span>{frete?.justificativaCancelamento || ""}</span>

                                            </>

                                        ) : ""

                                }
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Cod. Rastreio:</strong>
                                <span>{frete?.codigoEncomenda || ""}</span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Valor calculado:</strong>
                                <span>{moneyMask(frete?.valorEncomenda.toFixed(2)) || ""}</span>
                            </div>
                            {
                                frete?.valorRastreadoTransportadora != null ?
                                    (
                                        <div className="container-area-line">
                                            <strong className="line-label">Valor cobrado na postagem:</strong>
                                            <span>{moneyMask(frete?.valorRastreadoTransportadora.toFixed(2)) || ""}</span>
                                        </div>
                                    ) : ""
                            }
                            <div className="container-area-line">
                                <strong className="line-label">Prazo de entrega:</strong>
                                <span>{frete.tempoMinimo || ""} - {frete.tempoMaximo || ""} dias úteis </span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Valor Segurado:</strong>
                                <span>{moneyMask(frete?.valorSeguro.toFixed(2)) || ""}</span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Tipo de pacote</strong>
                                <span>{frete?.formato || ""}</span>
                            </div>

                        </div>
                        <div className="meus-envios-frete-container-area">

                            <div className="container-area-line">
                                {
                                    frete?.enderecoAgencia ?
                                        (
                                            <>
                                                <strong className="line-label">Agência:</strong>
                                                <span>{frete?.enderecoAgencia || ""}</span>
                                            </>
                                        ) : ""

                                }
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">Coleta:</strong>
                                <span>{frete?.cotacao?.coleta ? "Sim" : "Não"}</span>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">Aviso de recebimento:</strong>
                                <span>{frete?.cotacao?.avisoRecebimento ? "Sim" : "Não"}</span>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">Data:</strong>
                                <span>{frete?.data || ""}</span>
                            </div>

                        </div>


                    </dir>


                    <div className="meus-envios-frete-container">

                        <div className="meus-envios-frete-section">

                            <div className="meus-envios-frete-container-area-pessoa">
                                <div className="pessoa-titulo">
                                    <h4>Remetente</h4>
                                </div>
                                <div className="container-area-line">
                                    <strong>{frete?.remetente?.nome || ""} {frete?.remetente?.sobrenome || ""}</strong>
                                </div>

                                <div className="container-area-line">
                                    <strong className="line-label">CPF / CNPJ:</strong>
                                    <span>{frete?.remetente?.cpfCnpj.length === 11 ? cpfMask(frete?.remetente?.cpfCnpj) : cnpjMask(frete?.remetente?.cpfCnpj)}</span>
                                </div>

                                <div className="container-area-line">
                                    <strong className="line-label">Tel:</strong>
                                    <span>{frete?.remetente?.telefone ? telefoneMask(frete.remetente.telefone) : ""}</span>
                                </div>

                                <div className="container-area-line">
                                    <strong className="line-label">Email:</strong>
                                    <span>{frete?.remetente?.email}</span>
                                </div>

                                <div className="container-area-line">
                                    <strong className="line-label">Rua:</strong>
                                    <span>
                                        {frete?.remetente?.endereco?.logradouro}, {frete?.remetente?.endereco?.numero}
                                        {
                                            frete?.remetente?.endereco?.complemento ? "," : ""
                                        }
                                        {frete?.remetente?.endereco?.complemento}
                                    </span>
                                </div>
                                <div className="container-area-line">
                                    <strong className="line-label">Bairro:</strong>
                                    <span>{frete?.remetente?.endereco?.bairro}</span>
                                </div>
                                <div className="container-area-line">
                                    <strong className="line-label">Cidade/Estado:</strong>
                                    <span>{frete?.remetente?.endereco?.cidade} - {frete?.remetente?.endereco?.estado}</span>
                                </div>
                                <div className="container-area-line">
                                    <strong className="line-label">CEP:</strong>
                                    <span>{frete?.remetente?.endereco?.cep ? cepMask(frete?.remetente?.endereco?.cep) : ""}</span>
                                </div>
                            </div>

                            <div className="meus-envios-frete-container-area-pessoa">
                                <div className="pessoa-titulo">
                                    <h4>Destinatário</h4>
                                </div>
                                <div className="container-area-line">
                                    <strong>{frete?.destinatario?.nome || ""} {frete?.destinatario?.sobrenome || ""}</strong>
                                </div>

                                <div className="container-area-line">
                                    <strong className="line-label">CPF / CNPJ:</strong>
                                    <span className="line value">{frete?.destinatario?.cpfCnpj.length === 11 ? cpfMask(frete?.destinatario?.cpfCnpj) : cnpjMask(frete?.destinatario?.cpfCnpj)}</span>
                                </div>

                                <div className="container-area-line">
                                    <strong className="line-label">Tel:</strong>
                                    <span>{frete?.destinatario?.telefone ? telefoneMask(frete?.destinatario?.telefone) : ""}</span>
                                </div>
                                <div className="container-area-line">
                                    <strong className="line-label">Email:</strong>
                                    <span>{frete?.destinatario?.email}</span>
                                </div>

                                <div className="container-area-line">
                                    <strong className="line-label">Rua:</strong>
                                    <span>
                                        {frete?.destinatario?.endereco?.logradouro}, {frete?.destinatario?.endereco?.numero}
                                        {
                                            frete?.destinatario?.endereco?.complemento ? "," : ""
                                        }
                                        {frete?.destinatario?.endereco?.complemento}
                                    </span>
                                </div>
                                <div className="container-area-line">
                                    <strong className="line-label">Bairro:</strong>
                                    <span>{frete?.destinatario?.endereco?.bairro}</span>
                                </div>
                                <div className="container-area-line">
                                    <strong className="line-label">Cidade/Estado:</strong>
                                    <span>{frete?.destinatario?.endereco?.cidade} - {frete?.destinatario?.endereco?.estado}</span>
                                </div>
                                <div className="container-area-line">
                                    <strong className="line-label">CEP:</strong>
                                    <span>{frete?.destinatario?.endereco?.cep ? cepMask(frete?.destinatario?.endereco?.cep) : ""}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    {
                        frete.notaFiscal ?
                            <div className="container-nota-fiscal">
                                <div className="nota-fiscal-titulo">
                                    <h4>Nota Fiscal</h4>
                                </div>
                                <div className='nota-fiscal'>
                                    <div className="container-area-line">
                                        <strong className="line-label">Valor da nota fiscal:</strong>
                                        <span>{moneyMask(frete.notaFiscal.valorNotaFiscal.toFixed(2))}</span>
                                    </div>
                                    <div className="container-area-line">
                                        <strong className="line-label">N&uacute;mero da nota fiscal:</strong>
                                        <span>{notaFiscalMask(frete.notaFiscal.numeroDanfCte)}</span>
                                    </div>
                                    <div className="container-area-line">
                                        <strong className="line-label">CFOP:</strong>
                                        <span>{notaFiscalMask(frete.notaFiscal.cfop)}</span>
                                    </div>

                                </div>
                            </div>
                            : null
                    }


                    <div className="container-volumes-etiqueta">
                        <div className="volumes">
                            <strong>Volume</strong>
                            <div>
                                <p>C {frete?.dimensoes?.comprimentoPacote}cm L {frete?.dimensoes?.larguraPacote}cm A {frete?.dimensoes?.alturaPacote}cm C</p>
                                <p>Peso {frete?.dimensoes?.pesoPacote}Kg</p>
                            </div>
                        </div>
                        {
                            frete.statusFrete !== "AGUARDANDO_PAGAMENTO" && frete.statusFrete !== "EXPIRADO" && frete.statusFrete !== "CANCELADO" && frete.statusFrete !== "PENDENTE" && frete.statusFrete !== "CARRINHO" && frete.statusFrete !== "CARRINHO_EXPIRADO" ? (
                                <div className="etiqueta">
                                    {/*  <input type="button" className="button is-primary" value="Gerar etiqueta" onClick={e => { gerarEtiqueta()}}/> */}
                                    {frete.conteudo ? <input type="button" className="button is-link declaracao" value="Download Declaração de conteúdo" onClick={e => { gerarDeclaracao() }} /> : null}
                                </div>

                            ) : ""

                        }

                    </div>
                    <div className="rastreio-frete-container-area">
                        {
                            frete.ocorrencias != null && frete.ocorrencias.length > 0 ?
                                (
                                    <div className="pessoa-titulo">
                                        <h4>Acompanhamento do frete</h4>
                                    </div>

                                )
                                : ""
                        }

                        {
                            frete.ocorrencias != null && frete.ocorrencias.length > 0 ?
                                frete.ocorrencias.map((ocorrencia, index) =>
                                (
                                    <div className="meus-envios-frete-section">
                                        <div className="meus-envios-frete-container-area">
                                            <div className="container-area-line">
                                                <strong className="line-label">Status da transportadora:</strong>
                                                <span>{ocorrencia?.statusFreteTransportadora.trim()}</span>
                                            </div>
                                        </div>
                                        <div className="meus-envios-frete-container-area">
                                            <div className="container-area-line">
                                                <strong className="line-label">Agência:</strong>
                                                <span>{ocorrencia?.agencia} - {ocorrencia?.dataOcorrencia}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                                ) : ""
                        }


                    </div>


                </div>
            ) : ""}


        </MeusEnviosFreteStyle>
    )
}
