import { Types } from '../_constants/Types';

export const ActionCreators = {

    register: (user) => ({ type: Types.REGISTER_SUCCESS, payload: { user } }),
    login: (user) => ({ type: Types.LOGIN_SUCCESS, payload: { user } }),

    registerCloseMessage: (closeMessage) => ({type: Types.LOGIN_SUCCESS_CLOSE_MESSAGE, payload: { closeMessage }}),

    addLoja: (loja) => ({ type: Types.LOJA_SUCCESS, payload : { loja } }),
    editLoja: (loja) => ({ type: Types.LOJA_EDIT, payload : { loja } }),



    showModal: (modal) => ({ type: Types.SHOW_MODAL, payload: { modal } }),

    hideModal: (modal) => ({ type: Types.SHOW_MODAL, payload: { modal } }),

    addPerfilSelecionado: (perfil) => ({ type: Types.PERFIL_SELECINADO_ADD, payload: { perfil } }),
    getPerfilSelecionado: (perfil) => ({ type: Types.PERFIL_SELECINADO_SHOW, payload: { perfil } }),

    addAssinaturaSelecionada: (assinatura) => ({ type: Types.ASSINATURA_SELECIONADA_ADD, payload: { assinatura } }),
    getAssinaturaSelecionada: (assinatura) => ({ type: Types.ASSINATURA_SELECIONADA_SHOW, payload: { assinatura } }),

    isHeaderSubMenuClicked: (headerClicked) => ({type: Types.HEADER_MENU_CLICKED, payload: { headerClicked } }),

    freteCalcResultLandingPage : (freteCalcResultLandingPage) => ({type: Types.SHOW_COTACAO_FRETE_LANDING_PAGE, payload: { freteCalcResultLandingPage }}),
    backToCalculadoraLandingPage: (backToCalculadoraLandingPage) => ({type: Types.BACK_TO_CALCULADORA_FRETE_LANDING_PAGE, payload: {backToCalculadoraLandingPage}}),

    submitCalculoFrete: (calculadoraFormInfo) => ({ type: Types.SUBMIT_CALCULADORA_FORM, payload: {calculadoraFormInfo}} ),

    setCarrinhoInformacoes: (carrinhoInformacoes) => ({type: Types.SET_CARRINHO_INFORMACOES, payload: {carrinhoInformacoes}}),

    setFreteCompradoComSucesso: (freteCompradoComSucesso) => ({type: Types.SET_FRETE_COMPRADO_COM_SUCESSO, payload: {freteCompradoComSucesso}}),

    setFreteCotacoesCarrinho: (freteCotacoesCarrinho) => ({type: Types.SET_FRETE_COTACOES_CARRINHO, payload: {freteCotacoesCarrinho}}),

    setFreteCotacaoCarrinhoSelecionada: (freteCotacaoCarrinhoSelecionada) => ({type: Types.SET_FRETE_COTACAO_CARRINHO_SELECIONADA, payload: {freteCotacaoCarrinhoSelecionada}}),

    setFreteCotacoes: (freteCotacoes) => ({type: Types.SET_FRETE_COTACOES, payload: {freteCotacoes}}),

    setFreteCotacaoSelecionada: (freteCotacaoSelecionada) => ({type: Types.SET_FRETE_COTACAO_SELECIONADA, payload: {freteCotacaoSelecionada}}),

    setLoja: (loja) => ({type: Types.SET_LOJA, payload: {loja}}),

    addCalculadoraDadoCotacao: (dadosCalculadora) => ({type: Types.ADD_CALCULADORA_DADO_COTACAO, payload: {dadosCalculadora}}),

    addToCotacoesList: (dadosCalculadora, infosComplementares) => ({type: Types.ADD_TO_COTACOES_LIST, payload: {dadosCalculadora, infosComplementares}}),

    changeEndereco: (endereco) => ({type: Types.ENDERECO_EDITADO, payload: {endereco}}),

    setUsarDadosDoUsuario: (usarDadosDoUsuario) => ({type: Types.SET_USAR_DADOS_DO_USUARIO, payload: {usarDadosDoUsuario}}),
}
