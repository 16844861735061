import styled from "styled-components";

export const NotificacoesStyle = styled.div`
    display: flex;
    padding: 3%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  
    @media (max-width: 475px){
        margin-left: 3em;
    }
    
    @media(min-width: 1280px){
        position: relative;
        left: 20%;
        width: 80%;
    }
    @media( min-width: 1700px){
        padding-right: 10%;
    }

`
export const NotificacoesContainerStyle = styled.div`
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    max-width: 900px;
    
    @media(min-width: 1280px){
        display: flex;
        align-items: self-start;
        padding: 40px;
        width: 90%;
    }
    

`;