import styled from "styled-components";

export const ModalSpinner = styled.div`
    display: block;
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 41;
	background-color: rgb(0 0 0 / 48%);

	@media(min-width: 1000px){
		top: 0;
		left: 0;
	}
`

export const ModalSpinnerContainerStyle = styled.section`
	position: relative;
	top: 50%;
  	left: 50%;
  	transform: translate(-50%, -50%);
	width: 50%;

	a {
		color: #e6b222;
		font-weight: 600;
		letter-spacing: 0.2px;
		text-align: center;
	}

	p{
		text-align: center;
		font-size: 2.0rem;
		padding-bottom: 30px;
	}


@media (max-width: 430px) {
	width: 90%;
}


`;