export default class ValidaSenha{

    senha(senha){
       let retorno = false;
       let letrasMaiusculas = /[A-Z]/;
       let letrasMinusculas = /[a-z]/; 
       let numeros = /[0-9]/;
       let caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

       if(senha.length < 8) {
           return retorno;
       }

       let auxMaiuscula = 0;
       let auxMinuscula = 0;
       let auxNumero = 0;
       let auxEspecial = 0;

       for(let i = 0; i < senha.length; i++) {
           if(letrasMaiusculas.test(senha[i])){
               auxMaiuscula++;
           } else if (letrasMinusculas.test(senha[i])) {
               auxMinuscula++;
           } else if (numeros.test(senha[i])) {
               auxNumero++;
           } else if (caracteresEspeciais.test(senha[i])) {
               auxEspecial++;
           }

           if(auxMaiuscula > 0 && auxMinuscula > 0 && auxNumero > 0 && auxEspecial){
            retorno = true;
           }
       }
       return retorno;
    }
}