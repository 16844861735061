import styled from "styled-components";

export const NotificacaoListStyle = styled.ul`
    list-style: none;
    padding: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    @media (min-width: 1280px){
        width: 100%;
        padding: 0;
    }
`;

export const NotificacaoStyle = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    .notification-date{
        white-space: nowrap;
    }
    .header{
        display: flex;
        margin-bottom: 10px;
        flex-direction: column;
    }
    .arrow-icon{
        margin-right: 20px;
        cursor: pointer;
    }
    .assunto, .conteudo{
        margin-left: 35px;
        text-align: left;
    }
    .conteudo p{
        line-height: 30px;
    }
    .conteudo{
        margin-bottom: 14px;
    }
    .eye-icon{
        font-size: 2.3rem;
        cursor: pointer;
    }
    .assunto{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        >div{
            margin-bottom: 10px;
        }
    }
    margin-bottom: 30px;
    border-bottom: solid 2px #ddd;
    .left-header{
        display: flex;
        margin-bottom: 14px;
    } 

    @media(min-width: 1280px){
        .assunto{
            flex-direction: row;
            margin: 0;
        }
        .header{
            flex-direction: row;
            margin: 0;
        }
        .eye-icon{
            margin-left: 20px;
        }
        .left-header{
            margin-right: 35px;
        }
    }

`