import styled from "styled-components";

export const FooterStyle = styled.footer`
    position: absolute;
    left: 0;
    right: 0;
    background: #340040;
    padding: 2em 9em 2em 9em;

    a{
        margin: 0px;
        padding: 0px;
    }

    a{
        color: white;
        text-decoration: none;
    }

    ion-icon {
        color: white;
    }

    ul{
        color: white;
        font-weight: 700;
        font-size: 20px;
        line-height: 24.9px;
    }

    p{
        color: white;
    }

    .informacoes{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2em;

        .logoFlexFrete{
            margin-left: 2em;
            margin-right: 9em;
            img{
                transform: scale(1.2);
            }
        }
    }

    .flex{
        display: flex;
        align-items: center;
        img{
            margin-left: 30px;
        }
    }

    hr{
        border: none;
        height: 0.2px;
        background-color: #EBB013;
    }
    .version-media{
        padding-top: 2em;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .version{
        flex-grow: 1;
    }

    .redes-sociais{
        display: flex;

        li{
            margin-left: 20px;
        }
    }

    .suporte-termos{
        a{
            margin-right: 20px;
        }
    }

    .termos-button{
        border: none;
        background: none;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 1.1em;
        line-height: 162%;
        color: #FFFFFF;
        cursor: pointer;
    }

    @media screen and (min-width: 2560px) {
			.mobile-container{
			display: none;
		}
		}
		
	@media screen and (max-width: 1920px) {
			.mobile-container{
			display: none;
		}
	}

	@media screen and (max-width: 1440px) {
        padding: 1em 7em 1em 7em;
        .informacoes{
            .logoFlexFrete{
                margin-left: 2em;
                margin-right: 2em;
                /* img{
                    transform: scale(1.2);
                } */
            }
        }

		.mobile-container{
			display: none;
		}
	}

	@media screen and (max-width: 700px) {
            padding: 1em;

            ul{
                color: white;
                font-weight: 400;
                font-size: 15px;
                line-height: 24.9px;
                padding-top: 1em;
                margin-bottom: 0px;

                .image{
                    transform: scale(0.8);
                }
            }

            .flex{
                padding-bottom: 1em;

                img{
                    margin-left: 5px;
                }

                .img-first{
                    margin-right: 10em;
                }
            }

            .informacoes{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2em;

                .logoFlexFrete{
                    padding-top: 1em;
                    padding-bottom: 2em;
                    margin: 0 auto;
                    img{
                        transform: scale(1.2);
                    }
                }
            }

            hr{
                border: none;
                height: 1px;
                background-color: #EBB013;
            }

            .version-media{
                flex-direction: column;
                padding-top: 1em;
            }

            .suporte-termos {
                a {
                    margin-right: 100px;
                    font-weight: 700;
                }

            }

            .termos-button{
                font-weight: 700;
            }
			.mobile-container{
				display: flex;

                .version{
                    margin-bottom: 1em;
                }

                .redes-sociais{
                    margin-top: 1em;
                    margin-bottom: 1em;
                }
			}
			.desktop-container{
				display: none;
			}
	}

`;

export const FooterLogadoStyle = styled.footer`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #9E43AE;
    padding: 0em 9em 0em 25em;

    a{
        margin: 0px;
        padding: 0px;
    }

    a{
        color: white;
        text-decoration: none;
    }

    ion-icon {
        color: white;
    }

    ul{
        color: white;
        font-weight: 700;
        font-size: 20px;
        line-height: 24.9px;
    }

    p{
        color: white;
    }

    .informacoes{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2em;

        .logoFlexFrete{
            margin-left: 2em;
            margin-right: 9em;
            img{
                transform: scale(1.2);
            }
        }
    }

    .flex{
        display: flex;
        align-items: center;
        img{
            margin-left: 30px;
        }
    }

    hr{
        border: none;
        height: 0.2px;
        background-color: #EBB013;
    }
    .version-media{
        padding-top: 1em;
        padding-bottom: 1em;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .version{
        flex-grow: 1;
    }

    .redes-sociais{
        display: flex;

        li{
            margin-left: 20px;
        }
    }

    .suporte-termos{
        a{
            margin-right: 20px;
        }
    }

    .termos-button{
        border: none;
        background: none;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 1.1em;
        line-height: 162%;
        color: #FFFFFF;
        cursor: pointer;
    }

    @media screen and (min-width: 2560px) {
			.mobile-container{
			display: none;
		}
		}
		
	@media screen and (max-width: 1920px) {
			.mobile-container{
			display: none;
		}
	}

	@media screen and (max-width: 1440px) {
        padding: 0em 9em 0em 24em;
        .informacoes{
            .logoFlexFrete{
                margin-left: 2em;
                margin-right: 2em;
                /* img{
                    transform: scale(1.2);
                } */
            }
        }
		.mobile-container{
			display: none;
		}
	}

    @media screen and (max-width: 700px) {
            padding: 1em 1em 1em 6em;
            
            .version-media{
                flex-direction: column;
                padding-top: 1em;
                margin-bottom: 0px;
            }

            .suporte-termos {
                /* padding-bottom: 1em; */
                a {
                    margin-right: 100px;
                    font-weight: 700;
                }

            }

            .termos-button{
                font-weight: 700;
            }
			.mobile-container{
				display: flex;

                .version{
                    margin-bottom: 0px;
                }

                .redes-sociais{
                    margin-top: 1em;
                    margin-bottom: 1em;
                }
			}
			.desktop-container{
				display: none;
			}
	}

`;

export const WhatsAppStyle = styled.div`
  width: 64px;
  height: 64px;
  position: fixed;
  bottom: 140px;
  right: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  overflow: hidden;
  background-color: #25D366;
  z-index: 1000;

  iframe {
    margin: auto;
    background-color: transparent;
    width: 115%;
    height: 100%;
    transform: scale(1.3);
    border: 0; 
    border-radius: 10%;
    .message-us-button_logo{
      margin: auto;
    }
  }
`;
