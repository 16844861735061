import styled  from "styled-components";

export const LimitesFretesStyle = styled.div`
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 40px;
    .limites-fretes-title{
        color: #282828;
        font-size: 1.5em;
        font-weight: 600;
        letter-spacing: 0.2px;
    }
 
    .carteira-home{
        margin: 0;
    }
    
    header{
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
    }
    .limites-fretes-content{
        width: 100%;
        text-align: left;
        .aumentar-limite{
            width: 100%;
            text-align: center;
            margin: 25px 0;
            .button{
                color: rgba(136,48,152,1);
                width: 200px;
                font-weight: 800;
            }
            .button:hover{
                color: rgba(136,48,152,0.7);
            }
        }
        .limites-fretes-info{
            margin-bottom: 10px
        }
    }
  
`;

export const LimitesFreteProgrecao = styled.div`
    width: 100%;
    font-weight: bold;
    border-radius: 15px;
    background-color: #EBEBEB;
    height: 30px;
    .fretes-porcentagem-limite{
        ${props =>{   
            if(!props.andamento){
                return 'background-color: none;';
            }
            const percentagem = props.andamento / (props.total / 100);
            return `background-color: rgba(227,175,31,1);
                    width: ${percentagem}%;`;
        }}
        border-radius: 15px;
        height: 30px;
        padding: 4px 0 0 10px;
        span{
            height: 100%;
        }
    }

`