import styled from "styled-components";

export const LoginStyle = styled.section`
	padding-top: 8em;
	padding-bottom: 8em;
	/* margin-top: 2em; */

	@media(max-width: 1280px){
		padding-top: 2em;
		padding-bottom: 2em;
	}

	h1 {
		color: #282828;
		font-size: 1.8em;
	}

	form {
		width: 100%;
	}

	a {
		color: #e6b222;
		font-weight: 600;
		letter-spacing: 0.2px;
		text-align: center;
	}

    .cadastrar-container > h4{
        color: #000;
        font-weight: 600;
        letter-spacing: 0.2px;
    }

    .cadastrar{
        text-decoration: none;
        margin-top: 10px;
        color: white;
        border: none;
        font-weight: 600;
        letter-spacing: 0.3px;
        background: rgb(194,144,3);
        background: linear-gradient(266deg, rgba(194,144,3,1) 35%, rgba(227,175,31,1) 100%);
    }

	.cadastrar:hover{
		background: rgba(194, 144, 3, 0.7);
	}

	@media (max-width: 2560px) {
		margin-left: 35%;
		margin-right: 35%;

		.cadastrar-container{
			padding-top: 2em;
    	}
	}

	@media (max-width: 1440px) {
		margin-left: 25%;
		margin-right: 25%;

		.cadastrar-container{
			padding-bottom: 8em;
    	}
		
	}

	@media (max-width: 1024px) {
		margin-left: 15%;
		margin-right: 15%;

		.cadastrar-container{
			padding-bottom: 0;
    	}
	}

	@media (max-width: 770px) {
		margin-left: 15%;
		margin-right: 15%;
		padding-bottom: 50%;

		position: relative;
		z-index: 1;

	.cadastrar-container{
		padding-top: 2em;
		padding-bottom: 0;
    }
	}

	@media (max-width: 425px) {
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0;
	width: 100vw;
	padding-bottom: 0;

	.esqueciSenha{
		margin-top: 1em;
	}
	.esqueciSenha > small{
		
		font-size: 1em;
	}

	.cadastrar-container{
		padding-top: 5em;
		padding-bottom: 5em;
		background: rgb(136,48,152);
    	background: linear-gradient(132deg,rgba(136,48,152,1) 39%,rgba(163,71,179,1) 100%)
    }
		margin-bottom: 0;
    .cadastrar-container > h4{
		font-size: 1.1em;
        color: white;
        font-weight: 600;
		margin-bottom: 1em;
        letter-spacing: 0.2px;
    }

    .cadastrar{
		font-size: 1.2em;
        text-decoration: none;
        margin-top: 10px;
        color: white;
        border: none;
        font-weight: 600;
        letter-spacing: 0.3px;
        background: rgb(194,144,3);
        background: linear-gradient(266deg, rgba(194,144,3,1) 35%, rgba(227,175,31,1) 100%);
		width: 40%;
		height: 40px;
    }

	.columns{
		margin-bottom: 1em;
	}

    }
`;

export const FormLoginContainerStyle = styled.section`
	border-radius: 20px;
	border: none;
	margin: 1.5em;
	background-color: white;



	ion-icon {
		font-size: 2rem;
	}

	.input-enfase{
		flex-grow: 1;
	}

	.field{
		margin-bottom: 1.5em;
	}

	.field > .control > .input{
		font-size: 1.8rem;
	}

	.field > .control > .input:required:focus{
		border-color: red;
	}

	.input:required:focus{
		border-color: red;
	}

	.control > .button{
		font-size: 1.8rem;
	}

	.field > .label{
		text-align: left;
		font-size: 1em;
	}

	.label{
		text-align: left;
		font-size: 1em;	
	}

	.columns > .column > .field{
		margin-bottom: 0;
	}

	@media (max-width: 2560px) {
		padding: 3em 5em 3em 5em;
	}


	@media (max-width: 770px) {
		position: relative;
		top: 5em;
		padding: 3em 1.5em 3em 1.5em;
		margin-top: 0;
	}

	@media(max-width: 1280x){
		margin-top: 0;
	}
`;

export const RegisterStyle = styled.section`
	padding-top: 40px;
	margin-left: 5%;
	margin-right: 5%;
	h1 {
		color: #282828;
		font-size: 1.8em;
	}

	form {
		max-width: 500px;
	}

	a{
		color: #812990;
	}

@media (max-width: 2560px) {
	margin-left: 20%;
	margin-right: 20%;
}

@media (max-width: 1024px) {
	margin-left: 15%;
	margin-right: 15%;
}

@media (max-width: 770px) {
	margin-left: 0;
	margin-right: 0;
}
`;

export const ResetPasswordStyle = styled.section`
	padding-top: 40px;
	margin-left: 5%;
	margin-right: 5%;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	h1 {
		color: #282828;
		font-size: 1.8em;
	}

	form {
		width: 100%;
	}

	a{
		color: #812990;
	}
	.help{
		font-size: 0.7em !important;
    	color: #848490;
	} 

	.cadastrar-container > h4{
        color: #000;
        font-weight: 600;
        letter-spacing: 0.2px;
    }

    .cadastrar{
        text-decoration: none;
        margin-top: 10px;
        color: white;
        border: none;
        font-weight: 600;
        letter-spacing: 0.3px;
        background: rgb(194,144,3);
        background: linear-gradient(266deg, rgba(194,144,3,1) 35%, rgba(227,175,31,1) 100%);
    }

	
	@media (max-width: 2560px) {
		margin-left: 20%;
		margin-right: 20%;

		.cadastrar-container{
			padding-top: 2em;
    	}
	}

	@media (max-width: 1280px) {
		padding-bottom: 400px;
	}

	@media (min-width: 1280px) {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-left: 25%;
		margin-right: 25%;
		h1{
			margin: 30px 0 30px 0;
		}
		.recuperar-senha-container{
			/* margin-bottom: 80px;	 */
		}
		.check-email-container{
			margin-top: 100px;
		}
		.cadastrar-container{
			padding-bottom: 25em;
    	}
		padding-bottom: 400px;
	}

	@media (max-width: 1024px) {
		margin-left: 15%;
		margin-right: 15%;
	}

	
	@media (max-width: 770px) {
		margin-left: 0;
		margin-right: 0;	

		position: relative;
		z-index: 1;

		.cadastrar-container{
			padding-top: 5em;
		}
	}

	@media (max-width: 425px) {
		margin-left: 0;
		margin-right: 0;

		width: 100vw;

	.cadastrar-container{
		padding-top: 5em;
		padding-bottom: 5em;
		background: rgb(136,48,152);
    	background: linear-gradient(132deg,rgba(136,48,152,1) 39%,rgba(163,71,179,1) 100%)
    }
	@media(max-width: 1280px){
		
	}
    .cadastrar-container > h4{
        color: white;
        font-weight: 600;
        letter-spacing: 0.2px;
    }

    .cadastrar{
        text-decoration: none;
        margin-top: 10px;
        color: white;
        border: none;
        font-weight: 600;
        letter-spacing: 0.3px;
        background: rgb(194,144,3);
        background: linear-gradient(266deg, rgba(194,144,3,1) 35%, rgba(227,175,31,1) 100%);
    }

    }
`;

export const FormContainerStyle = styled.section`
	border-radius: 20px;
	border: none;
	margin: 1.5em;
	margin-bottom: 1em;
	padding: 3em 2em 3em 2em;
	background-color: white;
	max-width: 500px;
	ion-icon {
        font-size: 2rem;
    }

	input:disabled{
		color: #5f5e5e;
	}

	select:disabled{
		color: #5f5e5e;
	}

	.input-enfase{
		flex-grow: 1;
	}

	.field{
		margin-bottom: 1.5em;
	}

	.field > .control > .input{
		font-size: 1.8rem;
	}

	.field > .control > .input:required:focus{
		border-color: red;
	}

	.input:required:focus{
		border-color: red;
	}

	.control > .button{
		font-size: 1.8rem;
	}

	.field > .label{
		text-align: left;
		font-size: 1em;
	}

	.label{
		text-align: left;
		font-size: 1em;	
	}

	.columns > .column > .field{
		margin-bottom: 0;
	}

	.control > .checkbox {
		margin: 1em 0em 1em 0em;
		font-size: 1.8rem;
	}

	.radio-check-options{
		margin-top: 1.3rem;
		margin-bottom: 0;
	}

	.radio-check-options > .control{
		margin-top: 1.3rem;
		margin-bottom: 0;
	}

	.section-half{
		display: flex;
		justify-content: space-between;
	}

	.section-half > div:first-child{
		margin-right: 2em;
		flex-grow: 1;
	}

	.section-half > div:last-child{
		flex-grow: 1;
	}

	.select > select{
		font-size: 1.8rem;
	}

	.select > select > option{
		font-size: 1.8rem;
	}

	.help{
		margin-top: -18px;
		font-size: 1em;
		text-align: left;
	}

	hr {
		margin-top: 3em;
		margin-bottom: 3em;
	}
	h2{
		font-size: 1.2em;
	}

	@media (max-width: 770px) {
		padding: 3em 1.5em 3em 1.5em;
		margin-bottom: 0;
		.section-half > div:first-child{
			margin-right: 1em;
		}

	}
	@media (min-width: 1280px) {
		.recuperar-senha-container{
			width: 400px;
		}
	}
	
`;

export const BtnLoginRegisterChangePasswordStyle = styled.button`
	margin: 1em 0em 1em 0em;
	background: rgb(131, 43, 146);
	background: linear-gradient(
		267deg,
		rgba(131, 43, 146, 1) 39%,
		rgba(160, 69, 176, 1) 100%
	);
	color: white;
	font-size: 1.3em;
	font-weight: 600;
	letter-spacing: 0.3px;
	border: none;
	width: 60%;

	:hover{
		background: #c986d8;
		color: white;
	}

`;