import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '../../components/FlexFreteInput/Input';
import FlexSpinner from "../../components/FlexFreteSpinner/FlexSpinner";
import UsuarioService from '../../services/UsuarioService';
import validarEmail from '../../utils/ValidaEmail';
import { BtnLoginRegisterChangePasswordStyle, FormContainerStyle, ResetPasswordStyle } from './style';


const usuarioService = new UsuarioService();
function ResetPassword() {
  const [request, setRequest] =  useState({email: ''});
  const [isEmailInvalid, setIsEmailInvalid] =  useState(false);
  const [isLoading, setIsLoading] =  useState(false);
  const [mensagemErro, setMensagemErro] =  useState("");
  const [sucesso, setSucesso] =  useState(false);

  const onChange = (value) => {
    setRequest({
      email: value
    });
    setMensagemErro("");
    validateEmail(value);
  }

  const validateEmail = (value)=>{
    setIsEmailInvalid(!validarEmail(value));

  }
  const submit = (e) => {
    e.preventDefault();
    let index = 0;

    while (e.target[index] instanceof HTMLInputElement) {
      const nome = e.target[index].name;
      const value = e.target[index].value;
      if (nome === 'email'){
        validateEmail(value);
      }
      index++;
    }

    validateEmail();
    if(isEmailInvalid) {
      setMensagemErro("Email no formato inválido");
      return;
    }    
    setIsLoading(true);

    usuarioService.solicitarTrocaDeSenha(request, (err, sucess) => {
      setIsLoading(false);
      setSucesso(true)
    })
    return;
  }
 
  return (
    <ResetPasswordStyle> 
        
          {
            isLoading? <FlexSpinner/> :""
          }
          {
            !sucesso? (
              <> 
              <h1 className="title">Recuperar senha</h1>
              <FormContainerStyle className="recuperar-senha-container">
                <form onSubmit={submit}>
                  <Input type="email" name="email" className= {isEmailInvalid? "is-danger": ""}
                  label="Digite seu e-mail" required={true} onChange={(e) => { onChange(e.target.value);}} value={request.email}/>
      
                  <BtnLoginRegisterChangePasswordStyle className="button">Enviar</BtnLoginRegisterChangePasswordStyle>
      
      
                  {mensagemErro?
                            (<div className="notification is-warning">
                                {mensagemErro}
                            </div>):""
                  }
                </form>
              </FormContainerStyle>
                  </>
            ):
            (
              <FormContainerStyle className="check-email-container">
                <h2 className=""> <strong> Verifique o seu e-mail para alterar a senha. </strong></h2>
              </FormContainerStyle>

              )
          }
       
        <div className="cadastrar-container">
					<h4>Ainda não tem conta?</h4>
					<Link to="/register" className="cadastrar button">Cadastrar</Link>
				</div>
    </ResetPasswordStyle>
  );
}

export default ResetPassword;
