import styled from "styled-components";

export const CarrinhoPagamentoContentStyle = styled.div`
    /* margin-top: 40px; */
    background-color: #fff;
    width: 80%;
    max-width: 800px;
    border-radius: 10px;
    padding: 30px;
   

    p{
        text-align: left;
    }

    .finalizar-pagamento{
        margin-top: 10px;
		/*
		margin-bottom: 20px; */
		background: rgb(131, 43, 146);
		background: linear-gradient(
			267deg,
			rgba(131, 43, 146, 1) 39%,
			rgba(160, 69, 176, 1) 100%
		);
		color: white;
		font-weight: 600;
		letter-spacing: 0.3px;
		border: none;
	}

	.finalizar-pagamento:hover{
		background: #c986d8;
		color: white;
	}

`