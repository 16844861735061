import axios from 'axios';
import AutenticacaoService from './AutenticacaoService';

let instance = axios.create({
    baseURL: process.env.REACT_APP_HOST_API,
    timeout: 60000
})

instance.interceptors.request.use((config) => {
    let service = new AutenticacaoService()
    let token = service.token();
    if(token){
        config.headers.Authorization = token
    }
    return config;
})

instance.interceptors.response.use((response) => {
    return response
}, (error) => {
    if(error.response.status === 401) {
        let service = new AutenticacaoService();

        service.logout(()=>{
            window.location = "/"
        })
    }
    if(error.response.status === 403) {
        let service = new AutenticacaoService();
        service.logout(()=>{
            window.location = "/"
        })
    }
    return Promise.reject(error)
})

export default instance
