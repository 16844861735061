import React, { useCallback, useEffect, useState } from 'react';
import CarteiraFlexHeader from '../../components/CarteiraFlexHeader/CarteiraFlexHeader';
import Modal from '../../components/FlexFreteModal/Modal';
import FlexSpinner from '../../components/FlexFreteSpinner/FlexSpinner';
import InserirSaldo from '../../components/InserirSaldo/InserirSaldo';
import LimitesFretes from '../../components/LimitesFretes/LimitesFretes';
import MeusEnviosFrete from '../../components/MeusEnviosFreteList/MeusEnviosFrete';
import AssinaturaService from '../../services/AssinaturaService';
import FreteService from '../../services/FreteService';
import { HomeStyle } from './style';

const assinaturaService = new AssinaturaService();
const freteService = new FreteService();
function Home() {

  const [isLoading, setIsLoading] = useState(false);
  const [homeInfo, setHomeInfo] = useState({});
  const [fretes, setFretes] = useState([]);
  const [showInserirSaldo, setShowInserirSaldo] = useState(false);
  const [dinheiro, setDinheiro] = useState("");
  const [centavos, setCentavos] = useState("");
  const [showingFretes, setShowingFretes] = useState([false]);
  const [showDeleteSucessMessage, setShowDeleteSucessMessage] = useState(false);

  const [erro, setErro] = useState({
		isWrong: false,
		titulo: "",
		mensagem: "",
	})


  const [freteRequest, setFreteRequest] = useState({
    pageSize: 4,
    page: 0,
});

  const loadFretes = useCallback(() => {
    setIsLoading(true);
    freteService.listarMeusFretes(freteRequest, (err, sucess) => {
      setIsLoading(false);
      if (err) {
        console.log(err);
      } else {
        setFretes([...sucess]);
      }
    });
  }, [freteRequest]);

  const loadMoreFretes = () =>{
      setIsLoading(true);
      freteService.listarMeusFretes({...freteRequest, page: freteRequest.page + 1 }, (err, sucess)=>{
        setIsLoading(false);
          if(err){
              console.log(err);
          }else{
              if(sucess.length){
                setFreteRequest({...freteRequest,  page: freteRequest.page + 1});
                setFretes([...fretes, ...sucess]);
              }
          }
      })
  }

  function loadInfo(){
    setIsLoading(true);
    assinaturaService.loadAssinaturaSelecinadaHome((erro, sucesso) => {
      setIsLoading(false);
      if(erro){
        setErro({
          isWrong: true,
          mensagem: 'Erro ao obter informações da assinatura, entre em contato com o suporte ou tente novamente mais tarde.'
        })
        return
      }
      if(sucesso) {
        setHomeInfo(sucesso);
          let saldoSplited = sucesso.saldo.toString().split('.');
         if(saldoSplited.length === 2){
           let dinheiro = saldoSplited[0]
           let centavos = saldoSplited[saldoSplited.length - 1]
           setDinheiro(dinheiro)
           setCentavos(centavos)
         } else {
            setDinheiro(sucesso.saldo.toString())
            setCentavos("00")
         }
      }
    });
  }

  const deleteFrete = (frete) => {
    if(!window.confirm("Tem certeza que deseja cancelar o frete?")){
        return;
    }
    setIsLoading(true);
    const mensagem = "Cancelamento solicitado pelo assinante."
    freteService.deleteFrete(frete.uuid, mensagem,(err, sucess)=>{
        setIsLoading(false);
        if(err){
            console.error(err);
        }else{
          setShowDeleteSucessMessage(true);
        }
    });
}
const showHideFreteCallback = (index)=>{
  const showAux = showingFretes;
  
  const show = !showingFretes[index];
  showAux[index] = show;

  setShowingFretes([...showAux]);
}

  useEffect(() => {
    const callback = ( ) =>{
      loadInfo();
      loadFretes();
    }
    callback();
      // desabilitando devido ao carregar mais atualizar o request --> by Renan
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const callbackModal = () =>{
    setShowDeleteSucessMessage(false);
 }
  return (
    <>
    {
        isLoading? <FlexSpinner/> : ""
    }
      {
        erro.isWrong?
        (
          <Modal callbackModal={()=>{ setErro({isWrong: false })}} visivel={erro !== null} titulo={erro.titulo} conteudo={erro.mensagem} />
        ) : ""
      }
      {
        showDeleteSucessMessage ?
        (
            <Modal callbackModal={callbackModal}  sucesso={true} visivel={showDeleteSucessMessage} titulo={"Remoção de frete"} conteudo={"Frete removido com sucesso!"} />
        ) : ""
      }
      <div className="page-container-style">
      <HomeStyle>
          <h2 className="page-title">Home</h2>

          <div className= "header-home">
            <CarteiraFlexHeader className="carteira-home" dinheiro={dinheiro} centavos={centavos}  
              showEconomia={false} onClickInserirCreditos={ () => {setShowInserirSaldo(true)}} />
            <LimitesFretes className="header-limites-fretes" numeroFretes={homeInfo.numeroFretes} fretesAguardaPostar={homeInfo.fretesAguardaPostar}
              fretesAtivos={homeInfo.fretesAtivos} solicitarAumentarLimiteCallback=''/>
          </div>
        
          {
            showInserirSaldo? <InserirSaldo /> : ""
          }
          
          <ul className="fretes-list">
              <div className="formulario-titulo">
                <h2 className="formulario-busca-titulo">Status dos últimos fretes</h2>
              </div>
            {
              fretes.map( (frete, index) =>   
                  (   <MeusEnviosFrete frete={frete} key={Math.random().toFixed(10).toString()} 
                          deleteFreteCallback={deleteFrete} 
                          showBody={showingFretes[index]} setShowBodyCallback={showHideFreteCallback} index={index}/>
                  )
              )
            }

            {fretes.length >= freteRequest.pageSize? <input className="button button-load-more" value="Carregar mais" onClick={loadMoreFretes}/>: ""}

          </ul>
        
      </HomeStyle>
      </div>
    </>
  );
}

export default Home;
