import React, { useState } from 'react';
import Modal from '../../components/FlexFreteModal/Modal';
import { cepMask, cnpjMask, cpfMask, dataAtualFormatada, moneyMask, telefoneMask } from '../../utils/Masks';
import { EtiquetaStyle } from './style';


function CarrinhoEtiqueta(props) {
    const [showHide, setShowHide] = useState(false);

    const handleShowHide = () => {
        setShowHide(!showHide)
    }
    

  return (
      <>
    <EtiquetaStyle>

    <header className="meus-envios-frete-cabecalho-postado">
                    <div style={!showHide ? {marginRight: '40px'} : {marginRight: '0px'}}>
                        <span className="header-label">Transportadora: </span>
                        <strong>
                           {props.etiqueta?.transportadora.nomeTransportadora}
                        </strong>

                        <div className="show-frete-mobile" onClick={handleShowHide} >
                            <ion-icon  name={showHide ? "chevron-up-outline": "chevron-down-outline"}> </ion-icon>
                        </div>
                    </div>
                    
                   
                    <div style={!showHide ? {marginRight: '40px'} : {marginRight: '0px'}}>
                        <span className="header-label">Modalidade: </span>
                        <strong>{props.etiqueta?.produto.nomeProduto}</strong>
                    </div>

                    <div style={!showHide ? {marginRight: '40px'} : {marginRight: '0px'}}>
                        <span className="header-label">Prazo: </span>
                        <strong>
                        {
                            props.etiqueta?.tempoMinimo === props.etiqueta?.tempoMaximo ?
                            (
                                <>
                                {props.etiqueta?.tempoMinimo} dias úteis
                                </>
                            ) :
                            (
                                <>
                                {props.etiqueta?.tempoMinimo} a {props.etiqueta?.tempoMaximo} dias úteis
                                </>
                            )
                        }
                        </strong>
                    </div>

                    <div style={!showHide ? {marginRight: '40px'} : {marginRight: '0px'}}>
                    <span className="header-label">Valor: </span>
                        <strong className="expiracao-postado"> {props.etiqueta?.valorFrete ? moneyMask(props.etiqueta?.valorFrete.toFixed(2)) : ""}</strong>
                    </div>
                 
                    <div className="show-frete-desktop" onClick={handleShowHide} >
                       <ion-icon  name={showHide ? "chevron-up-outline": "chevron-down-outline"}> </ion-icon>

                    </div>
        </header>

            {
                showHide ?
                (
                     <div className="meus-envios-frete-container">
                        {
                            props.etiqueta?.transportadora?.nomeTransportadora === 'Jadlog' ?
                            (
                                <img src="https://setcesp.org.br/wp-content/uploads/2019/06/0-1.png"
                                alt={`${props.etiqueta?.transportadora.nomeTransportadora} logo`}
                                className="transportadora-logo"
                            />
                            ) :
                            (
                                <>
                                <img src="https://s2.glbimg.com/Bck2HF98b4egovbEM9gVNEvon9Y=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_08fbf48bc0524877943fe86e43087e7a/internal_photos/bs/2018/H/w/6cA3RURx2T5T4xBbSX7Q/correios2.jpg"
                                    alt={`${props.etiqueta?.transportadora.nomeTransportadora} logo`}
                                    className="transportadora-logo"
                                />
                            </>
                            )
                        }
                <div className="meus-envios-frete-section">
                    <div className="meus-envios-frete-container-area">
                        <div className="container-area-line">
                            <strong className="line-label">Modalidade:</strong>
                            <span>{props.etiqueta?.produto.nomeProduto}</span>
                        </div>
                        <div className="container-area-line">
                            <strong className="line-label">Cod. do frete:</strong>            
                            <span>{props.etiqueta?.uuid}</span>
                        </div>
                        <div className="container-area-line">
                            <strong className="line-label">Valor Segurado:</strong>
                            <span>{props.etiqueta?.valorSeguro.toFixed(2) ? moneyMask(props.etiqueta?.valorSeguro.toFixed(2)) : ""}</span>
                        </div>
                        <div className="container-area-line">
                            <strong className="line-label">Tipo de pacote:</strong>
                            <span>{props.etiqueta?.cotacao.formato}</span>
                        </div>
                    
                    </div>
                    <div className="meus-envios-frete-container-area">
                        <div className="container-area-line">
                            {
                                props.etiqueta?.agencia ?
                                (
                                    <>
                                        <strong className="line-label">Agência:</strong>
                                        <span>{props.etiqueta?.agencia}</span>
                                    </>
                                ) : ""
                            }
                        </div>

                        <div className="container-area-line">
                            <strong className="line-label">Coleta:</strong>            
                            <span>{props.etiqueta?.cotacao.coleta === true ? 'Sim' : 'Não'}</span>
                        </div>

                        <div className="container-area-line">
                            <strong className="line-label">Aviso de recebimento:</strong>
                            <span>{props.etiqueta?.cotacao.avisoRecebimento === true ? 'Sim' : 'Não'}</span>
                        </div>

                        <div className="container-area-line">
                            <strong className="line-label">Data:</strong>
                            <span>{dataAtualFormatada()}</span>
                        </div>
                    
                    </div>


                </div>

        
                <div className="meus-envios-frete-container">

                    <div className="meus-envios-frete-section">

                        <div className="meus-envios-frete-container-area-pessoa">
                            <div className="pessoa-titulo">
                                <h4>Remetente</h4>
                            </div>
                            <div className="container-area-line">
                                <strong>{props.etiqueta?.remetente.nome} {props.etiqueta?.remetente.sobrenome}</strong>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">CPF / CNPJ:</strong>            
                                <span>{props.etiqueta?.remetente.cpfCnpj.length === 11 ? cpfMask(props.etiqueta?.remetente.cpfCnpj) : cnpjMask(props.etiqueta?.remetente.cpfCnpj)}</span>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">Tel:</strong>
                                <span>{props.etiqueta?.remetente.telefone ? telefoneMask(props.etiqueta?.remetente.telefone) : ""}</span>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">Email:</strong>
                                <span>{props.etiqueta?.remetente.email}</span>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">Rua:</strong>
                                <span>
                                {props.etiqueta?.remetente.endereco.logradouro}
                                {props.etiqueta?.remetente.endereco.complemento ? "," : ""}
                                {props.etiqueta?.remetente.endereco.complemento}
                                , {props.etiqueta?.remetente.endereco.numero}
                                </span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Bairro:</strong>
                                <span>{props.etiqueta?.remetente.endereco.bairro}</span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Cidade/Estado:</strong>
                                <span>{props.etiqueta?.remetente.endereco.cidade} - {props.etiqueta?.remetente.endereco.estado}</span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">CEP:</strong>
                                <span>{props.etiqueta?.remetente.endereco.cep ? cepMask(props.etiqueta?.remetente.endereco.cep) : ""}</span>
                            </div>

                        </div>

                        <div className="meus-envios-frete-container-area-pessoa">
                            <div className="pessoa-titulo">
                                <h4>Destinatário</h4>
                            </div>
                            <div className="container-area-line">
                                <strong>{props.etiqueta?.destinatario.nome} {props.etiqueta?.destinatario.sobrenome}</strong>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">CPF / CNPJ:</strong>            
                                <span className="line value">{props.etiqueta?.destinatario.cpfCnpj.length === 11 ? cpfMask(props.etiqueta?.destinatario.cpfCnpj) : cnpjMask(props.etiqueta?.destinatario.cpfCnpj)}</span>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">Tel:</strong>
                                <span>{props.etiqueta?.destinatario.telefone ? telefoneMask(props.etiqueta?.destinatario.telefone) : ""}</span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Email:</strong>
                                <span>{props.etiqueta?.destinatario.email}</span>
                            </div>

                            <div className="container-area-line">
                                <strong className="line-label">Rua:</strong>
                                <span>
                                {props.etiqueta?.destinatario.endereco.logradouro}
                                {props.etiqueta?.destinatario.endereco.complemento ? "," : ""}
                                {props.etiqueta?.destinatario.endereco.complemento}
                                , {props.etiqueta?.destinatario.endereco.numero}
                                </span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Bairro:</strong>
                                <span>{props.etiqueta?.destinatario.endereco.bairro}</span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">Cidade/Estado:</strong>
                                <span>{props.etiqueta?.destinatario.endereco.cidade} - {props.etiqueta?.destinatario.endereco.estado}</span>
                            </div>
                            <div className="container-area-line">
                                <strong className="line-label">CEP:</strong>
                                <span>{props.etiqueta?.destinatario.endereco.cep ? cepMask(props.etiqueta?.destinatario.endereco.cep) : ""}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="container-volumes-etiqueta">
                    <div className="volumes">
                        <strong>Volume</strong>
                        <div>
                            <p>
                                {props.etiqueta?.cotacao.dimensoes.diametroPacote === null ?
                                    (
                                        <>
                                            {props.etiqueta?.cotacao.dimensoes?.comprimentoPacote}cm C, {props.etiqueta?.cotacao.dimensoes?.larguraPacote}cm L, {props.etiqueta?.cotacao.dimensoes?.alturaPacote}cm A
                                        </>
                                    ) :
                                    (
                                        <>
                                            {props.etiqueta?.cotacao.dimensoes.diametroPacote}cm D, {props.etiqueta?.cotacao.dimensoes?.alturaPacote}cm A
                                        </>
                                    )
                                }
                            </p>
                            <p>Peso {props.etiqueta?.cotacao.dimensoes.pesoPacote}Kg</p>
                        </div>
                    </div>
                    <div className="volumes">
                        <strong>Valor</strong>
                        <div>
                            <span> {props.etiqueta?.cotacao.valorFrete ? moneyMask(props.etiqueta?.cotacao.valorFrete.toFixed(2)) : ""}</span>
                        
                        </div>
                    </div>

            </div>
                    <div className="etiqueta">
                        <input type="button" className="button is-warning" value="Editar dados" onClick={() => props.editarItemCarrinho(props.index)} />
                        <input type="button" className="button is-danger declaracao" value="Remover do carrinho" onClick={() => props.removerDoCarrinho(props.index)} />
                        {
                            props.erro.isWrong === true ?
                                (
                            <Modal callbackModal={props.callbackModal} visivel={props.erro !== null} titulo={props.erro.titulo} conteudo={props.erro.mensagem} />
                         ) : null
                        }
                    </div>
            </div>
                ) : ""
            }
            
    </EtiquetaStyle>
</>
  
  );
}

export default CarrinhoEtiqueta;
