import styled from "styled-components";

export const FreteFormStyle = styled.section`
	padding-bottom: 0px;
	padding-top: 8px;
	background-color: #FFFFFF;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.labelInput{
		display: inline-block;
	}

	h2 {
		text-align: left;
		font-size: 2.5em;
		color: #340040;
		font-weight: 700;
	}

	.image{
		transform:scale(1.66);
	}

	.container{
		width: 100%;
		display: flex;
		flex-direction: row;
		margin-bottom: 1em;
	}

	.container section div{
		text-align: left;
		margin-top: 8em;
		margin-bottom: 8em;
		
		p{
			color: #340040;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 1.5em;
			line-height: 150%;
			font-feature-settings: 'liga' off;
		}
	}

	strong{
		color: #340040;
	}

	.titulo{
		color: #340040;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 4em;
		line-height: 70px;
		letter-spacing: -0.025em;
		font-feature-settings: 'liga' off;
		text-align: left;
	}

	form > .field > label{
		text-align: left;
		font-weight: 400;
		letter-spacing: 0.3px;
	}

	.cursor-not-allowed {
		pointer-events: none;
  cursor: url('caminho/para/imagens/proibido.cur'), not-allowed;
  
}


	@media screen and (min-width: 2560px) {
		.image{
			top:4em;
			right: 5em;
		}
	}

	@media screen and (max-width: 1920px) {
		.image{
			top:4em;
			right: 5em;
		}
	}

	@media screen and (max-width: 1440px){
		h2{
			font-size: 2.2em;
		}

		.container section div{
			margin-top: 3em;
			margin-bottom: 3em;

			p{
				font-size: 1.5em;
			}
		}
		

		.titulo{
			font-size: 3.6em;
			line-height: 60px;
		}
		.image{
			/* transform:scale(1.5); */
			top: 2.6em;
			right: 2em;
		}
		.image-frete-form{
			transform: scale(1.2);
			top: -1em;
			right: 2em;
		}
	}
  @media screen and (max-width: 1380px) {
    .image-frete-form{
      top: 0.2em;
    }
  }
	@media screen and (min-width: 2560px) {
			.mobile-container{
			display: none;
		}
		.mobile-container-form{
			display: none;
		}
		}
		
		@media screen and (max-width: 1920px) {
			.mobile-container{
			display: none;
		}
		.mobile-container-form{
			display: none;
		}
		}

		@media screen and (max-width: 1440px) {
		.mobile-container{
			display: none;
		}
		.mobile-container-form{
			display: none;
		}
		}

	@media screen and (max-width: 700px) {
		.mobile-container-form{
			display: inline;
		}
		.titulo{
			line-height: initial;
		}
		.mobile-container{
			display: flex;
			margin-left: 1em;


		h1{
			padding-left: 0.4em;
			line-height: initial;
		}

		h2{
			padding-left: 0.3em;
			font-size: 1.2em;
			line-height: 1em;
		}

		.titulo{
			font-size: 1.4em;
		}

		.mobile-picture{
			display: flex;
			margin-top: 2em;
			margin-bottom: 0px;

			div{
				margin-top: 0px;
				margin-bottom: 0px;
			}

			p{
				padding-left: 0.8em;
				font-size: 0.9em;
				text-align: left;
			}

			.mobile-main-text{
				margin-bottom: 2em;
			}

			.img-section{
				position: relative;
				left: -1em;
				top: -1em;
				transform: scale(1.3);
			}

			.image{
				transform:scale(1.0);
				top: 0px;
				right: 0px;
				left: 0px;
				bottom: 0px;
			}
		}

		}
		.desktop-container{
			display: none;
		}
	}
`;

export const FreteFormContainerStyle = styled.section`
	border-radius: 10px;
	margin: 0.5em;
	padding: 50px 80px 25px 80px;
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
	
	@media screen and (min-width: 2560px) {
		width: 60%;
	}
	@media screen and (max-width: 1920px) {
		width: 80%;
	}

	@media screen and (max-width: 1440px) {
		width: 87%;
	}
	
	@media (min-width: 1279px) {
		/* width: 80%; */
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	@media screen and (min-width: 1000px) {
		margin: 1.5em 20em 1.5em 20em;
	}
	@media screen and (min-width: 1200px) {
		margin: 1.5em 25em 1.5em 25em;
	}
	@media screen and (min-width: 700px) {
		margin: 1.5em 10em 1.5em 10em;
	}	
	@media screen and (max-width: 700px) {
		padding: 25px 40px 12.5px 40px;
		width: 100%;
	}	
`;

export const SectionFormStyle = styled.div`
	.valor-input{
		display: flex;
		align-items: baseline;
		position: relative;
		color: #340040;
		background-color: #EFEFEF;
		height: 45px;
		border-radius: 10px;
		border: 1px solid #dbdbdb;
		max-width: 92%;
		span {
			padding-left: 0.5em;
			position: absolute;
			top: 0.7em;
		}
		input{
			height: 44px;
          color:#202020;
		}
	}

	.valor-input-mobile{
		display: flex;
		align-items: baseline;
		position: relative;
		color: #340040;
		background-color: #EFEFEF;
		height: 45px;
		border-radius: 10px;
		border: 1px solid #dbdbdb;
		span {
			padding-left: 0.5em;
			position: absolute;
			top: 0.98em;
		}
		input{
			padding-left: 2.5em;
			height: 44px;
            color:#202020;
		}
	}

	.label{
		font-size: 1em;
		text-align: left;
		font-weight: 400;
		letter-spacing: 0.3px;
		color: #792783;
	}

	input, select{
        color:#202020;
		background-color: #EFEFEF;
		height: 45px;
		border-radius: 10px;
		max-width: 100%;
	}
	
	select::placeholder {
		color: #B4B4B4;
	}

	.selectionFilter{
		margin-left: 3em;
	}

	.selectWidth{
		width: 15em;
	}

	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{
		-webkit-appearance: none;
  		margin: 0;
	}
	input[type=number]{
		-moz-appearance: textfield;
	}

	.text{
		color: #340040;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 300;
		font-size: 0.8em;
		line-height: 0.6875em;
		text-align: left;
		margin-bottom: 1em;
	}


	.padding{
		min-width: 200px;
		width: 100%;
		padding: 10px 30px;
	}
	
	@media screen and (max-width: 1440px) {
		.label{
			font-size: 0.8em;
		}
	}

	@media screen and (max-width: 700px) {
		margin-right: 1em;
		margin-left: 1em;

		.dots{
		margin-bottom: 0;
		span{
			height: 10px;
			width: 10px;
			background-color: white;
			border-radius: 50%;
			display: inline-block;
			margin-right: 1em;

		}
	}

		.margin{
			margin-right: 0px;
		}

		.field{
			margin-bottom: 2em;
		}
		.is-grouped{
			flex-direction: column;
		}

		.selectionFilter{
			margin-left: 0px;
			margin-top: 2em;

			.opcoesTransacoes{
				width: 100%;

				.selectWidth{
					width: 150%;
				}
			}
		}

		.text{
			line-height: 1em;
		}

	}	
`
export const BtnFreteStyle = styled.div`
	display: flex;
	justify-content: center;

	.clean{
		margin-top: 20px;
		margin-bottom: 20px;
		color: #792783;
		font-weight: 700;
		font-size: 20px;
		letter-spacing: 0.3px;
		background: #FFFFFF;
		border-radius: 6.25em;
		border: 2px solid #792783;
	}

	.calcular{
		margin-right: 50px;
		margin-top: 20px;
		margin-bottom: 20px;
		background: #792783;
		color: white;
		font-weight: 700;
		font-size: 20px;
		letter-spacing: 0.3px;
		border: none;
		border-radius: 6.25em;

	}

	.calcular:hover{
		background: #c986d8;
		color: white;
	}

	@media screen and (max-width: 700px) {
		
	}

`;
