import styled from "styled-components";

export const InserirSaldoCardStyle = styled.div`

	.input-carteira{
		min-width: 10rem;
	}
	.inserir-saldo-title{
		color: #282828;
        font-weight: 600;
		font-size: 2.1rem;
        letter-spacing: 0.3px;
		margin-bottom: 15px;
		text-align: left;
		margin-bottom: 20px;
    }
	form {
		width: 100%;
	}

	a{
		color: #812990;
	}
	
	margin: 1.5em;

	small{
		font-size: 1.1em;
	}

	.form-background{
		border-radius: 20px;
		border: none;
		padding: 2em;
		background-color: white;
	}

	.btn-baixar-tabela{
        background: white;
        width: 45%;
		margin-right:  5%;
    }

	.button{
		margin-top: 2rem;
		margin-bottom: 2rem;
        background: rgb(131, 43, 146);
	    background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
	    );
        border: none;
        /* margin-right: 5%; */
        color: white;
        font-weight: 600;
		letter-spacing: 0.2px;
		width: 130px;
    }
  
	.valor-input{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 30px;
		>div{
			flex-grow: 1;
			display: flex;
			position: relative;
			margin-left: 5px;
			.moeda { 
				position: absolute; 
				/* display: ${props => props.valor? "block": "none"};  */
				left: 10px; 
				top: 4px; 
				z-index: 9; 

				/* color: #ccc; */
			  }
		}
		label{
			margin-right: 15px;
			font-size: 1.3rem;
			white-space: nowrap;
		}
		input{
			height: 30px;
			max-width: 150px;
			padding-left: ${props => props.valor? "30px": ""}; 
		}
	}
	.meios-de-pagamento-list{
		display: grid;
	}
	.meios-de-pagamento-title{
		margin-bottom: 25px;
		text-align: left;
		font-size: 1.3rem;
	}
	.meios-de-pagamento{
		display: flex;
		flex-direction: column;
		align-items: left;
	}
	label.checkbox{
		display: flex;
		align-items: center;
		input{
			margin-right: 20px;
		}
	}
	.meio-de-pagamento{
		margin-bottom: 30px;
		padding-bottom: 20px;
		.meio-depagamento-logo{
			max-width: 130px;
		}
	}

	${(props) => (props.compraFrete === true ? `width: 50%` : "")};

	@media(min-width: 1000px){
		.valor-input{
			width: 50%;
			input{
				max-width: 100%;		
			}
		}
		.meio-de-pagamento{
			border-bottom: 1px solid #ddd;
		}
		.meios-de-pagamento-list{
			grid-template-columns: 1fr 1fr;
		}
	}


@media (max-width: 2560px) {
	.formSection{
		display: flex;
	}

	.formChildren{
		flex-grow: 1;
		margin-right: 20px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.sectionTransacoesResultado{
		display: flex;
	}

	.resultadoBusca{
		flex-grow: 1;
	}

}
	
@media (max-width: 1280px) {
	.formSection{
		display: flex;
		flex-direction: column;
	}

	.formChildren{
		flex-grow: 0;
		margin-right: 0;
	}

	.formChildren > label {
        display: block;
        text-align: left;
    }

	max-width: 400px;

}

@media (max-width: 770px) {
	${(props) => (props.compraFrete === true ? `width: 82%` : "")};
}



//remove arrows input number
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
`;