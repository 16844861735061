import styled from "styled-components";

export const TransacoesCardStyle = styled.div`
	h1 {
		color: #282828;
        font-size: 1.5em;
        font-weight: 600;
		letter-spacing: 0.2px;
	}

	h2{
		color: #282828;
        font-weight: 600;
        letter-spacing: 0.3px;
		margin-bottom: 15px;
		text-align: left;
    }

	form {
		width: 100%;
	}

	margin: 1.5em;

	.form-background{
		border-radius: 20px;
		border: none;
		padding: 1em;
		background-color: white;
	}

	.button{
		margin-top: 2rem;
		margin-bottom: 2rem;
        background: rgb(131, 43, 146);
	    background: linear-gradient(
            267deg,
            rgba(131, 43, 146, 1) 39%,
            rgba(160, 69, 176, 1) 100%
	    );
        border: none;
        width: 40%;
        color: white;
        font-weight: 600;
		letter-spacing: 0.2px;
    }

	.button:hover{
		background: #c986d8;
  		color: white;
	}

	.sectionSelectAll{
		display: flex;
		align-items: baseline;
		justify-content: center;
	}

	.baixarTabela{
		background: rgb(247, 247, 247);
	    background: linear-gradient(
            94deg,
            rgba(247, 247, 247, 1) 39%,
            rgba(255, 255, 255, 1) 100%
	    );
        border: none;
        color: #9339a2;
        padding: 10px;
        font-weight: 600;
        font-size: 0.9em;
        letter-spacing: 0.3px;
        -webkit-box-shadow: 9px 7px 9px -5px rgb(97 96 96 / 32%);
        box-shadow: 9px 7px 9px -5px rgb(97 96 96 / 32%);
	}

	.baixarTabela:hover{
		background: rgba(255, 255, 255, 0.7);
		color: #282828;
	}

	.totalTransacoes{
        text-align: center;
    }

    .totalTransacoes > .totalValor{
        color: #009f57;
    }

	.showHidedetails{
		display: ${(props) => (props.toggle ? `initial` : `none`)};
	}

	.sectionSearchHeader{
		display: flex;
	}

	.formChildren > label {
        display: block;
        text-align: left;
    }

	.formChildren{
		align-self: baseline;
    }

	.totalTransacoes{
        text-align: center;
    }

    .totalTransacoes > .totalValor{
        color: #009f57;
    }

	.resultadoBusca{
		font-size: 1.4rem;
	}

	.resultadoBusca > header {
       display: flex;
	   align-items: center;
	   justify-content: space-between;
    }

    .resultadoBusca > header > p > span {
        font-weight: 800;
    }

    .resultadoBusca > header > p > .saldo{
        color: #009f57;
    }

	.resultadoBusca > header > p > .saldo-saida{
		color: rgb(215, 0, 42);
	}

    .resultadoBusca > header > p > .totalValorEconomizou{
        color: #e6b222;
    }

	.resultadoBuscaOutrasInformacoes{
		margin-top: 30px;
		margin-bottom: 30px;
		font-size: 1.3rem;
		text-align: left;
		span{
			font-weight: 800;
		}
	}
	ion-icon{
		cursor: pointer;
	}

@media (max-width: 2560px) {
	.transacaoTitle{
		margin-top: 10px;
		font-size: 1.3em;
		font-weight: 700;
	}

	.selectWidth{
		width: 16em;
	}

	.sectionSearchHeader{
		justify-content: flex-start;
	}

	.formSection{
		display: flex;
	}

	.form-background{
		padding: 1em 3em 1em 3em;
	}


	.formChildren{
		flex-grow: 1;
		margin-right: 95px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.formChildren > label {
		font-size: 1em;
        margin-bottom: 10px;
    }

	input, select{
		height: 45px;
	}

	.button{
		font-size: 1em;
        width: 12%;
    }

}

@media (max-width: 1440px) {

	.transacaoTitle{
		margin-top: 10px;
		font-size: 1em;
		font-weight: 700;
	}

	.sectionSearchHeader{
		justify-content: flex-start;
	}

	.selectWidth{
		width: 12em;
	}

	.dateWidth{
		width: 14em;
	}

	.formSection{
		display: flex;
	}

	.form-background{
		padding: 1em 2em 1em 2em;
	}

	.formChildren{
		flex-grow: 1;
		margin-right: 20px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.formChildren > label {
		font-size: 0.9em;
        margin-bottom: 10px;
    }

	input, select{
		height: 45px;
	}
	
	.button{
		font-size: 0.9em;
        width: 15%;
    }

}
	
@media (max-width: 1024px) {
	.formSection{
		display: flex;
		flex-direction: column;
	}

	.formChildren{
		flex-grow: 0;
		margin-right: 0;
	}

	.childrenDate{
		width: 50%;
	}

}

@media (max-width: 770px) {

	.transacaoTitle{
		margin-top: 10px;
		font-size: 1.1em;
		font-weight: 700;
	}
	
	.sectionSearchHeader{
		justify-content: center;
		flex-direction: column
	}

	.selectWidth{
		width: 18em;
	}

	.form-background{
		padding: 1em;
	}

	.formChildren > label {
        margin-bottom: 8px;
    }

	.formChildren{
		align-self: baseline;
        margin-bottom: 5px;
    }

	.formChildrenBottom{
		margin-bottom: 15px;
	}

	.button{
		font-size: 1em;
        width: 80%;
    }

	.resultadoBusca > header {
	   display: block;
    }

	.resultadoBusca > header > p {
	   text-align: left;
    }

}
	
`;