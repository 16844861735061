import React from 'react';

import {ContaStyle} from './style';
import { ContaEditPasswordComponent  } from '../../components/Conta/ContaEditPassword';

export default function ContaEditPassword() {
    
    return (
        <div className="page-container-style">

            <ContaStyle>
                <h2 className="page-title">Nova senha</h2>
                <ContaEditPasswordComponent />

            </ContaStyle>
        </div>
    );
}