import React, {useEffect} from "react";
import { ModalContainerStyle, ModalContentStyle, BtnModalVoltarStyle, BtnModalSeguirStyle } from "./style";

function Modal(props) {
	const { visivel, callbackModal, autoCloseTime } = props;

	const ocultarModal = () =>{
		callbackModal()
	}

	useEffect(() => {
		if(visivel && autoCloseTime){
			const timer = setTimeout(() => {
				callbackModal();
			}, autoCloseTime);

			return () => clearTimeout(timer);
		}
	}, [visivel, callbackModal, autoCloseTime]);
	return (
		<>
		<div className={props.visivel === true ?` modal is-active` : `modal`}>
			<div className="modal-background"></div>
			<div className="modal-content">
			<ModalContainerStyle>
				<ModalContentStyle>
				{
					props.sucesso === true ?
					(
						<div className="success">
							<ion-icon name="checkmark-circle-outline"></ion-icon>
						</div>
					) :
					(
						<div className="attention">
							<ion-icon name="alert-circle"></ion-icon>
						</div>
					)
				}
		
					<p>{props.titulo}</p>
					<p>
						{props.conteudo}
					</p>

				{
					props.sucesso === true ?
					(
						<>
						<BtnModalSeguirStyle onClick={ocultarModal}>Continuar</BtnModalSeguirStyle>
						</>
					) :
					(
						<>
						{
							props.titulo === 'Aviso' ?
							(
								<BtnModalVoltarStyle onClick={ocultarModal}>OK</BtnModalVoltarStyle>
							) : <BtnModalVoltarStyle onClick={ocultarModal}>Voltar</BtnModalVoltarStyle>
						}
						</>
					)
				}
			</ModalContentStyle>
			</ModalContainerStyle>
			</div>
		</div>
		</>
	);
}

export default Modal;
