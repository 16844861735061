import styled from "styled-components";


export const ContaStyle = styled.div`

    display: flex;
    padding: 3%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    left: 3em;
    width: calc(100% - 3em);
    .conta-section{
        margin-bottom: 20px;
        max-width: 850px;
    }

    .margin-top{
        margin-top: 1em;
    }

    .label-grey{
        color: #9a9a9a;
    }
    .page-title{
        font-weight: bold;
        font-size: 2rem;
    }
    .actions{
        display: flex;
        justify-content: space-evenly;
        gap: 1rem;
        width: 50%;
    }
    @media(max-width: 1280px){
        min-height: 70vh;
    }
    @media(max-width: 475px){
        .conta-section, .actions{
            max-width: 280px;
        }
    }
    @media(min-width: 1280px){
        position: relative;
        left: 290px;
        width: calc(100% - 290px);
        .conta-section{
            width: 100%;
        }
        .back-link{
            width: 90%;
            text-align: initial;
        }
        .md{
            font-size: 30px;
            border-radius: 60%;
        }
    }
    .md{
        color: #000;
    }
    .md:hover{
        color: rgba(0, 0, 0, 0.5);
    }
    .button{
        width: 120px;
        cursor: pointer;
        font-weight: bolder;
    }
    .button-submit{
        background: rgb(136,48,152);
        background: linear-gradient(30deg, rgba(136,48,152,1) 39%, rgba(163,71,179,1) 100%);
        color: #fff;
    }
    .button-submit:hover{
        background: rgba(136,48,152, 0.7);
    }
    .button-link{
          background-color: rgba(255, 255, 255, 1);
    }
    .button-link:hoover{
        background-color: rgba(255, 255, 255, 0.7);
    }
    .notification{
        margin-top: 20px;
    }
    .page-navegation-button{
        width: 140px;
    }
    .is-mudar-senha-button{
        color: rgb(136,48,152);

    }
    .actions{
        width:50%;
        .actions{
            width:90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
        }
        button:first-child, a:first-child{
        }
    }
    .edit-password-form{
        max-width: 500px;
    }

`;

export const ContaEditPasswordStyle = styled.div`

`;
