import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import ButtonLadingPage from '../../components/ButtonLandingPage/ButtonLadingPage';
import FreteCalcForm from "../../components/FreteCalcForm/FreteCalcForm";
import FreteCalcResultado from "../../components/FreteCalcResultado/FreteCalcResultado";
import { CepStyle, ComoFuncionaStyle, DepoimentoStyle, FAQStyle, LandingPageContainerStyle, LandingPageStyle, PropagandaCelularStyle, PropagandaStyle, QuemSomosStyle, TransportadorasStyle } from "./style";

function LandingPage() {
	const history = useHistory()
	const showFreteResult = useSelector((state) => state.thereIsFreteCalcResultLandingPage);
	const [comoFuncionaPosition, setComoFuncionaPosition] = useState(0)
	const [depos, setDepos] = useState(0)
	const [transportadoras, setTransportadoras] = useState(0)
	return (
		<section className="landing-page-section">
			<LandingPageStyle>
				<div className="desktop-container card-1">
					<LandingPageContainerStyle>
							<div className="center desktop">
								<ul className="texto-inicio">
									<li>Mande encomendas com  <span className="fundo-amarelo">frete até</span></li>
									<li><span className="fundo-amarelo">75% mais barato,</span> e ponto de coleta</li>
									<li><span className="fundo-amarelo">sempre perto de você.</span></li>
								</ul>
								<h1 className="title title-principal">Simplifique seu envio!</h1>
								<ButtonLadingPage
									funcao={() => {history.push("/register")}}
									colorType="#792783"
									classeComplementar="button is-rounded"
									texto="Comece já a economizar"
								/>
							</div>
							<div className="images">
								<picture className="landing-woman landing-woman-card-1">
									<img src="/banner2.png" className="image" alt="mulher-landing-page" />
								</picture>
							</div>
						
					</LandingPageContainerStyle>
				</div>

				<div className="mobile-container">
				<LandingPageContainerStyle>
						<div className="center desktop">
							<ul className="texto-inicio">
								<li>Mande encomendas com  <span className="fundo-amarelo">frete</span></li>
								<li><span className="fundo-amarelo">até 75% mais barato,</span> e ponto de</li>
								<li>coleta <span className="fundo-amarelo">sempre perto de você.</span></li>
							</ul>
							<h1 className="title title-principal">Simplifique seu envio!</h1>
						</div>
						<div className="images">
							<picture className="landing-woman">
								<img src="/banner2.png" className="image" alt="mulher-landing-page" />
							</picture>
						</div>
						<ButtonLadingPage
							funcao={() => {history.push("/register")}}
							colorType="#792783"
							classeComplementar="button is-rounded"
							texto="Comece já a economizar"
						/>
					
				</LandingPageContainerStyle>	
				</div>
			</LandingPageStyle>

			<ComoFuncionaStyle>	
				<span className="title desktop-container">Usar a Flex Frete para enviar as mercadorias </span>
				<br/>
				<span className="title desktop-container">para seus clientes é simples e fácil</span>

				<span className="title mobile-container">Usar a Flex Frete para enviar as mercadorias para seus clientes é simples e fácil</span>
				
				<h4>Veja como é:</h4>
				<picture className="flex-frete-logo-bg">
						<img src="/simbolo-escuro.png" className="image" alt="simbolo-landing-page" />
				</picture>

				<div className="box desktop-container">
					<div className="elemento">
						<div>
							<h1>
								1
							</h1>
							<p>
								Faça a cotação online.
							</p>
						</div>

						<img className="img" src="/seta.png" alt="seta" />
					</div>

					<div className="elemento">
						<div>
							<h1>
								2
							</h1>
							<p>
								Escolha a sua transportadora.
							</p>
						</div>
						

						<img className="img" src="/seta.png" alt="seta" />
					</div>

					<div className="elemento" style={{width: '22%'}}>
						<div>
							<h1>
								3
							</h1>
							<p>
								Imprima a etiqueta e cole no seu pacote.
							</p>
						</div>
						
						<img className="img" src="/seta.png" alt="seta" />
					</div>

					<div className="elemento">
						<div>
							<h1>
								4
							</h1>
							<p>
								Leve sua encomenda ao ponto de coleta mais próximo.
							</p>
						</div>

						<img className="img" src="/seta.png" alt="seta" />
					</div>

					<div className="elemento">
						<div>
							<h1>
								5
							</h1>
							<p>
								Acompanhe a entrega pelo site da Flex Frete!
							</p>
						</div>
						<img className="img" src="/seta.png" alt="seta" style={{opacity: '0'}} />
					</div>
				</div>

				<div className="box-mobile mobile-container">

				<Swiper
					pagination={true}
					modules={[Pagination]} 
					onSlideChange={(swiper) => setComoFuncionaPosition(swiper.activeIndex)}
				>
					<SwiperSlide>
						<div className="elemento-mobile">
							<div>
								<h1>
									1
								</h1>
								<p>
									Faça a cotação online.
								</p>
							</div>

							<img className="img" src="/seta.png" alt="seta" />
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="elemento-mobile">
							<div>
								<h1>
									2
								</h1>
								<p>
									Escolha a sua transportadora.
								</p>
							</div>
							

							<img className="img" src="/seta.png" alt="seta" />
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="elemento-mobile">
							<div>
								<h1>
									3
								</h1>
								<p>
									Imprima a etiqueta e cole no seu pacote.
								</p>
							</div>
							
							<img className="img" src="/seta.png" alt="seta" />
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="elemento-mobile">
							<div>
								<h1>
									4
								</h1>
								<p>
									Leve sua encomenda ao ponto de coleta mais próximo.
								</p>
							</div>

							<img className="img" src="/seta.png" alt="seta" />
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="elemento-mobile">
							<div>
								<h1>
									5
								</h1>
								<p>
									Acompanhe a entrega pelo site da Flex Frete!
								</p>
							</div>
							<img className="img" src="/seta.png" alt="seta"/>
						</div>
					</SwiperSlide>
				</Swiper>					
				</div>
				<div className="dots mobile-container" style={{justifyContent: 'center'}}>
					<span style={{backgroundColor: comoFuncionaPosition === 0 ? '#EBB013' : 'white'}}></span>
					<span style={{backgroundColor: comoFuncionaPosition === 1 ? '#EBB013' : 'white'}}></span>
					<span style={{backgroundColor: comoFuncionaPosition === 2 ? '#EBB013' : 'white'}}></span>
					<span style={{backgroundColor: comoFuncionaPosition === 3 ? '#EBB013' : 'white'}}></span>
					<span style={{backgroundColor: comoFuncionaPosition === 4 ? '#EBB013' : 'white'}}></span>
				</div>
				<ButtonLadingPage
					funcao={() => {
						window.scrollTo(0, 0)
						history.push("/register")
						
					}}
					colorType="#EBB013"
					classeComplementar="button is-rounded"
					texto="Comece já a simplificar"
				/>

			</ComoFuncionaStyle>

			<FreteCalcForm style={{paddingBottom: showFreteResult === true ? '0px' : '3em'}} />
			{
				!showFreteResult === true ?
					(
						null
					) :
					(
						<FreteCalcResultado />
					)
			}
			
			<TransportadorasStyle>
				<h2>Veja nossos <span className="fundo-amarelo">principais parceiros:</span></h2>
				<section className="content desktop-container">
					<div className="img-container">
						<div className="entregador">
							<img src="entregador.png" className="image" alt="entregador"/>
						</div>
					</div>
					<div className="transportadoras">
						<div className="box-transportadoras">
							<picture className="logo">
								<img src="correios-logo.svg" alt="correios"/>
							</picture>
							<br/>

							<span>
								SEDEX
							</span>
							<p>
							Entrega expressa de mercadorias e documentos
							</p>
							<span>
								PAC
							</span>
							<p>
							Entrega expressa de mercadorias e documentos
							</p>
							<span>
								MINI ENVIOS
							</span>

							<p>
							Encomendas até 350g sem urgência no prazo de entrega
							</p>
						</div>
						<div className="box-transportadoras">
							<picture className="logo logo-jadlog">
								<img src="jadlog2.png" alt="jadlog"/>
							</picture>
							<br/>
							<span>
								.COM
							</span>
							<p>
							Entrega rápida de produtos vendidos online até 30kg
							</p>

							<span>
							PACKAGE
							</span>
							<p>
							Pequenos volumes B2C e B2B com prazos rodoviários.

							</p>
						</div>
					</div>
				</section>

				<div className="img-container mobile-container">
					<div style={{display: 'flex', flexDirection: 'column', marginLeft: '2em'}}>
						<picture className="logo-v2-mobile" style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)', borderRadius:'10px', marginRight: '1em'}}>
							<img src="correios-logo.svg" alt="correios"/>
						</picture>
						<picture className="logo-jadlog-v2-mobile"  style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)', borderRadius:'10px', marginTop: '2em', marginRight: '1em'}}>
							<img src="jadlog2.png" alt="jadlog"/>
						</picture>
					</div>
					<div className="entregador">
						<img src="entregador.png" className="image" alt="entregador"/>
					</div>
				</div>
				<section className="content mobile-container">
					<div className="transportadoras">
					<Swiper
					pagination={true}
					modules={[Pagination]} 
					onSlideChange={(swiper) => setTransportadoras(swiper.activeIndex)}
					// onSwiper={(swiper) => console.log(swiper)}
				>
					<SwiperSlide>
					<div className="box-transportadoras-mobile" >
							<picture className="logo logo-mobile">
								<img src="correios-logo.svg" alt="correios"/>
							</picture>
							<br/>

							<span>
								SEDEX
							</span>
							<p>
							Entrega expressa de mercadorias e documentos
							</p>
							<span>
								PAC
							</span>
							<p>
							Entrega expressa de mercadorias e documentos
							</p>
							<span>
								MINI ENVIOS
							</span>

							<p>
							Encomendas até 350g sem urgência no prazo de entrega
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide>
					<div className="box-transportadoras-mobile">
							<picture className="logo logo-jadlog logo-jadlog-mobile">
								<img src="jadlog2.png" alt="jadlog"/>
							</picture>
							<br/>
							<span>
								.COM
							</span>
							<p>
							Entrega rápida de produtos vendidos online até 30kg
							</p>

							<span>
							PACKAGE
							</span>
							<p>
							Pequenos volumes B2C e B2B com prazos rodoviários.

							</p>
						</div>
					</SwiperSlide>
				</Swiper>
					</div>
				</section>

				<div className="dots mobile-container" style={{justifyContent: 'center'}}>
					<span style={{backgroundColor: transportadoras === 0 ? '#EBB013' : 'black'}}></span>
					<span style={{backgroundColor: transportadoras === 1 ? '#EBB013' : 'black'}}></span>
				</div>

				<h3>Utilize as duas maiores transportadoras do Brasil.</h3>
				<ButtonLadingPage
					funcao={() => {history.push("/register")}}
					colorType="#792783"
					classeComplementar="button is-rounded"
					texto="Economize agora"
				/>
			</TransportadorasStyle>
			
			<CepStyle>
				<section className="text">
					<p>
						Conheça uma rede com
					</p>
					<div style={{textAlign: 'left'}}>
						<span>
							+ de 10 mil pontos de coleta
						</span>
					</div>
					<p>
						para despachar seus pacotes!
					</p>
				</section>
				<section className="imagens">
					<picture className="flex-frete-logo-bg desktop">
							<img src="/logo-bg.png" className="image" alt="simbolo-landing-page" />
					</picture>		
					<picture className="logo">
						<img src="mulher-entregas.png" className="image" alt="mulher com varias entregas"/>
					</picture>
				</section>
			</CepStyle>

			<PropagandaStyle>

				<div className="imagem">
					<picture className="logo">
						<img src="mulher-celular.png" alt="mulher segurando um celular"/>
					</picture>
				</div>			
				<div className="content">
					<div className="content-text">
						<span className="text text-propaganda">Sem burocracia,</span>
						<span className="text text-propaganda">sem mensalidade</span>
						<span className="text text-propaganda">e sem complicação!</span>
					</div>
					<ul>
						<li>Você não paga mensalidade;</li>
						<li>Pode começar quando quiser;</li>
						<li>Pode aumentar seus limites;</li>
						<li>Basta se cadastrar e começar a aproveitar.</li>
					</ul>
					<ButtonLadingPage
						funcao={() => {history.push("/register")}}
						colorType="#792783"
						classeComplementar="button is-rounded"
						texto="Cadastre-se e economize já"
					/>
				</div>
			</PropagandaStyle>

			<PropagandaCelularStyle>
				<section className="text">
					<div className="primeiraParte">
						<p className="text-inicio">
						Controle total dos seus fretes
						</p>
						<p className="text-inicio">
						na palma da mão
						</p>
					</div>
					<div className="segundaParte">
						<span>
						Acompanhe o <strong>fluxo de entregas</strong> e gerencie
						</span>
						<span>
						<br/>
						<strong>créditos</strong> online, <strong>sem pagar nenhuma taxa.</strong>
						</span>
					</div>
					<div className="terceiraParte">
						<p className="text-fim">
						É só fazer seu cadastro gratuito!
						</p>

						<section className="imagens mobile-container margin">	
							<picture className="logo">
								<img src="celular.png" className="image" alt="celular área logada flexfrete"/>
							</picture>
						</section>
						<div className="btn-container">
							<ButtonLadingPage
								funcao={() => {history.push("/register")}}
								colorType="#792783"
								classeComplementar="button is-rounded"
								texto="Cadastre-se grátis agora"
							/>
						</div>
					</div>
				</section>
				<section className="imagens desktop-container">	
					<picture className="logo">
						<img src="celular.png" className="image" alt="celular área logada flexfrete"/>
					</picture>
				</section>
			</PropagandaCelularStyle>

			<DepoimentoStyle>
				<picture className="flex-frete-logo-bg">
					<img src="/aspas.png" alt="aspas" />
				</picture>
				<div className="conteudo">
					<section className="titulo">
						<h3>
						Pequenos e médios empreendedores já
						</h3>
						<h3>
						estão <strong>economizando e simplificando</strong>
						</h3>
						<h3>
						com a Flex Frete.
						</h3>
					</section>
					
					<p className="confira">Confira:</p>
					<div className="container-depoimento desktop-container">
						<section className="card card-esquerda card-depoimento">
							<picture className="flex-frete-aspas">
								<img src="/aspas-dois.png" alt="aspas-amarelo" />
							</picture>
							<div className="text-card">
								<p>
								Agora não preciso mais perder tempo indo longe para despachar minhas vendas. 
								A Flex Frete tem sempre um ponto de coleta perto e isso facilita demais a minha logística.
								</p>
								<span>
								Bruno Coutinho
								</span>
								<p style={{marginLeft: '16px', color: '#6B7280'}}>
								3D Ink
								</p>
							</div>
						</section>
						<div className="flex">
							<section className="card card-direita-top card-depoimento">
								<picture className="flex-frete-aspas">
									<img src="/aspas-dois.png" alt="aspas-amarelo" />
								</picture>
								<div className="text-card">
									<p>
									O serviço online da Flex Frete é muito fácil de usar. Em poucos minutos eu faço a cotação e imprimo minha etiqueta. 
									Fica muito organizado e eu consigo acompanhar tudo.
									</p>
									<span>
									Luidac Fonseca
									</span>
									<p style={{marginLeft: '16px', color: '#6B7280'}}>
									Cantinho Animal
									</p>
								</div>
							</section>

							<section className="card card-direita-botton card-depoimento">
								<picture className="flex-frete-aspas">
									<img src="/aspas-dois.png" alt="aspas-amarelo" />
								</picture>
								<div className="text-card">
									<p>
									Na Flex Frete encontrei um preço mais vantajoso do que direto com transportadoras, e um custo-benefício perfeito para o meu negócio. 
									Consegui otimizar muito o valor das minhas entregas.
									</p>
									<span>
									Luiza Alves
									</span>
									<p style={{marginLeft: '16px', color: '#6B7280'}}>
									Opção Festas
									</p>
								</div>
							</section>
						</div>
					</div>

					<div className="container-depoimento mobile-container">
						
					<Swiper
					pagination={true}
					modules={[Pagination]} 
					onSlideChange={(swiper) => setDepos(swiper.activeIndex)}
					spaceBetween={50}
				>
					<SwiperSlide>
					<section className="card">
							<picture className="flex-frete-aspas">
								<img src="/aspas-dois.png" alt="aspas-amarelo" />
							</picture>
							<div className="text-card">
								<p>
								Agora não preciso mais perder tempo indo longe para despachar minhas vendas. 
								A Flex Frete tem sempre um ponto de coleta perto e isso facilita demais a minha logística.
								</p>
								<span>
								Bruno Coutinho
								</span>
								<p style={{marginLeft: '16px', color: '#6B7280'}}>
								3D Ink
								</p>
							</div>
						</section>
					</SwiperSlide>
					<SwiperSlide>
					<section className="card">
								<picture className="flex-frete-aspas">
									<img src="/aspas-dois.png" alt="aspas-amarelo" />
								</picture>
								<div className="text-card">
									<p>
									O serviço online da Flex Frete é muito fácil de usar. Em poucos minutos eu faço a cotação e imprimo minha etiqueta. 
									Fica muito organizado e eu consigo acompanhar tudo.
									</p>
									<span>
									Luidac Fonseca
									</span>
									<p style={{marginLeft: '16px', color: '#6B7280'}}>
									Cantinho Animal
									</p>
								</div>
							</section>
					</SwiperSlide>
					<SwiperSlide >
					<section className="card">
								<picture className="flex-frete-aspas">
									<img src="/aspas-dois.png" alt="aspas-amarelo" />
								</picture>
								<div className="text-card">
									<p>
									Na Flex Frete encontrei um preço mais vantajoso do que direto com transportadoras, e um custo-benefício perfeito para o meu negócio. 
									Consegui otimizar muito o valor das minhas entregas.
									</p>
									<span>
									Luiza Alves
									</span>
									<p style={{marginLeft: '16px', color: '#6B7280'}}>
									Opção Festas
									</p>
								</div>
							</section>
					</SwiperSlide>
				</Swiper>
					</div>
					<div className="dots mobile-container" style={{justifyContent: 'center'}}>
						<span style={{backgroundColor: depos === 0 ? '#EBB013' : 'white'}}></span>
						<span style={{backgroundColor: depos === 1 ? '#EBB013' : 'white'}}></span>
						<span style={{backgroundColor: depos === 2 ? '#EBB013' : 'white'}}></span>
					</div>
					<div className="footer-depoimento-mobile">
						<p className="investir">Comece você também a aproveitar essas vantagens!</p>
						<div>
							<ButtonLadingPage
								funcao={() => {history.push("/register")}}
								colorType="#EBB013"
								classeComplementar="button is-rounded"
								texto="Quero economizar e simplificar"
							/>
						</div>
					</div>
					
				</div>	
				
			</DepoimentoStyle>

			<FAQStyle>
				<div className="titulo">					
					<h3>A Flex Frete nasceu para ajudar</h3>
					<h3>pequenos e médios empreendedores.</h3>
				</div>
				
				<div className="text-fundo-amarelo">
					<span>O que a Flex Frete faz por você?</span>
				</div>
				
				<div className="desktop-container">

					<div className="flex justify margin">
						<div className="item">
							<div className="logo-item">
								<picture className="logo">
									<img src="carro-entrega.png"  alt="carro entrega"/>
								</picture>
							</div>
							
							<p>
							<strong>Praticidade e agilidade</strong> para cotar e contratar.
							</p>
						</div>

						<div className="item">
							<div className="logo-item">
								<picture className="logo">
									<img src="sem-custo.png"  alt="sem custo"/>
								</picture>
							</div>
							
							<p>
							Tarifas até <strong>75% mais baratas.</strong>
							</p>
						</div>

						<div className="item">
							<div className="logo-item">
								<picture className="logo">
									<img src="sem-pagamento.png"  alt="sem pagamento"/>
								</picture>
							</div>
							
							<p>
							<strong>+ de 10 mil pontos de coleta</strong> para despachar suas encomendas.
							</p>
						</div>
						
						<div className="item ">
							<div className="logo-item">
								<picture className="logo">
									<img src="localizacao.png"  alt="localização"/>
								</picture>
							</div>
							
							<p>
							Acompanhamento das entregas e gerenciamento de créditos <strong>100% online, num só lugar.</strong>
							</p>
						</div>
					</div>
				</div>

				<div className="mobile-container">
				<div className="flex justify margin" style={{flexDirection: 'column'}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<div className="item">
							<div className="logo-item">
								<picture className="logo">
									<img src="carro-entrega.png"  alt="carro entrega"/>
								</picture>
							</div>
							
							<p>
							<strong>Praticidade e agilidade</strong> para cotar e contratar.
							</p>
						</div>

						<div className="item">
							<div className="logo-item">
								<picture className="logo">
									<img src="sem-custo.png"  alt="sem custo"/>
								</picture>
							</div>
							
							<p>
							Tarifas até <strong>75% mais baratas.</strong>
							</p>
						</div>
					</div>

					<div style={{display: 'flex', marginTop: '2em'}}>
						<div className="item">
							<div className="logo-item">
								<picture className="logo">
									<img src="sem-pagamento.png"  alt="sem pagamento"/>
								</picture>
							</div>
							
							<p>
							<strong>+ de 10 mil pontos de coleta</strong> para despachar suas encomendas.
							</p>
						</div>
						
						<div className="item ">
							<div className="logo-item">
								<picture className="logo">
									<img src="localizacao.png"  alt="localização"/>
								</picture>
							</div>
							
							<p>
							Acompanhamento das entregas e gerenciamento de créditos <strong>100% online, num só lugar.</strong>
							</p>
						</div>
					</div>

				</div>
				</div>
				<div>
					<ButtonLadingPage
						funcao={() => {history.push("/register")}}
						colorType="#792783"
						classeComplementar="button is-rounded"
						texto="Comece já a usar"
					/>
				</div>
				<div className="desktop-container">

					<div className="flex pagamentos">
						
						<img src="homem-celular.png" className="image" alt="homem segurando celular e sorrindo"/>
						
					
						<div className="flex-column box-pagamento">
							<h2>
							Facilitamos o seu pagamento.
							</h2>
							<p>
							Escolha entre várias opções que disponibilizamos:
							</p>
							<div className="box-item-pagamento">
								<span>
								CRÉDITO
								</span>
								<hr/>
								<div className="box-itens">
									<picture className="logo">
										<img src="visa.png" alt="Visa"/>
									</picture>

									<picture className="logo">
										<img src="mastercard.png" alt="Mastercard"/>
									</picture>

									<picture className="logo">
										<img src="american-express.png" alt="American Express"/>
									</picture>

									<picture className="logo">
										<img src="elo.png" alt="Elo"/>
									</picture>
								</div>

								<div className="box-itens" style={{paddingTop: '2em'}}>
									<picture className="logo">
										<img src="jcb.png" alt="Jcb"/>
									</picture>

									<picture className="logo">
										<img src="discover.png" alt="Discover"/>
									</picture>

									<picture className="logo">
										<img src="hipercard.png" alt="Hipercard"/>
									</picture>

									<picture className="logo">
										<img src="aura.png" alt="Aura"/>
									</picture>
								</div>
							</div>
							
							<div className="box-item-pagamento">
								<span>
								Boleto e pix
								</span>
								<hr/>
								<div className="box-itens">
									<picture className="logo">
										<img src="boleto.png" alt="Boleto"/>
									</picture>

									<picture className="logo">
										<img src="pix.png" alt="Pix"/>
									</picture>
								</div>
							</div>
						</div>
						
						<img src="mulher-com-celular.png" className="image" alt="mulher segurando celular e sorrindo"/>
						
					</div>
				</div>
				
				<div className="mobile-container pagamentos" style={{flexDirection: 'column'}}>

					<div style={{display: 'flex'}}>
						<div>
							<img src="homem-celular.png" className="image image-mobile" alt="homem segurando celular e sorrindo"/>
						</div>
						<div className="flex-column box-pagamento" style={{width: '150%'}}>
						<h2 style={{paddingRight: '0.4em'}}>
						Facilitamos o seu pagamento.
						</h2>
						<p style={{paddingRight: '0.4em'}}>
						Escolha entre várias opções que disponibilizamos:
						</p>
						</div>
					</div>
					
					
				
					<div className="flex-column box-pagamento mobile-position">
						<div className="box-item-pagamento">
							<span>
							CRÉDITO
							</span>
							<hr/>
							<div className="box-itens">
								<picture className="logo">
									<img src="visa.png" alt="Visa"/>
								</picture>

								<picture className="logo">
									<img src="mastercard.png" alt="Mastercard"/>
								</picture>

								<picture className="logo">
									<img src="american-express.png" alt="American Express"/>
								</picture>

								<picture className="logo">
									<img src="elo.png" alt="Elo"/>
								</picture>
							</div>

							<div className="box-itens" style={{paddingTop: '2em'}}>
								<picture className="logo">
									<img src="jcb.png" alt="Jcb"/>
								</picture>

								<picture className="logo">
									<img src="discover.png" alt="Discover"/>
								</picture>

								<picture className="logo">
									<img src="hipercard.png" alt="Hipercard"/>
								</picture>

								<picture className="logo">
									<img src="aura.png" alt="Aura"/>
								</picture>
							</div>
						</div>
						
						<div className="box-item-pagamento">
							<span>
							Boleto e pix
							</span>
							<hr/>
							<div className="box-itens">
								<picture className="logo">
									<img src="boleto.png" alt="Boleto"/>
								</picture>

								<picture className="logo">
									<img src="pix.png" alt="Pix"/>
								</picture>
							</div>
						</div>
					</div>		
				</div>
			</FAQStyle>
				
			<QuemSomosStyle>
				<picture>
					<img src="embalando.png" className="image" alt="Embalando"/>
				</picture>
				
				<div className="flex">
					<div>
						<span>Venha para</span>
					</div>
					<br/>
					<div>
						<span>a Flex Frete!</span>
					</div>

					<div className="texto-meio">
						<p>
						Traga mais <strong>agilidade</strong>, <strong>facilidade</strong> e <strong>economia</strong> para sua vida e seu negócio.
						</p>
					</div>

					<div className="btn">
						<ButtonLadingPage
							funcao={() => {history.push("/register")}}
							colorType="#792783"
							classeComplementar="button is-rounded"
							texto="Cadastre-se Agora"
						/>
					</div>
				</div>
				
			</QuemSomosStyle>
			
			
		</section>
	);
}

export default LandingPage;
