import styled from "styled-components";

export const CarteiraContainerStyle = styled.section`
	padding-top: 40px;


@media (max-width: 2560px) {
	margin-left: 20%;
	margin-right: 5%;
}

@media (max-width: 1440px) {
	margin-left: 25%;
	margin-right: 3%;
}

@media (max-width: 1024px) {
	margin-left: 15%;
	margin-right: 15%;
}

@media (max-width: 770px) {
	margin-left: 13%;
	margin-right: 5%;
}

@media (max-width: 430px) {
	margin-left: 18%;
	margin-right: 0%;
}

`;