import styled from "styled-components";

export const FormStyle = styled.form`
    width: 80%;
    max-width: 800px;
`;

export const CalculadoraComplementarForm = styled.div`
    margin-top: 40px;
    background-color: #fff;
    /* width: 80%;
    max-width: 800px; */
    border-radius: 10px;
    padding: 30px;
    .form-title{
        text-align: left;
    }
    label{
        font-size: 1.3rem;
        font-weight: 400;
        text-align: left;
    }

    .declaracao-pergunta{
        text-align: left;
        margin-bottom: 15px;
    }

    .declaracao{
        margin-left: 30px;

        div{
            text-align: left;
        }

        .radio {
            min-width: 20px;
            min-height: 20px;
            margin: 0px 10px 20px 0px;
        }

        .nota-fiscal-container{
            margin-left: 30px;

            .nota-fiscal{
                align-items: flex-end;
                justify-content: normal;
                margin: 5px 0px 20px 0px;

                p{
                    color: gray;
                    font-size: 12px;
                    margin 7px;
                }
            }

        }

        .declaracao-conteudo{
            margin-left: 30px;

            textarea{
                height: 61px;
                resize: none;
                padding 7px;
            }
            p{
              color: gray;
              font-size: 12px;
            }
        }

        .declaracao-disable{
            color: gray;

            .input-disable{
                min-width: 20px;
                min-height: 20px;
                margin-right: 10px;
            }

            p{
                font-size: 80%;
            }
        }
       
    }

    .valores-declaracao{
        text-align: left;
        margin-bottom: 40px;
        span{
            color: #009f57;
            font-weight: 600;
        }
        .valorTotalWarning{
            color: #e6b222;
        }
    }

    @media (max-width: 2560px) {
        .section-half{
            display: flex;
            justify-content: space-between;
        }

        .section-half > div:first-child{
            margin-right: 2em;
            flex-grow: 1;
        }

        .section-half > div:last-child{
            flex-grow: 1;
        }
    }

    @media (max-width: 1024px) {
    
    }

    @media (max-width: 770px) {
        .section-half{
            flex-direction: column;
        }

        .section-half > div:first-child{
            margin-right: 0;
        }
    }
    /* @media(max-width: 600px){
        
    } */
`

export const BtnSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button{
        font-size: 1.6rem;
        ion-icon{
            margin-right: 10px;
        }
    }

    .alterar-transportadora{
        border: none;
        background-color: transparent;
        color: #812990;
		font-weight: 600;
        letter-spacing: 0.3px;
        display: flex;
        align-items: center;
    }

    .add-carrinho{
        margin-top: 20px;
		/*
		margin-bottom: 20px; */
		background-color: white;
		color: #812990;
		font-weight: 600;
		letter-spacing: 0.3px;
	}

	.add-carrinho:hover{
  		background: rgba(255, 255, 255, 0.7);
	}

	.finalizar-pagamento{
        margin-top: 10px;
		/*
		margin-bottom: 20px; */
		background: rgb(131, 43, 146);
		background: linear-gradient(
			267deg,
			rgba(131, 43, 146, 1) 39%,
			rgba(160, 69, 176, 1) 100%
		);
		color: white;
		font-weight: 600;
		letter-spacing: 0.3px;
		border: none;
        padding-left: 2em;
        padding-right: 2em;
	}

	.finalizar-pagamento:hover{
		background: #c986d8;
		color: white;
	}

`